import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { deletePaymentMethod } from '../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import './deletepayment.scss';

const DeletePayment = (props) => {
    const navigate = useNavigate();

    const handleClose = () => {
        props.handleDelClose();
    }

    const handleDelAccount = async () => {
        try {
            let resp = await deletePaymentMethod({
                payment_method_id: props?.data
            });
            if (resp) {
                toast.success('Payment method removed successfully');
                window?.location?.reload();
            }
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occured while removing a payment method');
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='delete-payment-wrapper'
            show={props.show}
            onHide={handleClose}
            centered>
            <Modal.Header>
                <Modal.Title className="modalTitle">Delete Payment Method</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <div className="del-sub">
                    Are you sure you want to Delete Payment Method? This cannot be undone.
                </div>
                <div className='del-buttons-wrapper w-100'>
                    <Button
                        onClick={() => handleDelAccount()}
                        className="delBtn w-100">
                        Delete
                    </Button>
                    <Button className="cancelBtn w-100" onClick={handleClose}>
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DeletePayment;
