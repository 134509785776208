import React, { useState, useEffect } from "react";
import Navbar from "../../CustomComponents/Navbar";
import './styles.scss';
import { getUserProfile, getMyLeagues, joinLeague, fetchExploreLeagues, fetchPastRounds, fetchUpcomingRounds, fetchRecLeaguesPagination } from '../../api/request';
import { formatRoundsDate } from '../../utils/formatRoundsDate.js';
import { formatTime } from '../../utils/formatTime.js';
import {formatPrize} from '../../utils/formatPrize.js';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import svg from '../../assets/images/svg/index';
import Loader from '../../CustomComponents/Loader';
import SubscriptionPayment from './SubscriptionPayment.jsx';
import { formatNumberAsOrdinal } from '../../utils/formatNumberAsOrdinal';
import CurrentSeasonPopup from '../UserOnboarding/CurrentSeasonPopup';
import './styles.scss';
import { Button, Tab, Tabs } from "react-bootstrap";
import ErrorMsg from '../../CustomComponents/ErrorMsg';
import BottomTooltip from '../UserOnboarding/BottomTooltip';
import GameScheduling from '../GameScheduling';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const stripePromise = loadStripe(
    "pk_test_51N8OMlFdHTQtW0piO3f6beyjQy8pR3FQltBK5pAIts4sK3namjf9Bv2aVZ2AKdx5FeSER5BLvIh3oT3LBnndxV4k00120xHlb7"
);

const UserDashboard = () => {
    const navigate = useNavigate();

    localStorage?.removeItem('holeIndex')
    localStorage?.removeItem('roundId')
    const [loading, setLoading] = useState(false);
    const golfCourseImages = [svg?.RoundsDef1, svg?.RoundsDef2, svg?.RoundsDef3, svg?.RoundsDef4];

    const [price, setPrice] = useState(null)
    const [showCurrSeason, setShowCurrSeason] = useState(false);
    const [showEndDate, setEndDate] = useState('');
    const [showStartDate, setStartDate] = useState('');
    const [showNextSeason, setNextSeason] = useState(false);
    const [selData, setselData] = useState({});
    const [recLeague, setRecLeague] = useState(false);
    const [cardDetails, setCardDetails] = useState(null);
    const [userProfilePic, setuserProfilePic] = useState('');
    const [pastRounds, setPastRounds] = useState([]);
    const [upcomingRounds, setUpcomingRounds] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [activeTab, setActiveTab] = useState('upcomingRounds');
    const [userData, setUserData] = useState({});
    const [myLeaguesData, setMyLeagues] = useState([]);
    const [getCoverImage, setCoverImage] = useState('');
    const [selLeagueId, setSelLeagueId] = useState(0);
    const [userCreditBal, setUserCreditBal] = useState(null);
    const [leagueId, setleagueId] = useState(null);
    const [leagueData, setLeagueData] = useState([]);
    const [leagueDataResp, setLeagueDataResp] = useState({});
    const [getNext, setNext] = useState('');
    const [subPayment, setSubPayment] = useState(false);
    const [showLinx, setShowLinx] = useState(false);
    const [getshowscheduleRound, setshowscheduleRound] = useState(false);
    const [getPrize, setPrize] = useState(null);
    const [getLinxScore, setLinxScore] = useState(0);
    const [getRanking, setRanking] = useState(null);
    const [getRoundsCount, setRoundsCount] = useState(0);

    const handleTabSelect = (selectedTab) => {
        setActiveTab(selectedTab);
    };

    const handleMyLeague = (data) => {
        setCoverImage(data?.league?.cover_image);
        setSelLeagueId(data?.id);
        setPrize(data?.prize);
        setLinxScore(data?.linx_score);
        setRanking(data?.ranking);
        setRoundsCount(data?.round_count);
    }
    useEffect(() => {
        // fetchRecords(); 
        if(getNext!==null)       
        fetchRecommendedLeagues();
    }, [page]);

    const fetchRecords = () => {
        console.log('page number', page);
        // Simulated API response for this example
        // const simulatedApiResponse = { data: [], hasMore: true };
        // for (let i = 1; i <= 10; i++) {
        //   simulatedApiResponse.data.push({ id: i, name: `Record ${i}` });
        // }

        // setRecords([...records, ...simulatedApiResponse.data]);

        // // Update 'hasMore' based on the number of records
        // setHasMore(records.length > 5 && simulatedApiResponse.hasMore);
    };

    const loadMore = () => {
        if (getNext !== null) {
            setPage(page + 1);
        }
    };

    const handleJoinLeague = async (data) => {
        if (Number(userCreditBal) < Number(data?.season?.price)) {
            setleagueId(data?.id);
            setLeagueDataResp(data);
            setSubPayment(true);
        }
        if (Number(userCreditBal) >= Number(data?.season?.price)) {
            let resp = await joinLeague({
                "tier_id": Number(data?.id)
            });
            window.location.href = '/home';
            toast.success(resp?.message || 'League joined successfully');
        }
    }

    const handleClosePopup = () => {
        setShowCurrSeason(false);
        setRecLeague(false);
        setNextSeason(false);
    }

    const handleCloseSubPayment = () => {
        setSubPayment(false);
        setShowLinx(false);
    }

    useEffect(() => {
        // Fetch card details when the component mounts
        fetchUserDetails();
        fetchMyLeagues();
        fetchPreviousRoundsListing();
        fetchUpcomingRoundsListing();
    }, []);

    const fetchPreviousRoundsListing = async () => {
        try {
            let resp = await fetchPastRounds();
            setPastRounds(resp);
        }
        catch (err) {
            if (err?.response?.data?.detail === "Invalid token.") {
                toast.error(err?.response?.data?.detail || 'An error occurred while fetching previous rounds details.')
                navigate('/login');
            }
            else {
                toast.error(err?.response?.data?.message || 'An error occurred while fetching previous rounds details.')
            }
        }
    }

    const fetchUpcomingRoundsListing = async () => {
        setLoading(true)
        try {
            let resp = await fetchUpcomingRounds();
            setUpcomingRounds(resp);
            setLoading(false)
        }
        catch (err) {
            if (err?.response?.data?.detail === "Invalid token.") {
                toast.error(err?.response?.data?.detail || 'An error occurred while fetching upcoming rounds.')
                navigate('/login');
            }
            else {
                toast.error(err?.response?.data?.message || 'An error occurred while fetching upcoming rounds.')
            }
            setLoading(false)
        }
    }

    const fetchRecommendedLeagues = async () => {
        console.log('page number in fetch recommended leagues', page);
        try {
            let resp = await fetchRecLeaguesPagination(page);
            console.log('resp from fetchRecLeaguesPagination', resp);
            // let allData= [...leagueData, resp?.results];
            // console.log('allData', allData[0]);
            // setLeagueData(allData[0]);
            setLeagueData([...leagueData, ...resp?.results]);
            // setLeagueData((prevData) => [...prevData, ...resp?.results]);
            setNext(resp?.next);
        }
        catch (err) {
            if (err?.response?.data?.detail === "Invalid token.") {
                toast.error(err?.response?.data?.detail || 'An error occurred while fetching explore leagues.')
                navigate('/login');
            }
            else {
                toast.error(err?.response?.data?.message || 'An error occurred while fetching explore leagues.')
            }
        }
    }

    const fetchUserDetails = async () => {
        try {
            let resp = await getUserProfile();
            setUserData(resp);
            setuserProfilePic(resp?.profile_image);
            setUserCreditBal(resp?.credit_balance);
        }
        catch (err) {
            if (err?.response?.data?.detail === "Invalid token.") {
                toast.error(err?.response?.data?.detail || 'An error occurred while fetching user details.')
                navigate('/login');
            }
            else {
                toast.error(err?.response?.data?.message || 'An error occurred while fetching user details.')
            }
        }
    }


    const fetchMyLeagues = async () => {
        try {
            let resp = await getMyLeagues();
            setMyLeagues(resp);
            setSelLeagueId(resp[0]?.id)
            setCoverImage(resp[0]?.league?.cover_image);
            setPrize(resp[0]?.prize);
            setLinxScore(resp[0]?.linx_score);
            setRanking(resp[0]?.ranking);
            setRoundsCount(resp[0]?.round_count);
        }
        catch (err) {
            if (err?.response?.data?.detail === "Invalid token.") {
                toast.error(err?.response?.data?.detail || 'An error occurred while fetching user leagues.')
                navigate('/login');
            }
            else {
                toast.error(err?.response?.data?.message || 'An error occurred while fetching user leagues.')
            }
        }
    }

    return (
        <>
            <Navbar />
            <div className="UserDashboard-Wrapper">
                <div className={getCoverImage ? "league-img" : "def-league-img"}>
                    <img src={getCoverImage || svg?.UserDashboardDefault} className="def-img w-100 h-100" alt="def img NP" />
                </div>

                <div className="dashboard-body-wrapper">
                    <div className="user-league-score-wrapper">
                        <div className="user-data">
                            <div className="user-profile">
                                <img src={userData?.profile_image || svg?.DefUser} className="user-img" alt="user img NP" />
                            </div>

                            <div className="user-details">
                                <div className="u-name">{userData?.user?.name}</div>
                                <div className="user-handicap-attest-wrapper">
                                    <div className="user-handicap">
                                        H.I.™: {userData?.user?.hc_index}
                                    </div>
                                    <div className="user-attest">
                                        Attest %: {userData?.user?.attest_rate}
                                    </div>
                                </div>
                            </div>
                            <BottomTooltip tooltipText="Your Attest Rate is a calculation of how much of your play is confirmed by another Linx League Member. This follows you for your entire Linx Career, so find some friends and hit the Linx!" className='user-dashboard-tooltip' />
                        </div>
                        <div className="league-score-data">
                            <div className="league-score">
                                <div className="league-score-val">{getLinxScore}</div>
                                <div className="league-score-txt">Linx Score</div>
                            </div>
                            <div className="league-score">
                                <div className="league-score-val">{getRanking}
                                    <span className="score-ordinal">{getRanking !== null && formatNumberAsOrdinal(getRanking)}</span>
                                </div>
                                <div className="league-score-txt">Ranking</div>
                            </div>
                            <div className="league-score">
                                <div className="league-score-val">{getRoundsCount}/6</div>
                                <div className="league-score-txt">Rounds</div>
                            </div>
                            <div className="league-score">
                                <div className="league-score-val">${formatPrize(Math.floor(Number(getPrize)))}</div>
                                <div className="league-score-txt">Prize value</div>
                            </div>
                        </div>
                    </div>
                    <>{loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" /> :
                        <div className="rec-league-rounds-wrapper">
                            <div className="rec-leagues-data">
                                <div className="leagues-data">
                                    <div className="league-heading">My Leagues</div>
                                    <div className="league-listing">
                                        {
                                            myLeaguesData?.length > 0 ?
                                                myLeaguesData?.map((data, index) => (
                                                    <div className={Number(selLeagueId) === Number(data?.id) ? "active-league-item cursor-pointer" : "league-item cursor-pointer"} onClick={() => {
                                                        handleMyLeague(data)
                                                    }}>{data?.name} &nbsp; {data?.league?.name}</div>
                                                ))
                                                :
                                                <div className="no-leagues-found">Sign up for a league and get your shot at cash & prizes!</div>
                                        }
                                    </div>
                                </div>
                                <div className="rec-data mt-3">
                                    <div className="rec-heading">Recommended Leagues</div>
                                    <div className="rec-listing">
                                        {
                                            leagueData?.length > 0 ?
                                                (
                                                    <>
                                                        {
                                                            leagueData?.map((data, index) => (
                                                                <div className="rec-card">
                                                                    <div className="rec-card-data">
                                                                        <div className="rec-card-left-data">
                                                                            <div className="rec-league-name trimTxt1line">{data?.league?.name} {data?.season?.name}</div>
                                                                            <div className="prizes">Compete for prizes <>{Number(data?.prize) !== 0 ? <img src={svg?.RecPrizes} className="rec-prizes" alt="rec prizes NP" /> : null}</> </div>
                                                                        </div>
                                                                        <div className="rec-card-right-data">
                                                                            <div className="rec-handicap">({data?.handicap_from}-{data?.handicap_to} H.I.™)</div>
                                                                            <div className="rec-players">{data?.max_slots - data?.available_slots} {data?.max_slots - data?.available_slots > 0 ? 'players' : 'player'}</div>
                                                                        </div>
                                                                    </div>
                                                                    <Button className="join-league-btn" onClick={() => {
                                                                        setPrice(data?.season?.price)
                                                                        if (data?.league?.type === 1) {
                                                                            setRecLeague(false);
                                                                            if (data?.next_season?.id) {
                                                                                setNextSeason(true);
                                                                                setShowCurrSeason(false);
                                                                            }
                                                                            else {
                                                                                setNextSeason(false);
                                                                                setShowCurrSeason(true);
                                                                            }
                                                                        }
                                                                        else if (data?.league?.type === 2) {
                                                                            setRecLeague(true);
                                                                            if (data?.next_season?.id) {
                                                                                setNextSeason(false);
                                                                                setShowCurrSeason(false);
                                                                            }
                                                                            else {
                                                                                setNextSeason(false);
                                                                                setShowCurrSeason(false);
                                                                            }
                                                                        }
                                                                        setEndDate(data?.season?.end_date);
                                                                        setStartDate(data?.season?.start_date)
                                                                        setselData(data);
                                                                    }}>Join League</Button>
                                                                </div>
                                                            ))
                                                        }
                                                        <>{getNext !== null && (
                                                            <div onClick={() => loadMore()} disabled={!hasMore} className="cursor-poitner load-more">
                                                                {hasMore ? 'Load More' : 'No More Records'}
                                                            </div>
                                                        )}</>
                                                    </>
                                                )
                                                :
                                                <ErrorMsg errormsg={'No Records Exist'} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="rounds-data">
                                <div className="rounds-tabs-btn">
                                    <Tabs
                                        defaultActiveKey="upcomingRounds"
                                        id="uncontrolled-tab-example"
                                        className="rounds-data-tabs"
                                        onSelect={handleTabSelect}
                                    >
                                        <Tab eventKey="upcomingRounds" title="Upcoming Rounds">
                                            {
                                                upcomingRounds?.length > 0 &&
                                                <Button className="rounds-btn" onClick={() => {
                                                    setShowLinx(true);
                                                    setshowscheduleRound(false);
                                                }}>{
                                                        activeTab === 'pastRounds' ? 'Schedule a Round' : 'Hit the Linx'
                                                    }</Button>
                                            }
                                            <div className="upcoming-rounds-listing">
                                                {
                                                    upcomingRounds?.length > 0 ?
                                                        <>
                                                            {
                                                                upcomingRounds?.map((data, index) => {
                                                                    const randomImageIndex = Math.floor(Math.random() * golfCourseImages.length);
                                                                    const randomImage = golfCourseImages[randomImageIndex];
                                                                    return (
                                                                        <div className={data?.status === 'playing' ? "upcoming-rounds-card cursor-pointer activeCard" : "upcoming-rounds-card cursor-pointer"} onClick={() => navigate('/rounds', {
                                                                            state: {
                                                                                roundData: data,
                                                                                round: 'Upcoming Round',
                                                                                userData: userData
                                                                            }
                                                                        })}>
                                                                            <div className="up-rounds-data">
                                                                                <div className="up-rounds-sub-data">
                                                                                    <div className="up-rounds-img">
                                                                                        <img src={randomImage} className="up-round-img" alt="imge NP" />
                                                                                    </div>

                                                                                    <div className="up-round-score">{index + 1}</div>
                                                                                    <div className="up-round-details">
                                                                                        <div className="round-name trimTxt1line">
                                                                                            {data?.golf_course?.name}
                                                                                        </div>
                                                                                        <div className="round-date-time d-flex align-items-center">
                                                                                            <div className="round-city trimTxt1line">
                                                                                                {data?.golf_course?.city}, {data?.golf_course?.country}
                                                                                            </div>
                                                                                            <div className="up-round-date">{formatRoundsDate(data?.round_date)}</div>
                                                                                            <div className="up-round-time">{formatTime(data?.round_time)}</div>
                                                                                        </div>

                                                                                        <div className="up-round-users">
                                                                                            {
                                                                                                data?.players?.map((playerData, index) => (
                                                                                                    <img src={playerData?.profile_image || svg?.DefUser} className="up-rounds-users-img" alt="imgg Np" />
                                                                                                ))
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                {
                                                                                    data?.status === 'playing' &&
                                                                                    <div className="up-round-active">
                                                                                        Active
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    (data?.is_previous === true && data?.status !== 'playing') &&
                                                                                    <div className="up-round-active">
                                                                                        Past
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <div className="no-up-rounds">No Upcoming Rounds</div>
                                                            <Button className="rounds-btn posRel" onClick={() => {
                                                                setShowLinx(true);
                                                                setshowscheduleRound(false);
                                                            }}>{
                                                                    activeTab === 'pastRounds' ? 'Schedule a Round' : 'Hit the Linx'
                                                                }</Button>
                                                        </>
                                                }


                                            </div>
                                        </Tab>
                                        <Tab eventKey="pastRounds" title="Past Rounds">
                                            {
                                                pastRounds?.length > 0 &&
                                                <Button className="rounds-btn" onClick={() => {
                                                    setshowscheduleRound(true);
                                                    setShowLinx(true)
                                                }}>{
                                                        activeTab === 'pastRounds' ? 'Schedule a Round' : 'Hit the Linx'
                                                    }</Button>
                                            }
                                            <div className="upcoming-rounds-listing">
                                                {
                                                    pastRounds?.length > 0 ?
                                                        <>
                                                            {
                                                                pastRounds?.map((data, index) => {
                                                                    const randomImageIndex = Math.floor(Math.random() * golfCourseImages.length);
                                                                    const randomImage = golfCourseImages[randomImageIndex];
                                                                    return (<div className="upcoming-rounds-card cursor-pointer" onClick={() => navigate('/pastscorecard', {
                                                                        state: {
                                                                            roundId: data?.id,
                                                                            round: 'Past Round',
                                                                        }
                                                                    })}>
                                                                        <div className="up-rounds-data">
                                                                            <div className="up-rounds-sub-past-data">
                                                                                <div className="up-rounds-img">
                                                                                    <img src={randomImage} className="up-round-img" alt="imge NP" />
                                                                                </div>

                                                                                <div className="up-round-details">
                                                                                    <div className="round-name trimTxt1line">
                                                                                        {data?.golf_course?.name}
                                                                                    </div>
                                                                                    <div className="round-date-time">
                                                                                        <div className="round-city trimTxt1line">
                                                                                            {data?.golf_course?.city}, {data?.golf_course?.country}
                                                                                        </div>
                                                                                        <div className="up-round-date">{formatRoundsDate(data?.round_date)}</div>
                                                                                        <div className="up-round-time">{formatTime(data?.round_time)}</div>
                                                                                    </div>

                                                                                    <div className="up-round-users">
                                                                                        {
                                                                                            data?.players?.map((playerData, index) => (
                                                                                                <img src={playerData?.profile_image || svg?.DefUser} className="up-rounds-users-img" alt="imgg Np" />
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>)
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <div className="no-up-rounds">No Past Rounds</div>
                                                            <Button className="rounds-btn posRel" onClick={() => {
                                                                setshowscheduleRound(true);
                                                                setShowLinx(true)
                                                            }}>{
                                                                    activeTab === 'pastRounds' ? 'Schedule a Round' : 'Hit the Linx'
                                                                }</Button>
                                                        </>
                                                }
                                            </div>
                                        </Tab>
                                    </Tabs>

                                    {/* <Button className="rounds-btn">{
                                    activeTab === 'pastRounds' ? 'Schedule a Round' : 'Hit the Linx'
                                }</Button> */}
                                </div>
                            </div>
                        </div>
                    }</>
                </div>
            </div >
            {
                subPayment === true &&
                <SubscriptionPayment leagueId={leagueId} leagueData={leagueDataResp} userCreditBal={userCreditBal} showHide={subPayment} handleCloseSubPayment={handleCloseSubPayment} />
            }
            {
                showLinx === true &&
                <GameScheduling show={showLinx} handleLinxPopup={handleCloseSubPayment} showlinx={showLinx} getshowscheduleRound={getshowscheduleRound} userProfilePic={userProfilePic} />
            }
            {
                (showCurrSeason === true || showNextSeason === true) &&
                <CurrentSeasonPopup show={showCurrSeason || showNextSeason} handleClosePopup={handleClosePopup} showEndDate={showEndDate} nextSeason={showNextSeason} selData={selData} recLeague={recLeague} handleConfirm={handleJoinLeague} />
            }
            {
                recLeague === true &&
                <CurrentSeasonPopup show={recLeague} handleClosePopup={handleClosePopup} selData={selData} recLeague={recLeague} handleConfirm={handleJoinLeague} price={price} showStartDate={showStartDate} />
            }
        </>
    )
}

export default UserDashboard;