import React, { useState, useEffect } from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { Form, Row, Col, Image } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import svg from '../../../assets/images/svg/index'
import { fetchAllLeagues, fetchSelLeagueSeason, fetchSelSeasonTiers } from '../../../api/request';
import { toast } from 'react-toastify';
import './notifications.scss';

const AddNotifications = (props) => {
    const location = useLocation();
    const notiData = location?.state?.notiData;
    let editFlag = notiData?.length > 0 ? true : false;
    const navigate = useNavigate();
    const [tierError, setTierError] = useState(false);
    const [leagueVal, setLeagueVal] = useState({});
    const [leagueOptions, setleagueOptions] = useState([]);
    const [seasonOptions, setSeasonOptions] = useState([]);
    const [seasonVal, setSeasonVal] = useState({});
    const [tierOptions, setTierOptions] = useState([]);
    const [tierVal, setTierVal] = useState({});

    const schema = Yup.object().shape({
        name: Yup.string().required('Notification name is required'),
        description: Yup.string().required('Notification content is required'),
        startTime: Yup.string().required('Notification activation time is required'),
        startDate: Yup.date().required('Notification activation date is required'),
    });


    useEffect(() => {
        fetchLeagues();
    }, []);

    const fetchLeagues = async () => {
        try {
            let resp = await fetchAllLeagues();
            console.log('resp from fetchAllLeagues', resp);
            let temp = resp?.results;
            let arr = [];
            for (let i = 0; i < temp?.length; i++) {
                arr?.push({
                    label: temp[i]?.name,
                    value: temp[i]?.id
                })
            }
            setleagueOptions(arr);
        }
        catch (err) {
            console.log('error in fetching leagues data', err);
            toast.error(err?.response?.data?.message || 'An error occurred while processing your request.');
        }
    }

    const handleLeagueDropdown = async (event) => {
        setSeasonVal({});
        console.log('event in handle gender dropdown', event);
        setLeagueVal(event);
        try {
            let seasonResp = await fetchSelLeagueSeason({ id: event?.value });
            let seasonTemp = seasonResp;
            let seasonArr = [];
            for (let i = 0; i < seasonTemp?.length; i++) {
                seasonArr?.push({
                    label: seasonTemp[i]?.name,
                    value: seasonTemp[i]?.id
                })
            }
            setSeasonOptions(seasonArr);
            console.log('response from fetchSelLeagueSeason', seasonResp);
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occurred while fetching seasons');
        }
    }

    const handleSeasonDropdown = async (event) => {
        setSeasonVal(event);
        setTierVal({});
      
    }

    const handleTierDropdown = (event) => {
        setTierVal(event);
        setTierError(false);
    }

    return (
        <div className="AddNotifications-Wrapper adminpadding">
            <div className='admin-users-head-wrapper d-flex align-items-center' style={{ gap: '10px' }}>
                <img src={svg?.UserBack} className='back-arrow cursor-pointer' alt="backNP" onClick={() => navigate(-1)} />
                <div className="admin-noti-heading">
                    {editFlag===true ? 'Edit Notification' : 'Add New Notification'}
                </div>
            </div>

            <div className="add-noti-body">
                <Formik
                    validationSchema={schema}
                    initialValues={{ name: "", description: '', startDate: '', startTime: '' }}
                    onSubmit={async (values) => {
                        console.log('inside submit', values);
                        let body = {
                            league: leagueVal?.value,
                            season: seasonVal?.value,
                            tier: tierVal?.value,
                            name: values?.name,
                            content: values?.description,
                            notiDate: values?.startDate,
                            notiTime: values?.startTime
                        }
                        console.log('Add notifications body', body);
                    }}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        isValid,
                        dirty,
                        /* and other goodies */
                    }) => (
                        <form
                            className="create-noti-form w-100"
                            onSubmit={handleSubmit}
                            noValidate>
                            <div className="Auth-form-content w-100">
                                <Form.Group controlId="name" className="w-75">
                                    <label htmlFor="name" className="greyLabel notigreyLabel">
                                        Notification name
                                    </label>
                                    <div
                                        className={`form-floating mt-2 mb-3 ${touched.name && errors.name
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            type="name"
                                            name="name"
                                            // placeholder="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-100"
                                            isInvalid={touched.name && errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>

                                <Form.Group controlId='description' className="w-75">
                                    <label htmlFor='description' className="greyLabel notigreyLabel">
                                        Notification content
                                    </label>
                                    <div
                                        className={`form-floating mt-2 mb-3 ${touched?.description && errors?.description
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            as="textarea"
                                            name='description'
                                            // placeholder="name"
                                            value={values?.description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-100 noti-content-txt"
                                            isInvalid={touched?.description && errors?.description}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.description}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>

                                <div className="activation-wrapper w-100">
                                    <label htmlFor="date-time" className="greyLabel notigreyLabel">
                                        Activation time
                                    </label>

                                    <div className="activation-time-wrapper d-flex w-50">
                                        <Form.Group controlId="startDate" className="w-100">
                                            <div
                                                className={`form-floating mt-2 mb-3 w-100 ${touched.startDate && errors.startDate
                                                    ? 'has-validation-error'
                                                    : ''
                                                    }`}>
                                                <Form.Control
                                                    style={{ paddingRight: '0' }}
                                                    type="date"
                                                    name="startDate"
                                                    min={new Date()?.toISOString()?.split('T')[0]}
                                                    // placeholder="name"
                                                    value={values.startDate}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="w-100 custom-date-input"
                                                    isInvalid={touched.startDate && errors.startDate}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.startDate}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Form.Group>

                                        <Form.Group controlId="startTime" className="w-100">
                                            <div
                                                className={`form-floating mt-2 mb-3 w-100 ${touched.startTime && errors.startTime
                                                    ? 'has-validation-error'
                                                    : ''
                                                    }`}>
                                                <Form.Control
                                                    type="time"
                                                    name="startTime"
                                                    // placeholder="name"
                                                    value={values.startTime}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="w-100"
                                                    isInvalid={touched.startTime && errors.startTime}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.startTime}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="mt-2"></div>
                                <label className="greyLabel notigreyLabel">League</label>
                                <Select
                                    options={leagueOptions}
                                    value={leagueVal}
                                    placeholder={
                                        <div className="select-placeholder-text">Select</div>
                                    }
                                    onChange={handleLeagueDropdown}
                                    className="w-50 mt-2 noti-dropdown"
                                />

                                <div className="mt-2"></div>
                                <label className="greyLabel notigreyLabel">Season</label>
                                <Select
                                    options={seasonOptions}
                                    value={seasonVal}
                                    placeholder={
                                        <div className="select-placeholder-text">Select</div>
                                    }
                                    onChange={handleSeasonDropdown}
                                    className="w-50 mt-2 noti-dropdown"
                                />

                                <div className="mt-2"></div>
                                <label className="greyLabel notigreyLabel">Tier</label>
                                <Select
                                    options={tierOptions}
                                    value={tierVal}
                                    placeholder={
                                        <div className="select-placeholder-text">Select</div>
                                    }
                                    onChange={handleTierDropdown}
                                    className="w-50 mt-2 noti-dropdown"
                                />

                                <>{tierError === true &&
                                    <div className="invalid-feedback displayBlock">
                                        Tier is required
                                    </div>
                                }</>


                                <div className="d-grid gap-2 noti-btn">
                                    <button
                                        type="submit"
                                        onClick={() => {
                                            if (tierVal?.value === undefined) {
                                                setTierError(true)
                                            }
                                        }
                                        }
                                        className="btn btn-primary add-noti-btn">
                                        {editFlag===true ? 'Update Notification' : 'Add Notification'}
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div >
    )
}

export default AddNotifications