import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    LoginSocialGoogle,
    LoginSocialApple,
    LoginSocialFacebook,
} from 'reactjs-social-login';
import { ReactComponent as GoogleIcon } from '../../../assets/images/svg/GoogleLogo.svg';
import { ReactComponent as AppleIcon } from '../../../assets/images/svg/AppleLogo.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/images/svg/FacebookLogo.svg';
import svg from '../../../assets/images/svg/index';
import { googleSocialLogin, facebookSocialLogin, appleSocialLogin } from '../../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

const SocialLogin = ({ type, onLoginSuccess, onLoginError }) => {
    const onLoginSuccessCallback = useCallback(
        provider =>
            async ({ data }) => {
                const getToken = {
                    google: () => ({ access_token: data?.access_token }),
                    facebook: () => ({ access_token: data?.accessToken }),
                    apple: () => ({
                        access_token: data?.authorization?.id_token,
                        code: data?.authorization?.code,
                        id_token: data?.authorization?.id_token,
                    }),
                };
                // eslint-disable-next-line
                const userData = {
                    ...data,
                    ...getToken[provider](),
                };
                // eslint-disable-next-line
                if (provider === 'google') {
                    try {
                        let resp = await googleSocialLogin({
                            access_token: userData?.access_token,
                            code: userData?.access_token
                        });
                        if (resp?.token) {
                            localStorage.setItem('token', resp?.token)
                            localStorage.setItem('userId', resp?.user?.id);
                            localStorage.setItem('userProfile', resp?.user?.profile?.profile_image);
                            localStorage.setItem('on_boarding_complete', resp?.on_boarding_complete);
                            localStorage.setItem('refer_url', resp?.user?.refer_url);
                            if (resp?.admin === true) {
                                window.location.href = '/admin/dashboard'
                            } else {
                                window.location.href = '/onboarding'
                            }
                        }
                    }
                    catch (err) {
                        toast.error(err?.response?.data?.message || 'An error occurred while processing your request.');
                    }
                }

                if (provider === 'facebook') {
                    try {
                        let resp = await facebookSocialLogin({
                            access_token: userData?.access_token,
                            code: userData?.access_token
                        });
                        if (resp?.token) {
                            localStorage.setItem('token', resp?.token)
                            localStorage.setItem('userId', resp?.user?.id);
                            localStorage.setItem('userProfile', resp?.user?.profile?.profile_image);
                            localStorage.setItem('on_boarding_complete', resp?.on_boarding_complete);
                            localStorage.setItem('refer_url', resp?.user?.refer_url);
                            if (resp?.admin === true) {
                                window.location.href = '/admin/dashboard'
                            } else {
                                window.location.href = '/onboarding'
                            }
                        }
                    }
                    catch (err) {
                        toast.error(err?.response?.data?.message || 'An error occurred while processing your request.');
                    }
                }
                if (provider === 'apple') {
                    try {
                        let resp = await appleSocialLogin({
                            access_token: userData?.access_token,
                            code: userData?.code,
                            id_token: userData?.id_token
                        });
                        if (resp?.token) {
                            localStorage.setItem('token', resp?.token)
                            localStorage.setItem('userId', resp?.user?.id);
                            localStorage.setItem('userProfile', resp?.user?.profile?.profile_image);
                            localStorage.setItem('on_boarding_complete', resp?.on_boarding_complete);
                            localStorage.setItem('refer_url', resp?.user?.refer_url);
                            if (resp?.admin === true) {
                                window.location.href = '/admin/dashboard'
                            } else {
                                window.location.href = '/onboarding'
                            }
                        }
                    }
                    catch (err) {
                        toast.error(err?.response?.data?.message || 'An error occurred while processing your request.');
                    }
                }
                onLoginSuccess && onLoginSuccess(provider, userData);
            },
        [onLoginSuccess]
    );

    const onLoginErrorCallback = useCallback(
        provider => error => {
            onLoginError && onLoginError(provider, error);
        },
        [onLoginError]
    );

    return (
        <div className="social-login__options mt-3">
            <LoginSocialGoogle
                isOnlyGetToken
                // client_id={"993169876801-92aink9vca20s5ver7bj9uapbmlkgia1.apps.googleusercontent.com"}
                client_id={"1079183307077-g6isid6o4a8fg0fn3j8gaagbro6cmtlc.apps.googleusercontent.com"}
                onResolve={onLoginSuccessCallback('google')}
                onReject={onLoginErrorCallback('google')}
                scope="openid profile email"
                className="social-login__options__google"
            >
                <GoogleIcon className="google_social_login cursor-pointer" />
            </LoginSocialGoogle>

            <LoginSocialApple
                client_id="com.linxleague.services"
                // client_id='com.ios.cinebudget2'
                scope={'name email'}
                // redirect_uri='https://little-union-41061-staging.botics.co/auth/login'
                redirect_uri={"https://linx-league-golf-ap-37-staging.botics.co/accounts/apple/login/callback/"}
                onResolve={onLoginSuccessCallback('apple')}
                onReject={onLoginErrorCallback('apple')}
                className="social-login__options__apple"
            >
                <AppleIcon className='cursor-pointer' />
            </LoginSocialApple>

            <LoginSocialFacebook
                // appId='295653992853711' //Arun's Project app id
                appId='3480834018848172' // Linx league old app id
                // appId='1475122759950032'
                onResolve={onLoginSuccessCallback('facebook')}
                onReject={onLoginErrorCallback('facebook')}
                className="social-login__options__facebook"
            >
                <FacebookIcon className='cursor-pointer' />
            </LoginSocialFacebook>
        </div>
    );
};
export default SocialLogin;