
import React, { useEffect, useState, useRef } from "react";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Loader from '../../CustomComponents/Loader';
import {
    Typography,
    TextField,
    Button,
    Stepper,
    Paper,
    Step,
    Radio,
    // StepLabel,
    LinearProgress
} from "@material-ui/core";
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Visa from '../../assets/images/png/Visa.png';
import Mastercard from '../../assets/images/png/Mastercard.png';
import {
    useForm,
    Controller
} from "react-hook-form";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmPayment, fetchGetPaymentMethods, addPaymentMethod } from '../../api/request';
import { useWindowSize } from "../../utils/useWindowSize.js";
import './subs.scss';

let mobileBreakpoint = 821;
const stripePromise = loadStripe(
    "pk_test_51N8OMlFdHTQtW0piO3f6beyjQy8pR3FQltBK5pAIts4sK3namjf9Bv2aVZ2AKdx5FeSER5BLvIh3oT3LBnndxV4k00120xHlb7"
);

const PaymentformElements = (props) => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentForm data={props?.subData} show={props?.show} handleNext={props?.handleNext} handleClose={props?.closePopup} leagueId={props?.leagueId} leagueData={props?.leagueData}
            />
        </Elements>
    )
}

const PaymentForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0);
    const { getValues } = useForm();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [expDateErr, setExpDateErr] = useState(false);
    const [cardCvvErr, setCardCvvErr] = useState(false);
    const [cvvValue, setCvvValue] = useState('');
    const [cardNumErr, setCardNumErr] = useState(false);
    const [discount, setDiscount] = useState(null);
    const [couponCode, setCouponCode] = useState('');
    const [savedCards, setSavedCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showAddPayment, setShowAddPayment] = useState(false);
    const [showAddCardDisabled, setAddCardDisabled] = useState(false);
    const [selectedCard, setSelectedCard] = useState('');
    const [paymentData, setPaymentData] = useState({});
    const cardExpiryRef = useRef(null);
    const cardNumberRef = useRef(null);
    const cardCvcRef = useRef(null);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    useEffect(() => {
        fetchCardDetails()
    }, []);

    const fetchCardDetails = async () => {
        setLoading(true)
        try {
            let resp = await fetchGetPaymentMethods();
            setSavedCards(resp?.data);
            setLoading(false)
        }
        catch (err) {
            setLoading(false)
        }
    };

    const fetchCoupon = async () => {
        try {
            const response = await axios.get(`https://api.stripe.com/v1/coupons/${couponCode}`, {
                headers: {
                    Authorization: 'Bearer sk_test_51N8OMlFdHTQtW0piycecLmtsyGbiB1O17MaJGT7XzFv56wJGK6cT747qx2XQDteZxgPdqsVvREE56f5AWXGzRjBE00OKYL1dxp',
                },
            });
            if (response?.data?.amount_off !== null) {
                setDiscount(response?.data?.amount_off / 100);
            }
            else if (response?.data?.percent_off !== null) {
                setDiscount(Number((props?.data?.subscriptionData?.price) * (response?.data?.percent_off / 100)).toFixed(2));
            }
        } catch (error) {
            console.error('Error fetching coupon:', error?.response?.data?.error?.message);
            toast.error(error?.response?.data?.error?.message || 'Error in fetching coupon details');
        }
    };

    const addPaymentHandler = async (event) => {
        setAddCardDisabled(true)
        event?.preventDefault();
        setIsFormSubmitted(true);
        // Handle Stripe submission here
        if (!stripe || !elements) {
            // Stripe hasn't loaded yet
            return;
        }
        const data = {}

        const cardElement = elements.getElement(CardNumberElement);
        if (data?.cardName === '') {
            toast.error("Please provide a valid credit card number");
            return;
        }
        stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: data?.cardName,
            },
        })
            .then(function (result) {
                addPaymentMethodFunc(result?.paymentMethod)
                // Handle result.error or result.paymentMethod
            });
    };

    const addPaymentMethodFunc = async (data) => {
        try {
            let resp = await addPaymentMethod({
                payment_method_id: data?.id
            });
            if (resp) {
                toast.success('Payment method added successfully');
                setAddCardDisabled(false)
                window?.location?.reload();
            }
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occured while adding a new payment method');
        }
    }

    const { control, handleSubmit, formState: { errors } } = useForm();

    const handleConfirmPayment = async () => {
        if (paymentData?.id === undefined) {
            toast.error('Please select any Saved card before proceeding with the payment!!')
            return;
        }
        try {
            const body = {
                "cents": Number(props?.data?.subscriptionData?.price - discount) * 100,
                "tier_id": props?.leagueId,
                "subscription_id": props?.data?.subscriptionData?.id,
                "coupon_code": couponCode,
                "payment_method_id": paymentData?.id
            }
            let resp = await confirmPayment(body);
            toast.success(resp?.message || 'Congratulations, we have received your payment and your are now registered to the league');
            localStorage.setItem('on_boarding_complete', resp?.on_boarding_complete);
            // props?.handleNext(props);
            window?.location?.reload();
        }

        catch (error) {
            console.error("Error:", error);
            toast.error(error?.response?.data?.message);
        }
    }

    const renderImage = (cardBrand) => {
        if (cardBrand === 'visa') {
            return Visa
        }
        if (cardBrand === 'mastercard') {
            return Mastercard
        }
        if (cardBrand?.toLowerCase() === 'amex') {
            return 'https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg'
        }
        if (cardBrand?.toLowerCase() === 'diners') {
            return 'https://js.stripe.com/v3/fingerprinted/img/diners-fbcbd3360f8e3f629cdaa80e93abdb8b.svg'
        }
        if (cardBrand?.toLowerCase() === 'jcb') {
            return 'https://js.stripe.com/v3/fingerprinted/img/jcb-271fd06e6e7a2c52692ffa91a95fb64f.svg'
        }
        if (cardBrand?.toLowerCase() === 'discover') {
            return 'https://js.stripe.com/v3/fingerprinted/img/discover-ac52cd46f89fa40a29a0bfb954e33173.svg'
        }
        if (cardBrand?.toLowerCase() === 'unionpay') {
            return 'https://js.stripe.com/v3/fingerprinted/img/unionpay-8a10aefc7295216c338ba4e1224627a1.svg'
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='sub-content-wrapper schedule-round-wrapper'
            show={props.show}
            onHide={props?.handleClose}
            dialogClassName={getwidth < mobileBreakpoint && "modal-from-bottom"} // Apply the custom CSS class
            animation={false} // Disable built-in animations
            centered={getwidth < mobileBreakpoint ? false : true}>
            <Modal.Header closeButton>
                <Modal.Title className="modalTitle">
                    <div className="subscription-info d-flex mt-2">
                        <div className="subscription-txt">Payment</div>
                        <div className="subscription-prize">{props?.data?.recommendedLeaguesData?.name}</div>
                    </div></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" />
                        :
                        <div className="payment-body-wrapper d-flex">
                            <div className="payment-body-form-wrapper d-flex">
                                {/* <form
                                onSubmit={handleSubmit(onSubmit)}
                                className="payment-body-form-wrapper d-flex"
                            > */}
                                <div className='payment-body'>
                                    <>{savedCards?.length > 0 && <div className="saved-card-title">Saved Cards</div>}</>
                                    <div className='saved-cards-listing d-flex flex-column mt-2'>
                                        {
                                            savedCards?.map((item, index) => (
                                                <div className='saved-card'>
                                                    <div className='card-type'>
                                                        <img src={renderImage(item?.card?.brand)} className='card-type-img w-100 h-100' alt="no NP" />
                                                    </div>
                                                    <div className='card-details d-flex flex-column'>
                                                        <div className='saved-card-num'>{item?.card?.brand?.charAt(0)?.toUpperCase() + item?.card?.brand?.slice(1)} ****{item?.card?.last4}</div>
                                                        <div className='saved-card-expiry'>Expires {item?.card?.exp_month}/{item?.card?.exp_year}</div>
                                                    </div>
                                                    <div className='del-saved-card'>
                                                        <Radio checked={selectedCard === item?.id
                                                        }
                                                            onChange={() => {
                                                                setSelectedCard(item?.id);
                                                                setPaymentData(item)
                                                            }
                                                            }
                                                            value={item?.card?.brand}
                                                            name="checkedCardType" />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="payment-sub-title trimTxt1line mt-3" onClick={() => setShowAddPayment(!showAddPayment)}>+Add new payment method</div>
                                    {
                                        showAddPayment === true &&
                                        <form
                                            className="payment-body-form-wrapper"
                                            onSubmit={(e) => addPaymentHandler(e)}
                                        >
                                            <div className="row mt-4 payment-form-row">
                                                <div className="form-field w-100 card-number-wrapper">
                                                    <label htmlFor="card-number" className="txt-label">Card number<span className="red-label">*</span></label>
                                                    <div className={`custom-card-element ${errors.cardNumber ? 'has-error' : ''}`}>
                                                        <CardNumberElement
                                                            name="cardNumber"
                                                            id="card-number"
                                                            className="card-number-txt"
                                                            // className={`card-number-txt ${cardType}`}
                                                            options={{
                                                                showIcon: true,
                                                                style: {
                                                                    base: {
                                                                        fontSize: "16px",
                                                                        fontWeight: "400",
                                                                        fontFamily: "Proxima Nova Condensed",
                                                                        border: '1px solid #ced4da',
                                                                        "::placeholder": {
                                                                            color: "#aab7c4",
                                                                            fontFamily: "Proxima Nova Condensed",
                                                                        },
                                                                    },
                                                                    invalid: {
                                                                        color: "#9e2146",
                                                                    },
                                                                },
                                                            }}
                                                            onChange={(event) => {
                                                                cardNumberRef.current = event;
                                                                setCardNumErr(false);
                                                            }}
                                                        />
                                                    </div>
                                                    {/* {errors.cardNumber && <p className="error text-danger-txt">{errors.expDate.message}</p>} */}
                                                    {(isFormSubmitted && cardNumErr) && <p className="error text-danger-txt">Card number is required</p>}
                                                </div>

                                                <div className="form-field payment-exp-cvv w-100 d-flex">
                                                    <div className="exp-date-wrapper w-50">
                                                        <label htmlFor="exp-date" className="txt-label trimTxt1line">Expiration date<span className="red-label">*</span></label>
                                                        <div className={`custom-card-element ${errors.expDate ? 'has-error' : ''}`}>
                                                            <CardExpiryElement
                                                                name="expDate"
                                                                options={{
                                                                    style: {
                                                                        base: {
                                                                            fontSize: "16px",
                                                                            fontWeight: "400",
                                                                            fontFamily: "Proxima Nova Condensed",
                                                                            border: '1px solid #ced4da',
                                                                            "::placeholder": {
                                                                                color: "#aab7c4",
                                                                                fontFamily: "Proxima Nova Condensed",
                                                                            },
                                                                        },
                                                                        invalid: {
                                                                            color: "#9e2146",
                                                                        },
                                                                    },
                                                                }}
                                                                onChange={(event) => {
                                                                    cardExpiryRef.current = event;
                                                                    setExpDateErr(false);
                                                                }}
                                                            />
                                                        </div>
                                                        {/* {errors.expDate && <p className="error text-danger-txt">{errors.expDate.message}</p>} */}
                                                        {(isFormSubmitted && expDateErr) && <p className="error text-danger-txt">Expiration date is required</p>}
                                                    </div>

                                                    <div className="form-field w-100 cvc-wrapper">
                                                        <label htmlFor="cvv" className="txt-label">CVV<span className="red-label">*</span></label>
                                                        <div className={`custom-card-element ${errors.cardCvc ? 'has-error' : ''}`}>
                                                            <CardCvcElement
                                                                id="cvv"
                                                                options={{
                                                                    style: {
                                                                        base: {
                                                                            fontSize: "16px",
                                                                            fontWeight: "400",
                                                                            fontFamily: "Proxima Nova Condensed",
                                                                            border: '1px solid #ced4da',
                                                                            "::placeholder": {
                                                                                color: "#aab7c4",
                                                                                fontFamily: "Proxima Nova Condensed",
                                                                            },
                                                                        },
                                                                        invalid: {
                                                                            color: "#9e2146",
                                                                        },
                                                                    },
                                                                }}
                                                                onChange={(event) => {
                                                                    cardCvcRef.current = event;
                                                                    setCardCvvErr(event.empty);
                                                                    setCvvValue(event.complete ? event.value : '');
                                                                }}

                                                            />
                                                        </div>
                                                        {/* {isFormSubmitted && cardCvvErr && <p className="error text-danger-txt">Cvv is a required field</p>} */}
                                                    </div>

                                                </div>

                                                <div className="form-field w-100">
                                                    <label htmlFor="card-name" className="txt-label">Card holder name<span className="red-label">*</span></label>
                                                    <Controller
                                                        control={control}
                                                        name="cardName"
                                                        rules={{
                                                            required: "Card holder name is required",
                                                        }}
                                                        render={({ field }) => (
                                                            <>
                                                                <TextField
                                                                    id="card-name"
                                                                    variant="outlined"
                                                                    placeholder="Enter Card holder name"
                                                                    fullWidth
                                                                    margin="normal"
                                                                    {...field}
                                                                />
                                                                {errors?.cardName && <p className="error text-danger-txt" style={{ marginBottom: '0px' }}>{errors?.cardName.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <Button className='add-payment-btn  w-100' type="submit" disabled={showAddCardDisabled}>Add Payment</Button>
                                            </div>
                                        </form>
                                    }

                                    <div className="coupon-wrapper w-100 mt-3">
                                        <div className="form-field w-100">
                                            <label htmlFor="card-name" className="txt-label">Coupon Code</label>
                                            <div className="coupon-wrapper-2 d-flex justify-content-between align-items-center w-100">
                                                <TextField
                                                    id="coupon-code"
                                                    variant="outlined"
                                                    placeholder="Enter Coupon code"
                                                    fullWidth
                                                    margin="normal"
                                                    onChange={(e) => setCouponCode(e.target.value)}
                                                // onChange={(e) => field.onChange(e.target.value)}
                                                />
                                                <Button className="apply-coupun-btn w-25" onClick={() => {
                                                    fetchCoupon()
                                                }}>Apply </Button>

                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div className="order-details">
                                    <div className="order-details-head">
                                        Order Summary
                                    </div>

                                    <div className="order-season-wrapper d-flex justify-content-between align-items-center">
                                        <div className="order-season-title">
                                            {props?.data?.subscriptionData?.name}
                                        </div>
                                        <div className="order-season-value">
                                            {
                                                props?.data?.subscriptionData?.price &&
                                                <>
                                                    ${props?.data?.subscriptionData?.price}
                                                </>
                                            }

                                        </div>
                                    </div>

                                    <div className="order-season-wrapper d-flex justify-content-between align-items-center">
                                        <div className="order-season-title">
                                            Discount
                                        </div>
                                        <div className="order-season-value">
                                            {
                                                discount &&
                                                <>
                                                    ${discount}
                                                </>
                                            }

                                        </div>
                                    </div>

                                    <div className="order-line"></div>

                                    <div className="order-total-wrapper d-flex justify-content-between align-items-center">
                                        <div className="order-total-title">
                                            Total
                                        </div>
                                        <div className="order-total-value">
                                            ${props?.data?.subscriptionData?.price - discount}
                                        </div>
                                    </div>

                                    <>{getwidth >= mobileBreakpoint &&
                                        <Button className="order-btn" type="button" onClick={() => handleConfirmPayment()}>
                                            Confirm Payment
                                        </Button>
                                    }</>

                                    <div className="order-desc">A recurring charge of $60.00 will automatically be applied to your payment method and start on May 1, 2023. You may cancel at any time, effective at the end of the billing period, by going to your payment settings. All amounts paid are non-refundable, subject to certain exceptions. By clicking “Confirm Payment”, you agree to our <span className="terms-con cursor-pointer" onClick={() => navigate('/terms-conditions')}>Terms of Service</span> and authorize this recurring charge.</div>

                                    <>{getwidth < mobileBreakpoint &&
                                        <Button className="order-btn" type="button" onClick={() => handleConfirmPayment()}>
                                            Confirm Payment
                                        </Button>
                                    }</>

                                </div>
                            </div>

                            {/* </form> */}
                        </div>

                }
            </Modal.Body>
        </Modal>
    );
};

export default PaymentformElements