import React, { useEffect } from "react";
import svg from '../../assets/images/svg/index';
import LinearStepper from "./LinearStepper";
import './styles.scss';

const UserOnboarding = () => {
    return (
        <div className="user-onboarding-wrapper">
            <div className="img-wrapper">
                {/* <img src={svg.BackArrow} className="backarrow" alt="back arrow NP" /> */}
                <img src={svg?.Logo} className="logo" alt="logo np" />
            </div>
            <div className="linear-form-wrapper">
                <LinearStepper />
            </div>
        </div>
    )
}

export default UserOnboarding;