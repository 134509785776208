import React from "react"
import { Spinner } from "react-bootstrap"
import './spinner.scss';

const Loader = ({ loadingMsg, style, classprop }) => {
  return (
    <div className={classprop? classprop:'spinner-container'}>
      <Spinner animation="border" role="status" style={style}></Spinner>
      <pre>{loadingMsg}</pre>
    </div>
  )
}

export default Loader
