import React, { useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import svg from '../../assets/images/svg/index';
import { changePhoto } from '../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './uploadphoto.scss';

const UploadPhoto = (props) => {
    const [selFile, setSelFile] = useState(props?.userProfilePic || '');
    const handleClose = () => {
        props?.closeUploadPhoto();
    }

    const handleSave = async () => {
        const formData = new FormData();
        if (selFile instanceof File) {
            formData.append('profile_image', selFile);
        }
        try {
            let resp = await changePhoto(formData, props?.userId);
            if (resp) {
                toast.success('Photo changed successfully');
                props?.closeUploadPhoto();
                window.location.reload();
            }
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'Error in change user photo');
        }
    }

    const handleFileInputClick = (e) => {
        setSelFile(e?.target?.files[0]);
    }

    const uploadPicImg = () => {
        if (selFile) {
            if (selFile instanceof File) {
                return URL.createObjectURL(selFile);
            }
            else {
                return selFile
            }
        }
        else {
            return svg?.DefUser
        }
    }

    return (
        <div className="UploadPhoto-Container">
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                className='upload-photo-wrapper'
                show={props.show}
                onHide={handleClose}
                centered>
                <Modal.Header>
                    <Modal.Title className="modalTitle">Change Photo</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modalBody">
                    <div className="upload-body">
                        <img src={uploadPicImg()} className="def-photo" alt="def NP" />
                        <div className="upload-bodycont">
                            <input type="file" id="file-input" name="file-input"
                                accept="image/*"
                                onChange={(e) => handleFileInputClick(e)} />

                            <label id="file-input-label" for="file-input"
                            >Choose File</label>
                            <span className="sel-file">{selFile?.name}</span>
                        </div>
                    </div>
                    <div className='del-buttons-wrapper w-100'>
                        <Button className="cancelBtn w-50" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            className="saveBtn w-50" onClick={() => handleSave()}>
                            Save
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default UploadPhoto;