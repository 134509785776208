import React, { useState, useEffect } from "react";
import Logo from '../../../assets/images/svg/Logo.svg';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Row, Col, Image } from 'react-bootstrap';
import { LoginSocialGoogle } from "reactjs-social-login"
import { GoogleLoginButton } from "react-social-login-buttons"
import AppleLogin from "react-apple-login"
import FacebookLogin from "react-facebook-login"
import { useNavigate } from 'react-router-dom';
import SocialLogin from './SocialLogin.jsx';
import svg from '../../../assets/images/svg/index'
import { login, fetchBasicUserInfo } from '../../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import './styles.scss';

const Signin = () => {
  const navigate = useNavigate();
  const [stateVal, setStateVal] = useState('');
  const [callbackUrl, setCallbackUrl] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const localToken= localStorage.getItem('token');

  const schema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    // password: Yup.string().required('Password is required'),
    password: Yup.string().required('Password is required').matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
      "Password must contain at least 8 characters, one capital letter, one number, and one special character")
  });


  useEffect(() => {
    if (localStorage.getItem('token')) {
      callLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  const callLogin = async () => {
    try {
      let resp = await fetchBasicUserInfo();
      if (resp) {
        navigate('/home');
      }
    }
    catch (err) {
      if (err?.response?.data?.detail === "Invalid token.") {
        toast.error(err?.response?.data?.detail || 'An error occurred while processing your requests')
        // navigate('/login');
      }
      else {
        toast.error(err?.response?.data?.message || 'An error occurred while processing your requests')
      }
    }
  }
  return (
    <div className="user-signin-wrapper d-flex justify-content-center">
      <div className="user-signin-form-wrapper w-100 mt-4">
        <img src={Logo} className="app-logo" alt="app logo NP" />
        <Formik
          validationSchema={schema}
          initialValues={{ email: localStorage.getItem("email") || "", password: "", rememberme: false }}
          onSubmit={async (values) => {
            let body = {
              "username": values.email,
              "password": values.password
            }
            if (values.rememberme[0] === 'on') {
              localStorage.setItem("email", values?.email)
              // localStorage.setItem("password", values?.password)
              localStorage.setItem('rememberme', true);
            }
            try {
              let resp = await login(body);
              if (resp?.token) {
                localStorage.setItem('token', resp?.token)
                localStorage.setItem('userId', resp?.user?.id);
                localStorage.setItem('userProfile', resp?.user?.profile?.profile_image);
                localStorage.setItem('on_boarding_complete', resp?.on_boarding_complete);
                localStorage.setItem('refer_url', resp?.user?.refer_url);
                if (resp?.admin === true) {
                  window.location.href = '/admin/dashboard'
                } else {
                  window.location.href = '/onboarding'
                }
              }
            }
            catch (error) {
              toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
            }
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            /* and other goodies */
          }) => (
            <form
              className="login-form"
              onSubmit={handleSubmit}
              noValidate>
              <div className="Auth-form-content w-100">
                <div className="signin_heading">Sign In</div>
                <Form.Group controlId="email">
                  <div
                    className={`form-floating  mb-3 ${touched.email && errors.email
                      ? 'has-validation-error'
                      : ''
                      }`}>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="w-100"
                      isInvalid={touched.email && errors.email}
                    />
                    {!values.email && (
                      <label htmlFor="email" className="greyLabel">
                        Email
                      </label>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group controlId="password">
                  <div
                    className={`form-floating mb-3 pass-div ${touched.password && errors.password
                      ? 'has-validation-error'
                      : ''
                      }`}>
                    <Form.Control
                      // type='password'
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.password && errors.password}
                      className="custom-password-input"
                    />
                    {/* {touched.password && errors.password ? null : ( */}
                      <div className="input-group-append eyeicon-img">
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          style={{ color: '#2563EB' }}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </div>
                    {/* )} */}
                    {!values.password && (
                      <label htmlFor="email" className="greyLabel">
                        Password
                      </label>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>

                <Row>
                  <Col xs={6} md={6} className="rememberme_checkbox">
                    <div className="form-check">
                      <label className="form-check-label remember_me">Remember me</label>
                      <input
                        className="form-check-input chkBox  cursor-pointer"
                        type="checkbox"
                        checked={values?.rememberme}
                        name={"rememberme"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div className="invalid-feedback">
                        Please provide a valid city.
                      </div>
                    </div>
                  </Col>
                  <Col className="forgot_pass cursor-pointer">
                    <p className="text-end text-primary remember_me">
                      <small
                        onClick={() => navigate('/forgot-pass')}
                      >
                        Forgot Password?
                      </small>
                    </p>
                  </Col>
                </Row>

                <div className="d-grid gap-2 mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary signin_btn"
                    style={{ width: '100%' }}>
                    Sign In
                  </button>
                </div>

                <div className="login-footer d-flex align-items-center mt-4">
                  <div class="loginLine"></div>
                  <span class="logintxt">Or</span>
                  <div class="loginLine"></div>
                </div>

                <div className="social-logins-wrapper d-flex align-items-center justify-content-center mt-4">
                  <SocialLogin />
                </div>
                {/* <div className="social-logins-wrapper d-flex justify-content-space-evenly mt-4">
                  <img src={svg.GoogleLogo} alt="google NP" />
                  <img src={svg.AppleLogo} alt="apple NP" />
                  <img src={svg.FacebookLogo} alt="fb NP" />
                </div> */}

                <div className="no-account-txt mt-4 cursor-pointer" onClick={() => navigate('/signup')}>
                  Don’t have an account? Sign Up Here!
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div >
  )
}

export default Signin;