import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { deleteRound } from '../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import './deleteround.scss';

const DeleteRound = (props) => {
    const navigate = useNavigate();

    const handleClose = () => {
        props.closeDeleteRound();
    }

    const handleDelAccount = async () => {
        try {
            let resp = await deleteRound(props?.roundData?.id);
            toast.success('Round deleted sucessfully');
            navigate('/home')
        }
        catch (error) {
            toast.error(error?.response?.data?.message || 'Error in delete round')
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='delete-round-wrapper'
            show={props.show}
            onHide={handleClose}
            centered>
            <Modal.Header>
                <Modal.Title className="modalTitle">Delete Round</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <div className="del-sub">
                    Are you sure you want to Delete Round? This cannot be undone.
                </div>
                <div className='del-buttons-wrapper w-100'>
                    <Button
                        onClick={() => handleDelAccount()}
                        className="delBtn w-100">
                        Delete Round
                    </Button>
                    <Button className="cancelBtn w-100" onClick={handleClose}>
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DeleteRound;
