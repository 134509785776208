import React, { useState, useEffect } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import svg from '../../../assets/images/svg/index';
import { Accordion } from 'react-bootstrap-accordion';
import 'react-bootstrap-accordion/dist/index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './specificCourse.scss';

const SpecificCourse = (props) => {
    const [userData, setuserData] = useState(props?.data);
    const navigate = useNavigate();

    return (
        <div className="specifc-course-wrapper">
            <div className="SpecifcUsers-row">
                <div className="SpecifcUsers-right-col">
                    {/* <div className="right-col-heading">Leagues</div> */}
                    <div className="right-col-data mt-4">
                        <div className="right-col-row1 d-flex justify-content-between">
                            <div className="right-col-row1-col1">
                                <div className="row1-col1-label">{userData?.original?.email}</div>
                            </div>

                            <div className="right-col-row1-col2 d-flex">
                                <div className="col2-col1 d-flex flex-column">
                                    <div className="score-txt">4</div>
                                    <div className="score-label  mt-2">Total Courses</div>
                                </div>

                                <div className="col2-col1 d-flex flex-column">
                                    <div className="score-txt">154</div>
                                    <div className="score-label  mt-2">Total Players</div>
                                </div>

                                <div className="col2-col1 d-flex flex-column">
                                    <div className="score-txt">52</div>
                                    <div className="score-label  mt-2">Total Rounds</div>
                                </div>

                            </div>
                        </div>
                        <Accordion title="Played courses">
                            <div className="courses-body mt-2">
                                <div className="course-body-item-wrapper d-flex justify-content-between">
                                    <div className="course-item-name">
                                        St Johns Golf & Country Club
                                    </div>
                                    <div className="course-item-info d-flex justify-content-between">
                                        <div className="course-item-txt">
                                        8 visits
                                        </div>
                                        <div className="course-item-txt">
                                           23 rounds
                                        </div>
                                    </div>
                                </div>
                                <div className="course-body-item-wrapper d-flex justify-content-between">
                                    <div className="course-item-name">
                                        St Johns Golf & Country Club
                                    </div>
                                    <div className="course-item-info d-flex justify-content-between">
                                        <div className="course-item-txt">
                                            84 visits
                                        </div>
                                        <div className="course-item-txt">
                                            14 rounds
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpecificCourse;