import React, { useState, useEffect } from 'react';
import { privacyPolicy } from '../../api/request';
import Loader from '../../CustomComponents/Loader';
import { formatDate } from '../../utils/formatDate';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useWindowSize } from "../../utils/useWindowSize.js";
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

let mobileBreakpoint = 821;
const PrivacyPolicy = (props) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width])

    const fetchData = async () => {
        setLoading(true);
        try {
            let resp = await privacyPolicy();
            if (resp?.results) {
                setData(resp?.results[0])
            }
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
        }
    }

    return (
        <>
            {
                loading === true ?
                    <Loader loadingMsg="Data is Loading... Please Wait" />
                    :
                    <div className='privacy-policy-settings-wrapper'>
                        <div className='policy-body-wrapper'>
                            {getwidth >= mobileBreakpoint && <div className='policy-heading'>Privacy Policy</div>
                            }
                            <div className={getwidth >= mobileBreakpoint ? 'policy-date-time mt-4' : 'policy-date-time'}>Last Updated: {formatDate(data?.updated_at)}</div>
                            <div className='policy-body mt-3'>{data?.body}</div>
                        </div>
                    </div>
            }

        </>
    )
}

export default PrivacyPolicy;