export function hoursAgo(givenTime) {
    // Parse the given time into hours, minutes, and seconds
    const [givenHours, givenMinutes, givenSeconds] = givenTime.split(':').map(Number);

    // Get the current time
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentSeconds = currentTime.getSeconds();

    // Calculate the time difference in hours
    const hoursDifference = currentHours - givenHours;
    const minutesDifference = currentMinutes - givenMinutes;
    const secondsDifference = currentSeconds - givenSeconds;

    // Convert the time difference to hours
    const totalHoursAgo = hoursDifference + minutesDifference / 60 + secondsDifference / 3600;

    return totalHoursAgo.toFixed(0);
}
