import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import './createleaguepopup.scss';

const CreateLeaguePopup = (props) => {
    const navigate = useNavigate();

    const handleClose = () => {
        props.handleDelClose();
    }
    const handleDelAccount = async () => {
        navigate('/admin/dashboard/create-league/create-season', {
            state: { leagueData: props?.leagueResp}
        })
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='create-league-wrapper'
            show={props.show}
            onHide={handleClose}
            centered>
            <Modal.Header>
                <Modal.Title className="modalTitle">Create League</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <div className="del-sub">
                    League has been created successfully. Do you wish to create Season and Tiers further?
                </div>
                <div className='del-buttons-wrapper w-100'>
                    <Button
                        onClick={() => handleDelAccount()}
                        className="delBtn w-100">
                        Yes
                    </Button>
                    <Button className="cancelBtn w-100" onClick={()=>{
                        handleClose()
                        navigate('/admin/dashboard')
                    }
                        }>
                        No
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CreateLeaguePopup;
