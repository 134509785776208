import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from "../../utils/useWindowSize.js";
import { ghinLogin } from '../../api/ghinApi/ghinLogin.js';
import { teeSetListing } from '../../api/ghinApi/teeSetListing.js';
import Select from 'react-select';
import { roundTeeOff } from '../../api/request.js'
import TeeOffTooltip from '../../CustomComponents/TeeOffTooltip';
import './teeOff.scss';

let mobileBreakpoint = 821;
const NotiTeeOff = (props) => {
    const navigate = useNavigate();
    const [teeSet, setTeeSet] = useState([]);
    const [teeSetOptions, setTeeSetOptions] = useState([]);
    const [teeSetVal, setTeeSetVal] = useState({});
    const [tierError, setTierError] = useState(false);
    const [getwidth, setWidth] = useState(0)
    const [height, width] = useWindowSize()
    const [getTeeSetListing, setTeeSetListing] = useState([]);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    useEffect(() => {
        handleGhinLogin();
    }, []);


    const handleGhinLogin = async () => {
        let gender = props?.userData?.gender === 'FEMALE' ? 'F' : 'M';
        let resp = await ghinLogin();
        try {
            let teesetResp = await teeSetListing(resp?.token, props?.roundData?.golf_course_usga_id, gender);
            setTeeSetListing(teesetResp);
            setTeeSet(teesetResp);
            handleTeeSetDropDown(teesetResp);
        }
        catch (err) {
            console.log('Error in fetching tee_set listing', err);
        }
    }

    const handleTeeSetDropDown = (teesetResp) => {
        const arr = [];
        for (let i = 0; i < teesetResp?.length; i++) {
            arr?.push({
                label: `${teesetResp[i]?.TeeSetRatingName} ${teesetResp[i]?.TotalYardage}y    ${teesetResp[i]?.TotalPar}`,
                value: teesetResp[i]?.TeeSetRatingId
            })
        }
        setTeeSetOptions(arr);
        setTeeSetVal(arr[0])
    }

    const handleTierDropdown = (event) => {
        setTeeSetVal(event);
        setTierError(false);
    }

    const handleClose = () => {
        props.handleTeeOffPopupClose();
    }

    const handleTeeConfirm = async () => {
        let filteredTeeSet = [];
        let holesArr = [];
        let tempHolesArr = []
        let roundType = props?.roundData?.round_type;
        filteredTeeSet = teeSet?.filter((item) => item?.TeeSetRatingId === teeSetVal?.value);

        if (roundType === 'F9') {
            tempHolesArr = [...filteredTeeSet[0]?.Holes?.slice(0, (filteredTeeSet[0]?.Holes?.length) / 2)];
        }
        else {
            tempHolesArr = [...filteredTeeSet[0]?.Holes?.slice(8, filteredTeeSet[0]?.Holes?.length - 1)];
        }

        for (let i = 0; i < tempHolesArr?.length; i++) {
            holesArr?.push({
                usga_hole_id: tempHolesArr[i]?.HoleId,
                number: i + 1,
                par: tempHolesArr[i]?.Par,
                length: tempHolesArr[i]?.Length,
                allocation: tempHolesArr[i]?.Allocation
            })
        }

        const body = {
            tee_set: {
                usga_id: filteredTeeSet[0]?.TeeSetRatingId,
                name: filteredTeeSet[0]?.TeeSetRatingName,
                gender: filteredTeeSet[0]?.Gender,
                total_yards: filteredTeeSet[0]?.TotalYardage,
                total_meters: filteredTeeSet[0]?.TotalMeters
            },
            holes: holesArr
        }

        try {
            let resp = await roundTeeOff(body, props?.roundData?.id);
            toast.success(resp?.message || 'Tee off done successfully');
            window?.location?.reload()

        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occured in tee_off');
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='tee-off-popup-wrapper'
            show={props.show}
            onHide={handleClose}
            dialogClassName={getwidth < mobileBreakpoint && "modal-from-bottom"}
            animation={false} // Disable built-in animations
            centered={getwidth < mobileBreakpoint ? false : true}
        >
            <Modal.Header closeButton className='move-popup-header d-flex'>
                <Modal.Title className="modalTitle move-to-title">Round  Setup</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <div className="del-sub">
                    {props?.roundData?.golf_course}
                </div>

                <div className='select-wrapper w-100'>
                    <div className="mt-2">
                        <label className="greyLabel move-to-league-label">Select a Tee</label>
                        &nbsp;
                        <TeeOffTooltip />
                        {
                            (teeSet?.length > 0 && teeSetOptions?.length > 0) &&
                            <Select
                                options={teeSetOptions}
                                value={teeSetVal}
                                placeholder={
                                    <div className="select-placeholder-text">Select</div>
                                }
                                onChange={handleTierDropdown}
                                className="w-100 gender-dropdown mt-2"
                            />
                        }
                    </div>
                    <>{tierError === true &&
                        <div className="invalid-feedback displayBlock">
                            Select a Tee is required
                        </div>
                    }</>
                </div>

                <div className='del-buttons-wrapper w-100 mt-2'>
                    <Button
                        onClick={() => handleTeeConfirm()}
                        className="delBtn w-100">
                        Confirm
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default NotiTeeOff;
