import React, { useState } from 'react';
import svg from '../../assets/images/svg/index';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';

const TeeOffTooltip = (props) => {

    return (
        <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={<Tooltip id={`tee-offtooltip-bottom`}><div className='tee-off-data'>
                <div className='tool-head'>Recommended tees by score: </div>
                <div className='tool-options mt-3'><span className='blueclr'>BLUE</span> <span className='blue-data'>- 80 - under</span></div>
                <div className='tool-options'><span className='whiteclr'>WHITE</span> <span className='blue-data'>- 80 - 90</span></div>
                <div className='tool-options'><span className='goldclr'>GOLD</span> <span className='blue-data'>- 91 - 100</span></div>
                <div className='tool-options'><span className='redclr'>RED</span> <span className='blue-data'>- 100+</span></div>
                <div className='tool-bottom mt-3'>By playing from the appropriate tees everyone can help maintain a good pace of play and enjoy the game more.</div>
            </div></Tooltip>}
        >
            <img src={svg?.Info} className={`tooltip-trigger cursor-pointer ${props?.className}`} alt="info NP" />
        </OverlayTrigger>
    );
};

export default TeeOffTooltip;
