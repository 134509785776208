const EndPoints = {
  login: "api/v1/login/",
  signUp: "api/v1/signup/",
  forgotPassword: "api/v1/forgot-password/",
  resetPass: 'api/v1/reset-password/',
  logout: 'api/v1/logout/',
  changePassword: 'api/v1/change-password/',
  deleteAccount: 'api/v1/profile/',
  privacyPolicy: '/modules/privacy-policy/',
  termsConditions: '/modules/terms-and-conditions/',
  sendFeedback: 'api/v1/feedback/',
  manageNotifications: 'api/v1/profile/',
  getUserProfile: 'api/v1/get-user-profile/',
  profile: 'api/v1/profile/',
  inviteFriends: 'api/v1/invite/',
  adminAppusers: 'admin/api/v1/users/',
  adminLeagueRanking: 'admin/api/v1/league-ranking/',
  moveUsers: 'admin/api/v1/move-user/',
  adminAppUsersGhin: 'admin/api/v1/users/ghin_score/',
  adminFeedback: 'admin/api/v1/feedback/',
  updateUserProfile: 'api/v1/profile/',
  fbsociallogin: "modules/social-auth/facebook/login/",
  getAdContent: "admin/api/v1/app-content/",
  getUserAdContent: "api/v1/app-content/",
  manageSubscriptions: 'admin/api/v1/subscriptions/',
  updateManageSubscriptions: 'admin/api/v1/subscriptions/update_subscriptions/',
  leagues: 'admin/api/v1/leagues/',
  admintiers: 'admin/api/v1/season/',
  adminSeason: 'admin/api/v1/season/',
  deleteTier: 'admin/api/v1/tier/',
  recommendedLeagues: 'api/v1/recommended-leagues/',
  subscription: 'api/v1/subscription/',
  exploreLeagues: 'api/v1/explore-leagues/',
  confirmPayment: 'modules/payments/confirm-payment/',
  savedCardDetails: 'modules/payments/saved-card-details/',
  joinleague: 'api/v1/join-league/',
  savedCard: 'modules/payments/saved-card-details/',
  myLeagues: 'api/v1/my-leagues/',
  pastRound: 'round/api/v1/past-round/',
  upComingRound: 'round/api/v1/upcoming-round/',
  searchRoundUser: 'round/api/v1/search-user/',
  scheduleRounds: 'round/api/v1/round/',
  userNotifications: 'api/v1/notification/',
  readNotifications: 'api/v1/notification/read-all/',
  acceptRejectNotification: 'round/api/v1/round/',
  reportUser: 'api/v1/report-user/',
  otherUserProfile: 'api/v1/get-other-profile/',
  paymentHistory: 'api/v1/payments/',
  googleLogin: 'modules/social-auth/google/login/',
  facebookLogin: 'modules/social-auth/facebook/login/',
  appleLogin: 'modules/social-auth/apple/login/',
  removeFlag: 'admin/api/v1/users/',
  ghin: 'api/v1/add-ghin/',
  resendLink: 'api/v1/signup/resend-confirmation/',
  standings: 'api/v1/standings/',
  standingLeagues: 'api/v1/user-leagues/',
  standingHistory: 'api/v1/standings-history/',
  getPaymentMethods: 'modules/payments/get-payments-methods/',
  paymentMethod: 'modules/payments/payment-method/',
  recommendedLeaguesWithPagination: 'api/v1/explore-leagues-pagination/',
}

export default EndPoints
