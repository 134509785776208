import React, { useState, useEffect } from "react";
import { Formik, useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from "../../utils/useWindowSize.js";
import { inviteFriends } from '../../api/request.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

let mobileBreakpoint = 821;
const Invitefriends = () => {
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)
    const initialVal = { email: '', shareLink: localStorage?.getItem('refer_url') };
    const copyLink = (event) => {
        if (event.target.classList.contains("copyBtn")) {
            // Prevent the default action (copying to clipboard) for the "Copy" button
            event.preventDefault();
            const tempInput = document.createElement("input")
            tempInput.value = initialVal.shareLink
            document.body.appendChild(tempInput)
            tempInput.select()
            document.execCommand("copy")
            document.body.removeChild(tempInput)
            toast.success('Link copied successfully');
        }
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    return (
        <div className="change-password-settings-wrapper">

            <>{getwidth >= mobileBreakpoint &&
                <div className="password-heading">
                    Invite Friends
                </div>
            }</>
            <div className={getwidth >= mobileBreakpoint ? "password-body mt-4" : "password-body"}>

                <div className="sub-heading">Golf is better with friends! Earn a <b>free season</b> when you invite 3 friends who join Linx League!</div>
                <Formik
                    initialValues={initialVal}
                    onSubmit={async (values, { resetForm }) => {
                        const emailsArray = values?.email.split(',').map(email => email.trim()); const body = {
                            "emails": emailsArray
                        }
                        try {
                            let resp = await inviteFriends(body);
                            if (resp === '') {
                                toast.success('Invitation sent successfully');
                                resetForm();
                            }
                        }
                        catch (err) {
                            toast.error(err?.response?.data?.message || 'An error occurred while processing your request.');
                        }

                    }}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                        /* and other goodies */
                    }) => (
                        <form
                            className="login-form mt-4"
                            onSubmit={handleSubmit}
                            noValidate>
                            <div className="Auth-form-content w-100">

                                <Form.Group controlId="email">

                                    <label className="change-pass-label settings-label">Email address (comma separated)</label>
                                    <div
                                        className={`form-floating mb-3 ${touched.email && errors.email
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <div className={getwidth >= mobileBreakpoint ? "form-input-wrapper w-75" : "form-input-wrapper w-100"}>
                                            <Form.Control
                                                className={getwidth >= mobileBreakpoint ? "w-75 mt-2 p-2" : "w-100 mt-2 p-2"}
                                                type='text'
                                                name="email"
                                                style={{ borderRadius: '8px' }}
                                                placeholder="Email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.email && errors.email}
                                            />
                                            <button
                                                // type="submit"
                                                className={getwidth >= mobileBreakpoint ? "btn btn-primary sendBtn mt-2 w-25" : "btn btn-primary sendBtn mt-3 w-100"}>
                                                Send
                                            </button>
                                        </div>
                                        <Form.Control.Feedback type="invalid" className="w-50">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </div>

                                </Form.Group>

                                <Form.Group controlId="shareLink">
                                    <label className="change-pass-label settings-label">Share your link</label>
                                    <div
                                        className={`form-floating mb-3 ${touched.shareLink && errors.shareLink
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <div className={getwidth >= mobileBreakpoint ? "input-group w-75" : "input-group w-100"}>
                                            <Form.Control
                                                className={getwidth >= mobileBreakpoint ? "w-75 mt-2 p-2" : "w-100 mt-2 p-2"}
                                                type='text'
                                                name="shareLink"
                                                placeholder="https://referrallinkjacobdulanlinx"
                                                value={values.shareLink?.slice(0, 35)}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                style={{ borderRadius: '8px' }}
                                                isInvalid={touched.shareLink && errors.shareLink}
                                            />
                                            <button variant="primary" className="copyBtn" onClick={(event) => copyLink(event)}>Copy</button>

                                        </div>
                                        <Form.Control.Feedback type="invalid" className="w-50">
                                            {errors.shareLink}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>

                            </div>
                        </form>
                    )
                    }
                </Formik >
            </div >
        </div >
    )
}

export default Invitefriends;