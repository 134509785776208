import React, { useState } from "react";
import Logo from '../../../assets/images/svg/Logo.svg';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

const VerificationSuccess = () => {
    const navigate = useNavigate();

    const handleSubmit = () => {
    }

    return (
        <div className="verify-email-success-wrapper d-flex justify-content-center">
            <div className="user-signin-form-wrapper w-100">
                <img src={Logo} className="app-logo" alt="app logo NP" />
                <form
                    className="login-form width335"
                    onSubmit={handleSubmit}
                    noValidate>
                    <div className="verify-content w-100">
                        <div className="signin_heading">Verified!</div>

                        <div className="verify-email-txt">
                            You have successfully verified your email!
                        </div>
                        <div className="d-grid">
                            <button
                                type="submit"
                                className="btn btn-primary signin_btn"
                                style={{ width: '100%' }}
                                onClick={() => navigate('/login')}>
                                Back to Login
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default VerificationSuccess;