import React, { useEffect, useState } from "react";
import Navbar from "../../CustomComponents/Navbar";
import { useNavigate } from "react-router-dom";
import { fetchStandingsLeagues, fetchStandingsSeasons, fetchStandingsHistory } from '../../api/request';
import { convertDateToMonth } from '../../utils/ConvertDateToMonth.js';
import Select from 'react-select';
import Loader from '../../CustomComponents/Loader';
import svg from '../../assets/images/svg/index';
import { useWindowSize } from "../../utils/useWindowSize.js";
import ErrorMsg from '../../CustomComponents/ErrorMsg';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './standings.scss';

let mobileBreakpoint = 821;
const StandingsHistory = () => {
    const navigate = useNavigate();
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)
    const [standingsData, setStandingsData] = useState([]);
    const [leagueOptions, setLeagueOptions] = useState([]);
    const [leagueVal, setLeagueVal] = useState('Select league');
    const [seasonOptions, setSeasonOptions] = useState([]);
    const [seasonVal, setSeasonVal] = useState('Select season');
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [noFound, setNoFound] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontFamily: "Proxima Nova Condensed", // Change to your desired font-family
            fontSize: "16px", // Change to your desired font-size
        }),
    };

    useEffect(() => {
        fetchData();
        fetchLeagues();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            let resp = await fetchStandingsHistory();
            if (resp?.length > 0) {
                setLoading(false);
            }
            else {
                setLoading(false);
                setNoFound(true);
            }

            setStandingsData(resp);
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occured while fetching standings history');
            setLoading(false);
        }
    }

    const fetchLeagues = async () => {
        try {
            let resp = await fetchStandingsLeagues();
            let arr = [];
            for (let i = 0; i < resp?.length; i++) {
                arr?.push({
                    label: resp[i]?.name,
                    value: resp[i]?.id
                })
            }
            setLeagueOptions(arr);
        }
        catch (err) {
            toast?.error(err?.response?.data?.message || 'An error occurred while fetching leagues');
        }
    }

    const fetchLeaguesSeasons = async (id) => {
        try {
            let resp = await fetchStandingsSeasons(id);
            let arr = [];
            for (let i = 0; i < resp?.length; i++) {
                arr?.push({
                    label: resp[i]?.name,
                    value: resp[i]?.id
                })
            }
            setSeasonOptions(arr);
        }
        catch (err) {
            toast?.error(err?.response?.data?.message || 'An error occurred while fetching leagues');
        }
    }

    const fetchFilteredData = async (leagueid, seasonid, search) => {
        setDataLoading(true);
        try {
            let resp = await fetchStandingsHistory(leagueid, seasonid, search);
            if (resp?.length > 0) {
                setStandingsData(resp);
                setDataLoading(false);
            }
            if (resp?.length === 0) {
                // setNoFound(true);
                setStandingsData(resp);
                setDataLoading(false);
            }
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occured while fetching standings history');
            setDataLoading(false);
        }
    }

    const handleLeagueDropdown = (event) => {
        setLeagueVal(event);
        setSeasonVal('Select season');
        fetchLeaguesSeasons(event?.value);
        fetchFilteredData(event?.value, '', search);
    }

    const handleSeasonDropdown = (event) => {
        setSeasonVal(event);
        fetchFilteredData(leagueVal?.value, event?.value, search);
    }

    return (
        <>
            <Navbar />
            <>{loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" />
                :
                <>{
                    noFound === true ?
                        <ErrorMsg errormsg={'No Records Exist'} />
                        :
                        <div className="standings-history">
                            <div className="standings-header d-flex align-items-center">
                                <img src={svg.BackArrow} className="backarrow" alt="back NP" onClick={() => navigate(-1)} />
                                <div className="explore-leagues-title">Standings History</div>
                            </div>

                            <div className="leagues-header-body">
                                <div className='app-users-filters d-flex  mt-4'>
                                    <div className="search-bar-leagues">
                                        <span className="search-icon" onClick={() => {
                                            fetchFilteredData(leagueVal?.value, seasonVal?.value, search);
                                        }}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z" stroke="#4A514C" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16.4431 16.4438L20.9995 21.0002" stroke="#4A514C" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>


                                        </span>
                                        <input
                                            type="text"
                                            value={search}
                                            className="SearchLabel searchUsers w-100"
                                            onChange={(e) => {
                                                setSearch(e?.target?.value);
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    setSearch(e?.target?.value);
                                                    fetchFilteredData(leagueVal?.value, seasonVal?.value, e?.target?.value);
                                                }
                                            }}
                                            placeholder="Search leagues...."
                                            style={{ '::placeholder': { color: '#4A514C99' } }}
                                        />
                                    </div>
                                    <div className='app-users-dropdown d-flex'>
                                        <Select
                                            options={leagueOptions}
                                            value={leagueVal}
                                            placeholder={
                                                <div className="select-placeholder-text">Select league</div>
                                            }
                                            onChange={handleLeagueDropdown}
                                            className="w-100 gender-dropdown"
                                            styles={customStyles}
                                        />

                                        <Select
                                            options={seasonOptions}
                                            value={seasonVal}
                                            placeholder={
                                                <div className="select-placeholder-text">Select season</div>
                                            }
                                            onChange={handleSeasonDropdown}
                                            className="w-100 gender-dropdown"
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>

                                <div className="explore-leagues-body d-flex flex-column mt-4">
                                    <>{dataLoading === true ? <Loader loadingMsg="Data is Loading... Please Wait" classprop={"loading-container"} /> :
                                        <>
                                            {
                                                standingsData?.map((data, index) => (

                                                    <div className="explore-league-card" key={index}>

                                                        <div className="league-card-body1">
                                                            <div className="body1">
                                                                <div className="body1-name trimTxt1line">{data?.tier?.name} {data?.name}</div>
                                                                <div className="body1-date">{convertDateToMonth(data?.tier?.season?.start_date)} - {convertDateToMonth(data?.tier?.season?.end_date)}</div>
                                                            </div>

                                                            <>{getwidth < mobileBreakpoint &&
                                                                <div className="join-league-wrapper-body1 d-flex align-items-center justify-content-between">
                                                                    <div className="leaderboard-txt">Leaderboard</div>
                                                                </div>
                                                            }</>
                                                        </div>

                                                        <div className="league-card-body2 d-flex align-items-center">
                                                            <div className="body2-ghin d-flex flex-column">
                                                                <div className="ghin-range">{data?.standings?.round_count === 0 ? 'N/A' : data?.standings?.score}</div>
                                                                <div className="ghin-txt">Score</div>
                                                            </div>
                                                            <div className="divider"></div>

                                                            <div className="body2-ghin d-flex flex-column">
                                                                <div className="ghin-range">{data?.standings?.ranking || ' '}</div>
                                                                <div className="ghin-txt">Ranking</div>
                                                            </div>
                                                            <div className="divider"></div>

                                                            <div className="body2-ghin d-flex flex-column">
                                                                <div className="ghin-range">{data?.standings?.round_count}/6</div>
                                                                <div className="ghin-txt">Rounds</div>
                                                            </div>

                                                            <>{getwidth >= mobileBreakpoint && <div className="join-league-wrapper d-flex flex-column">
                                                                <div className="leaderboard-txt">Leaderboard</div>
                                                            </div>
                                                            }</>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </>
                                    }</>
                                </div>
                            </div>
                        </div>
                }</>
            }</>
        </>
    )
}

export default StandingsHistory;