export function formatNumberAsOrdinal(number) {
    if (number % 100 >= 11 && number % 100 <= 13) {
        return "th";
    } else {
        switch (number % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    }
}