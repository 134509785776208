import React, { useState, useEffect } from "react"
import AdminSidebar from '../components/Admin/AdminSidebar';
import { Container, Row, Col } from "react-bootstrap"
import { useWindowSize } from "../utils/useWindowSize.js"

let mobileBreakpoint = 990
const AdminLayout = ({ children }) => {
  const [height, width] = useWindowSize()
  const [getwidth, setWidth] = useState(0)
  const [showSidebar, setShowSidebar] = React.useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window?.innerWidth)
    }
  }, [width])

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar)
  }
  return (
    <Container fluid>
      <Row className="admin-layout-cont">
        <Col
          md={2}
          xs={12}
          className={`admin-sidenav-wrapper p-0 ${showSidebar ? "open" : ""}`}
        >
          <AdminSidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />
        </Col>
        <Col
          className={
            getwidth >= mobileBreakpoint
              ? "page-body col-md-10 col-sx-12"
              : "page-body col-md-12 col-xs-12"
          }
          style={{
            background: '#F1F2F2', height: '100vh',
            overflowY: 'auto'
          }}
        >
          <div className="page-content-wrapper" 
          // style={{ padding: '80px' }}
          >{children}</div>
        </Col>
      </Row>
    </Container>
  )
}

export default AdminLayout
