import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { fetchAllLeagues, fetchSelLeagueSeason, fetchSelSeasonTiers, moveUsers } from '../../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import './moveLeaguespopup.scss';

const MoveToLeaguesPopup = (props) => {
    const navigate = useNavigate();
    const [leagueVal, setLeagueVal] = useState('');
    const [selLeague, setSelLeague] = useState(props?.userData);
    const [leagueOptions, setleagueOptions] = useState([]);
    const [seasonOptions, setSeasonOptions] = useState([]);
    const [seasonVal, setSeasonVal] = useState({});
    const [tierOptions, setTierOptions] = useState([]);
    const [tierVal, setTierVal] = useState({});
    const [tierError, setTierError] = useState(false);

    useEffect(() => {
        fetchLeagues();
    }, []);

    const fetchLeagues = async () => {
        try {
            let resp = await fetchAllLeagues();
            let temp = resp?.results;
            let arr = [];
            for (let i = 0; i < temp?.length; i++) {
                arr?.push({
                    label: temp[i]?.name,
                    value: temp[i]?.id
                })
            }
            setleagueOptions(arr);
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occurred while processing your request.');
        }
    }

    const handleLeagueDropdown = async (event) => {
        setSeasonVal({});
        setLeagueVal(event);
        try {
            let seasonResp = await fetchSelLeagueSeason({ id: event?.value });
            let seasonTemp = seasonResp;
            let seasonArr = [];
            for (let i = 0; i < seasonTemp?.length; i++) {
                seasonArr?.push({
                    label: seasonTemp[i]?.name,
                    value: seasonTemp[i]?.id
                })
            }
            setSeasonOptions(seasonArr);
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occurred while fetching seasons');
        }
    }

    const handleSeasonDropdown = async (event) => {
        setSeasonVal(event);
        setTierVal({});
        try {
            let seasonResp = await fetchSelSeasonTiers(event?.value);
            let seasonTemp = seasonResp;
            let seasonArr = [];
            for (let i = 0; i < seasonTemp?.length; i++) {
                seasonArr?.push({
                    label: seasonTemp[i]?.name,
                    value: seasonTemp[i]?.id
                })
            }
            setTierOptions(seasonArr);
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occurred while fetching tiers');
        }
    }

    const handleTierDropdown = (event) => {
        setTierVal(event);
        setTierError(false);
    }

    const handleClose = () => {
        props.handleMovePopupClose();
    }

    const handleMoveTo = async () => {
        let arr = [];
        for (let i = 0; i < selLeague?.length; i++) {
            arr?.push({
                tier: selLeague[i]?.tier_id,
                to_tier: tierVal?.value,
                user: selLeague[i]?.user_id
            })
        }
        const body = {
            users: arr
        }
        try {
            let resp = await moveUsers(body);
            let userStr = '';
            let successMsg = ''
            let erroruserStr = '';
            let errorMsg = ''
            for (let i = 0; i < resp?.length; i++) {
                if (resp[i]?.status === true) {
                    userStr += resp[i]?.user + ',';
                    successMsg = resp[i]?.message
                }
                else {
                    erroruserStr += resp[i]?.user + ',';
                    errorMsg = resp[i]?.message
                }
            }
            if (userStr?.length > 0)
                toast.success(userStr?.substring(0, userStr?.length - 1) + " " + successMsg);
            if (erroruserStr?.length > 0) {
                toast.error(erroruserStr?.substring(0, erroruserStr?.length - 1) + " " + errorMsg)
            }
            setTimeout(() => {
                window?.location?.reload();
            }, 1000);

            props.handleMovePopupSuccessClose();
        }
        catch (err) {
            // toast.error(err?.response?.data?.message || 'An error while moving user');
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='move-leagues-popup-wrapper'
            show={props.show}
            onHide={handleClose}
            centered>
            <Modal.Header closeButton className='move-popup-header d-flex'>
                <Modal.Title className="modalTitle move-to-title">Move to</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <div className="del-sub">
                    Move selected to another league
                </div>

                <div className='select-wrapper w-100'>
                    <div className='mt-1'>
                        <label className='move-to-league-label'>Leagues</label>
                        <Select
                            options={leagueOptions}
                            value={leagueVal}
                            placeholder={
                                <div className="select-placeholder-text">Select</div>
                            }
                            onChange={handleLeagueDropdown}
                            className="w-100 gender-dropdown  mt-2"
                        />
                    </div>

                    <div className="mt-2">
                        <label className="greyLabel move-to-league-label">Season</label>
                        <Select
                            options={seasonOptions}
                            value={seasonVal}
                            // placeholder={
                            //     <div className="select-placeholder-text">Select</div>
                            // }
                            placeholder="Select"
                            onChange={handleSeasonDropdown}
                            className="w-100 gender-dropdown mt-2"
                        /></div>

                    <div className="mt-2">
                        <label className="greyLabel move-to-league-label">Tier</label>
                        <Select
                            options={tierOptions}
                            value={tierVal}
                            placeholder={
                                <div className="select-placeholder-text">Select</div>
                            }
                            onChange={handleTierDropdown}
                            className="w-100 gender-dropdown mt-2"
                        /></div>

                    <>{tierError === true &&
                        <div className="invalid-feedback displayBlock">
                            Tier is required
                        </div>
                    }</>
                </div>

                <div className='del-buttons-wrapper w-100 mt-2'>
                    <Button
                        onClick={() => handleMoveTo()}
                        className="delBtn w-100">
                        Confirm
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default MoveToLeaguesPopup;
