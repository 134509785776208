import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import './movepopup.scss';

const MoveToPopup = (props) => {
    const [leagueVal, setLeagueVal] = useState('');
    const [leagueOptions, setLeagueOptions] = useState([{
        label: 'l1',
        value: 'l1'
    },
    {
        label: 'l2',
        value: 'l2'
    },
    {
        label: 'l3',
        value: 'l3'
    }])
    const navigate = useNavigate();

    const handleClose = () => {
        props.handleMovePopupClose();
    }

    const handleMoveTo = async () => {}

    const handleLeagueDropdown = (event) => {
        setLeagueVal(event);
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='move-popup-wrapper'
            show={props.show}
            onHide={handleClose}
            centered>
            <Modal.Header closeButton className='move-popup-header d-flex'>
                <Modal.Title className="modalTitle move-to-title">Move to</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <div className="del-sub">
                    Move selected to another league
                </div>

                <div className='select-wrapper w-100'>
                    <label className='move-to-league-label'>Leagues</label>
                    <Select
                        options={leagueOptions}
                        value={leagueVal}
                        placeholder={
                            <div className="select-placeholder-text">Select</div>
                        }
                        onChange={handleLeagueDropdown}
                        className="w-100 gender-dropdown  mt-2"
                    />
                </div>
                <div className='del-buttons-wrapper w-100 mt-4'>
                    <Button
                        onClick={() => handleMoveTo()}
                        className="delBtn w-100">
                        Confirm
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default MoveToPopup;
