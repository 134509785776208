import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';
import svg from '../../../assets/images/svg/index';
import { fetchAdminUsers } from '../../../api/request';
import Loader from '../../../CustomComponents/Loader';
import DeleteNotifications from './DeleteNotifications.jsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    useTable,
    usePagination,
    useSortBy,
    useRowSelect,
    useGlobalFilter,
} from 'react-table';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';

const AdminNotifications = () => {
    const navigate = useNavigate();
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [getNextPage, setNextPage] = useState(false);
    const [getPrevPage, setPrevPage] = useState(false);
    const [getData, setData] = useState({});
    const [loading, setLoading] = useState(true); // Add loading state
    const [noFound, setNoFound] = useState(false);
    const [products, setProducts] = useState([]);
    const productsRef = useRef(products);
    const [userId, setUserId] = useState(null);
    const [showSingleUser, setshowSingleUser] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [rowCheckboxStates, setRowCheckboxStates] = useState({});
    const [showDelPopup, setDelPopup] = useState(false);

    const handlePrevPage = () => {
        console.log('canPreviousPage', getPrevPage);
        if (getData?.next !== null) {
            setNextPage(false);
        }
        if (getData?.previous !== null) {
            const urlParams = new URLSearchParams(new URL(getData?.previous).search);
            const pageValue = urlParams.get("page");
            if (pageValue === null) {
                setCurrentPageIndex(1);
                fetchUsers('1');
            }
            else {
                setCurrentPageIndex(currentPageIndex - 1);
                fetchUsers(pageValue);
            }
        }
        else {
            setPrevPage(true);
        }
    }

    const handleNextPage = () => {
        console.log('NextPage', getNextPage);
        if (getData?.previous !== null) {
            setPrevPage(false);
        }
        if (getData?.next !== null) {
            const urlParams = new URLSearchParams(new URL(getData?.next).search);
            const pageValue = urlParams.get("page");
            setCurrentPageIndex(currentPageIndex + 1);
            fetchUsers(pageValue);
        }
        else {
            setNextPage(true);
        }
    };
    const handleDelClose = () => {
        setDelPopup(false);
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async (pageValue) => {
        setLoading(true);
        try {
            let resp = await fetchAdminUsers(pageValue || currentPageIndex);
            console.log('response from fetchAdminUsers', resp);
            if (resp) {
                setProducts(resp?.results);
                setData(resp);
                if (resp?.next !== null) {
                    setNextPage(false);
                }
                setTotalCount(resp?.count);
                setLoading(false);
            }
        }
        catch (err) {
            console.log('Error in fetch users', err);
            setLoading(false);
            if (err?.response?.data?.detail === "Invalid token.") {
                navigate('/admin/login');
            }
        }
    }

    useEffect(() => {
        // Update the ref value whenever the products state changes
        productsRef.current = products;
    }, [products]);

    const data = useMemo(() => products, [products]);

    const handleSelectAllRows = (event) => {
        // const allRowsData = products?.map((row) => row);
        console.log('allRowsData', productsRef?.current);

        const isChecked = event?.target?.checked;
        const updatedRowCheckboxStates = productsRef.current.reduce(
            (checkboxStates, row) => {
                checkboxStates[row.id] = isChecked;
                return checkboxStates;
            },
            {}
        );
        console.log('updatedRowCheckboxStates', updatedRowCheckboxStates);

        setSelectedRows(isChecked ? productsRef.current : []);
        setRowCheckboxStates(updatedRowCheckboxStates);
        setSelectAllChecked(isChecked)
    };

    const handleSelectRow = (event, row) => {
        const isChecked = event?.target?.checked;
        setRowCheckboxStates((prevCheckboxStates) => ({
            ...prevCheckboxStates,
            [row?.original?.id]: isChecked,
        }));
        if (isChecked) {
            setSelectedRows((prevSelected) => [...prevSelected, row.original]);;
        } else {
            setSelectedRows((prevSelected) =>
                prevSelected.filter((selectedRow) => selectedRow.id !== row.original.id)
            );
        }
    };

    useEffect(() => {
        console.log('selectedRows', selectedRows);
    }, [selectedRows]);

    useEffect(() => {
        // Reset selectedRows when data changes
        setSelectedRows([]);
    }, [data]);

    const columns = useMemo(
        () => [
            {
                Header: (
                    <>
                        <input
                            type="checkbox"
                            onChange={(event) => {
                                handleSelectAllRows(event);
                            }}
                            className="custom-checkbox"
                        />
                        <span className='header-txt'>Notification Name</span>
                    </>
                ),
                Cell: ({ row }) => (
                    <div className='uname-cell cursor-pointer'
                    >
                        <input
                            type="checkbox"
                            checked={rowCheckboxStates[row.original.id] || false}
                            onChange={(event) => {
                                handleSelectRow(event, row);
                            }
                            }
                            className="custom-checkbox"
                        />
                        <span className='header-cell' onClick={() => {
                            setUserId(row?.original?.id);
                            setshowSingleUser(true);
                        }
                        }>{row?.original?.full_name}</span>
                    </div>
                ),
                accessor: 'full_name',
                sticky: 'left',
                disableSortBy: selectAllChecked,
            },
            {
                Header: 'Content',
                accessor: 'email',
                sticky: 'left',
                disableSortBy: true,
            },
            {
                Header: 'Activation time',
                accessor: 'gender',
                sticky: 'left',
                disableSortBy: true,
            },
            {
                Header: 'Sent to',
                accessor: 'referrals',
                sticky: 'left',
                disableSortBy: true,
            },
        ],
        [rowCheckboxStates, selectAllChecked],
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
    );

    const pageCount = Math.ceil(totalCount / 10);

    return (
        <>
            {
                loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" />
                    :
                    <div className="admin-users-wrapper admin-noti-wrapper adminpadding">
                        <div className='admin-users-head-wrapper d-flex align-items-center' style={{ gap: '10px' }}>
                            <div className="admin-users-heading">
                                Notifications
                            </div>
                        </div>

                        <div className="admin-users-body">
                            <div
                                style={{
                                    padding: '0px 30px',
                                    backgroundColor: '##F3F4F6',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: '20px',
                                    borderColor: '#1334B9',
                                }}>
                            </div>
                            <>
                                <div className='app-users-filters-wrapper d-flex'>
                                    <>{
                                        selectedRows?.length === 0 &&
                                        <div className='admin-header-btns'>
                                            <Button className='edit-noti cursor-pointer' onClick={() => navigate('/adminnotifications/add-notification')}>Add Notification</Button>
                                        </div>
                                    }</>
                                    <>{selectedRows?.length > 1 &&
                                        <div className='admin-header-btns'>
                                            <Button className='delete-btn cursor-pointer' onClick={() => setDelPopup(true)}>Delete</Button>
                                        </div>
                                    }</>
                                    <>{selectedRows?.length === 1 &&
                                        <div className='admin-header-btns'>
                                            <Button className='delete-btn cursor-pointer' onClick={() => setDelPopup(true)}>Delete</Button>
                                            <Button className='edit-noti cursor-pointer' onClick={() => navigate('/adminnotifications/add-notification', {
                                                state: { notiData: selectedRows }
                                            })}>Edit Notification</Button>
                                        </div>
                                    }</>
                                </div>
                                <div className="table-container mt-4">
                                    <table {...getTableProps()} className="table-cls w-100">
                                        <thead className='app-users-table-head'>
                                            {headerGroups?.map((headerGroup) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers?.map((column) => (
                                                        <th
                                                            {...column.getHeaderProps(
                                                                column.getSortByToggleProps(),
                                                            )}>
                                                            {column.render('Header')}
                                                            <span>
                                                                &nbsp;
                                                                {column.disableSortBy === false ? (
                                                                    <Image src={svg?.Selector} className='cursor-pointer'></Image>
                                                                ) : null}
                                                            </span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {page?.map((row) => {
                                                prepareRow(row);
                                                return (
                                                    <tr
                                                        {...row?.getRowProps()}
                                                        className='table-body-row'
                                                    >

                                                        {row?.cells?.map((cell, cellIndex) => {
                                                            return (
                                                                <td {...cell?.getCellProps()}>
                                                                    {cell.render('Cell')}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="paginationBox d-flex justify-content-center mt-4">
                                    <div className='pagination-body d-flex justify-content-center align-items-center'>
                                        <img src={svg.Previcon}
                                            onClick={() => {
                                                if (getData?.previous !== null) handlePrevPage()
                                            }}
                                            disabled={getData?.previous === null}
                                            className={getData?.previous !== null ? 'previcon' : 'disabled'} alt="prev-icon-NP" />
                                        {Array.from({ length: pageCount }, (_, index) => (
                                            <button
                                                key={index}
                                                onClick={() => {
                                                    setCurrentPageIndex(index + 1)
                                                    fetchUsers(index + 1)
                                                }}
                                                className={`paginationButton ${currentPageIndex === index + 1 ? 'activeButton' : ''
                                                    }`}>
                                                {index + 1}
                                            </button>
                                        ))}
                                        <img src={svg.Nexticon} alt="prev-icon-NP"
                                            onClick={() => {
                                                if (getData?.next !== null) handleNextPage()
                                            }}
                                            disabled={getData?.next === null}
                                            className={getData?.next !== null ? 'previcon' : 'disabled'} />
                                    </div>
                                </div>
                            </>
                        </div>
                    </div >

            }
            {
                showDelPopup === true &&
                <DeleteNotifications handleDelClose={handleDelClose} show={showDelPopup} data={selectedRows} />
            }
        </>
    )
}

export default AdminNotifications;