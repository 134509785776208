import React, { useEffect, useState, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { formatRoundsDate } from '../../utils/formatRoundsDate.js';
import Navbar from "../../CustomComponents/Navbar";
import svg from '../../assets/images/svg/index';
import Visa from '../../assets/images/png/Visa.png';
import Mastercard from '../../assets/images/png/Mastercard.png';
import { managePaymentHistory, fetchGetPaymentMethods, addPaymentMethod, deletePaymentMethod } from '../../api/request';
import Loader from '../../CustomComponents/Loader';
import DeletePayment from './DeletePayment.jsx';
import {
    TextField,
} from "@material-ui/core";
import { Button } from 'react-bootstrap';
import ErrorMsg from '../../CustomComponents/ErrorMsg';
import {
    useForm,
    Controller,
    FormProvider,
    useFormContext,
} from "react-hook-form";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { toast } from 'react-toastify';
import { useWindowSize } from "../../utils/useWindowSize.js";
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
const stripePromise = loadStripe('pk_test_51N8OMlFdHTQtW0piO3f6beyjQy8pR3FQltBK5pAIts4sK3namjf9Bv2aVZ2AKdx5FeSER5BLvIh3oT3LBnndxV4k00120xHlb7');

const cardId = 'card_1Niw9zFdHTQtW0piHgzGcOE8';
const mobileBreakpoint = 820;
const ManagePayments = () => {
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0);
    const [loading, setLoading] = useState(false);
    const [getPaymentHistory, setPaymentHistory] = useState([]);
    const [savedCards, setSavedCards] = useState([]);
    const [showDeletePayment, setDeletePayment] = useState(false);
    const [showPaymentId, setPaymentId] = useState(null);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    useEffect(() => {
        fetchPaymentHistory();
        fetchCardDetails();
    }, []);

    const fetchPaymentHistory = async () => {
        setLoading(true);
        try {
            let resp = await managePaymentHistory();
            setPaymentHistory(resp);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            toast.error(err?.response?.data?.message || 'Error file fetching payment history');
        }
    }


    const fetchCardDetails = async () => {
        setLoading(true)
        try {
            let resp = await fetchGetPaymentMethods();
            setSavedCards(resp?.data);
            setLoading(false)
        }
        catch (err) {
            setLoading(false)
        }
    };

    const renderImage = (cardBrand) => {
        if (cardBrand === 'visa') {
            return Visa
        }
        if (cardBrand === 'mastercard') {
            return Mastercard
        }
        if (cardBrand?.toLowerCase() === 'amex') {
            return 'https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg'
        }
        if (cardBrand?.toLowerCase() === 'diners') {
            return 'https://js.stripe.com/v3/fingerprinted/img/diners-fbcbd3360f8e3f629cdaa80e93abdb8b.svg'
        }
        if (cardBrand?.toLowerCase() === 'jcb') {
            return 'https://js.stripe.com/v3/fingerprinted/img/jcb-271fd06e6e7a2c52692ffa91a95fb64f.svg'
        }
        if (cardBrand?.toLowerCase() === 'discover') {
            return 'https://js.stripe.com/v3/fingerprinted/img/discover-ac52cd46f89fa40a29a0bfb954e33173.svg'
        }
        if (cardBrand?.toLowerCase() === 'unionpay') {
            return 'https://js.stripe.com/v3/fingerprinted/img/unionpay-8a10aefc7295216c338ba4e1224627a1.svg'
        }
    }

    const handleRemovePaymentMethod = async (data) => {
        setDeletePayment(true);
        setPaymentId(data?.id);
    }
    const closeDeletePayment = () => {
        setDeletePayment(false);
    }


    return (
        <div>
            <Navbar />
            {
                loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" />
                    :
                    <div className="manage-payments-wrapper">
                        <div className="def-league-img-payments">
                            {
                                getwidth >= mobileBreakpoint &&
                                <img src={svg?.UserDashboardDft} className="def-img w-100 h-100" alt="def img NP" />
                            }
                        </div>

                        <div className='payments-body-wrapper'>
                            <div className='payment-heading'>Payments</div>
                            <div className='payment-body-1'>
                                <div className='sub-payment-wrapper d-flex2'>
                                    <div className='subscription-info'>
                                        <div className='sub-head'>Subscription</div>
                                        <div className='sub-line mt-2'></div>
                                        <div className='sub-data-wrapper'>
                                            <div className='sub-name'>{getPaymentHistory[0]?.subscription}</div>
                                            <div className='sub-data-summary d-flex justify-content-space-between'>
                                                <div className='sub-price-info d-flex flex-column'>
                                                    <div className='sub-price'>${getPaymentHistory[0]?.credits}</div>
                                                    <div className='sub-nextpayment'>Next Payment</div>
                                                </div>

                                                <div className='sub-view-summary'>
                                                    View Summary
                                                </div>
                                            </div>

                                            <div className='sub-line'></div>

                                            <div className='sub-date-renew-wrapper'>
                                                <div className='sub-data-amt-wrapper d-flex justify-content-between'>
                                                    <div className='sub-date'>
                                                        {getPaymentHistory[0]?.created && formatRoundsDate(getPaymentHistory[0]?.created?.split(' ')[0])}
                                                    </div>
                                                    <div className='sub-amount'>
                                                        Amount: ${getPaymentHistory[0]?.amount}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='payment-info'>
                                        <div className='payment-head'>Payment Method</div>
                                        <div className='sub-line mt-2'></div>
                                        <div className='payment-data-wrapper'>
                                            <div className='saved-cards-listing d-flex flex-column mt-2'>
                                                {
                                                    savedCards?.map((item, index) => (
                                                        <div className='saved-card'>
                                                            <div className='card-type'>
                                                                <img src={renderImage(item?.card?.brand)} className='card-type-img w-100 h-100' alt="no NP" />
                                                            </div>
                                                            <div className='card-details d-flex flex-column'>
                                                                <div className='saved-card-num'>{item?.card?.brand?.charAt(0)?.toUpperCase() + item?.card?.brand?.slice(1)} ****{item?.card?.last4}</div>
                                                                <div className='saved-card-expiry'>Expires {item?.card?.exp_month}/{item?.card?.exp_year}</div>
                                                            </div>
                                                            <div className='del-saved-card'>
                                                                <img src={svg?.DeletePlayer} className='del-card-img w-100 h-100' alt="imgg np" onClick={() => handleRemovePaymentMethod(item)} />
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div className='add-payment mt-4'>
                                                <div className='payment-head'>Add Payment Method</div>
                                                <div className='sub-line mt-2'></div>
                                            </div>
                                        </div>
                                        <div className='payment-history'>
                                            <Elements stripe={stripePromise}>
                                                <Paymentform />
                                            </Elements>
                                        </div>
                                    </div>
                                </div>
                                <div className='payment-history-wrapper'>
                                    <div className='payment-his-heading'>Payment History</div>
                                    <div className='sub-line mt-2'></div>
                                    <div className='payment-history-card-listing'>
                                        {
                                            getPaymentHistory?.map((data, index) => (
                                                <>
                                                    <div className='payment-history-card' key={index}>
                                                        <div className='his-body-wrapper d-flex justify-content-between'>
                                                            <div className='his-date-name d-flex flex-column'>
                                                                <div className='his-name trimTxt1line'>{data?.league} &nbsp;{data?.tier}</div>
                                                                <div className='his-date'>{data?.created && formatRoundsDate(data?.created?.split(' ')[0])}</div>
                                                            </div>

                                                            <div className='his-amount-status d-flex flex-column'>
                                                                <div className='his-amt'>${data?.amount}</div>
                                                                <div className='his-status'>{data?.status} </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            {
                showDeletePayment === true &&
                <DeletePayment show={showDeletePayment} data={showPaymentId} handleDelClose={closeDeletePayment} />
            }
        </div>
    );
}


const Paymentform = () => {
    const cardExpiryRef = useRef(null);
    const cardNumberRef = useRef(null);
    const cardCvcRef = useRef(null);
    const stripe = useStripe();
    const elements = useElements();
    const { control, handleSubmit, formState: { errors } } = useForm();
    const [getToken, setToken] = useState(null);
    const [card, setCard] = useState(null);
    const [switchToggle, setSwitchToggle] = useState(false);
    const [cardCvvErr, setCardCvvErr] = useState(true);
    const [cvvValue, setCvvValue] = useState('');
    const [cardNumErr, setCardNumErr] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [expDateErr, setExpDateErr] = useState(true);
    const [showAddCardDisabled, setAddCardDisabled] = useState(false);

    const addPaymentHandler = async (event) => {
        setAddCardDisabled(true)
        event?.preventDefault();
        setIsFormSubmitted(true);
        // Handle Stripe submission here
        if (!stripe || !elements) {
            // Stripe hasn't loaded yet
            return;
        }
        const data = {}

        const cardElement = elements.getElement(CardNumberElement);
        if (data?.cardName === '') {
            toast.error("Please provide a valid credit card number");
            return;
        }
        stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: data?.cardName,
            },
        })
            .then(function (result) {
                addPaymentMethodFunc(result?.paymentMethod)
            });
    };

    const addPaymentMethodFunc = async (data) => {
        try {
            let resp = await addPaymentMethod({
                payment_method_id: data?.id
            });
            if (resp) {
                toast.success('Payment method added successfully');
                setAddCardDisabled(false)
                window?.location?.reload();
            }
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occured while adding a new payment method');
        }
    }
    const handleFieldChange = (e) => {
    }

    return (

        <form
            className="payment-body-form-wrapper"
            onSubmit={(e) => addPaymentHandler(e)}
        >
            <div className='payment-body'>
                <div className="row mt-4 payment-form-row">
                    <div className="form-field w-100 card-number-wrapper">
                        <label htmlFor="card-number" className="txt-label">Card number</label>
                        <div className={`custom-card-element mt-2 ${errors.cardNumber ? 'has-error' : ''}`}>
                            <CardNumberElement
                                name="cardNumber"
                                id="card-number"
                                className="card-number-txt"
                                options={{
                                    showIcon: true,
                                    style: {
                                        base: {
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            fontFamily: "Proxima Nova Condensed",
                                            border: '1px solid #ced4da',
                                            "::placeholder": {
                                                color: "#aab7c4",
                                                fontFamily: "Proxima Nova Condensed",
                                            },
                                        },
                                        invalid: {
                                            color: "#9e2146",
                                        },
                                    },
                                }}
                                onChange={(event) => {
                                    cardNumberRef.current = event;
                                    if (event?.empty === false) {
                                        setCardNumErr(false);
                                    }
                                    else {
                                        setCardNumErr(true);
                                    }
                                    setToken(null);
                                }}
                            />
                        </div>
                        {((cardNumErr === true)) && <p className="error text-danger-txt">Card number is required</p>}
                    </div>

                    <div className="form-field payment-exp-cvv w-100 d-flex mt-2">
                        <div className="exp-date-wrapper w-50">
                            <label htmlFor="exp-date trimTxt1line" className="txt-label">Expiration date</label>
                            <div className={`custom-card-element mt-2 ${errors.expDate ? 'has-error' : ''}`}>
                                <CardExpiryElement
                                    name="expDate"
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: "16px",
                                                fontWeight: "400",
                                                fontFamily: "Proxima Nova Condensed",
                                                border: '1px solid #ced4da',
                                                "::placeholder": {
                                                    color: "#aab7c4",
                                                    fontFamily: "Proxima Nova Condensed",
                                                },
                                            },
                                            invalid: {
                                                color: "#9e2146",
                                            },
                                        },
                                    }}
                                    onChange={(event) => {
                                        cardExpiryRef.current = event;
                                        if (event?.empty === false) {
                                            setExpDateErr(false);
                                        }
                                        else {
                                            setExpDateErr(true)
                                        }
                                    }}
                                />
                            </div>
                            {(expDateErr === true) && <p className="error text-danger-txt">Expiration date is required</p>}
                        </div>

                        <div className="form-field w-50 cvc-wrapper">
                            <label htmlFor="cvv" className="txt-label">CVV</label>
                            <div className={`custom-card-element mt-2 ${errors.cardCvc ? 'has-error' : ''}`}>
                                <CardCvcElement
                                    id="cvv"
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: "16px",
                                                fontWeight: "400",
                                                fontFamily: "Proxima Nova Condensed",
                                                border: '1px solid #ced4da',
                                                "::placeholder": {
                                                    color: "#aab7c4",
                                                    fontFamily: "Proxima Nova Condensed",
                                                },
                                            },
                                            invalid: {
                                                color: "#9e2146",
                                            },
                                        },
                                    }}
                                    onChange={(event) => {
                                        cardCvcRef.current = event;
                                        if (event?.empty === false) {
                                            setCardCvvErr(false);
                                        }
                                        else {
                                            setCardCvvErr(true)
                                        }
                                        setCvvValue(event.complete ? event.value : '');
                                    }}
                                />
                            </div>
                            {(cardCvvErr === true) && <p className="error text-danger-txt">CVC is required</p>}
                        </div>

                    </div>

                    <div className="form-field w-100 mt-2 card-holder-name-wrapper">
                        <label htmlFor="card-name" className="txt-label">Card holder name</label>
                        <Controller
                            control={control}
                            name="cardName"
                            rules={{
                                required: "Card holder name is required",
                            }}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        id="card-name"
                                        variant="outlined"
                                        placeholder="Enter Card holder name"
                                        fullWidth
                                        margin="normal"
                                        onChange={(e) => {
                                            // field.onChange(e);
                                            handleFieldChange(e);
                                        }}
                                        {...field}
                                    />

                                    {(field?.value === '' || field?.value === undefined) && <p className="error text-danger-txt minusMargin" style={{ marginBottom: '0px' }}>Card holder name is required</p>}
                                </>
                            )}
                        />
                    </div>
                </div>
            </div>
            <Button className='add-payment-btn  w-100' type="submit" disabled={showAddCardDisabled}>Add Payment</Button>
        </form>
    )
}

export default ManagePayments;
