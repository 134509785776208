import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';
import svg from '../../../assets/images/svg/index';
import { fetchAdminUsers, fetchGenderAdminUsers } from '../../../api/request';
import Loader from '../../../CustomComponents/Loader';
import { toast } from 'react-toastify';
import SpecificCourse from './SpecificCourse.jsx';
import 'react-toastify/dist/ReactToastify.css';
import {
    useTable,
    usePagination,
    useSortBy,
    useRowSelect,
    useGlobalFilter,
} from 'react-table';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';

const AdminCourses = () => {
    const navigate = useNavigate();
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [getNextPage, setNextPage] = useState(false);
    const [getPrevPage, setPrevPage] = useState(false);
    const [getData, setData] = useState({});
    const [loading, setLoading] = useState(true); // Add loading state
    const [noFound, setNoFound] = useState(false);
    const [products, setProducts] = useState([]);
    const productsRef = useRef(products);
    const [search, setSearch] = useState('');

    const handlePrevPage = () => {
        if (getData?.next !== null) {
            setNextPage(false);
        }
        if (getData?.previous !== null) {
            const urlParams = new URLSearchParams(new URL(getData?.previous).search);
            const pageValue = urlParams.get("page");
            if (pageValue === null) {
                setCurrentPageIndex(1);
                fetchUsers('1');
            }
            else {
                setCurrentPageIndex(currentPageIndex - 1);
                fetchUsers(pageValue);
            }
        }
        else {
            setPrevPage(true);
        }
    }

    const handleNextPage = () => {
        if (getData?.previous !== null) {
            setPrevPage(false);
        }
        if (getData?.next !== null) {
            const urlParams = new URLSearchParams(new URL(getData?.next).search);
            const pageValue = urlParams.get("page");
            setCurrentPageIndex(currentPageIndex + 1);
            fetchUsers(pageValue);
        }
        else {
            setNextPage(true);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async (pageValue) => {
        setLoading(true);
        try {
            let resp = await fetchAdminUsers(pageValue || currentPageIndex, search);
            if (resp) {
                setProducts(resp?.results);
                setData(resp);
                if (resp?.next !== null) {
                    setNextPage(false);
                }
                setTotalCount(resp?.count);
                setLoading(false);
            }
        }
        catch (err) {
            setLoading(false);
            if (err?.response?.data?.detail === "Invalid token.") {
                navigate('/admin/login');
            }
        }
    }

    const fetchFilteredData = async (value, pageVal, search) => {
        let resp = await fetchGenderAdminUsers(value, pageVal || currentPageIndex, search);
        if (resp) {
            setProducts(resp?.results);
            setData(resp);
            if (resp?.next !== null) {
                setNextPage(false);
            }
            setTotalCount(resp?.count);
            setLoading(false);
        }
    }

    useEffect(() => {
        // Update the ref value whenever the products state changes
        productsRef.current = products;
    }, [products]);

    const data = useMemo(() => products, [products]);

    const columns = useMemo(
        () => [
            {
                Header: 'Email',
                accessor: 'email',
                sticky: 'left',
                disableSortBy: false,
            }
        ],
        [],
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
    );
    
    const tableRows = useMemo(() => {
        return page?.map((row, rowIndex) => {
            prepareRow(row);
            return (
                <SpecificCourse key={row.id} data={row} />
            );
        });
    }, [page]); // Only recompute when 'page' changes

    const pageCount = Math.ceil(totalCount / 10);
    const startIndex = (currentPageIndex - 1) * pageCount;
    const endIndex = startIndex + pageCount;

    return (
        <>
            {
                loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" />
                    :
                    <div className="admin-courses-wrapper adminpadding">
                        <div className='admin-users-head-wrapper d-flex align-items-center' style={{ gap: '10px' }}>
                            <div className="admin-users-heading">
                                Courses Analytics
                            </div>
                        </div>

                        <div className="admin-users-body">
                            <div
                                style={{
                                    padding: '0px 30px',
                                    backgroundColor: '##F3F4F6',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: '20px',
                                    borderColor: '#1334B9',
                                }}>
                            </div>
                            <>
                                <div className='app-users-filters-wrapper d-flex justify-content-between'>
                                    <div className='app-users-filters d-flex'>
                                        <div className="search-bar w-75">
                                            <input
                                                type="text"
                                                value={search}
                                                className="SearchLabel searchUsers w-100"
                                                onChange={(e) => {
                                                    setSearch(e?.target?.value);
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setSearch(e?.target?.value);
                                                        fetchFilteredData(1, e?.target?.value);
                                                    }
                                                }}
                                                placeholder="Search"
                                                style={{ '::placeholder': { color: '#4A514C99' } }}
                                            />

                                            <span className="search-icon" onClick={() => {
                                                fetchFilteredData(1, search);
                                            }}>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.75293 12.8767C8.13916 12.8767 9.41748 12.4285 10.4634 11.6814L14.3979 15.616C14.5806 15.7986 14.8213 15.8899 15.0786 15.8899C15.6182 15.8899 16 15.4749 16 14.9436C16 14.6946 15.917 14.4539 15.7344 14.2795L11.8247 10.3616C12.6465 9.28247 13.1362 7.94604 13.1362 6.49341C13.1362 2.98218 10.2642 0.110107 6.75293 0.110107C3.25 0.110107 0.369629 2.97388 0.369629 6.49341C0.369629 10.0046 3.2417 12.8767 6.75293 12.8767ZM6.75293 11.4988C4.01367 11.4988 1.74756 9.23267 1.74756 6.49341C1.74756 3.75415 4.01367 1.48804 6.75293 1.48804C9.49219 1.48804 11.7583 3.75415 11.7583 6.49341C11.7583 9.23267 9.49219 11.4988 6.75293 11.4988Z" fill="#88BD36" />
                                                </svg>

                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-container mt-4">
                                    <table {...getTableProps()} className="table-cls w-100">
                                        {/* <tbody {...getTableBodyProps()}>
                                            {page?.map((row, rowIndex) => {
                                                prepareRow(row);
                                                return (
                                                    <SpecificCourse data={row} />
                                                );
                                            })}
                                        </tbody> */}
                                        <tbody {...getTableBodyProps()}>
                                            {tableRows}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="paginationBox d-flex justify-content-center mt-4">
                                    <div className='pagination-body d-flex justify-content-center align-items-center'>
                                        <img src={svg.Previcon}
                                            onClick={() => {
                                                if (getData?.previous !== null) handlePrevPage()
                                            }}
                                            disabled={getData?.previous === null}
                                            className={getData?.previous !== null ? 'previcon' : 'disabled'} alt="prev-icon-NP" />
                                        {Array.from({ length: pageCount }, (_, index) => (
                                            <button
                                                key={index}
                                                onClick={() => {
                                                    setCurrentPageIndex(index + 1)
                                                    fetchUsers(index + 1)
                                                }}
                                                className={`paginationButton ${currentPageIndex === index + 1 ? 'activeButton' : ''
                                                    }`}>
                                                {index + 1}
                                            </button>
                                        ))}
                                        <img src={svg.Nexticon} alt="prev-icon-NP"
                                            onClick={() => {
                                                if (getData?.next !== null) handleNextPage()
                                            }}
                                            disabled={getData?.next === null}
                                            className={getData?.next !== null ? 'previcon' : 'disabled'} />
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
            }
        </>
    )
}
export default AdminCourses;