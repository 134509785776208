import React, { useState } from "react";
import svg from '../../../assets/images/svg/index'
import Logout from "../../Settings/Logout";
import { NavLink } from "react-router-dom"
import './styles.scss';

const AdminSidebar = () => {
    const [showLogout, setshowLogout] = useState(false);

    const handleLogout = () => {
        setshowLogout(true);
    }

    const handleDelClose = () => {
        setshowLogout(false);
    }

    return (
        <>
            <div className="admin-sidebar-wrapper">
                <div className="admin-side-logo">
                    <img src={svg?.AdminLogo} className="admin-logo" alt="app logo NP" />
                </div>
                <div className="admin-sidebar-body">
                    <ul>
                        <li>
                            <NavLink
                                exact
                                to="/admin/dashboard"
                                activeclassname="active"
                                className="anchor-item"
                            // // onClick={toggleSidebar}
                            >
                                <img
                                    src={svg?.league}
                                    className="settings-icon"
                                    alt="settings icon np"
                                />{" "}
                                <span className="nav-txt">Leagues</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink
                                exact
                                to="/admin/subscriptions"
                                activeclassname="active"
                                className="anchor-item"
                            // onClick={toggleSidebar}
                            >
                                <img
                                    src={svg?.Subscriptions}
                                    className="settings-icon"
                                    alt="settings icon np"
                                />{" "}
                                Subscriptions
                            </NavLink>
                        </li>

                        <li>
                            <NavLink
                                exact
                                to="/admin/users"
                                activeclassname="active"
                                className="anchor-item"
                            // onClick={toggleSidebar}
                            >
                                <img
                                    src={svg?.Users}
                                    className="settings-icon"
                                    alt="settings icon np"
                                />{" "}
                                Users
                            </NavLink>
                        </li>

                        <li>
                            <NavLink
                                exact
                                to="/admin/adContent"
                                activeclassname="active"
                                className="anchor-item"
                            // onClick={toggleSidebar}
                            >
                                <img
                                    src={svg?.AdContent}
                                    className="settings-icon"
                                    alt="settings icon np"
                                />{" "}
                                Ad Content
                            </NavLink>
                        </li>

                        {/* <li>
                            <NavLink
                                exact
                                to="/admin/courses"
                                activeclassname="active"
                                className="anchor-item"
                            // onClick={toggleSidebar}
                            >
                                <img
                                    src={svg?.Courses}
                                    className="settings-icon"
                                    alt="settings icon np"
                                />{" "}
                                Courses
                            </NavLink>
                        </li> */}

                        {/* <li>
                            <NavLink
                                exact
                                to="/admin/notifications"
                                activeclassname="active"
                                className="anchor-item"
                            // onClick={toggleSidebar}
                            >
                                <img
                                    src={svg?.Notifications}
                                    className="settings-icon"
                                    alt="settings icon np"
                                />{" "}
                                Notifications
                            </NavLink>
                        </li> */}

                        <li>
                            <NavLink
                                exact
                                to="/admin/feedback"
                                activeclassname="active"
                                className="anchor-item"
                            // onClick={toggleSidebar}
                            >
                                <img
                                    src={svg?.Feedback}
                                    className="settings-icon"
                                    alt="settings icon np"
                                />{" "}
                                Feedback
                            </NavLink>
                        </li>

                    </ul>
                </div>
                <div className="admin-logout" onClick={() => handleLogout()}>
                    <div
                        className="anchor-item"
                    // onClick={toggleSidebar}
                    >
                        <img
                            src={svg?.Logout}
                            className="settings-icon"
                            alt="settings icon np"
                        />{" "}
                        <span className="nav-txt">Logout</span>
                    </div>
                </div>
            </div>
            {
                showLogout === true &&
                <Logout handleDelClose={handleDelClose} show={showLogout} adminLogout="true" />
            }
        </>
    )
}

export default AdminSidebar