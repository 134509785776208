import React, { useState, useEffect } from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useWindowSize } from "../../utils/useWindowSize.js";
import { sendFeedback } from '../../api/request.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

let mobileBreakpoint = 821;
const SendFeedback = () => {
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)

    const schema = Yup.object().shape({
        message: Yup.string().required('Message is required'),
        feedbackTitle: Yup.string().required('Title is required')
    });

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width])

    return (
        <div className="change-password-settings-wrapper send-feedback-wrapper">
            <>{getwidth >= mobileBreakpoint &&
                <div className="password-heading">
                    Send Feedback
                </div>
            }</>
            <div className={getwidth >= mobileBreakpoint ? "password-body mt-4" : "password-body"}>
                <div className="sub-heading">Tell us what we could do better. Your feedback is very appreciated!</div>
                <Formik
                    validationSchema={schema}
                    initialValues={{ feedbackTitle: '', message: '' }}
                    onSubmit={async (values, { resetForm }) => {
                        let body = {
                            "subject": values.feedbackTitle,
                            "message": values.message
                        }
                        try {
                            let resp = await sendFeedback(body);
                            if (resp) {
                                toast.success(resp.message);
                                resetForm()
                            }
                        }
                        catch (err) {
                            toast.error(err?.response?.data?.detail);
                        }

                    }}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                        /* and other goodies */
                    }) => (
                        <form
                            className="login-form mt-3"
                            onSubmit={handleSubmit}
                            noValidate>
                            <div className="Auth-form-content w-100">

                                <Form.Group controlId="feedbackTitle">

                                    <label className="change-pass-label settings-label">Title</label>
                                    <div
                                        className={`form-floating mb-3 ${touched.feedbackTitle && errors.feedbackTitle
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            className={getwidth >= mobileBreakpoint ? "w-75 mt-2 p-2" : "w-100 mt-2 p-2"}
                                            type='text'
                                            name="feedbackTitle"
                                            // placeholder="Enter title..."
                                            value={values.feedbackTitle}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.feedbackTitle && errors.feedbackTitle}
                                            placeholder="Email"
                                        />
                                        <Form.Control.Feedback type="invalid" className={getwidth >= mobileBreakpoint ? "w-50" : "w-100"}>
                                            {errors.feedbackTitle}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>

                                <Form.Group controlId="message">
                                    <label className="change-pass-label settings-label">Message</label>
                                    <div
                                        className={`form-floating mb-3 ${touched.message && errors.message
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            className={getwidth >= mobileBreakpoint ? "w-75 mt-2 p-2" : "w-100 mt-2 p-2"}
                                            as="textarea" rows={6}
                                            style={{ height: '115px' }}
                                            name="message"
                                            placeholder="Write a message..."
                                            value={values.message}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.message && errors.message}
                                        />
                                        <Form.Control.Feedback type="invalid" className={getwidth >= mobileBreakpoint ? "w-50" : "w-100"}>
                                            {errors.message}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>

                                <div className="d-grid gap-2 mt-3">
                                    <button
                                        type="submit"
                                        className={getwidth >= mobileBreakpoint ? "btn btn-primary reset-btn" : "btn btn-primary reset-btn w-100"}>
                                        Submit
                                    </button>
                                </div>

                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}
export default SendFeedback;