import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {formatDate} from '../../utils/formatDate';
import './popup.scss';

const CurrentSeasonPopup = (props) => {
    const navigate = useNavigate();
    const [showRemDays, setRemDays] = useState('');

    const handleClose = () => {
        props.handleClosePopup();
    }

    useEffect(() => {
        calcRemainingDays();
    }, []);

    const calcRemainingDays = async () => {
        // Define the end date (2023-08-31)
        const endDate = new Date(props?.showEndDate);

        // Get the current date
        const currentDate = new Date();

        // Calculate the time difference in milliseconds
        const timeDifference = endDate - currentDate;

        // Convert milliseconds to days
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        setRemDays(daysDifference);

    }

    const handleSeasonConfirm = async () => {
        props?.handleConfirm(props?.selData, props?.price);
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className={props?.recLeague === true ? 'season-popup-wrapper rec-popup-height' : 'season-popup-wrapper'}
            show={props.show}
            onHide={handleClose}
            centered>
            <Modal.Header>
                <Modal.Title className="modalTitle">Selected League</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <div className="del-sub">
                    {
                        props?.nextSeason === false &&
                        <>{
                            `You are registering for the current season with ${showRemDays} days remaining to log your rounds. Confirm and continue with the active season, or go back and register for the next available season.`
                        }
                        </>
                    }

                    {
                        props?.nextSeason === true &&

                        <>{
                            `You are registering for the upcoming season. Confirm to continue, or press back if you would like to register for the active season.`
                        }
                        </>
                    }

                    {
                        props?.recLeague === true &&

                        <>{
                            `A recurring charge of ${props?.price}  will automatically be applied to your payment method and start on ${formatDate(props?.showStartDate)}. You may cancel at any time, effective at the end of the billing period, by going to your payment settings. All amounts paid are non-refundable, subject to certain exceptions. By clicking “Confirm Payment”, you agree to our Terms of Service and authorize this recurring charge.`
                        }
                        </>
                    }

                </div>
                <div className='del-buttons-wrapper w-100'>
                    <Button
                        onClick={() => handleSeasonConfirm()}
                        className="delBtn w-100">
                        Confirm
                    </Button>
                    <Button className="cancelBtn w-100" onClick={handleClose}>
                        Return to League Selection
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CurrentSeasonPopup;
