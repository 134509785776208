import React, { useEffect, useState } from "react";
import Navbar from "../../CustomComponents/Navbar";
import { fetchExploreLeagues, getUserProfile, joinLeague } from '../../api/request';
import { convertDateToMonth } from '../../utils/ConvertDateToMonth.js';
import Select from 'react-select';
import Loader from '../../CustomComponents/Loader';
import svg from '../../assets/images/svg/index';
import { useWindowSize } from "../../utils/useWindowSize.js";
import ErrorMsg from '../../CustomComponents/ErrorMsg';
import CurrentSeasonPopup from '../UserOnboarding/CurrentSeasonPopup';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import SubscriptionPayment from "./SubscriptionPayment";

let mobileBreakpoint = 821;
const ExploreAllLeagues = () => {
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)
    const [userCreditBal, setUserCreditBal] = useState(null);
    const [leagueId, setleagueId] = useState(null);
    const [leagueData, setLeagueData] = useState([]);
    const [leagueDataResp, setLeagueDataResp] = useState({});
    const [showCurrSeason, setShowCurrSeason] = useState(false);
    const [showEndDate, setEndDate] = useState('');
    const [showStartDate, setStartDate] = useState('');
    const [showNextSeason, setNextSeason] = useState(false);
    const [selData, setselData] = useState({});
    const [recLeague, setRecLeague] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontFamily: "Proxima Nova Condensed", // Change to your desired font-family
            fontSize: "16px", // Change to your desired font-size
        }),
    };

    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [noFound, setNoFound] = useState(false);
    const [subPayment, setSubPayment] = useState(false);
    const [typeOptions, setTypeOptions] = useState([{
        label: 'Competitive',
        value: '1'
    },
    {
        label: 'Recreational',
        value: '2'
    },
    {
        label: 'All',
        value: '3'
    }])
    const [typeVal, settypeVal] = useState('');
    const [price, setPrice] = useState(null)

    useEffect(() => {
        fetchData();
        fetchUserProfile();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            let resp = await fetchExploreLeagues();
            if (resp?.length > 0) {
                setLoading(false);
            }
            else {
                setLoading(false);
                setNoFound(true);
            }

            setLeagueData(resp);
        }
        catch (err) {
            setLoading(false);
        }
    }

    const handleClosePopup = () => {
        setShowCurrSeason(false);
        setRecLeague(false);
        setNextSeason(false);
    }

    const fetchUserProfile = async () => {
        try {
            let resp = await getUserProfile();
            setUserCreditBal(resp?.credit_balance);
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occurred while processing your request.')
        }
    }

    const handleJoinLeague = async (data) => {
        if (Number(userCreditBal) < Number(data?.season?.price)) {
            setleagueId(data?.id);
            setLeagueDataResp(data);
            setSubPayment(true);
            setShowCurrSeason(false);
            setRecLeague(false);
        }
        if (Number(userCreditBal) >= Number(data?.season?.price)) {
            let resp = await joinLeague({
                "tier_id": Number(data?.id)
            });
            window.location.href = '/home';
            toast.success(resp?.message || 'League joined successfully');
        }
    }

    const handleCloseSubPayment = () => {
        setSubPayment(false);
    }


    const fetchFilteredData = async (leaguetype, search) => {
        setDataLoading(true);
        try {
            let resp = await fetchExploreLeagues(leaguetype, search);
            if (resp?.length > 0) {
                setLeagueData(resp);
                setDataLoading(false);
            }
            if (resp?.length === 0) {
                // setNoFound(true);
                setLeagueData(resp);
                setDataLoading(false);
            }
        }
        catch (err) {
            setDataLoading(false);
        }
    }

    const handleGenderDropdown = (event) => {
        settypeVal(event);
        fetchFilteredData(event?.value, search);
    }

    return (
        <>
            <Navbar />
            <>{loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" />
                :
                <>{
                    noFound === true ?
                        <ErrorMsg errormsg={'No Records Exist'} />
                        :
                        <div className="explore-all-leagues">
                            <div className="explore-leagues-title">Explore Leagues</div>

                            <div className="leagues-header-body">
                                <div className='app-users-filters d-flex  mt-4'>
                                    <div className="search-bar-leagues">
                                        <span className="search-icon" onClick={() => {
                                            fetchFilteredData(typeVal?.value, search);
                                        }}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z" stroke="#4A514C" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16.4431 16.4438L20.9995 21.0002" stroke="#4A514C" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>


                                        </span>
                                        <input
                                            type="text"
                                            value={search}
                                            className="SearchLabel searchUsers w-100"
                                            onChange={(e) => {
                                                setSearch(e?.target?.value);
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    setSearch(e?.target?.value);
                                                    fetchFilteredData(typeVal?.value, e?.target?.value);
                                                }
                                            }}
                                            placeholder="Search leagues...."
                                            style={{ '::placeholder': { color: '#4A514C99' } }}
                                        />
                                    </div>
                                    <div className='app-users-dropdown d-flex'>
                                        <Select
                                            options={typeOptions}
                                            value={typeVal}
                                            placeholder={
                                                <div className="select-placeholder-text">Select type</div>
                                            }
                                            onChange={handleGenderDropdown}
                                            className="w-100 gender-dropdown"
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>

                                <div className="explore-leagues-body d-flex flex-column mt-4">
                                    <>{dataLoading === true ? <Loader loadingMsg="Data is Loading... Please Wait" classprop={"loading-container"} /> :
                                        <>
                                            {
                                                leagueData?.map((data, index) => (

                                                    <div className="explore-league-card" key={index}>

                                                        <div className="league-card-body1">
                                                            <div className="body1">
                                                                <div className="body1-name trimTxt1line">{data?.league?.name} {data?.name}</div>
                                                                <div className="body1-date mt-1">{convertDateToMonth(data?.season?.start_date)} - {convertDateToMonth(data?.season?.end_date)}</div>
                                                            </div>

                                                            <>{getwidth < mobileBreakpoint &&
                                                                <div className="join-league-wrapper-body1 d-flex align-items-center justify-content-between">
                                                                    {/* <div className="leaderboard-txt">Leaderboard</div> */}
                                                                    <img src={svg?.PlusIcon} onClick={() => {
                                                                        setPrice(data?.season?.price)
                                                                        if (data?.league?.type === 1) {
                                                                            setRecLeague(false);
                                                                            if (data?.next_season?.id) {
                                                                                setNextSeason(true);
                                                                                setShowCurrSeason(false);
                                                                            }
                                                                            else {
                                                                                setNextSeason(false);
                                                                                setShowCurrSeason(true);
                                                                            }
                                                                        }
                                                                        else if (data?.league?.type === 2) {
                                                                            setRecLeague(true);
                                                                            if (data?.next_season?.id) {
                                                                                setNextSeason(false);
                                                                                setShowCurrSeason(false);
                                                                            }
                                                                            else {
                                                                                setNextSeason(false);
                                                                                setShowCurrSeason(false);
                                                                            }
                                                                        }
                                                                        setEndDate(data?.season?.end_date);
                                                                        setselData(data);
                                                                        setStartDate(data?.season.start_date)
                                                                    }} className="join-league-img cursor-pointer" alt="join league NP" title="Join League" />
                                                                </div>
                                                            }</>
                                                        </div>

                                                        <div className="league-card-body2 d-flex align-items-center">
                                                            <div className="body2-ghin d-flex flex-column">
                                                                <div className="ghin-range">
                                                                    {data?.league
                                                                        ?.type === 2 ? 'N/A' : `${data?.handicap_from}-${data?.handicap_to}`}
                                                                </div>

                                                                <div className="ghin-txt">GHIN</div>
                                                            </div>
                                                            <div className="divider"></div>

                                                            <div className="body2-ghin d-flex flex-column">
                                                                <div className="ghin-range">{data?.available_slots}</div>
                                                                <div className="ghin-txt">Spots available</div>
                                                            </div>
                                                            <div className="divider"></div>

                                                            <div className="body2-ghin d-flex flex-column">
                                                                <div className="ghin-range">{Number(data?.season?.price)?.toFixed(1)}/<span className="credit-txt">season</span></div>
                                                                <div className="ghin-txt">Credits</div>
                                                            </div>

                                                            <>{getwidth >= mobileBreakpoint && <div className="join-league-wrapper d-flex flex-column">
                                                                {/* <div className="leaderboard-txt">Leaderboard</div> */}
                                                                <Button className="join-league-btn" onClick={() =>
                                                                //  handleJoinLeague(data)
                                                                {
                                                                    setPrice(data?.season?.price)
                                                                    if (data?.league?.type === 1) {
                                                                        setRecLeague(false);
                                                                        if (data?.next_season?.id) {
                                                                            setNextSeason(true);
                                                                            setShowCurrSeason(false);
                                                                        }
                                                                        else {
                                                                            setNextSeason(false);
                                                                            setShowCurrSeason(true);
                                                                        }
                                                                    }
                                                                    else if (data?.league?.type === 2) {
                                                                        setRecLeague(true);
                                                                        if (data?.next_season?.id) {
                                                                            setNextSeason(false);
                                                                            setShowCurrSeason(false);
                                                                        }
                                                                        else {
                                                                            setNextSeason(false);
                                                                            setShowCurrSeason(false);
                                                                        }
                                                                    }
                                                                    setEndDate(data?.season?.end_date);
                                                                    setselData(data);
                                                                    setStartDate(data?.season.start_date)
                                                                }
                                                                }>Join League</Button>
                                                            </div>
                                                            }</>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </>
                                    }</>
                                </div>
                            </div>
                        </div>
                }</>
            }</>
            {
                subPayment === true &&
                <SubscriptionPayment leagueId={leagueId} leagueData={leagueDataResp} userCreditBal={userCreditBal} showHide={subPayment} handleCloseSubPayment={handleCloseSubPayment} />
            }
            {
                (showCurrSeason === true || showNextSeason === true) &&
                <CurrentSeasonPopup show={showCurrSeason || showNextSeason} handleClosePopup={handleClosePopup} showEndDate={showEndDate} nextSeason={showNextSeason} selData={selData} recLeague={recLeague} handleConfirm={handleJoinLeague} />
            }
            {
                recLeague === true &&
                <CurrentSeasonPopup show={recLeague} handleClosePopup={handleClosePopup} selData={selData} recLeague={recLeague} handleConfirm={handleJoinLeague} price={price} showStartDate={showStartDate} />
            }
        </>
    )
}

export default ExploreAllLeagues;