import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function ghinLogin() {
    const apiUrl = 'https://api-uat.ghin.com/api/v1/users/login.json';
    const headers = {
        // Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Set the Content-Type header to application/json
        // Add any other headers if required
    };
    const requestBody = {
        user: {
            email: 'support@linxleague.com',
            password: 'ConnectSwingEarn1!',
        },
    };

    return axios
        .post(apiUrl, requestBody, { headers })
        .then((response) => {
            // Handle the API response here
            return response?.data;
        })
        .catch((error) => {
            // Handle errors here
            console.error('API Request Error:', error);
            toast.error(error || 'An error occured while processing your request')
        });

}