import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { deleteAccount } from '../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteAccountChildPopup from './DeleteAccountChildPopup.jsx';
import { useNavigate } from 'react-router-dom';
import './delete.scss';

const DeleteAccount = (props) => {
    const [showDeleteAccountChildPopup, setDeleteAccountChildPopup] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        props.handleDelClose();
    }

    const handleCloseDelChild = () => {
        setDeleteAccountChildPopup(false);
        handleClose();
    }

    const handleDelAccount = async () => {
        setDeleteAccountChildPopup(true);
        
        // props?.handleDelClose();
        // let body = { user_id: Number(localStorage.getItem('userId')) };
        // try {
        //     let resp = await deleteAccount(body);
        //     toast.success(resp?.message);
        //     localStorage.removeItem('email')
        //     localStorage.removeItem('password')
        //     localStorage.removeItem('rememberme')
        //     localStorage.removeItem('token')
        //     localStorage.removeItem('userId');
        //     localStorage.removeItem('on_boarding_complete');
        //     localStorage.removeItem('refer_url');
        //     localStorage.removeItem('userProfile');
        //     localStorage.removeItem('userEmail');
        //     localStorage.removeItem('isAdmin');
        //     localStorage.removeItem('holeIndex')
        //     localStorage.removeItem('roundId')
        //     navigate('/signup');
        //     // }
        // }
        // catch (error) {
        //     toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
        // }
    }

    return (
        <>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                className='delete-acc-wrapper'
                show={props.show}
                onHide={handleClose}
                centered>
                <Modal.Header>
                    <Modal.Title className="modalTitle">Delete Account</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modalBody">
                    <div className="del-sub">
                        Are you sure you want to Delete Account? This cannot be undone.
                    </div>
                    <div className='del-buttons-wrapper w-100'>
                        <Button
                            onClick={() => handleDelAccount()}
                            className="delBtn w-100">
                            Delete Account
                        </Button>
                        <Button className="cancelBtn w-100" onClick={handleClose}>
                            Cancel
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            {
                showDeleteAccountChildPopup === true &&
                <DeleteAccountChildPopup show={showDeleteAccountChildPopup} closeDelChild={handleCloseDelChild} />
            }
        </>
    );
};

export default DeleteAccount;
