import React, { useState, useEffect } from "react";
import Navbar from "../../CustomComponents/Navbar";
import { Formik } from 'formik';
import { Form, Row, Col, Image, Button } from 'react-bootstrap';
import svg from '../../assets/images/svg/index';
import { fetchBasicUserInfo, updateUserProfile, addGhin } from '../../api/request';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import Loader from '../../CustomComponents/Loader';
import UploadPhoto from "./UploadPhoto";
import { isBefore } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Radio
} from "@material-ui/core";
import './styles.scss';
import { useForm, Controller } from 'react-hook-form';

const BasicUserProfile = () => {
    const phoneRegExp = /^\+?([0-9]{1,4})?[ .-]?\(?([0-9]{3})\)?[ .-]?([0-9]{3})[ .-]?([0-9]{4})$/;
    // const phoneRegExp = /^\d{3}-\d{3}-\d{4}$/;

    const navigate = useNavigate();
    const { control, handleSubmit, formState: { errors } } = useForm();
    const schema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        fname: Yup.string().required('First Name is required'),
        lname: Yup.string().required('Last Name is required'),
        zipcode: Yup.string().required('Zipcode is required'),
        gender: Yup.string().required('Gender is required'),
        dob: Yup.date()
            .required('Date of Birth is required')
            .test('is-adult', 'You must be at least 18 years old', function (value) {
                // Calculate today's date minus 18 years
                const minDate = new Date();
                minDate.setFullYear(minDate.getFullYear() - 18);

                // Check if the selected date is before the calculated minimum date
                return isBefore(value, minDate);
            }),
    });
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(false);
    const [genderVal, setGenderVal] = useState('');
    const [shareLink, setShareLink] = useState(localStorage.getItem('refer_url'));
    const [ghinNumber, setGhinNumber] = useState('');
    const [showGhinTxt, setShowGhinTxt] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [phoneVal, setPhoneVal] = useState('');
    const [showPhoneError, setPhoneError] = useState(false);
    const [phoneErrorMsg, setphoneErrorMsg] = useState('');

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        setLoading(true);
        try {
            let resp = await fetchBasicUserInfo();
            if (resp) {
                setUserData(resp);
                setLoading(false);
                setGhinNumber(resp?.ghin);
                setPhoneVal(resp?.phone_number);
                if (resp?.ghin !== null) {
                    setShowGhinTxt(true);
                }
            }
        }
        catch (err) {
            if (err?.response?.data?.detail === "Invalid token.") {
                navigate('/login');
            }
            setLoading(false);
        }
    }

    const handlePhoneChange = (e) => {
        setPhoneError(false);
        setPhoneVal(e);
    }

    const closeUploadPhoto = () => {
        setShowUpload(false);
    }

    const handleGhinChange = (e) => {
        setGhinNumber(e?.target?.value);
    }

    const copyLink = () => {
        const tempInput = document.createElement("input")
        tempInput.value = shareLink
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand("copy")
        document.body.removeChild(tempInput)
        toast.success('Link copied successfully');
    }

    const handleAddGhin = async (flag) => {
        if (ghinNumber === '') {
            toast.error('Ghin Number should not be empty');
            return;
        }
        try {
            let resp = await addGhin({
                ghin: ghinNumber
            });
            if (resp) {
                toast.success(resp?.message || `USGA ${flag === 'update' ? 'updated' : 'added'} successfuly`);
                window.location.reload()
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.message || 'No Golfer found with the provided GHIN')
        }
    }

    return (
        <>
            <Navbar />
            {
                loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" /> :
                    <div className="user-profile-wrapper">
                        <div className="user-profile-header">
                            <div className="user-header d-flex align-items-center">
                                <div className="user-img-div">
                                    <img src={userData?.profile_image || svg?.DefUser} className="def-user-img" alt="img Np" />
                                    <img src={svg?.EditPen} className="user-edit-profile" alt="img Np" onClick={() => setShowUpload(true)} />
                                </div>
                                <div className="user-info-div d-flex flex-column">
                                    <div className="user-name">{`${userData?.user?.first_name} ${userData?.user?.last_name}`}</div>
                                    <div className="user-email">{userData?.user?.email}</div>
                                </div>
                            </div>
                        </div>

                        <div className="user-profile-body">
                            <div className="user-profile-info">
                                <Formik
                                    validationSchema={schema}
                                    initialValues={{
                                        email: userData?.user?.email || '', fname: userData?.user?.first_name || '', lname: userData?.user?.last_name || '', dob: userData?.birthdate || '', zipcode: userData?.zip_code || '', gender: userData?.gender || ''
                                    }}
                                    onSubmit={async (values) => {
                                        if (phoneVal === '') {
                                            setPhoneError(true);
                                            setphoneErrorMsg('Phone number is required')
                                            return;
                                        }
                                        if (!phoneRegExp.test(phoneVal)) {
                                            setPhoneError(true);
                                            setphoneErrorMsg('Invalid phone number. Please use the format XXX-XXX-XXXX')
                                            return;
                                        }
                                        let body =
                                        {
                                            user: {
                                                "email": values?.email || '',
                                                "first_name": values?.fname || '',
                                                "last_name": values?.lname || '',
                                            },
                                            "phone_number": phoneVal || '',
                                            "birthdate": values?.dob || '',
                                            "zip_code": values?.zipcode || '',
                                            "gender": values?.gender || '',
                                            "ghin": ghinNumber || ''

                                        }
                                        try {
                                            let resp = await updateUserProfile(body, userData?.user?.id);
                                            if (resp) {
                                                toast.success('User details updated successfully');
                                                window.location.reload()
                                            }
                                        }
                                        catch (error) {
                                            toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
                                        }

                                        // try {
                                        //     let resp = await addGhin({
                                        //         ghin: ghinNumber
                                        //     });
                                        //     if (resp) {
                                        //         toast.success(resp?.message || 'USGA updated successfuly');
                                        //         window.location.reload()
                                        //     }
                                        // }
                                        // catch (error) {
                                        //     toast.error(error?.response?.data?.message || 'Error in updating USGA')
                                        // }

                                    }}>
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue
                                        /* and other goodies */
                                    }) => (
                                        <form
                                            className="login-form"
                                            onSubmit={handleSubmit}
                                            noValidate>
                                            <div className="Auth-form-content w-100">
                                                <Form.Group controlId="email">
                                                    <label htmlFor="email" className="greyLabel account-label">
                                                        Email
                                                    </label>
                                                    <div
                                                        className={`form-floating  mb-3 ${touched.email && errors.email
                                                            ? 'has-validation-error'
                                                            : ''
                                                            }`}>
                                                        <Form.Control
                                                            disabled
                                                            type="email"
                                                            name="email"
                                                            placeholder="Email"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="w-100"
                                                            isInvalid={touched.email && errors.email}
                                                        />
                                                        {!values.email && (
                                                            <label htmlFor="email" className="greyLabel account-label">
                                                                Email
                                                            </label>
                                                        )}
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.email}
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>

                                                <div className="fname-lname-wrapper d-flex justify-content-between w-100">
                                                    <Form.Group controlId="fname" className="w-100">
                                                        <label htmlFor="fname" className="greyLabel account-label">
                                                            First Name
                                                        </label>
                                                        <div
                                                            className={`form-floating mb-3 ${touched.fname && errors.fname
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type='text'
                                                                name="fname"
                                                                placeholder="Enter first name"
                                                                value={values.fname}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                isInvalid={touched.fname && errors.fname}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.fname}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group controlId="lname" className="w-100">
                                                        <label htmlFor="lname" className="greyLabel account-label">
                                                            Last Name
                                                        </label>
                                                        <div
                                                            className={`form-floating mb-3 ${touched.lname && errors.lname
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type='text'
                                                                name="lname"
                                                                placeholder="Enter first name"
                                                                value={values.lname}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                isInvalid={touched.lname && errors.lname}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.lname}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </div>

                                                <div className="fname-lname-wrapper d-flex justify-content-between w-100">
                                                    <Form.Group controlId="phoneNumber" className="w-100">
                                                        <label htmlFor="phoneNumber" className="greyLabel account-label">
                                                            Phone Number
                                                        </label>
                                                        <div
                                                            className={`form-floating mb-3 ${touched.phoneNumber && errors.phoneNumber
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Controller
                                                                name="phoneNumber"
                                                                control={control}
                                                                defaultValue=""
                                                                rules={{
                                                                    required: 'Phone number is required',
                                                                    pattern: {
                                                                        value: phoneRegExp,
                                                                        message: 'Invalid phone number. Please use the format XXX-XXX-XXXX',
                                                                    },
                                                                }}
                                                                render={({ field }) => (
                                                                    <PhoneInput
                                                                        country={'us'}
                                                                        // value={values.phoneNumber}
                                                                        value={phoneVal}
                                                                        className="phone-input"
                                                                        onChange={(e) => handlePhoneChange(e)}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                )}
                                                            />
                                                            {
                                                                showPhoneError === true &&
                                                                <Form.Control.Feedback type="" className="text-danger invalid-terms-feedback">
                                                                    {phoneErrorMsg}
                                                                </Form.Control.Feedback>
                                                            }
                                                            {/* {errors.phoneNumber && (
                                                                <Form.Control.Feedback type="" className="text-danger invalid-terms-feedback">
                                                                    {errors.phoneNumber.message}
                                                                </Form.Control.Feedback>
                                                            )} */}
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group controlId="dob" className="w-100">
                                                        <label htmlFor="dob" className="greyLabel account-label account-label">
                                                            Date of Birth
                                                        </label>
                                                        <div
                                                            className={`form-floating mb-3 ${touched.dob && errors.dob
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type='date'
                                                                name="dob"
                                                                placeholder="Enter date of birth"
                                                                value={values.dob}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                isInvalid={touched.dob && errors.dob}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.dob}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </div>

                                                <div className="fname-lname-wrapper d-flex justify-content-between w-100">
                                                    <Form.Group controlId="zipcode" className="w-100">
                                                        <label htmlFor="zipcode" className="greyLabel account-label">
                                                            Zip Code
                                                        </label>
                                                        <div
                                                            className={`form-floating mb-3 ${touched.zipcode && errors.zipcode
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type='text'
                                                                name="zipcode"
                                                                placeholder="Enter Zip Code"
                                                                value={values.zipcode}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                isInvalid={touched.zipcode && errors.zipcode}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.zipcode}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group controlId="gender" className="w-100">
                                                        <label htmlFor="gender" className="greyLabel account-label">
                                                            Gender
                                                        </label>
                                                        <div
                                                            className={`form-floating mb-3 ${touched.gender && errors.gender
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <div className="mt-2">
                                                                {
                                                                    values.gender === "MALE" &&
                                                                    <label>
                                                                        <Radio value="MALE" name="gender"
                                                                            checked={values.gender === "MALE"}
                                                                            onChange={() => setFieldValue("gender", "MALE")}
                                                                            disabled={true}
                                                                        />
                                                                        Male
                                                                    </label>
                                                                }
                                                                {values.gender === "FEMALE" &&
                                                                    <label>
                                                                        <Radio value="FEMALE" name="gender" checked={values.gender === "FEMALE"}
                                                                            onChange={() => setFieldValue("gender", "FEMALE")}
                                                                            disabled={true}
                                                                        />
                                                                        Female
                                                                    </label>
                                                                }
                                                                {/* <label>
                                                                    <Radio value="BOTH" name="gender"  checked={values.gender === "BOTH"}
                                                                        onChange={() => setFieldValue("gender", "BOTH")} />
                                                                    Both
                                                                </label> */}
                                                            </div>
                                                            <Form.Control.Feedback className="text-danger invalid-terms-feedback">
                                                                {errors.gender}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </div>

                                                <div className="d-grid gap-2 mt-3 update-btn-div">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary update-btn">
                                                        Update
                                                    </button>
                                                </div>

                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>

                            <div className={showGhinTxt === true ? "ghin-profile" : "ghin-profile mt-4"}>
                                {/* <>{showGhinTxt === true ? */}
                                <div className="add-ghin-txt">
                                    <div className="w-100">
                                        <label htmlFor="fname" className="greyLabel account-label">
                                            USGA (WHS Integration)
                                        </label>
                                        <div
                                            className="form-floating mb-3">
                                            <input
                                                className="ghin-number-txt"
                                                type='text'
                                                placeholder="Enter USGA Number"
                                                value={ghinNumber}
                                                onChange={(e) => handleGhinChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* : */}
                                <Button className="add-ghin-btn w-100" onClick={() => handleAddGhin(showGhinTxt === true ? 'update' : 'add')}>{showGhinTxt === true ? 'Update your GHIN' : 'Add your GHIN'}</Button>
                                {/* }</> */}

                                <div className="referral-link-input mt-4">
                                    <label htmlFor="email" className="greyLabel account-label">
                                        Referral Link
                                    </label>
                                    <div className="input-group share-input-group w-100">
                                        <input
                                            className="w-100 p-2 shareLink trimTxt1line"
                                            type='text'
                                            name="shareLink"
                                            value={shareLink?.slice(0, 35)}
                                            placeholder={userData?.shareLink}
                                            style={{ borderRadius: '8px' }}
                                        />
                                        <button variant="primary" className="copyBtn" onClick={copyLink}>Copy</button>
                                    </div>
                                </div>

                                <div className="referred-users d-flex flex-column justify-content-start mt-4">
                                    <div className="ref-users">Referred Users</div>
                                    <div className="ref-users-num">{userData?.referred_users}</div>
                                </div>

                                <div className="credit-bal-wrapper mt-4">
                                    <div className="credit-bal-txt">Credit balance:</div>
                                    <div className="credit-bal-val-wrapper mt-2">
                                        <img src={svg?.Star} className="start-img" alt="star Np" />
                                        <div className="credit-val">{userData?.credit_balance}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

            }
            {
                showUpload === true &&
                <UploadPhoto show={showUpload} closeUploadPhoto={closeUploadPhoto} userId={userData?.user?.id} userProfilePic={userData?.profile_image} />
            }
        </>
    )
}
export default BasicUserProfile;