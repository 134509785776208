import React, { useEffect, useState } from "react";
import Switch from 'react-bootstrap-switch';
import { manageNotifications, getUserProfile } from '../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ManageNotification = () => {
    const [initialToggle, setInitialToggle] = useState(false);
    const [switchToggle, setSwitchToggle] = useState(initialToggle);
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        try {
            let resp = await getUserProfile();
            setInitialToggle(resp?.is_notification);
            setSwitchToggle(resp?.is_notification)
        }
        catch (err) {
        }
    }

    const handleNotifications = async () => {
        setSwitchToggle(!switchToggle)
        try {
            let body = {
                "is_notification": !switchToggle
            }
            let resp = await manageNotifications(body, userId);
            toast.success('Notification status updated successfully');
        }
        catch (err) {
            toast.error(err?.response?.data?.detail || 'Error in updating notification status');
        }
    }
    return (
        <div className="manage-notifications">
            <div className="manage-noti-heading">Manage Notifications</div>

            <div className="manage-noti-body mt-4">
                <div className="noti-sub">Notifications</div>
                <div className="form-check form-switch">
                    <input className="form-check-input noti-switch" type="checkbox" checked={switchToggle} onChange={() => handleNotifications()} />
                </div>

            </div>
        </div>
    )
}

export default ManageNotification