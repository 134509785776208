import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function teeSetListing(token, courseId, gender) {
    const apiUrl = 'https://api-uat.ghin.com/api/v1/courses/';
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Set the Content-Type header to application/json
        // Add any other headers if required
    };

    return axios
        .get(`${apiUrl}${courseId}/tee_set_ratings.json?gender=${gender}`, { headers })
        .then((response) => {
            // Handle the API response here
            return response?.data?.TeeSets;
        })
        .catch((error) => {
            // Handle errors here
            console.error('API Request Error:', error);
            toast.error(error || 'An error occured while processing your request')
        });

}