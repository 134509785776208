import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './logo.svg';
import Signin from './components/UserAuth/Signin';
import Signup from './components/UserAuth/Signup';
import VerifyEmail from './components/UserAuth/VerifyEmail';
import VerificationSuccess from './components/UserAuth/VerificationSuccess';
import ForgotPassword from './components/UserAuth/ForgotPassword';
import ResetPassword from './components/UserAuth/ResetPassword';
import DataDeletion from './components/DataDeletion';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import UserOnboarding from './components/UserOnboarding';
import UserDashboard from './components/UserDashboard';
import RoundDetails from './components/RoundDetails';
import Scorecard from './components/Scorecard';
import PastRoundScoreCard from './components/Scorecard/PastRoundScoreCard';
import Standings from './components/UserStandings';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import BasicUserProfile from './components/BasicUserProfile';
import Settings from './components/Settings';
import Login from './components/Admin/Login';
import AdminAppusers from './components/Admin/AdminAppusers';
import AdminLeaguesList from './components/Admin/AdminLeaguesList';
import AdminLayout from './Layouts/AdminLayout';
import AdminFeedback from './components/Admin/AdminFeedback';
import AdContent from './components/Admin/AdContent';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-bootstrap-accordion/dist/index.css'
import ManageSubscription from './components/Admin/ManageSubscription';
import CreateLeague from './components/Admin/CreateLeague';
import CreateSeason from './components/Admin/CreateSeason';
import CreateTiers from './components/Admin/CreateTiers';
import SpecificLeague from './components/Admin/SpecificLeague';
import EditLeague from './components/Admin/CreateLeague/EditLeague';
import EditSeason from './components/Admin/CreateSeason/EditSeason';
import EditTier from './components/Admin/CreateTiers/EditTier';
import ExploreAllLeagues from './components/ExploreAllLeagues';
import AdminCourses from './components/Admin/AdminCourses';
import AdminNotifications from './components/Admin/AdminNotifications';
import AddNotifications from './components/Admin/AdminNotifications/AddNotifications';
import StandingsHistory from './components/UserStandings/StandingsHistory';
import OtherUserProfile from './components/OtherUserProfile';
import ManagePayments from './components/ManagePayments';

function App() {
  const isAuthenticated = localStorage?.getItem("token")
  const isAdmin = localStorage?.getItem('isAdmin');
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact
            path="/login"
            element={<Signin />} />

          <Route exact
            path="/signup"
            element={<Signup />} />

          <Route exact
            path="/verify-email"
            element={<VerifyEmail />} />

          <Route exact
            path="/verify-success"
            element={<VerificationSuccess />} />

          <Route exact
            path="/forgot-pass"
            element={<ForgotPassword />} />

          <Route exact path='/reset-password'
            element={<ResetPassword />} />

          <Route exact path='/delete-data' element={<DataDeletion />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route exact path='/terms-conditions' element={<TermsConditions />} />
          <Route exact path="/onboarding" element={
            isAuthenticated ? (
              localStorage.getItem('on_boarding_complete') === 'false' ?
                <UserOnboarding />
                :
                <Navigate to="/home" />
            ) : (
              <Navigate to="/login" />
            )
          } />

          <Route exact path='/home' element={
            isAuthenticated ? (
              localStorage.getItem('on_boarding_complete') === 'true' ?
                <UserDashboard />
                :
                <Navigate to="/onboarding" />
            ) : (
              <Navigate to="/login" />
            )
          } />

          <Route exact path='/rounds' element={
            isAuthenticated ?
              <RoundDetails /> : (
                <Navigate to="/login" />
              )
          } />

          <Route exact path='/scorecard' element={
            isAuthenticated ?
              <Scorecard /> : (
                <Navigate to="/login" />
              )
          } />

          <Route exact path='/pastscorecard' element={
            isAuthenticated ?
              <PastRoundScoreCard /> : (
                <Navigate to="/login" />
              )
          } />

          <Route exact path='/standings' element={
            isAuthenticated ?
              <Standings /> : (
                <Navigate to="/login" />
              )
          } />

          <Route exact path='/standings/history' element={
            isAuthenticated ?
              <StandingsHistory /> : (
                <Navigate to="/login" />
              )
          } />

          <Route exact path='/userprofile' element={
            isAuthenticated ? (
              <BasicUserProfile />
            ) :
              <Navigate to="/login" />

          } />

          <Route exact path='/otheruserprofile' element={
            isAuthenticated ? (
              <OtherUserProfile />
            ) :
              <Navigate to="/login" />

          } />

          <Route exact path='/settings' element={
            isAuthenticated ? (
              <Settings />
            ) : (
              <Navigate to="/login" />
            )} />

          <Route exact path='/managepayments' element={
            isAuthenticated ? (
              <ManagePayments />
            ) : (
              <Navigate to="/login" />
            )} />

          <Route exact path='/exploreLeagues' element={
            isAuthenticated ? (
              <ExploreAllLeagues />
            ) : (
              <Navigate to="/login" />
            )} />

          <Route exact path='/admin/login' element={<Login />} />
          <Route exact path='/admin/users' element={
            (isAuthenticated && isAdmin === 'true') ? (
              <AdminLayout>
                <AdminAppusers />
              </AdminLayout>
            )
              :
              <Navigate to='/admin/login' />
          } />

          <Route exact path='admin/dashboard' element={
            (isAuthenticated && isAdmin === 'true') ? (
              <AdminLayout>
                <AdminLeaguesList />
              </AdminLayout>
            ) :
              <Navigate to='/admin/login' />
          } />

          <Route path='/admin/feedback' element={
            (isAuthenticated && isAdmin === 'true') ? (
              <AdminLayout>
                <AdminFeedback />
              </AdminLayout>
            ) :
              <Navigate to='/admin/login' />
          }
          />

          <Route path='/admin/adContent' element={
            (isAuthenticated && isAdmin === 'true') ? (
              <AdminLayout>
                <AdContent />
              </AdminLayout>
            ) :
              <Navigate to='/admin/login' />
          }
          />

          <Route path='/admin/subscriptions' element={
            (isAuthenticated && isAdmin === 'true') ? (
              <AdminLayout>
                <ManageSubscription />
              </AdminLayout>
            ) :
              <Navigate to='/admin/login' />
          }
          />

          <Route path='/admin/dashboard/create-league' element={
            (isAuthenticated && isAdmin === 'true') ? (
              <AdminLayout>
                <CreateLeague />
              </AdminLayout>
            ) :
              <Navigate to='/admin/login' />
          }
          />

          <Route path='/admin/courses' element={
            (isAuthenticated && isAdmin === 'true') ? (
              <AdminLayout>
                <AdminCourses />
              </AdminLayout>
            ) :
              <Navigate to='/admin/login' />
          }
          />

          <Route path='/admin/notifications' element={
            (isAuthenticated && isAdmin === 'true') ? (
              <AdminLayout>
                <AdminNotifications />
              </AdminLayout>
            ) :
              <Navigate to='/admin/login' />
          }
          />

          <Route path='/adminnotifications/add-notification' element={
            (isAuthenticated && isAdmin === 'true') ? (
              <AdminLayout>
                <AddNotifications />
              </AdminLayout>
            ) :
              <Navigate to='/admin/login' />
          }
          />

          <Route path='/admin/dashboard/edit-league' element={
            (isAuthenticated && isAdmin === 'true') ? (
              <AdminLayout>
                <EditLeague />
              </AdminLayout>
            ) :
              <Navigate to='/admin/login' />
          }
          />

          <Route path='/admin/dashboard/create-league/create-season' element={
            (isAuthenticated && isAdmin === 'true') ? (
              <AdminLayout>
                <CreateSeason />
              </AdminLayout>
            ) :
              <Navigate to='/admin/login' />
          }
          />

          <Route path='/admin/dashboard/create-league/edit-season' element={
            (isAuthenticated && isAdmin === 'true') ? (
              <AdminLayout>
                <EditSeason />
              </AdminLayout>
            ) :
              <Navigate to='/admin/login' />
          }
          />

          <Route path='/admin/dashboard/create-league/create-season/create-tiers' element={
            (isAuthenticated && isAdmin === 'true') ? (
              <AdminLayout>
                <CreateTiers />
              </AdminLayout>
            ) :
              <Navigate to='/admin/login' />
          }
          />

          <Route path='/admin/dashboard/edit-tier' element={
            (isAuthenticated && isAdmin === 'true') ? (
              <AdminLayout>
                <EditTier />
              </AdminLayout>
            ) :
              <Navigate to='/admin/login' />
          }
          />

          <Route path='/admin/dashboard/specific-league' element={
            (isAuthenticated && isAdmin === 'true') ? (
              <AdminLayout>
                <SpecificLeague />
              </AdminLayout>
            ) :
              <Navigate to='/admin/login' />
          }
          />

        </Routes>
        <ToastContainer />
      </Router>
    </div>
  );
}

export default App;
