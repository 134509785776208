import React, { useState, useEffect, useRef } from "react";
import moment from 'moment'
import {
    Typography,
    TextField,
    Button,
    Stepper,
    Paper,
    Step,
    Radio,
    // StepLabel,
    LinearProgress
} from "@material-ui/core";
import { isBefore } from 'date-fns';
import axios from 'axios';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from "@stripe/react-stripe-js";
import CurrentSeasonPopup from "./CurrentSeasonPopup.jsx";
import BottomTooltip from './BottomTooltip.jsx';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { profileCreation, profileCreationUpload, fetchBasicUserInfo, fetchRecLeaguesBanner, fetchRecommendedLeagues, fetchSubscriptionData, confirmPayment, joinLeague, addGhin } from '../../api/request';
import { convertDateToMonth } from '../../utils/ConvertDateToMonth.js';
import { useWindowSize } from "../../utils/useWindowSize.js";
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import svg from '../../assets/images/svg/index.js';
import {
    useForm,
    Controller,
    FormProvider,
    useFormContext,
} from "react-hook-form";

let userId = Number(localStorage.getItem('userId'));

const stripePromise = loadStripe(
    "pk_test_51N8OMlFdHTQtW0piO3f6beyjQy8pR3FQltBK5pAIts4sK3namjf9Bv2aVZ2AKdx5FeSER5BLvIh3oT3LBnndxV4k00120xHlb7"
);

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
    },
    stepContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: theme.spacing(2),
    },
    stepContent: {
        width: "100%",
    },
    stepperConnector: {
        display: "none",
    },
}));

const formDataBody = new FormData();

function getSteps() {
    return [
        "Basic information",
        "USGA Ghin",
        "Recommended Leagues",
        "Subscription",
        "Payment",
        "FinishPage"
    ];
}
const BasicForm = ({ control, errors }) => {
    const phoneRegExp = /^\+?([0-9]{1,4})?[ .-]?\(?([0-9]{3})\)?[ .-]?([0-9]{3})[ .-]?([0-9]{4})$/;

    // const { errors } = formState;
    const [phoneNumber, setphonenumber] = useState('');
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)
    const [showbasicform, setBasicform] = useState(true);
    const [selImage, setSelImage] = useState('');

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    const handleButtonClick = () => {
        document.getElementById("file-upload").click();
    };
    const handleFileSelect = async (event) => {
        const selectedFile = event.target.files[0];
        setSelImage(event.target.files[0]);
        formDataBody.append('profile_image', selectedFile);
        try {
            let resp = await profileCreationUpload(formDataBody, userId);
            if (resp) {
                toast.success('Profile picture uploaded successfuly');
            }
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'Error in uploading profile picture');
        }
    };
    return (
        <>
            <div className="heading-wrapper">
                <div className="form-heading form-heading-step0">Welcome to Linx League!</div>
                <div className="form-sub-heading form-heading-step0">We just need some basic info to get your profile setup.
                    You’ll be able to edit this later.</div>
            </div>
            <div className="form-wrapper">
                <div className="user-profile-input">
                    <img src={selImage ? URL.createObjectURL(selImage) : svg?.DefUser} className="user-profile-img" alt="imageNP" />
                    <div className="user-file-input">
                        <Button variant="contained" className="change-pic-btn" onClick={handleButtonClick}>
                            Change Photo
                        </Button>
                    </div>
                </div>

                <>
                    <div className="row mt-4">
                        <div className="form-field w50">
                            <label htmlFor="first-name" className="txt-label">First Name <span className="red-label">*</span></label>
                            <Controller
                                control={control}
                                name="firstName"
                                rules={{ required: 'First name is required' }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <TextField
                                            id="first-name"
                                            variant="outlined"
                                            placeholder="Enter Your First Name"
                                            fullWidth
                                            margin="normal"
                                            onChange={onChange}
                                            value={value}
                                        />
                                        {errors?.firstName && <p className="error text-danger-txt">{errors?.firstName.message}</p>}
                                    </>
                                )}
                            />
                        </div>

                        <div className={getwidth > mobileBreakpoint ? "form-field w-50" : "form-field w-100 mt-2"}>
                            <label htmlFor="last-name" className="txt-label">Last Name <span className="red-label">*</span></label>
                            <Controller
                                control={control}
                                name="lastName"
                                rules={{ required: 'Last name is required' }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <TextField
                                            id="last-name"
                                            // label="Last Name"
                                            variant="outlined"
                                            placeholder="Enter Your Last Name"
                                            fullWidth
                                            margin="normal"
                                            onChange={onChange}
                                            value={value}
                                        />
                                        {errors?.lastName && <p className="error text-danger-txt">{errors?.lastName.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    <div className={getwidth > mobileBreakpoint ? "row mt-4" : "row mt-2"}>
                        <div className="form-field w50">
                            <label htmlFor="first-name" className="txt-label">Phone number <span className="red-label">*</span></label>
                            <Controller
                                control={control}
                                name="phoneNumber"
                                rules={{
                                    required: 'Phone number is required',
                                    pattern: {
                                        value: phoneRegExp, // Adjust the regex pattern for your specific validation needs
                                        message: 'Invalid phone number. Please use the format XXX-XXX-XXXX',
                                    },
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <PhoneInput
                                            country={'us'}
                                            value={phoneNumber}
                                            onChange={onChange}
                                        />
                                        {errors?.phoneNumber && <p className="error text-danger-txt">{errors?.phoneNumber?.message}</p>}
                                    </>
                                )}
                            />
                        </div>

                        <div className={getwidth > mobileBreakpoint ? "form-field w50" : "form-field w50 mt-3"}>
                            <label htmlFor="first-name" className="txt-label">Date of Birth <span className="red-label">*</span></label>
                            <Controller
                                control={control}
                                name="dateOfBirth"
                                rules={{
                                    required: 'Date of birth is required',
                                    validate: (value) => {
                                        // Calculate today's date minus 18 years
                                        const minDate = new Date();
                                        minDate.setFullYear(minDate.getFullYear() - 18);

                                        // Parse the selected date from the input field
                                        const selectedDate = new Date(value);

                                        // Compare the selected date with the minimum allowed date
                                        if (selectedDate < minDate) {
                                            return true; // Validation passed, the user is 18 or older
                                        } else {
                                            return "You must be at least 18 years old"; // Validation failed
                                        }
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <input id="dateOfBirth" class="form-control date-input" type="date"
                                            max={new Date().toISOString().split("T")[0]}
                                            onChange={onChange}
                                            value={value}
                                        />
                                        {errors?.dateOfBirth && <p className="error text-danger-txt">{errors?.dateOfBirth.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    <div className={getwidth > mobileBreakpoint ? "row mt-4" : "row mt-3"}>
                        <div className="form-field w50">
                            <label htmlFor="first-name" className="txt-label">Zip Code<span className="red-label">*</span></label>
                            <Controller
                                control={control}
                                name="zipCode"
                                rules={{
                                    required: 'Zipcode is required',
                                    minLength: {
                                        value: 5,
                                        message: 'Zipcode must be a 5-digit number'
                                    },
                                    maxLength: {
                                        value: 5,
                                        message: 'Zipcode must be a 5-digit number'
                                    }
                                }}

                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <TextField
                                            type="number"
                                            inputProps={{
                                                pattern: "\\d*", // Allow only numeric input
                                                inputMode: "numeric", // Set input mode to numeric
                                                maxLength: 5,
                                                minLength: 5
                                            }}
                                            id="zip-code"
                                            variant="outlined"
                                            placeholder="Enter Zip Code"
                                            fullWidth
                                            margin="normal"
                                            style={{ borderRadius: '8px' }}
                                            onChange={onChange}
                                            value={value}
                                        />
                                        {errors?.zipCode && <p className="error text-danger-txt">{errors?.zipCode?.message}</p>}
                                    </>
                                )}
                            />
                        </div>

                        <div className={width > mobileBreakpoint ? "form-field w50" : "form-field w50 mt-2"}>
                            <label htmlFor="gender" className="txt-label">Gender<span className="red-label">*</span></label>
                            <Controller
                                control={control}
                                name="gender"
                                rules={{ required: 'Gender is required' }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <div className="mt-2 genderHeight">
                                            <label>
                                                <Radio value="MALE" name="gender" checked={value === "MALE"} onChange={() => onChange("MALE")} />
                                                Male
                                            </label>
                                            <label>
                                                <Radio value="FEMALE" name="gender" checked={value === "FEMALE"} onChange={() => onChange("FEMALE")} />
                                                Female
                                            </label>
                                        </div>

                                        {errors?.gender && <p className="error text-danger-txt">{errors?.gender?.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>

                </>
            </div>
            <input
                type="file"
                id="file-upload"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileSelect}
            />
            {/* </Modal> */}
        </>
    );
};

const validationSchema = yup.object().shape({
    UsgaNumber: yup.string()
        .matches(/^\d{8}$/, 'Must be an 8-digit number')
        .required('This field is required'),
});

const UsgaForm = () => {
    // const { control } = useFormContext();
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)
    const { control } = useFormContext();
    const { formState: { errors } } = useFormContext();
    const { methods } = useForm({
        resolver: yupResolver(validationSchema), // Use yupResolver here
    });

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width])

    return (
        <>
            <div className="heading-wrapper">
                <div className="form-heading-step1">USGA Information</div>
                <div className="form-sub-heading-step1">Upload your Handicap Index® number. This is not required but we encourage you to add it, otherwise you cannot participate in competitive leagues.</div>
            </div>
            <FormProvider {...methods}>
                <div className="form-wrapper">
                    <>
                        <div className="row mt-4">
                            <div className="form-field w-100">
                                <label htmlFor="usga-id" className="txt-label">USGA (WHS Integration)</label>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: true,            // You can make the field required if needed
                                        minLength: 8,
                                        maxLength: 8,
                                        pattern: /^[0-9]*$/,       // Use a regular expression to allow only digits
                                    }}
                                    name="UsgaNumber"
                                    render={({ field }) => (
                                        <TextField
                                            type="number"
                                            id="usga-id"
                                            variant="outlined"
                                            placeholder="Enter Handicap ID (CDGA#)"
                                            fullWidth
                                            margin="normal"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.UsgaNumber && (
                                    <span className="error text-danger-txt">Enter a valid 8-digit number.</span>
                                )}
                            </div>
                        </div>

                        <>{getwidth < mobileBreakpoint &&
                            < div className="form-wrapper-usga linear-forms mt-4" style={{
                                backgroundColor: '#F1F2F2'
                            }}>
                                <>
                                    <div className="mt-4">
                                        <div className="usga-logo-cont d-flex justify-content-center align-items-center">
                                            <img src={svg?.UsgaLogo} className="usga-logo" alt="imagenp" />
                                        </div>
                                        <div className="usga-body-wrapper-mob mt-3">
                                            <div className="usga-body-txt">Having a Handicap Index makes the game more fun and enjoyable by connecting you to a community of nearly 3 million golfers.</div>
                                            <div className="usga-link mt-4">Go to https://getahandicap.usga.org/ to get your Handicap Index</div>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <Button
                                            className="nextBtn w-50 mx-auto"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => window.location.href = 'https://getahandicap.usga.org/v2app/#/golfer-registration/sign-up'}
                                        >
                                            Go to Site
                                        </Button>
                                    </div>
                                </>
                            </div>
                        }</>
                    </>
                </div>
            </FormProvider>
        </>
    );
};

const LeaguesForm = (props) => {
    let incomingUsga = props?.usga_num;
    const navigate = useNavigate();
    const { control } = useFormContext();
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0);
    const [btnSel, setbtnSel] = useState(false);
    const [selIndex, setSelIndex] = useState(-1);
    const [getBanner, setBanner] = useState('');
    const [recLeaguesData, setRecLeaguesData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [recreationalLeagues, setRecreationalLeagues] = useState([]);
    const [showCurrSeason, setShowCurrSeason] = useState(false);
    const [showEndDate, setEndDate] = useState('');
    const [showStartDate, setStartDate] = useState('');
    const [showNextSeason, setNextSeason] = useState(false);
    const [selData, setselData] = useState({});
    const [recLeague, setRecLeague] = useState(false);
    const [chkState, setCheckState] = useState([]);
    const [recChkState, setRecChkState] = useState([]);
    const [currentId, setId] = useState(null);
    const [nextId, setNextId] = useState(null);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    useEffect(() => {
        fetchBanner();
        fetchRecLeagues();
    }, []);

    const fetchBanner = async () => {
        try {
            let resp = await fetchRecLeaguesBanner();
            setBanner(resp[0]?.image || '');
        }
        catch (err) {
        }
    }

    const handleRecLeaguesChk = (type, index, recId) => {
        if (!recId) {
            toast.error('No next season exists for this league')
            return;
        }
        if (type === 'comp') {
            const updatedCheckboxStates = [...chkState];
            updatedCheckboxStates[index] = !updatedCheckboxStates[index];
            setCheckState(updatedCheckboxStates);
            setNextId(recId);
        }
        else {
            const updatedCheckboxStatesRec = [...recChkState];
            updatedCheckboxStatesRec[index] = !updatedCheckboxStatesRec[index];
            setRecChkState(updatedCheckboxStatesRec);
            setNextId(recId);
        }
    };

    const handleClosePopup = () => {
        setShowCurrSeason(false);
        setRecLeague(false);
        setNextSeason(false);
    }

    const handleConfirm = async (incomingData, selPrice) => {
        if (Number(selPrice) <= 0) {
            let resp = await joinLeague({
                "tier_id": Number(incomingData?.id)
            });
            toast.success(resp?.message || 'League joined successfully');
            window.location.href = '/home';
            localStorage.setItem('on_boarding_complete', resp?.on_boarding_complete);
        }
        else {
            props?.handleNext(incomingData, true, currentId, nextId);
        }
    }

    const fetchRecLeagues = async () => {
        try {
            let resp = await fetchRecommendedLeagues();
            if (resp) {
                let arr = [];
                let chkArr = [];
                let recArr = [];
                let chkArrREc = [];
                for (let i = 0; i < resp?.length; i++) {
                    if (resp[i]?.league?.type === 1) {
                        arr?.push(resp[i]);
                        if (resp[i]?.next_season?.id) {
                            chkArr?.push(true);
                        }
                        else {
                            chkArr?.push(false);
                        }
                    }
                    setCheckState(chkArr);
                    if (resp[i]?.league?.type === 2) {
                        recArr?.push(resp[i]);
                        if (resp[i]?.next_season?.id) {
                            chkArrREc?.push(true);
                        }
                        else {
                            chkArrREc?.push(false);
                        }
                    }
                }
                setAllData(resp);
                setRecLeaguesData(arr);
                setRecChkState(chkArrREc);
                setRecreationalLeagues(recArr);
            }
        }
        catch (err) {
        }
    }

    const handleLeaguesFormColumns = () => {
        if (getwidth >= mobileBreakpoint) {
            return
        }
        if (allData?.length === 0) {
            return 0
        }
        if (allData?.length === 1) {
            return '1fr'
        }
        if (allData?.length === 2) {
            return '1fr 1fr'
        }
        if (allData?.length === 3) {
            return '1fr 1fr 1fr'
        }
    }
    return (
        <>
            <div className="heading-wrapper">
                <div className="form-heading">Recommended Leagues</div>
                <div className="form-sub-heading">Here are recommendations for a competitive and recreational league based on your GHIN  and location.You may join other leagues after the onboarding process.  </div>
            </div>

            <>{getwidth > mobileBreakpoint &&
                <div className="banner-info">
                    <>{getBanner === '' && <div className="add-banner-txt">Ad Banner</div>}</>
                    <img src={getBanner !== '' ? getBanner : svg?.Banner} className={getBanner === '' ? "banner objectFitCover w-100" : "banner w-100"} alt="banenr NP" />
                </div>
            }</>
            <>
                {
                    (incomingUsga !== '') ?
                        <div className={"leagues-form-wrapper"} style={{ gridTemplateRows: handleLeaguesFormColumns() }}>
                            {
                                recLeaguesData?.length === 1 &&
                                <div className={(btnSel === true && selIndex === 0) ? "league-card-border league-card" : "league-card"}>
                                    <div className="league-img-wrapper w-100">
                                        <img src={recLeaguesData[0]?.league?.cover_image === null ? svg?.LeagueDef : recLeaguesData[0]?.league?.cover_image} className="league-img w-100 h-100" alt="leagueimage NP" />
                                        <>{recLeaguesData[0]?.prize && <img src={svg?.Cup} className="cup-img" alt="Cup NP" />
                                        }</>
                                    </div>
                                    <div className="rec-title-wrapper">
                                        <div className="rec-title trimTxt1line">{recLeaguesData[0]?.league?.name} {recLeaguesData[0]?.name}</div>
                                        <BottomTooltip />
                                    </div>

                                    <div className="ghin-spots d-flex align-items-center">
                                        <div className="rec-ghin-range">({recLeaguesData[0]?.handicap_from}-{recLeaguesData[0]?.handicap_to} GHIN)</div>
                                        <div className="rec-spots-div"><span className="rec-spots">{recLeaguesData[0]?.available_slots}</span> <span className="rec-spots-txt">Spots available</span></div>
                                    </div>

                                    <div className="rec-season-prize trimTxt1line mt-2">${recLeaguesData[0]?.prize}+ in prizes each season</div>

                                    <div className="rec-info trimTxt1line">{recLeaguesData[0]?.season?.description}</div>

                                    <div className="rec-credits d-flex align-items-center  mt-3">
                                        <div className="credits">Credits:</div>
                                        <div className="credit-score-wrapper">
                                            <img src={svg?.Star} className="star-img" alt="star IMg NP" />
                                            <div className="credit-score">{recLeaguesData[0]?.season?.price}</div>
                                        </div>
                                    </div>

                                    <div className="rec-seasons d-flex mt-2">
                                        <div className="season-range">{convertDateToMonth(recLeaguesData[0]?.season?.start_date)} - {convertDateToMonth(recLeaguesData[0]?.season?.end_date)}</div>
                                        {
                                            recLeaguesData[0]?.next_season?.id &&
                                            <div className="form-check form-switch">
                                                <input
                                                    // className="form-check-input noti-switch"
                                                    className={`form-check-input noti-switch ${!chkState[0] ? 'not-checked' : 'form-check-input noti-switch'}`}
                                                    type="checkbox"
                                                    // checked={recLeaguesData[0]?.next_season?.id ? true : false}
                                                    // checked={(chkState[0]?.index === 0 && chkState[0]?.chk === true) ? true : false}
                                                    checked={chkState[0]}
                                                    onChange={(event) => handleRecLeaguesChk('comp', 0, recLeaguesData[0]?.next_season?.id)} />
                                            </div>

                                        }
                                        {
                                            recLeaguesData[0]?.next_season?.id &&
                                            <div className="nxt-season">Next Season</div>
                                        }
                                    </div>
                                    <Button className={(btnSel === true && selIndex === 0) ? "rec-selected-btn" : "rec-sel-btn cursor-pointer"} onClick={() => {
                                        if (recLeaguesData[0]?.next_season?.id && chkState[0] === true) {
                                            setNextSeason(true);
                                            setShowCurrSeason(false);
                                            setNextId(recLeaguesData[0]?.next_season?.id);
                                        }
                                        else {
                                            setNextSeason(false);
                                            setShowCurrSeason(true);
                                            setId(recLeaguesData[0]?.id);
                                        }
                                        setbtnSel(true);
                                        setSelIndex(0);
                                        setEndDate(recLeaguesData[0]?.season?.end_date);
                                        setStartDate(recLeaguesData[0]?.season?.start_date)
                                        setselData(recLeaguesData[0]);
                                        setRecLeague(false);
                                    }}>{(btnSel === true && selIndex === 0) ? 'Selected' : 'Select'}</Button>
                                </div>
                            }


                            <>{recLeaguesData?.length === 2 &&
                                <>
                                    <div className={(btnSel === true && selIndex === 0) ? "league-card-border league-card" : "league-card"}>
                                        <div className="league-img-wrapper w-100">
                                            <img src={recLeaguesData[0]?.league?.cover_image === null ? svg?.LeagueDef : recLeaguesData[0]?.league?.cover_image} className="league-img w-100 h-100" alt="leagueimage NP" />
                                            <>{recLeaguesData[0]?.prize && <img src={svg?.Cup} className="cup-img" alt="Cup NP" />
                                            }</>
                                        </div>
                                        <div className="rec-title-wrapper">
                                            <div className="rec-title trimTxt1line">{recLeaguesData[0]?.league?.name} {recLeaguesData[0]?.name}</div>
                                            <BottomTooltip />
                                        </div>

                                        <div className="ghin-spots d-flex align-items-center">
                                            <div className="rec-ghin-range">({recLeaguesData[0]?.handicap_from}-{recLeaguesData[0]?.handicap_to} GHIN)</div>
                                            <div className="rec-spots-div"><span className="rec-spots">{recLeaguesData[0]?.available_slots}</span> <span className="rec-spots-txt">Spots available</span></div>
                                        </div>

                                        <div className="rec-season-prize trimTxt1line mt-2">${recLeaguesData[0]?.prize}+ in prizes each season</div>

                                        <div className="rec-info trimTxt1line">{recLeaguesData[0]?.season?.description}</div>

                                        <div className="rec-credits d-flex align-items-center  mt-3">
                                            <div className="credits">Credits:</div>
                                            <div className="credit-score-wrapper">
                                                <img src={svg?.Star} className="star-img" alt="star IMg NP" />
                                                <div className="credit-score">{recLeaguesData[0]?.season?.price}</div>
                                            </div>
                                        </div>

                                        <div className="rec-seasons d-flex mt-2">
                                            <div className="season-range">{convertDateToMonth(recLeaguesData[0]?.season?.start_date)} - {convertDateToMonth(recLeaguesData[0]?.season?.end_date)}</div>
                                            {recLeaguesData[0]?.next_season?.id &&
                                                <>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            // className="form-check-input noti-switch"
                                                            className={`form-check-input noti-switch ${!chkState[0] ? 'not-checked' : 'form-check-input noti-switch'}`}
                                                            type="checkbox"
                                                            // checked={recLeaguesData[0]?.next_season?.id ? true : false}
                                                            // checked={(chkState[0]?.index === 0 && chkState[0]?.chk === true) ? true : false}
                                                            checked={chkState[0]}
                                                            onChange={(event) => handleRecLeaguesChk('comp', 0, recLeaguesData[0]?.next_season?.id)} />
                                                    </div>
                                                    <div className="nxt-season">Next Season</div>
                                                </>
                                            }
                                        </div>
                                        <Button className={(btnSel === true && selIndex === 0) ? "rec-selected-btn" : "rec-sel-btn cursor-pointer"} onClick={() => {
                                            if (recLeaguesData[0]?.next_season?.id && chkState[0] === true) {
                                                setNextSeason(true);
                                                setShowCurrSeason(false);
                                                setNextId(recLeaguesData[0]?.next_season?.id);
                                            }
                                            else {
                                                setNextSeason(false);
                                                setShowCurrSeason(true);
                                                setId(recLeaguesData[0]?.id);
                                            }
                                            setbtnSel(true);
                                            setSelIndex(0);
                                            setEndDate(recLeaguesData[0]?.season?.end_date);
                                            setStartDate(recLeaguesData[0]?.season?.start_date)
                                            setselData(recLeaguesData[0]);
                                            setRecLeague(false);
                                        }}>{(btnSel === true && selIndex === 0) ? 'Selected' : 'Select'}</Button>
                                    </div>
                                    <div className={(btnSel === true && selIndex === 1) ? "league-card-border league-card" : "league-card"}>
                                        <div className="league-img-wrapper w-100">
                                            <img src={recLeaguesData[1]?.league?.cover_image === null ? svg?.LeagueDef : recLeaguesData[1]?.league?.cover_image} className="league-img w-100 h-100" alt="leagueimage NP" />
                                            <>{recLeaguesData[1]?.prize && <img src={svg?.Cup} className="cup-img" alt="Cup NP" />
                                            }</>
                                        </div>
                                        <div className="rec-title-wrapper">
                                            <div className="rec-title trimTxt1line">{recLeaguesData[1]?.league?.name} {recLeaguesData[1]?.name}</div>
                                            <BottomTooltip />
                                        </div>

                                        <div className="ghin-spots d-flex align-items-center">
                                            <div className="rec-ghin-range">({recLeaguesData[1]?.handicap_from}-{recLeaguesData[1]?.handicap_to} GHIN)</div>
                                            <div className="rec-spots-div"><span className="rec-spots">{recLeaguesData[1]?.available_slots}</span> <span className="rec-spots-txt">Spots available</span></div>
                                        </div>

                                        <div className="rec-season-prize trimTxt1line mt-2">${recLeaguesData[1]?.prize}+ in prizes each season</div>

                                        <div className="rec-info trimTxt1line">{recLeaguesData[1]?.season?.description}</div>

                                        <div className="rec-credits d-flex align-items-center  mt-3">
                                            <div className="credits">Credits:</div>
                                            <div className="credit-score-wrapper">
                                                <img src={svg?.Star} className="star-img" alt="star IMg NP" />
                                                <div className="credit-score">{recLeaguesData[1]?.season?.price}</div>
                                            </div>
                                        </div>

                                        <div className="rec-seasons d-flex mt-2">
                                            <div className="season-range">{convertDateToMonth(recLeaguesData[1]?.season?.start_date)} - {convertDateToMonth(recLeaguesData[1]?.season?.end_date)}</div>
                                            {
                                                recLeaguesData[1]?.next_season?.id &&
                                                <>
                                                    <div className="form-check form-switch">
                                                        <input className="form-check-input noti-switch"
                                                            type="checkbox" checked={chkState[1]}
                                                            onChange={(event) => handleRecLeaguesChk('comp', 1, recLeaguesData[1]?.next_season?.id)} />
                                                    </div>
                                                    <div className="nxt-season">Next Season</div>
                                                </>
                                            }
                                        </div>
                                        <Button className={(btnSel === true && selIndex === 1) ? "rec-selected-btn" : "rec-sel-btn cursor-pointer"} onClick={() => {
                                            if (recLeaguesData[1]?.next_season?.id && chkState[1] === true) {
                                                setNextSeason(true);
                                                setShowCurrSeason(false);
                                                setNextId(recLeaguesData[1]?.next_season?.id);
                                            }
                                            else {
                                                setNextSeason(false);
                                                setShowCurrSeason(true);
                                                setId(recLeaguesData[1]?.id);
                                            }
                                            setbtnSel(true)
                                            setSelIndex(1);
                                            setEndDate(recLeaguesData[1]?.season?.end_date);
                                            setStartDate(recLeaguesData[1]?.season?.start_date)
                                            setselData(recLeaguesData[1]);
                                            setRecLeague(false);
                                        }}>{(btnSel === true && selIndex === 1) ? 'Selected' : 'Select'}</Button>
                                    </div>
                                </>
                            }</>


                            <>{recreationalLeagues?.length !== 0 &&
                                <div className={(btnSel === true && selIndex === 2) ? "league-card-border league-card" : "league-card"}>
                                    <div className="league-img-wrapper w-100">
                                        <img src={recreationalLeagues[0]?.league?.cover_image === null ? svg?.LeagueDef : recreationalLeagues[0]?.league?.cover_image} className="league-img w-100 h-100" alt="leagueimage NP" />
                                        {/* <>{recreationalLeagues[0]?.prize && <img src={svg?.Cup} className="cup-img" alt="Cup NP" />
                            }</> */}
                                    </div>
                                    <div className="rec-title-wrapper">
                                        <div className="rec-title trimTxt1line">{recreationalLeagues[0]?.league?.name} {recreationalLeagues[0]?.name}</div>
                                        <BottomTooltip />
                                    </div>

                                    <div className="ghin-spots rec-txt">
                                        Recreational(no GHIN required)
                                    </div>

                                    <div className="rec-season-prize trimTxt1line mt-2"></div>

                                    <div className="rec-info trimTxt1line"></div>

                                    <div className="rec-credits d-flex align-items-center  mt-3">
                                        <div className="credits">Credits:</div>
                                        <div className="credit-score-wrapper">
                                            <img src={svg?.Star} className="star-img" alt="star IMg NP" />
                                            <div className="credit-score">{recreationalLeagues[0]?.season?.price}</div>
                                        </div>
                                    </div>

                                    <div className="rec-seasons d-flex mt-2">
                                        <div className="season-range">{convertDateToMonth(recreationalLeagues[0]?.season?.start_date)} - {convertDateToMonth(recreationalLeagues[0]?.season?.end_date)}</div>
                                        {
                                            recreationalLeagues[0]?.next_season?.id &&
                                            <>
                                                <div className="form-check form-switch">
                                                    <input
                                                        // className="form-check-input noti-switch" 
                                                        className={`form-check-input noti-switch ${!recChkState[0] ? 'not-checked' : 'form-check-input noti-switch'}`}
                                                        type="checkbox"
                                                        // checked={recreationalLeagues[0]?.next_season?.id ? true : false}
                                                        checked={recChkState[0]}
                                                        onChange={(event) => handleRecLeaguesChk('rec', 0, recreationalLeagues[0]?.next_season?.id)}
                                                    />
                                                </div>
                                                <div className="nxt-season">Next Season</div>
                                            </>
                                        }
                                    </div>
                                    <Button className={(btnSel === true && selIndex === 2) ? "rec-selected-btn" : "rec-sel-btn cursor-pointer"} onClick={() => {
                                        if (recreationalLeagues[0]?.next_season?.id && recChkState[0] === true) {
                                            setNextId(recreationalLeagues[0]?.next_season?.id);
                                        }
                                        else {
                                            setId(recreationalLeagues[0]?.id);
                                        }
                                        setbtnSel(true)
                                        setSelIndex(2);
                                        setselData(recreationalLeagues[0]);
                                        setRecLeague(true);

                                    }}>{(btnSel === true && selIndex === 2) ? 'Selected' : 'Select'}</Button>
                                </div>
                            }</>
                        </div>
                        : <>
                            {/* code for recreational leagues */}
                            <div className={"leagues-form-wrapper"} style={{ gridTemplateRows: handleLeaguesFormColumns() }}>
                                {
                                    recreationalLeagues?.length === 1 &&
                                    <div className={(btnSel === true && selIndex === 0) ? "league-card-border league-card" : "league-card"}>
                                        <div className="league-img-wrapper w-100">
                                            <img src={recreationalLeagues[0]?.league?.cover_image === null ? svg?.LeagueDef : recreationalLeagues[0]?.league?.cover_image} className="league-img w-100 h-100" alt="leagueimage NP" />
                                            {/* <>{recreationalLeagues[0]?.prize && <img src={svg?.Cup} className="cup-img" alt="Cup NP" />
                                        }</> */}
                                        </div>
                                        <div className="rec-title-wrapper">
                                            <div className="rec-title trimTxt1line">{recreationalLeagues[0]?.league?.name} {recreationalLeagues[0]?.name}</div>
                                            <BottomTooltip />
                                        </div>

                                        <div className="ghin-spots rec-txt">
                                            Recreational(no GHIN required)
                                        </div>

                                        <div className="rec-season-prize trimTxt1line mt-2"></div>

                                        <div className="rec-info trimTxt1line"></div>

                                        <div className="rec-credits d-flex align-items-center  mt-3">
                                            <div className="credits">Credits:</div>
                                            <div className="credit-score-wrapper">
                                                <img src={svg?.Star} className="star-img" alt="star IMg NP" />
                                                <div className="credit-score">{recreationalLeagues[0]?.season?.price}</div>
                                            </div>
                                        </div>

                                        <div className="rec-seasons d-flex mt-2">
                                            <div className="season-range">{convertDateToMonth(recreationalLeagues[0]?.season?.start_date)} - {convertDateToMonth(recreationalLeagues[0]?.season?.end_date)}</div>
                                            {
                                                recreationalLeagues[0]?.next_season?.id &&
                                                <>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            // className="form-check-input noti-switch" 
                                                            className={`form-check-input noti-switch ${!recChkState[0] ? 'not-checked' : 'form-check-input noti-switch'}`}
                                                            type="checkbox"
                                                            // checked={recreationalLeagues[0]?.next_season?.id ? true : false}
                                                            checked={recChkState[0]}
                                                            onChange={(event) => handleRecLeaguesChk('rec', 0, recreationalLeagues[0]?.next_season?.id)}
                                                        />
                                                    </div>
                                                    <div className="nxt-season">Next Season</div>
                                                </>
                                            }
                                        </div>
                                        <Button className={(btnSel === true && selIndex === 0) ? "rec-selected-btn" : "rec-sel-btn cursor-pointer"} onClick={() => {
                                            if (recreationalLeagues[0]?.next_season?.id && recChkState[0] === true) {
                                                setNextId(recreationalLeagues[0]?.next_season?.id);
                                            }
                                            else {
                                                setId(recreationalLeagues[0]?.id);
                                            }
                                            setbtnSel(true)
                                            setSelIndex(0);
                                            setselData(recreationalLeagues[0]);
                                            setRecLeague(true);

                                        }}>{(btnSel === true && selIndex === 0) ? 'Selected' : 'Select'}</Button>
                                    </div>
                                }
                                <>{recreationalLeagues?.length === 2 &&
                                    <>
                                        <div className={(btnSel === true && selIndex === 0) ? "league-card-border league-card" : "league-card"}>
                                            <div className="league-img-wrapper w-100">
                                                <img src={recreationalLeagues[0]?.league?.cover_image === null ? svg?.LeagueDef : recreationalLeagues[0]?.league?.cover_image} className="league-img w-100 h-100" alt="leagueimage NP" />
                                            </div>
                                            <div className="rec-title-wrapper">
                                                <div className="rec-title trimTxt1line">{recreationalLeagues[0]?.league?.name} {recreationalLeagues[0]?.name}</div>
                                                <BottomTooltip />
                                            </div>

                                            <div className="ghin-spots rec-txt">
                                                Recreational(no GHIN required)
                                            </div>

                                            <div className="rec-season-prize trimTxt1line mt-2"></div>

                                            <div className="rec-info trimTxt1line"></div>

                                            <div className="rec-credits d-flex align-items-center  mt-3">
                                                <div className="credits">Credits:</div>
                                                <div className="credit-score-wrapper">
                                                    <img src={svg?.Star} className="star-img" alt="star IMg NP" />
                                                    <div className="credit-score">{recreationalLeagues[0]?.season?.price}</div>
                                                </div>
                                            </div>

                                            <div className="rec-seasons d-flex mt-2">
                                                <div className="season-range">{convertDateToMonth(recreationalLeagues[0]?.season?.start_date)} - {convertDateToMonth(recreationalLeagues[0]?.season?.end_date)}</div>
                                                {
                                                    recreationalLeagues[0]?.next_season?.id &&
                                                    <>
                                                        <div className="form-check form-switch">
                                                            <input
                                                                // className="form-check-input noti-switch" 
                                                                className={`form-check-input noti-switch ${!recChkState[0] ? 'not-checked' : 'form-check-input noti-switch'}`}
                                                                type="checkbox"
                                                                // checked={recreationalLeagues[0]?.next_season?.id ? true : false}
                                                                checked={recChkState[0]}
                                                                onChange={(event) => handleRecLeaguesChk('rec', 0, recreationalLeagues[0]?.next_season?.id)}
                                                            />
                                                        </div>
                                                        <div className="nxt-season">Next Season</div>
                                                    </>
                                                }
                                            </div>
                                            <Button className={(btnSel === true && selIndex === 2) ? "rec-selected-btn" : "rec-sel-btn cursor-pointer"} onClick={() => {
                                                if (recreationalLeagues[0]?.next_season?.id && recChkState[0] === true) {
                                                    setNextId(recreationalLeagues[0]?.next_season?.id);
                                                }
                                                else {
                                                    setId(recreationalLeagues[0]?.id);
                                                }
                                                setbtnSel(true)
                                                setSelIndex(0);
                                                setselData(recreationalLeagues[0]);
                                                setRecLeague(true);

                                            }}>{(btnSel === true && selIndex === 0) ? 'Selected' : 'Select'}</Button>
                                        </div>

                                        <div className={(btnSel === true && selIndex === 1) ? "league-card-border league-card" : "league-card"}>
                                            <div className="league-img-wrapper w-100">
                                                <img src={recreationalLeagues[1]?.league?.cover_image === null ? svg?.LeagueDef : recreationalLeagues[1]?.league?.cover_image} className="league-img w-100 h-100" alt="leagueimage NP" />
                                            </div>
                                            <div className="rec-title-wrapper">
                                                <div className="rec-title trimTxt1line">{recreationalLeagues[1]?.league?.name} {recreationalLeagues[1]?.name}</div>
                                                <BottomTooltip />
                                            </div>

                                            <div className="ghin-spots rec-txt">
                                                Recreational(no GHIN required)
                                            </div>

                                            <div className="rec-season-prize trimTxt1line mt-2"></div>

                                            <div className="rec-info trimTxt1line"></div>

                                            <div className="rec-credits d-flex align-items-center  mt-3">
                                                <div className="credits">Credits:</div>
                                                <div className="credit-score-wrapper">
                                                    <img src={svg?.Star} className="star-img" alt="star IMg NP" />
                                                    <div className="credit-score">{recreationalLeagues[1]?.season?.price}</div>
                                                </div>
                                            </div>

                                            <div className="rec-seasons d-flex mt-2">
                                                <div className="season-range">{convertDateToMonth(recreationalLeagues[1]?.season?.start_date)} - {convertDateToMonth(recreationalLeagues[1]?.season?.end_date)}</div>
                                                {
                                                    recreationalLeagues[1]?.next_season?.id &&
                                                    <>
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className={`form-check-input noti-switch ${!recChkState[1] ? 'not-checked' : 'form-check-input noti-switch'}`}
                                                                type="checkbox"
                                                                // checked={recreationalLeagues[0]?.next_season?.id ? true : false}
                                                                checked={recChkState[1]}
                                                                onChange={(event) => handleRecLeaguesChk('rec', 1, recreationalLeagues[1]?.next_season?.id)}
                                                            />
                                                        </div>
                                                        <div className="nxt-season">Next Season</div>
                                                    </>
                                                }
                                            </div>
                                            <Button className={(btnSel === true && selIndex === 1) ? "rec-selected-btn" : "rec-sel-btn cursor-pointer"} onClick={() => {
                                                if (recreationalLeagues[1]?.next_season?.id && recChkState[1] === true) {
                                                    setNextId(recreationalLeagues[1]?.next_season?.id);
                                                }
                                                else {
                                                    setId(recreationalLeagues[1]?.id);
                                                }
                                                setbtnSel(true)
                                                setSelIndex(1);
                                                setselData(recreationalLeagues[1]);
                                                setRecLeague(true);

                                            }}>{(btnSel === true && selIndex === 1) ? 'Selected' : 'Select'}</Button>
                                        </div>
                                    </>
                                }</>

                                <>{recreationalLeagues?.length === 3 &&
                                    <>
                                        <div className={(btnSel === true && selIndex === 0) ? "league-card-border league-card" : "league-card"}>
                                            <div className="league-img-wrapper w-100">
                                                <img src={recreationalLeagues[0]?.league?.cover_image === null ? svg?.LeagueDef : recreationalLeagues[0]?.league?.cover_image} className="league-img w-100 h-100" alt="leagueimage NP" />
                                            </div>
                                            <div className="rec-title-wrapper">
                                                <div className="rec-title trimTxt1line">{recreationalLeagues[0]?.league?.name} {recreationalLeagues[0]?.name}</div>
                                                <BottomTooltip />
                                            </div>

                                            <div className="ghin-spots rec-txt">
                                                Recreational(no GHIN required)
                                            </div>

                                            <div className="rec-season-prize trimTxt1line mt-2"></div>

                                            <div className="rec-info trimTxt1line"></div>

                                            <div className="rec-credits d-flex align-items-center  mt-3">
                                                <div className="credits">Credits:</div>
                                                <div className="credit-score-wrapper">
                                                    <img src={svg?.Star} className="star-img" alt="star IMg NP" />
                                                    <div className="credit-score">{recreationalLeagues[0]?.season?.price}</div>
                                                </div>
                                            </div>

                                            <div className="rec-seasons d-flex mt-2">
                                                <div className="season-range">{convertDateToMonth(recreationalLeagues[0]?.season?.start_date)} - {convertDateToMonth(recreationalLeagues[0]?.season?.end_date)}</div>
                                                {
                                                    recreationalLeagues[0]?.next_season?.id &&
                                                    <>
                                                        <div className="form-check form-switch">
                                                            <input
                                                                // className="form-check-input noti-switch" 
                                                                className={`form-check-input noti-switch ${!recChkState[0] ? 'not-checked' : 'form-check-input noti-switch'}`}
                                                                type="checkbox"
                                                                // checked={recreationalLeagues[0]?.next_season?.id ? true : false}
                                                                checked={recChkState[0]}
                                                                onChange={(event) => handleRecLeaguesChk('rec', 0, recreationalLeagues[0]?.next_season?.id)}
                                                            />
                                                        </div>
                                                        <div className="nxt-season">Next Season</div>
                                                    </>
                                                }
                                            </div>
                                            <Button className={(btnSel === true && selIndex === 0) ? "rec-selected-btn" : "rec-sel-btn cursor-pointer"} onClick={() => {
                                                if (recreationalLeagues[0]?.next_season?.id && recChkState[0] === true) {
                                                    setNextId(recreationalLeagues[0]?.next_season?.id);
                                                }
                                                else {
                                                    setId(recreationalLeagues[0]?.id);
                                                }
                                                setbtnSel(true)
                                                setSelIndex(0);
                                                setselData(recreationalLeagues[0]);
                                                setRecLeague(true);

                                            }}>{(btnSel === true && selIndex === 0) ? 'Selected' : 'Select'}</Button>
                                        </div>

                                        <div className={(btnSel === true && selIndex === 1) ? "league-card-border league-card" : "league-card"}>
                                            <div className="league-img-wrapper w-100">
                                                <img src={recreationalLeagues[1]?.league?.cover_image === null ? svg?.LeagueDef : recreationalLeagues[1]?.league?.cover_image} className="league-img w-100 h-100" alt="leagueimage NP" />
                                            </div>
                                            <div className="rec-title-wrapper">
                                                <div className="rec-title trimTxt1line">{recreationalLeagues[1]?.league?.name} {recreationalLeagues[1]?.name}</div>
                                                <BottomTooltip />
                                            </div>

                                            <div className="ghin-spots rec-txt">
                                                Recreational(no GHIN required)
                                            </div>

                                            <div className="rec-season-prize trimTxt1line mt-2"></div>

                                            <div className="rec-info trimTxt1line"></div>

                                            <div className="rec-credits d-flex align-items-center  mt-3">
                                                <div className="credits">Credits:</div>
                                                <div className="credit-score-wrapper">
                                                    <img src={svg?.Star} className="star-img" alt="star IMg NP" />
                                                    <div className="credit-score">{recreationalLeagues[1]?.season?.price}</div>
                                                </div>
                                            </div>

                                            <div className="rec-seasons d-flex mt-2">
                                                <div className="season-range">{convertDateToMonth(recreationalLeagues[1]?.season?.start_date)} - {convertDateToMonth(recreationalLeagues[1]?.season?.end_date)}</div>
                                                {
                                                    recreationalLeagues[1]?.next_season?.id &&
                                                    <>
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className={`form-check-input noti-switch ${!recChkState[1] ? 'not-checked' : 'form-check-input noti-switch'}`}
                                                                type="checkbox"
                                                                // checked={recreationalLeagues[0]?.next_season?.id ? true : false}
                                                                checked={recChkState[1]}
                                                                onChange={(event) => handleRecLeaguesChk('rec', 1, recreationalLeagues[1]?.next_season?.id)}
                                                            />
                                                        </div>
                                                        <div className="nxt-season">Next Season</div>
                                                    </>
                                                }
                                            </div>
                                            <Button className={(btnSel === true && selIndex === 1) ? "rec-selected-btn" : "rec-sel-btn cursor-pointer"} onClick={() => {
                                                if (recreationalLeagues[1]?.next_season?.id && recChkState[1] === true) {
                                                    setNextId(recreationalLeagues[1]?.next_season?.id);
                                                }
                                                else {
                                                    setId(recreationalLeagues[1]?.id);
                                                }
                                                setbtnSel(true)
                                                setSelIndex(1);
                                                setselData(recreationalLeagues[1]);
                                                setRecLeague(true);

                                            }}>{(btnSel === true && selIndex === 1) ? 'Selected' : 'Select'}</Button>
                                        </div>

                                        <div className={(btnSel === true && selIndex === 2) ? "league-card-border league-card" : "league-card"}>
                                            <div className="league-img-wrapper w-100">
                                                <img src={recreationalLeagues[2]?.league?.cover_image === null ? svg?.LeagueDef : recreationalLeagues[2]?.league?.cover_image} className="league-img w-100 h-100" alt="leagueimage NP" />
                                            </div>
                                            <div className="rec-title-wrapper">
                                                <div className="rec-title trimTxt1line">{recreationalLeagues[2]?.league?.name} {recreationalLeagues[2]?.name}</div>
                                                <BottomTooltip />
                                            </div>

                                            <div className="ghin-spots rec-txt">
                                                Recreational(no GHIN required)
                                            </div>

                                            <div className="rec-season-prize trimTxt1line mt-2"></div>

                                            <div className="rec-info trimTxt1line"></div>

                                            <div className="rec-credits d-flex align-items-center  mt-3">
                                                <div className="credits">Credits:</div>
                                                <div className="credit-score-wrapper">
                                                    <img src={svg?.Star} className="star-img" alt="star IMg NP" />
                                                    <div className="credit-score">{recreationalLeagues[2]?.season?.price}</div>
                                                </div>
                                            </div>

                                            <div className="rec-seasons d-flex mt-2">
                                                <div className="season-range">{convertDateToMonth(recreationalLeagues[2]?.season?.start_date)} - {convertDateToMonth(recreationalLeagues[2]?.season?.end_date)}</div>
                                                {
                                                    recreationalLeagues[2]?.next_season?.id &&
                                                    <>
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className={`form-check-input noti-switch ${!recChkState[2] ? 'not-checked' : 'form-check-input noti-switch'}`}
                                                                type="checkbox"
                                                                // checked={recreationalLeagues[0]?.next_season?.id ? true : false}
                                                                checked={recChkState[1]}
                                                                onChange={(event) => handleRecLeaguesChk('rec', 2, recreationalLeagues[2]?.next_season?.id)}
                                                            />
                                                        </div>
                                                        <div className="nxt-season">Next Season</div>
                                                    </>
                                                }
                                            </div>
                                            <Button className={(btnSel === true && selIndex === 2) ? "rec-selected-btn" : "rec-sel-btn cursor-pointer"} onClick={() => {
                                                if (recreationalLeagues[2]?.next_season?.id && recChkState[2] === true) {
                                                    setNextId(recreationalLeagues[2]?.next_season?.id);
                                                }
                                                else {
                                                    setId(recreationalLeagues[2]?.id);
                                                }
                                                setbtnSel(true)
                                                setSelIndex(2);
                                                setselData(recreationalLeagues[2]);
                                                setRecLeague(true);

                                            }}>{(btnSel === true && selIndex === 2) ? 'Selected' : 'Select'}</Button>
                                        </div>
                                    </>
                                }</>
                            </div>
                        </>
                }
            </>

            <>{getwidth <= mobileBreakpoint &&
                <div className="banner-info-mob">
                    <>{getBanner === '' && <div className="add-banner-txt">Ad Banner</div>}</>
                    <img src={getBanner === '' ? svg?.Banner : getBanner} className="banner-mob h-100 w-100" alt="banenr NP" />
                </div>
            }</>

            {
                (showCurrSeason === true || showNextSeason === true) &&
                <CurrentSeasonPopup show={showCurrSeason || showNextSeason} handleClosePopup={handleClosePopup} showEndDate={showEndDate} nextSeason={showNextSeason} selData={selData} recLeague={recLeague} handleConfirm={handleConfirm} />
            }
            {
                recLeague === true &&
                <CurrentSeasonPopup show={recLeague} handleClosePopup={handleClosePopup} selData={selData} recLeague={recLeague} handleConfirm={handleConfirm} price={recreationalLeagues[0]?.season?.price} showStartDate={showStartDate} />
            }
        </>
    );
};

const SubscriptionForm = (props) => {
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0);
    const [subscriptionData, setSubscriptionData] = useState([]);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    useEffect(() => {
        fetchSubData();
    }, []);

    const fetchSubData = async () => {
        try {
            let resp = await fetchSubscriptionData();
            setSubscriptionData(resp);
        }
        catch (err) {
        }
    }

    const handleTriangleTxt = (dataIndex) => {
        if (dataIndex === 0) {
            return '';
        }
        if (dataIndex === 1) {
            return 'Most Popular!';
        }
        if (dataIndex === 2) {
            return 'Best Value!'
        }
    }

    const handleSubConfirm = (incomingData) => {
        let obj = {
            recommendedLeaguesData: props?.recommendedData,
            subscriptionData: incomingData
        }
        props?.handleNext(obj, true);
    }

    return (
        <>
            <div className="heading-wrapper">
                <div className="form-heading">Select Subscription</div>
                <div className="form-sub-heading">Select a subscription plan and add your payment details so you can compete in a league.</div>
            </div>

            <div className="subscription-info d-flex mt-2">
                <div className="subscription-txt">{props?.recommendedData?.name}</div>
                <div className="subscription-prize">{`Up to $${parseInt(props?.recommendedData?.prize)} prizes each season`}</div >
            </div >

            <div className="subscription-form-wrapper">
                {
                    subscriptionData?.map((data, index) => (

                        <div className="subs-card d-flex flex-column" key={index}>
                            <>{index !== 0 &&
                                <>
                                    <div className="triangle-topleft"></div>
                                    <>{index === 1 &&
                                        <img src={svg?.SubStar} className="subs-star" alt="Star NP" />
                                    }</>
                                </>
                            }</>

                            {/* No disabled on onboarding as per mandeep  */}
                            {Number(data?.credits) < Number(props?.leaguePrice) && (
                                <div className="disabled-overlay"></div>
                            )}
                            <div className={index === 2 ? 'triangle-txt right5px' : 'triangle-txt'}>{handleTriangleTxt(index)}</div>
                            <div className="sub-heading trimTxt1line">{data?.name}</div>
                            <div className="sub-green-line"></div>
                            <div className="sub-description trimTxt1line">{data?.description}</div>
                            <div className="sub-price trimTxt1line">${parseInt(data?.price)}</div>
                            <div className="sub-credits-wrapper d-flex flex-column align-items-center">
                                <div className="credits-head">
                                    Credits
                                </div>
                                <div className="credit-score-wrapper d-flex align-items-center">
                                    <img src={svg?.Star} className="star-img" alt="star IMg NP" />
                                    <div className="credit-score">{data?.credits}</div>
                                </div>
                            </div>
                            <button className="sub-select" onClick={() => handleSubConfirm(data)}>Select</button>
                        </div>
                    ))
                }
            </div>
        </>
    );
};

const PaymentForm = (props) => {
    let discountVal = 0;
    const navigate = useNavigate();
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0);
    const stripe = useStripe();
    const elements = useElements();
    const { getValues } = useForm();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [expDateErr, setExpDateErr] = useState(false);
    const [cardCvvErr, setCardCvvErr] = useState(false);
    const [cvvValue, setCvvValue] = useState('');
    const [cardNumErr, setCardNumErr] = useState(false);
    const [discount, setDiscount] = useState(null);
    const [couponCode, setCouponCode] = useState('');

    const cardExpiryRef = useRef(null);
    const cardNumberRef = useRef(null);
    const cardCvcRef = useRef(null);
    const [couponData, setCouponData] = useState(null);


    const fetchCoupon = async () => {
        try {
            const response = await axios.get(`https://api.stripe.com/v1/coupons/${couponCode}`, {
                headers: {
                    Authorization: 'Bearer sk_test_51N8OMlFdHTQtW0piycecLmtsyGbiB1O17MaJGT7XzFv56wJGK6cT747qx2XQDteZxgPdqsVvREE56f5AWXGzRjBE00OKYL1dxp',
                },
            });
            if (response?.data?.amount_off !== null) {
                setDiscount(response?.data?.amount_off / 100);
                // discountVal = response?.data?.amount_off / 100;
            }
            else if (response?.data?.percent_off !== null) {
                setDiscount(Number((props?.data?.subscriptionData?.subscriptionData?.price) * (response?.data?.percent_off / 100)).toFixed(2));
                // discountVal = Number((props?.data?.subscriptionData?.subscriptionData?.price) * (response?.data?.percent_off / 100)).toFixed(2)
            }
        } catch (error) {
            console.error('Error fetching coupon:', error?.response?.data?.error?.message);
            toast.error(error?.response?.data?.error?.message || 'Error in fetching coupon details');
        }
    };

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    const { control, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data, event) => {
        setIsFormSubmitted(true);
        event.preventDefault();
        // Handle Stripe submission here
        if (!stripe || !elements) {
            // Stripe hasn't loaded yet
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);
        const { token, error } = await stripe.createToken(cardElement, {
            card: {
                cvc: data?.cardCvv,
                name: data?.cardName,
            }
        });
        const { cardNumber, cardCvv, expDate } = getValues(['cardNumber', 'cardCvv', 'expDate']);

        // Perform your validation logic here
        // if (!cardNumber || !cardCvv || !expDate) {
        //     toast.error("Please fill in all card details");
        //     return;
        // }

        // if (token) {
        // Call your server with the token for further processing
        if (token === undefined) {
            toast.error("Please provide a valid credit card number");
            return;
        }
        try {
            const body = {
                "cents": Number(props?.data?.subscriptionData?.subscriptionData?.price - discount) * 100,
                "stripe_token": token?.id,
                "stripe_card_id": token?.card?.id,
                "tier_id": props?.data?.leagueId,
                "subscription_id": props?.data?.subscriptionData?.subscriptionData?.id,
                "coupon_code": couponCode,
            }
            let resp = await confirmPayment(body);
            toast.success(resp?.message || 'Congratulations, we have received your payment and your are now registered to the league');
            localStorage.setItem('on_boarding_complete', resp?.on_boarding_complete);
            props?.handleNext(props);
        }

        catch (error) {
            console.error("Error:", error);
            toast.error(error?.response?.data?.message);
        }
    };

    return (
        <>
            <div className="heading-wrapper">
                <div className="form-heading">Make Payment</div>
                <div className="form-sub-heading">Select a subscription plan and add your payment details so you can compete in a league.</div>
            </div>

            <div className="payment-body-wrapper d-flex">

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="payment-body-form-wrapper d-flex"
                >
                    <div className='payment-body'>
                        <div className="payment-sub-title trimTxt1line">Add payment details</div>
                        <div className="row mt-4 payment-form-row">
                            <div className="form-field w-100 card-number-wrapper">
                                <label htmlFor="card-number" className="txt-label">Card number<span className="red-label">*</span></label>
                                <div className={`custom-card-element ${errors?.cardNumber ? 'has-error' : ''}`}>
                                    <CardNumberElement
                                        name="cardNumber"
                                        id="card-number"
                                        className="card-number-txt"
                                        // className={`card-number-txt ${cardType}`}
                                        options={{
                                            showIcon: true,
                                            style: {
                                                base: {
                                                    fontSize: "16px",
                                                    fontWeight: "400",
                                                    fontFamily: "Proxima Nova Condensed",
                                                    border: '1px solid #ced4da',
                                                    "::placeholder": {
                                                        color: "#aab7c4",
                                                        fontFamily: "Proxima Nova Condensed",
                                                    },
                                                },
                                                invalid: {
                                                    color: "#9e2146",
                                                },
                                            },
                                        }}
                                        onChange={(event) => {
                                            cardNumberRef.current = event;
                                            setCardNumErr(false);
                                        }}
                                    />
                                </div>
                                {/* {errors.cardNumber && <p className="error text-danger-txt">{errors.expDate.message}</p>} */}
                                {(isFormSubmitted && cardNumErr) && <p className="error text-danger-txt">Card number is required</p>}
                            </div>

                            <div className="form-field payment-exp-cvv w-100 d-flex">
                                <div className="exp-date-wrapper w-50">
                                    <label htmlFor="exp-date trimTxt1line" className="txt-label">Expiration date<span className="red-label">*</span></label>
                                    <div className={`custom-card-element ${errors.expDate ? 'has-error' : ''}`}>
                                        <CardExpiryElement
                                            name="expDate"
                                            options={{
                                                style: {
                                                    base: {
                                                        fontSize: "16px",
                                                        fontWeight: "400",
                                                        fontFamily: "Proxima Nova Condensed",
                                                        border: '1px solid #ced4da',
                                                        "::placeholder": {
                                                            color: "#aab7c4",
                                                            fontFamily: "Proxima Nova Condensed",
                                                        },
                                                    },
                                                    invalid: {
                                                        color: "#9e2146",
                                                    },
                                                },
                                            }}
                                            onChange={(event) => {
                                                cardExpiryRef.current = event;
                                                setExpDateErr(false);
                                            }}
                                        />
                                    </div>
                                    {/* {errors.expDate && <p className="error text-danger-txt">{errors.expDate.message}</p>} */}
                                    {(isFormSubmitted && expDateErr) && <p className="error text-danger-txt">Expiration date is required</p>}
                                </div>

                                <div className="form-field w-100 cvc-wrapper">
                                    <label htmlFor="cvv" className="txt-label">CVV<span className="red-label">*</span></label>
                                    <div className={`custom-card-element ${errors.cardCvc ? 'has-error' : ''}`}>
                                        <CardCvcElement
                                            id="cvv"
                                            options={{
                                                style: {
                                                    base: {
                                                        fontSize: "16px",
                                                        fontWeight: "400",
                                                        fontFamily: "Proxima Nova Condensed",
                                                        border: '1px solid #ced4da',
                                                        "::placeholder": {
                                                            color: "#aab7c4",
                                                            fontFamily: "Proxima Nova Condensed",
                                                        },
                                                    },
                                                    invalid: {
                                                        color: "#9e2146",
                                                    },
                                                },
                                            }}
                                            onChange={(event) => {
                                                cardCvcRef.current = event;
                                                setCardCvvErr(event.empty);
                                                setCvvValue(event.complete ? event.value : '');
                                            }}

                                        />
                                    </div>
                                    {/* {isFormSubmitted && cardCvvErr && <p className="error text-danger-txt">Cvv is a required field</p>} */}
                                </div>

                            </div>

                            <div className="form-field w-100">
                                <label htmlFor="card-name" className="txt-label">Card holder name<span className="red-label">*</span></label>
                                <Controller
                                    control={control}
                                    name="cardName"
                                    rules={{
                                        required: "Card holder name is required",
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                id="card-name"
                                                variant="outlined"
                                                placeholder="Enter Card holder name"
                                                fullWidth
                                                margin="normal"
                                                {...field}
                                            />
                                            {errors?.cardName && <p className="error text-danger-txt" style={{ marginBottom: '0px' }}>{errors?.cardName.message}</p>}
                                        </>
                                    )}
                                />
                            </div>
                            <div className="coupon-wrapper w-100">
                                <div className="form-field w-100">
                                    <label htmlFor="card-name" className="txt-label">Coupon Code</label>
                                    <div className="coupon-wrapper-2 d-flex justify-content-between align-items-center w-100">
                                        <TextField
                                            id="coupon-code"
                                            variant="outlined"
                                            placeholder="Enter Coupon code"
                                            fullWidth
                                            margin="normal"
                                            onChange={(e) => setCouponCode(e.target.value)}
                                        // onChange={(e) => field.onChange(e.target.value)}
                                        />
                                        <Button className="apply-coupun-btn w-25" onClick={() => {
                                            fetchCoupon()
                                        }}>Apply </Button>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className="order-details">

                        <div className="order-details-head">
                            Order Summary
                        </div>

                        <div className="order-season-wrapper d-flex justify-content-between align-items-center">
                            <div className="order-season-title">
                                {props?.data?.subscriptionData?.subscriptionData?.name}
                            </div>
                            <div className="order-season-value">
                                {
                                    props?.data?.subscriptionData?.subscriptionData?.price &&
                                    <>
                                        ${props?.data?.subscriptionData?.subscriptionData?.price}
                                    </>
                                }

                            </div>
                        </div>

                        <div className="order-season-wrapper d-flex justify-content-between align-items-center">
                            <div className="order-season-title">
                                Discount
                            </div>
                            <div className="order-season-value">
                                {
                                    discount &&
                                    <>
                                        ${discount}
                                    </>
                                }

                            </div>
                        </div>

                        <div className="order-line"></div>

                        <div className="order-total-wrapper d-flex justify-content-between align-items-center">
                            <div className="order-total-title">
                                Total
                            </div>
                            <div className="order-total-value">
                                ${props?.data?.subscriptionData?.subscriptionData?.price - discount}
                            </div>
                        </div>

                        <>{getwidth >= mobileBreakpoint &&
                            <Button className="order-btn" type="button" onClick={handleSubmit(onSubmit)}>
                                Confirm Payment
                            </Button>
                        }</>


                        <div className="order-desc">A recurring charge of $60.00 will automatically be applied to your payment method and start on May 1, 2023. You may cancel at any time, effective at the end of the billing period, by going to your payment settings. All amounts paid are non-refundable, subject to certain exceptions. By clicking “Confirm Payment”, you agree to our <span className="terms-con cursor-pointer" onClick={() => navigate('/terms-conditions')}>Terms of Service</span> and authorize this recurring charge.</div>

                        <>{getwidth < mobileBreakpoint &&
                            <Button className="order-btn" type="button" onClick={handleSubmit(onSubmit)}>
                                Confirm Payment
                            </Button>
                        }</>
                    </div>

                </form>
            </div>
        </>
    );
};

const PaymentformElements = (props) => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentForm data={props} handleNext={props?.handleNext} />
        </Elements>
    )
}

const FinishPage = () => {
    return (
        <div className="FinishPage-wrapper">
            <div className="FinishPage-body">
                <div className="FinishPage-head">
                    Welcome to
                </div>
                <img src={svg?.AdminLogo} className="logo-img" alt="" />

                <div className="FinishPage-body-2">You're officially part of an elite community of golf enthusiasts.</div>
                <div className="FinishPage-body-2">Link up with your friends, dust off those clubs. It's time to connect, swing, and earn!</div>
            </div>

            {/* <Button className="FinishPage-btn">Go to Dashboard</Button> */}
        </div>
    )
}

function getStepContent(step, handleNext, getRecommendedData, getSubscriptionData, leagueId, leaguePrice, getGhinNumber, methods, errors) {
    switch (step) {
        case 0:
            return <BasicForm control={methods.control} errors={methods.formState.errors} />;
        case 1:
            return <UsgaForm />;
        case 2:
            return <LeaguesForm handleNext={handleNext} usga_num={getGhinNumber} />;
        case 3:
            return <SubscriptionForm handleNext={handleNext} recommendedData={getRecommendedData} leagueId={leagueId} leaguePrice={leaguePrice} />;
        case 4:
            return <PaymentformElements handleNext={handleNext} subscriptionData={getSubscriptionData} leagueId={leagueId} leaguePrice={leaguePrice} />;
        case 5:
            return <FinishPage />;
        default:
            return <null />
    }
}

let mobileBreakpoint = 820;

const LinearStepper = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0);
    const [hideGhinBack, setHideGhinBack] = useState(false);
    const [hideRecBack, setHideRecBack] = useState(false);

    const validationSchema = yup.object().shape({
        firstName: yup.string().required('First name is required'),
        lastName: yup.string().required('Last name is required'),
        // phoneNumber: yup.string().required('Phone number is required').test(
        //     'is-valid-format',
        //     'Invalid phone number. Please use the format XXX-XXX-XXXX',
        //     (value) => /^\d{3}-\d{3}-\d{4}$/.test(value)
        // ),
        // dateOfBirth: yup.date()
        //     .required('Date of Birth is required')
        //     .test('is-adult', 'You must be at least 18 years old', function (value) {
        //         // Calculate today's date minus 18 years
        //         const minDate = new Date();
        //         minDate.setFullYear(minDate.getFullYear() - 18);
        //         const isAdult = isBefore(value, minDate);
        //         console.log('isAdult:', isAdult);

        //         return isAdult;
        //         // Check if the selected date is before the calculated minimum date
        //         // return isBefore(value, minDate);
        //     }),
        zipCode: yup
            .string()
            .required('Zip code is required')
            .matches(/^\d{5}$/, 'Zip code must be a 5-digit number'),
        gender: yup.string().required('Gender is required'),
    });

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        try {
            let resp = await fetchBasicUserInfo();
            if (resp) {
                if (resp?.user?.first_name !== null && resp?.user?.last_name !== null && resp?.phone_number !== null && resp?.zip_code !== null && resp?.gender !== null && resp?.birthdate !== null) {
                    setActiveStep(1);
                    setCompletedSteps([1])
                    setHideGhinBack(true);
                }
                if (resp?.ghin !== null) {
                    setActiveStep(2);
                    setCompletedSteps([2]);
                    setHideRecBack(true);
                    setGhinNumber(resp?.ghin)
                }
                // setActiveStep(0) // add this if want's to lands directly on subscription page
            }
        }
        catch (err) {
            if (err?.response?.data?.detail === "Invalid token.") {
                // toast.error(err?.response?.data?.detail || 'An error occurred while fetching upcoming rounds.')
                // window.location.href='/login';
                navigate('/login')
            }
            else {
                toast.error(err?.response?.data?.message || 'An error occurred while fetching upcoming rounds.')
            }
        }
    }

    const methods = useForm({
        defaultValues: {
            firstName: "",
            lastName: "",
            dateOfBirth: null,
            zipCode: "",
            gender: "",
            phoneNumber: "",
            UsgaNumber: ''
        },
    });

    const [activeStep, setActiveStep] = useState(0);
    const [completedSteps, setCompletedSteps] = useState([0]);
    const [skippedSteps, setSkippedSteps] = useState([]);
    const [getRecommendedData, setRecommendedData] = useState({});
    const [leagueId, setLeagueId] = useState(null);
    const [leaguePrice, setLeaguePrice] = useState(0);
    const [getGhinNumber, setGhinNumber] = useState('');
    const [getSubscriptionData, setSubscriptionData] = useState({});
    const steps = getSteps();

    const isStepOptional = (step) => {
        return step === 1 || step === 2;
    };

    const isStepSkipped = (step) => {
        return skippedSteps.includes(step);
    };

    const callNextStepfunc = () => {
        if (activeStep !== steps.length - 1) {
            setActiveStep(activeStep + 1);
            setSkippedSteps(
                skippedSteps.filter((skipItem) => skipItem !== activeStep)
            );
        }
    }

    const handleNext = async (data, recLeaguesClick = false, currentId, nextId, errors) => {
        const formData = methods.getValues();
        try {
            if (activeStep === 0) {
                await validationSchema.validate(data, { abortEarly: false });
                // Validation succeeded, handle form submission

                if (isStepOptional(activeStep) && !errors?.dateOfBirth) {
                    // If the step is optional and dateOfBirth is valid, allow the next step
                    callNextStepfunc();
                    setCompletedSteps([activeStep + 1]);
                    return;
                }
                if (isStepOptional(activeStep) && !errors?.phoneNumber) {
                    // If the step is optional and dateOfBirth is valid, allow the next step
                    callNextStepfunc();
                    setCompletedSteps([activeStep + 1]);
                    return;
                }
                if (recLeaguesClick === true && data) {
                }
                try {
                    let body = {
                        user: {
                            first_name: formData?.firstName,
                            last_name: formData?.lastName
                        },
                        gender: formData?.gender,
                        birthdate: formData?.dateOfBirth,
                        phone_number: formData?.phoneNumber,
                        zip_code: String(formData?.zipCode)
                    }
                    let resp = await profileCreation(body, userId);
                    if (resp) {
                        toast.success('Profile updated successfuly');
                        callNextStepfunc();
                        setCompletedSteps([activeStep + 1]);
                    }
                }
                catch (err) {
                    toast.error(err?.response?.data?.message || 'Error in profile creation');
                }
            }
            if (activeStep === 1) {
                try {
                    let body = {
                        ghin: formData?.UsgaNumber
                    }
                    let resp = await addGhin(body);
                    setGhinNumber(formData.UsgaNumber);
                    if (resp) {
                        toast.success(resp?.message || 'USGA added successfuly');
                        callNextStepfunc();
                        setCompletedSteps([activeStep + 1]);
                    }
                }
                catch (err) {
                    toast.error(err?.response?.data?.message || 'Error in adding USGA');
                }
            }
            if (activeStep === 2 && recLeaguesClick === true && data) {
                callNextStepfunc();
                setCompletedSteps([activeStep + 1]);
                setRecommendedData(data);
                setLeaguePrice(data?.season?.price)
                if (currentId) {
                    setLeagueId(currentId)
                }
                else {
                    setLeagueId(nextId);
                }
            }

            if (activeStep === 3 && data) {
                callNextStepfunc();
                setCompletedSteps([activeStep + 1]);
                setSubscriptionData(data);
            }
            if (activeStep === 4) {
                callNextStepfunc();
                setCompletedSteps([activeStep + 1]);
            }
        }
        catch (validationErrors) {
            // Validation failed, handle error messages
            const validationErrorMessages = {};
            validationErrors.inner.forEach((error) => {
                validationErrorMessages[error.path] = error.message;
            });
        }
    };

    const handleBack = () => {
        const newActiveStep = activeStep - 1;
        setActiveStep(newActiveStep);
        setCompletedSteps([newActiveStep])
    };

    const handleSkip = () => {
        const newActiveStep = activeStep + 1;
        if (!isStepSkipped(activeStep)) {
            setSkippedSteps([...skippedSteps, activeStep]);
        }
        setActiveStep(newActiveStep);
        setCompletedSteps([newActiveStep])
    };

    return (
        <div>
            <>{activeStep < steps.length - 1 &&
                <Typography className="stepnumber">
                    Step {activeStep + 1} of {steps.length - 1}
                </Typography>
            }</>
            {
                activeStep < steps.length - 1 &&
                <Paper elevation={0} square>
                    <Stepper alternativeLabel activeStep={activeStep} connector={<div className={classes.stepperConnector} />}>
                        {steps?.slice(0, 5)?.map((step, index) => {
                            const stepProps = {};
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={index}>
                                    <LinearProgress value={completedSteps.includes(index) ? 100 : 0} variant="determinate" />
                                </Step>
                            );
                        })}
                    </Stepper>
                </Paper>
            }

            <>
                <div className={classes.stepContainer}>
                    {
                        (activeStep < (steps.length - 1)) ?
                            (
                                <div className=''>
                                    <Typography variant="h6">{steps[activeStep]}</Typography>
                                </div>
                            )
                            : null
                    }
                    <div className={classes.stepContent}>
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(handleNext)} className={(activeStep !== 2 && activeStep !== 3 && activeStep !== 4 && activeStep !== 5) ? 'linear-forms' : ""}>

                                {activeStep === steps.length - 1 ? (
                                    <FinishPage />
                                )
                                    :
                                    getStepContent(activeStep, handleNext, getRecommendedData, getSubscriptionData, leagueId, leaguePrice, getGhinNumber, methods, methods?.errors)
                                }
                                <>{activeStep !== 0 && (
                                    <>{
                                        (hideGhinBack !== true && activeStep === 1) || (hideRecBack !== true && activeStep === 2) || (activeStep === 3) || (activeStep === 4) ?
                                            <img src={svg?.BackArrow} className="backarrow cursor-pointer" alt="back arrow NP"
                                                onClick={handleBack} /> : null
                                    }</>
                                )
                                }</>
                                <>{(activeStep !== 3 && activeStep !== 4) &&
                                    <Button
                                        className="nextBtn"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            if (activeStep === 5) {
                                                window.location.href = '/home';
                                            }
                                        }}
                                        type="submit"
                                    >
                                        {activeStep === steps.length - 1 ? "Go To Dashboard" : "Next Step"}
                                        {
                                            (getwidth >= mobileBreakpoint && activeStep !== 5) &&

                                            <img src={svg?.RightArrow} className="RightArrow" style={{ marginLeft: '10px' }} alt="RightArrow" />
                                        }
                                    </Button>
                                }</>

                                {isStepOptional(activeStep) && (
                                    <>
                                        {
                                            activeStep === 1 &&
                                            <div
                                                className="skipBtn mt-3 cursor-pointer"
                                                onClick={handleSkip}
                                            >
                                                Skip
                                            </div>
                                        }
                                    </>
                                )}
                            </form>
                            <>
                                {
                                    getwidth >= mobileBreakpoint && activeStep === 1 &&
                                    <form onSubmit={methods.handleSubmit(handleNext)} className="linear-forms mt-4" style={{
                                        backgroundColor: '#F1F2F2'
                                    }}>
                                        < div className="form-wrapper-usga linear-forms" style={{
                                            backgroundColor: '#F1F2F2'
                                        }}>
                                            <>
                                                <div className="mt-4">
                                                    <img src={svg?.UsgaLogo} className="usga-logo" alt="imagenp" />
                                                    <div className="usga-body-wrapper mt-3">
                                                        <div className="usga-body-txt">Having a Handicap Index makes the game more fun and enjoyable by connecting you to a community of nearly 3 million golfers.</div>
                                                        <div className="usga-link mt-4">Go to https://getahandicap.usga.org/ to get your Handicap Index</div>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <Button
                                                        className="nextBtn w-25 mx-auto"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => window.location.href = 'https://getahandicap.usga.org/v2app/#/golfer-registration/sign-up'}
                                                    >
                                                        Go to Site
                                                    </Button>
                                                </div>
                                            </>
                                        </div>
                                    </form>}
                            </>
                        </FormProvider>
                    </div>
                </div>
            </>
        </div >
    );
};

export default LinearStepper;