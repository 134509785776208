import React from 'react';

const ErrorMsg = ({ errormsg }) => {
  return (
    <div className="alert alert-danger mt-5 error-msg text-center w-100" role="alert">
      {errormsg}
    </div>
  );
};

export default ErrorMsg;
