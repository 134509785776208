import React, { useEffect } from "react";
import Navbar from '../../CustomComponents/Navbar';
import './styles.scss';

const DataDeletion = () => {
    return (
        <>
            <Navbar />
            <div className="DataDeletion-wrapper">
                If you want to delete your data, write to <span className="support-email">support@linxleague.com</span>
            </div>
        </>
    )
}

export default DataDeletion;