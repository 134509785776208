import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import { createTiers } from '../../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import svg from '../../../assets/images/svg/index';
import './styles.scss';

const CreateTiers = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let data = location?.state?.leagueData;
    let seasonData = location?.state?.seasonData;

    const [tiers, setTiers] = useState([
        {
            name: "",
            description: "",
            startDate: "",
            endDate: "",
            noOfSlots: "",
            prizeVal: "",
            prizeDesc: ""
        }
    ]);

    return (
        <div className="create-tiers-wrapper adminpadding">
            <div className="season-back-arrow-cont">
                <img src={svg?.SeasonBack} alt="back arrow NP" className="back-arrow-img cursor-pointer" onClick={() => navigate(-1)} />
                <div className="create-season-heading">
                    Create Tiers: {data?.name}
                </div>
            </div>
            <div className="create-season-body mt-4">
                <Formik
                    // validationSchema={schema}
                    initialValues={{
                        // tiers: tiers.map(tier => ({ ...tier }))
                        tiers: Array.from({ length: tiers.length }, (_, index) => ({ ...tiers[0], id: index }))
                    }}
                    onSubmit={async (values) => {
                        let tiers = []
                        for (let i = 0; i < values?.tiers.length; i++) {

                            if (values?.tiers?.[i]?.name === '' || values?.tiers?.[i]?.name === undefined || values?.tiers?.[i]?.noOfSlots === '' || values?.tiers?.[i]?.noOfSlots === undefined || values?.tiers?.[i]?.prizeVal === '' || values?.tiers?.[i]?.prizeVal === undefined || ((values?.tiers?.[i]?.startDate === '' || values?.tiers?.[i]?.startDate === undefined) && data?.type === 1) || ((values?.tiers?.[i]?.endDate === '' || values?.tiers?.[i]?.endDate === undefined) && data?.type === 1)) {
                                return
                            }
                            if (values?.tiers[i]?.startDate >= values?.tiers[i]?.endDate && data?.type !== 2) {
                                toast.error('Handicap To should be greated than Handicap From');
                                return;
                            }
                            if (data?.type !== 2) {
                                tiers?.push({
                                    name: values?.tiers[i]?.name,
                                    description: values?.tiers[i]?.description?.replace(/\n/g, " ") || '',
                                    handicap_from: values?.tiers[i]?.startDate || 0,
                                    handicap_to: values?.tiers[i]?.endDate || 0,
                                    max_slots: values?.tiers[i]?.noOfSlots,
                                    prize: String(values?.tiers[i]?.prizeVal),
                                    prize_description: values?.tiers[i]?.prizeDesc,
                                })
                            }
                            else {
                                tiers?.push({
                                    name: values?.tiers[i]?.name,
                                    description: values?.tiers[i]?.description?.replace(/\n/g, " ") || '',
                                    max_slots: values?.tiers[i]?.noOfSlots,
                                    prize: String(values?.tiers[i]?.prizeVal),
                                    prize_description: values?.tiers[i]?.prizeDesc,
                                })
                            }
                        }
                        let body = {
                            tiers
                        }
                        try {
                            let resp = await createTiers(body, seasonData[0]?.id);
                            if (resp) {
                                toast.success('Tiers created successfully');
                                navigate('/admin/dashboard')
                            }
                        }
                        catch (error) {
                            toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
                        }
                    }}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        isValid,
                        dirty,
                        /* and other goodies */
                    }) => (
                        <form
                            className="create-league-form w-75"
                            onSubmit={handleSubmit}
                            noValidate>
                            {tiers?.map((tier, index) => (
                                <div className={`Auth-form-content w-100  ${index !== tiers.length - 1 ? 'with-border' : ''}`} key={index}>
                                    <Form.Group controlId={`tiers[${index}]?.name`}>
                                        <label htmlFor={`tiers[${index}]?.name`} className="greyLabel">
                                            Tier Name
                                        </label>
                                        <div
                                            className={`form-floating mt-2 mb-3 ${touched?.tiers?.[index]?.name && errors?.tiers?.[index]?.name
                                                ? 'has-validation-error'
                                                : ''
                                                }`}>
                                            <Form.Control
                                                type="text"
                                                name={`tiers[${index}].name`}
                                                value={values.tiers[index]?.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="w-100"
                                                isInvalid={touched?.tiers?.[index]?.name && errors?.tiers?.[index]?.name}
                                            />
                                            {(values?.tiers?.[index]?.name === '' || values?.tiers?.[index]?.name === undefined) && (
                                                <Form.Control.Feedback type="invalid" className="d-block">
                                                    Tier name is required
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId={`tiers[${index}]?.description`}>
                                        <label htmlFor={`tiers[${index}]?.description`} className="greyLabel">
                                            Description
                                        </label>
                                        <div
                                            className={`form-floating mt-2 mb-3 ${touched?.description && errors?.description
                                                ? 'has-validation-error'
                                                : ''
                                                }`}>
                                            <Form.Control
                                                as="textarea"
                                                name={`tiers[${index}].description`}
                                                // placeholder="name"
                                                value={values.tiers?.[index]?.description}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="w-100"
                                                isInvalid={touched?.description && errors?.description}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.description}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>

                                    <div className="season-dates-wrapper d-flex justify-content-center align-items-center w-100">

                                        <>{data?.type !== 2 &&
                                            <div className="handicap-range w-50">
                                                <label htmlFor={`tiers[${index}]?.startDate`} className="greyLabel">
                                                    Handicap range
                                                </label>
                                                <div className="handicap-range-field d-flex" style={{ gap: '10px' }}>
                                                    <Form.Group controlId={`tiers[${index}]?.startDate`} className="w-50">
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched?.startDate && errors?.startDate
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <label style={{ display: values.tiers[index]?.startDate !== '' && values.tiers[index]?.startDate >= 0 ? 'none' : 'block' }} className="floating-handicap-label">
                                                                From:
                                                            </label>

                                                            <Form.Control
                                                                type="number"
                                                                onFocus={() => {
                                                                    if (!values.tiers[index]?.startDate) {
                                                                        setFieldValue(`tiers[${index}].startDate`, '');
                                                                    }
                                                                }}
                                                                onInput={(e) => {
                                                                    e.target.value = Math.abs(e.target.value);
                                                                    handleChange(e); // Trigger the handleChange function to update the form state.
                                                                }}
                                                                name={`tiers[${index}].startDate`}
                                                                value={values.tiers[index]?.startDate}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched?.startDate && errors?.startDate}
                                                            />
                                                            {((values?.tiers?.[index]?.startDate === '' || values?.tiers?.[index]?.startDate === undefined) && data?.type === 1) && (
                                                                <Form.Control.Feedback type="invalid" className="d-block">
                                                                    Handicap From is required
                                                                </Form.Control.Feedback>
                                                            )}
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group controlId={`tiers[${index}]?.endDate`} className="w-50">
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched?.endDate && errors?.endDate
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <label style={{ display: values.tiers[index]?.endDate !== '' && values.tiers[index]?.endDate >= 0 ? 'none' : 'block' }} className="floating-handicap-label">
                                                                To:
                                                            </label>

                                                            <Form.Control
                                                                type="number"
                                                                onFocus={() => {
                                                                    if (!values.tiers[index]?.endDate) {
                                                                        setFieldValue(`tiers[${index}].endDate`, '');
                                                                    }
                                                                }}
                                                                onInput={(e) => {
                                                                    e.target.value = Math.abs(e.target.value);
                                                                    handleChange(e); // Trigger the handleChange function to update the form state.
                                                                }}
                                                                name={`tiers[${index}].endDate`}
                                                                value={values.tiers[index]?.endDate}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched?.endDate && errors?.endDate}
                                                            />
                                                            {((values?.tiers?.[index]?.endDate === '' || values?.tiers?.[index]?.endDate === undefined) && data?.type === 1) && (
                                                                <Form.Control.Feedback type="invalid" className="d-block">
                                                                    Handicap To is required
                                                                </Form.Control.Feedback>
                                                            )}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        }</>

                                        <Form.Group controlId={`tiers[${index}]?.noOfSlots`} className={data?.type === 2 ? 'w-100' : 'w-50'}>
                                            <label htmlFor={`tiers[${index}]?.noOfSlots`} className="greyLabel">
                                                Number of slots
                                            </label>
                                            <div
                                                className={`form-floating mt-2 mb-3 ${touched?.noOfSlots && errors?.noOfSlots
                                                    ? 'has-validation-error'
                                                    : ''
                                                    }`}>
                                                <Form.Control
                                                    type="number"
                                                    name={`tiers[${index}].noOfSlots`}
                                                    onInput={(e) => {
                                                        e.target.value = Math.abs(e.target.value);
                                                        handleChange(e); // Trigger the handleChange function to update the form state.
                                                      }}
                                                    // placeholder="name"
                                                    value={values.tiers[index]?.noOfSlots}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="w-100"
                                                    isInvalid={touched?.noOfSlots && errors?.noOfSlots}
                                                />
                                                {(values?.tiers?.[index]?.noOfSlots === '' || values?.tiers?.[index]?.noOfSlots === undefined) && (
                                                    <Form.Control.Feedback type="invalid" className="d-block">
                                                        Number of slots are required
                                                    </Form.Control.Feedback>
                                                )}
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.noOfSlots}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <Form.Group controlId={`tiers[${index}]?.prizeVal`}>
                                        <label htmlFor={`tiers[${index}]?.prizeVal`} className="greyLabel">
                                            Prize value
                                        </label>
                                        <div
                                            className={`form-floating mt-2 mb-3 ${touched?.prizeVal && errors?.prizeVal
                                                ? 'has-validation-error'
                                                : ''
                                                }`}>
                                            <Form.Control
                                                type="number"
                                                name={`tiers[${index}].prizeVal`}
                                                // placeholder="name"
                                                value={values.tiers[index]?.prizeVal}
                                                min="0"
                                                onInput={(e) => {
                                                    e.target.value = Math.abs(e.target.value);
                                                    handleChange(e); // Trigger the handleChange function to update the form state.
                                                }}
                                                onChange={(e) => {
                                                    // Ensure only positive numbers are entered
                                                    const inputValue = e.target.value;
                                                    if (/^[+]?\d*\.?\d*$/.test(inputValue)) {
                                                        handleChange(e);
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                className="w-100"
                                                isInvalid={touched?.prizeVal && errors?.prizeVal}
                                            />
                                            {(values?.tiers?.[index]?.prizeVal === '' || values?.tiers?.[index]?.prizeVal === undefined) && (
                                                <Form.Control.Feedback type="invalid" className="d-block">
                                                    Prize value is required
                                                </Form.Control.Feedback>
                                            )}
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId={`tiers[${index}]?.prizeDesc`}>
                                        <label htmlFor={`tiers[${index}]?.prizeDesc`} className="greyLabel">
                                            Prize description
                                        </label>
                                        <div
                                            className={`form-floating mt-2 mb-3 ${touched?.prizeDesc && errors?.prizeDesc
                                                ? 'has-validation-error'
                                                : ''
                                                }`}>
                                            <Form.Control
                                                type="text"
                                                name={`tiers[${index}].prizeDesc`}
                                                // placeholder="name"
                                                value={values.tiers[index]?.prizeDesc}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="w-100"
                                                isInvalid={touched?.prizeDesc && errors?.prizeDesc}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.prizeDesc}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                </div>
                            ))}
                            <div className="add-another-tier cursor-pointer mt-4" onClick={() => setTiers([...tiers, { ...tiers[0] }])}>
                                + Add another tier
                            </div>
                            {
                                <div className="d-grid gap-2 mt-4">
                                    <button
                                        type="submit"
                                        // onClick={() => (genderVal?.length === 0) ?
                                        //     setGenderError(true)
                                        //     :
                                        //     setGenderError(false)
                                        // }
                                        className="btn btn-primary create-league-btn">
                                        Create Tiers
                                    </button>
                                </div>
                            }
                        </form>
                    )}
                </Formik>
            </div >
        </div >
    )
}

export default CreateTiers;