// Base Url for api requests
export const BASE_URL = "https://linx-league-golf-ap-37-staging.botics.co/"

// successStatusCodes
export const successStatusCode = [200, 201, 202]

export const APP_JSON_HEADER = async (passToken, formDataParam) => {
  const token = (localStorage.getItem("token")) || ""
  let header = {}
  if (formDataParam === "true") {
    header["Content-Type"] = "multipart/form-data"
  } else {
    header["Content-Type"] = "application/json"
    header.Accept = "application/json"
  }

  if (passToken === true) {
    if (token) {
      header.Authorization = `Token ${token}`
    }
  }
  return header
}
