import React, { useState, useEffect } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import { fetchSpecificuser, deleteSpecificUser, removeUserFlag } from '../../../api/request';
import svg from '../../../assets/images/svg/index';
import { Accordion } from 'react-bootstrap-accordion';
import 'react-bootstrap-accordion/dist/index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { convertDateToMonth } from '../../../utils/ConvertDateToMonth';
import { formatTime } from '../../../utils/formatTime';
import { formatRoundsDate } from '../../../utils/formatRoundsDate'
import ErrorMsg from '../../../CustomComponents/ErrorMsg';
import 'react-toastify/dist/ReactToastify.css';
import './specificuser.scss';

const SpecifcUsers = (props) => {
    const [userData, setuserData] = useState(null);
    const [leagueData, setLeagueData] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        fetchSingleuser();
    }, []);

    const fetchSingleuser = async () => {
        try {
            let resp = await fetchSpecificuser(props?.userId);
            setuserData(resp?.user_detail);
            setLeagueData(resp?.leagues);
        }
        catch (err) {
            if (err?.response?.data?.detail === "Invalid token.") {
                navigate('/admin/login');
            }
        }
    }

    const deleteUser = async () => {
        try {
            let resp = await deleteSpecificUser(props?.userId);
            toast.success('User deleted successfully');
            props?.closeshowSingleUser();
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'Error in User deletion');
        }
    }

    const handleRemoveFlag = async (id) => {
        try {
            let resp = await removeUserFlag(id);
            toast.success(resp?.message || 'User flag removed successfully');;
            props?.closeshowSingleUser();
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'Error in removing user flag');
        }
    }

    return (
        <div className="SpecifcUsers-Wrapper">
            <div className="SpecifcUsers-row">
                <div className="SpecifcUsers-left-col">
                    <div className="user-profile">
                        <img src={userData?.profile_image !== null ? userData?.profile_image : svg?.DefaultUser} className="user-profile" alt="imageNP" />
                    </div>

                    <div className="user-name">{userData?.full_name} {userData?.is_reported === true && <img src={svg?.Flag} className="reported-user cursor-pointer" alt="reprted user NP" />}</div>
                    <div className="user-email">{userData?.email}</div>
                    <div className="user-phone">{'(' + userData?.phone_number?.substring(1, 4) + ')' + '-' + '(' + userData?.phone_number?.substring(4, 7)+ ')' + '-' + '(' + userData?.phone_number?.substring(7)+ ')'}</div>

                    <div className="location-wrapper d-flex flex-column">
                        <div className="gender-label" style={{ marginTop: '30px' }}>Location</div>
                        <div className="gender-txt">{userData?.zip_code || 'India'}</div>
                    </div>

                    <div className="gender-attest-wrapper d-flex justify-content-between">
                        <div className="gender-wrapper d-flex flex-column">
                            <div className="gender-label">Gender</div>
                            <div className="gender-txt">{userData?.gender}</div>
                        </div>

                        <div className="gender-wrapper d-flex flex-column">
                            <div className="gender-label">GHIN</div>
                            <div className="gender-txt">{userData?.hc_index}</div>
                        </div>

                        <div className="gender-wrapper d-flex flex-column">
                            <div className="gender-label">Attest%</div>
                            <div className="gender-txt">{userData?.attest}</div>
                        </div>
                    </div>

                    <div className="gender-attest-wrapper d-flex justify-content-between">
                        <div className="gender-wrapper d-flex flex-column">
                            <div className="gender-label">Referrals</div>
                            <div className="gender-txt">{userData?.referrals}</div>
                        </div>

                        <div className="gender-wrapper d-flex flex-column">
                            <div className="gender-label">Revenue from member</div>
                            <div className="gender-txt">{userData?.revenue}</div>
                        </div>
                    </div>

                    {
                        userData?.is_reported === true ?
                            <div className="report-user-flag cursor-pointer" onClick={() => handleRemoveFlag(userData?.id)}>Remove Flag</div>
                            :
                            <div className="report-user-flag cursor-pointer"></div>
                    }
                    <Button className="delete-user w-100" onClick={() => deleteUser()}>Delete User</Button>
                </div>

                <div className="SpecifcUsers-right-col">
                    <div className="right-col-heading">Leagues</div>
                    {
                        leagueData?.length === 0 ?
                            <ErrorMsg errormsg={'No Records Exist'} />
                            :
                            leagueData?.map((item, index) => (
                                <div className="right-col-data mt-4">
                                    <div className="right-col-row1 d-flex justify-content-between">
                                        <div className="right-col-row1-col1">
                                            <div className="row1-col1-label">{item?.league?.name} {item?.tier?.name}</div>
                                            <div className="row1-row1-col1-body d-flex mt-2">
                                                <div className="row1-body">{convertDateToMonth(item?.season?.start_date)} - {convertDateToMonth(item?.season?.end_date)}</div>
                                                <div className="row1-body">Paid: ${Number(item?.season?.price)?.toFixed(2)}</div>
                                            </div>
                                        </div>

                                        <div className="right-col-row1-col2 d-flex">
                                            <div className="col2-col1 d-flex flex-column">
                                                <div className="score-txt">{item?.score}</div>
                                                <div className="score-label  mt-2">Score</div>
                                            </div>

                                            <div className="col2-col1 d-flex flex-column">
                                                <div className="score-txt">{item?.ranking}</div>
                                                <div className="score-label  mt-2">Ranking</div>
                                            </div>

                                            <div className="col2-col1 d-flex flex-column">
                                                <div className="score-txt">{item?.played_courses?.length}/6</div>
                                                <div className="score-label  mt-2">Rounds</div>
                                            </div>

                                        </div>
                                    </div>
                                    <Accordion title="Played courses">
                                        <div className="courses-body mt-2">
                                            {
                                                item?.played_courses?.map((data, indexChild) => (
                                                    <div className="course-body-item-wrapper d-flex justify-content-between">
                                                        <div className="course-item-name">
                                                            {data?.name}
                                                        </div>
                                                        <div className="course-item-info d-flex justify-content-between">
                                                            <div className="course-item-txt">
                                                                {data?.holes} Hole
                                                            </div>
                                                            <div className="course-item-txt">
                                                                {formatRoundsDate(data?.round_date)}
                                                            </div>
                                                            <div className="course-item-txt">
                                                                Start {formatTime(data?.round_time)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </Accordion>
                                </div>
                            ))
                    }

                </div>
            </div>
        </div>
    )
}

export default SpecifcUsers;