import React, { useState, useEffect } from "react";
import Loader from '../../../CustomComponents/Loader';
import { manageSubscriptions, updateSubscriptions } from '../../../api/request';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

const ManageSubscription = () => {
    const schema = Yup.object().shape({
        subscription1: Yup.string().required('Subscription1 Name is required'),
        price1: Yup.string().required('Price is required'),
        credit1: Yup.string().required('Credits are required'),
        subscription2: Yup.string().required('Subscription2 Name is required'),
        price2: Yup.string().required('Price is required'),
        credit2: Yup.string().required('Credits are required'),
        subscription3: Yup.string().required('Subscription3 Name is required'),
        price3: Yup.string().required('Price is required'),
        credit3: Yup.string().required('Credits are required'),

    });

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // Add loading state
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            let resp = await manageSubscriptions();
            if (resp) {
                setLoading(false);
                setData(resp);
            }
        }
        catch (err) {
            setLoading(false);
            if (err?.response?.data?.detail === "Invalid token.") {
                navigate('/admin/login');
            }
        }
    }

    return (
        <>{
            loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" />
                :
                <div className="manage-subscription-wrapper adminpadding">
                    <div className="admin-subscription-heading">
                        Manage subscriptions
                    </div>

                    <div className="manage-subscriptions-body p-4">
                        <div className="subscriptions-body-heading pb-4">
                            Subscriptions
                        </div>

                        <div className="subs-body pt-4">
                            <Formik
                                validationSchema={schema}
                                initialValues={{ id1: data[0]?.id, subscription1: data[0]?.name || "", price1: data[0]?.price || "", credit1: data[0]?.credits || null, discount1: data[0]?.discount || null, description1: data[0]?.description || '', id2: data[1]?.id, subscription2: data[1]?.name || "", price2: data[1]?.price || "", credit2: data[1]?.credits || null, discount2: data[1]?.discount || null, description2: data[1]?.description || '', id3: data[2]?.id, subscription3: data[2]?.name || "", price3: data[2]?.price || "", credit3: data[2]?.credits || null, discount3: data[2]?.discount || null, description3: data[2]?.description || '', }}
                                onSubmit={async (values) => {
                                    let body = {
                                        "subscriptions": [
                                            {
                                                "id": values?.id1,
                                                "name": values?.subscription1,
                                                "price": String(values?.price1),
                                                "credits": values?.credit1,
                                                "discount": values?.discount1,
                                                "description": values?.description1
                                            },
                                            {
                                                "id": values?.id2,
                                                "name": values?.subscription2,
                                                "price": String(values?.price2),
                                                "credits": values?.credit2,
                                                "discount": values?.discount2,
                                                "description": values?.description2
                                            },
                                            {
                                                "id": values?.id3,
                                                "name": values?.subscription3,
                                                "price": String(values?.price3),
                                                "credits": values?.credit3,
                                                "discount": values?.discount3,
                                                "description": values?.description3
                                            }
                                        ]
                                    }
                                    try {
                                        let resp = await updateSubscriptions(body);
                                        if (resp) {
                                            toast.success('Subscriptions updated successfully');
                                            fetchData();
                                        }
                                    }
                                    catch (error) {
                                        toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
                                    }
                                }}>
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    isValid,
                                    dirty,
                                    /* and other goodies */
                                }) => (
                                    <form
                                        className="login-form w-100"
                                        onSubmit={handleSubmit}
                                        noValidate>
                                        <div className="Auth-form-content w-100">

                                            <div className="subs1-container pb-4">
                                                <div className="subscription1-first-row w-100">
                                                    <Form.Group controlId="subscription1" className="w-100">
                                                        <label htmlFor="subscription1" className="subsTitleLabel">
                                                            Subscription 1 Title
                                                        </label>
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched.subscription1 && errors.subscription1
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type="text"
                                                                name="subscription1"
                                                                placeholder="Enter subscription 1 title"
                                                                value={values.subscription1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched.subscription1 && errors.subscription1}
                                                            />
                                                            {!values.subscription1 && (
                                                                <label htmlFor="subscription1" className="greyLabel">
                                                                    Enter subscription 1 title
                                                                </label>
                                                            )}
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.subscription1}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group controlId="price1" className="w-100">
                                                        <label htmlFor="price1" className="txtLabel">
                                                            Price
                                                        </label>
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched.price1 && errors.price1
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type="number"
                                                                name="price1"
                                                                placeholder="Enter price"
                                                                value={values.price1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched.price1 && errors.price1}
                                                            />
                                                            {!values.price1 && (
                                                                <label htmlFor="price1" className="greyLabel">
                                                                    Enter subscription 1 price
                                                                </label>
                                                            )}
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.price1}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group controlId="credit1" className="w-100">
                                                        <label htmlFor="credit1" className="txtLabel">
                                                            Credits
                                                        </label>
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched.credit1 && errors.credit1
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type="number"
                                                                name="credit1"
                                                                placeholder="Enter Credit"
                                                                value={values.credit1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched.credit1 && errors.credit1}
                                                            />
                                                            {!values.credit1 && (
                                                                <label htmlFor="credit1" className="greyLabel">
                                                                    Enter Credit
                                                                </label>
                                                            )}
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.credit1}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </div>

                                                <div className="subscription1-first-row w-100">
                                                    <Form.Group controlId="description1" className="width32">
                                                        <label htmlFor="description1" className="txtLabel">
                                                            Sub-title
                                                        </label>
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched.description1 && errors.description1
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type="text"
                                                                name="description1"
                                                                placeholder="Enter Credit"
                                                                value={values.description1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched.description1 && errors.description1}
                                                            />
                                                            {!values.description1 && (
                                                                <label htmlFor="description1" className="greyLabel">
                                                                    Enter Sub-title
                                                                </label>
                                                            )}
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.description1}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group controlId="discount1" className="width32">
                                                        <label htmlFor="discount1" className="txtLabel">
                                                            Discount
                                                        </label>
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched.discount1 && errors.discount1
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type="number"
                                                                name="discount1"
                                                                placeholder="Enter discount"
                                                                value={values.discount1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched.discount1 && errors.discount1}
                                                            />
                                                            {!values.discount1 && (
                                                                <label htmlFor="discount1" className="greyLabel">
                                                                    Enter Discount
                                                                </label>
                                                            )}
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.discount1}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <div className="subs1-container pb-4 pt-4">
                                                <div className="subscription1-first-row w-100">
                                                    <Form.Group controlId="subscription2" className="w-100">
                                                        <label htmlFor="subscription2" className="subsTitleLabel">
                                                            Subscription 2 Title
                                                        </label>
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched.subscription2 && errors.subscription2
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type="text"
                                                                name="subscription2"
                                                                placeholder="Enter subscription 2 title"
                                                                value={values.subscription2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched.subscription2 && errors.subscription2}
                                                            />
                                                            {!values.subscription2 && (
                                                                <label htmlFor="subscription2" className="greyLabel">
                                                                    Enter subscription 2 title
                                                                </label>
                                                            )}
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.subscription2}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group controlId="price2" className="w-100">
                                                        <label htmlFor="price2" className="txtLabel">
                                                            Price
                                                        </label>
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched.price2 && errors.price2
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type="number"
                                                                name="price2"
                                                                placeholder="Enter price"
                                                                value={values.price2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched.price2 && errors.price2}
                                                            />
                                                            {!values.price2 && (
                                                                <label htmlFor="price2" className="greyLabel">
                                                                    Enter price
                                                                </label>
                                                            )}
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.price2}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group controlId="credit2" className="w-100">
                                                        <label htmlFor="credit2" className="txtLabel">
                                                            Credits
                                                        </label>
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched.credit2 && errors.credit2
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type="number"
                                                                name="credit2"
                                                                placeholder="Enter Credit"
                                                                value={values.credit2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched.credit2 && errors.credit2}
                                                            />
                                                            {!values.credit2 && (
                                                                <label htmlFor="credit2" className="greyLabel">
                                                                    Enter Credit
                                                                </label>
                                                            )}
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.credit2}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </div>

                                                <div className="subscription1-first-row w-100">
                                                    <Form.Group controlId="description2" className="width32">
                                                        <label htmlFor="description2" className="txtLabel">
                                                        Sub-title
                                                        </label>
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched.description2 && errors.description2
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type="text"
                                                                name="description2"
                                                                placeholder="Enter Credit"
                                                                value={values.description2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched.description2 && errors.description2}
                                                            />
                                                            {!values.description2 && (
                                                                <label htmlFor="description2" className="greyLabel">
                                                                    Enter Sub-title
                                                                </label>
                                                            )}
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.description2}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group controlId="discount2" className="width32">
                                                        <label htmlFor="discount2" className="txtLabel">
                                                            Discount
                                                        </label>
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched.discount2 && errors.discount2
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type="number"
                                                                name="discount2"
                                                                placeholder="Enter Discount"
                                                                value={values.discount2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched.discount2 && errors.discount2}
                                                            />
                                                            {!values.discount2 && (
                                                                <label htmlFor="discount2" className="greyLabel">
                                                                    Enter Discount
                                                                </label>
                                                            )}
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.discount2}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <div className="subs1-container pb-4 pt-4 border-bottom-0">
                                                <div className="subscription1-first-row w-100">
                                                    <Form.Group controlId="subscription3" className="w-100">
                                                        <label htmlFor="subscription3" className="subsTitleLabel">
                                                            Subscription 3 Title
                                                        </label>
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched.subscription3 && errors.subscription3
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type="text"
                                                                name="subscription3"
                                                                placeholder="Enter subscription 3 title"
                                                                value={values.subscription3}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched.subscription3 && errors.subscription3}
                                                            />
                                                            {!values.subscription3 && (
                                                                <label htmlFor="subscription3" className="greyLabel">
                                                                    Enter subscription 3 title
                                                                </label>
                                                            )}
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.subscription3}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group controlId="price3" className="w-100">
                                                        <label htmlFor="price3" className="txtLabel">
                                                            Price
                                                        </label>
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched.price3 && errors.price3
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type="number"
                                                                name="price3"
                                                                placeholder="Enter price"
                                                                value={values.price3}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched.price3 && errors.price3}
                                                            />
                                                            {!values.price3 && (
                                                                <label htmlFor="price3" className="greyLabel">
                                                                    Enter price
                                                                </label>
                                                            )}
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.price3}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group controlId="credit3" className="w-100">
                                                        <label htmlFor="credit3" className="txtLabel">
                                                            Credits
                                                        </label>
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched.credit3 && errors.credit3
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type="number"
                                                                name="credit3"
                                                                placeholder="Enter Credit"
                                                                value={values.credit3}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched.credit3 && errors.credit3}
                                                            />
                                                            {!values.credit3 && (
                                                                <label htmlFor="credit3" className="greyLabel">
                                                                    Enter Credit
                                                                </label>
                                                            )}
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.credit3}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </div>

                                                <div className="subscription1-first-row w-100">
                                                    <Form.Group controlId="description3" className="width32">
                                                        <label htmlFor="description3" className="txtLabel">
                                                        Sub-title
                                                        </label>
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched.description3 && errors.description3
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type="text"
                                                                name="description3"
                                                                placeholder="Enter Credit"
                                                                value={values.description3}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched.description3 && errors.description3}
                                                            />
                                                            {!values.description3 && (
                                                                <label htmlFor="description3" className="greyLabel">
                                                                    Enter Sub-title
                                                                </label>
                                                            )}
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.description3}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group controlId="discount3" className="width32">
                                                        <label htmlFor="discount3" className="txtLabel">
                                                            Discount
                                                        </label>
                                                        <div
                                                            className={`form-floating mt-2 mb-3 ${touched.discount3 && errors.discount3
                                                                ? 'has-validation-error'
                                                                : ''
                                                                }`}>
                                                            <Form.Control
                                                                type="number"
                                                                name="discount3"
                                                                placeholder="Enter Discount"
                                                                value={values.discount3}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="w-100"
                                                                isInvalid={touched.discount3 && errors.discount3}
                                                            />
                                                            {!values.discount3 && (
                                                                <label htmlFor="discount3" className="greyLabel">
                                                                    Enter Discount
                                                                </label>
                                                            )}
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.discount3}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <div className="d-grid gap-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary save_btn w-25">
                                                    Save Changes
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
        }
        </>
    )
}

export default ManageSubscription;