import React, { useEffect, useState } from "react";
import Navbar from "../../CustomComponents/Navbar";
import svg from '../../assets/images/svg/index';
import { Carousel, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { useWindowSize } from "../../utils/useWindowSize.js";
import { formatNumberAsOrdinal } from '../../utils/formatNumberAsOrdinal';
import { formatPrize } from '../../utils/formatPrize';
import { convertDateToMonth } from '../../utils/ConvertDateToMonth';
import Loader from '../../CustomComponents/Loader';
import { userStandings } from '../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

const mobileBreakpoint = 821;
const Standings = () => {
    const navigate = useNavigate();
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)
    const [carouselData, setCarouselData] = useState([]);
    const [userData, setUserData] = useState({});
    const [standingsData, setStandingsData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    useEffect(() => {
        fetchStandingsData();
    }, []);

    const fetchStandingsData = async () => {
        setLoading(true)
        try {
            let resp = await userStandings();
            setCarouselData(resp);
            setStandingsData(resp[0]?.leaderboard)
            let userdata = resp[0]?.leaderboard;
            let obj = {};
            for (let i = 0; i < userdata?.length; i++) {
                if (userdata[i]?.user?.id === Number(localStorage?.getItem('userId'))) {
                    obj.score = userdata[i]?.score;
                    obj.ranking = i + 1;
                    obj.rounds = userdata[i]?.round_count;
                    break;
                }
            }
            obj['prize'] = resp[0]?.tier?.prize;
            setUserData(obj);
            setLoading(false)
        }
        catch (err) {
            setLoading(false)
            toast.error(err?.response?.data?.message || 'An error occured while fetching standings');
        }
    }

    const handleRankImg = (rankNum) => {
        if (Number(rankNum) === 1) {
            return svg?.OneNoStanding
        }
        if (Number(rankNum) === 2) {
            return svg?.TwoNoStanding
        }
        if (Number(rankNum) === 3) {
            return svg?.ThreeNoStanding
        }
        if (Number(rankNum) === 4) {
            return svg?.FourNoStanding
        }
        else {
            return svg?.FourNoStanding
        }
    }

    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToCSV = (apiData, fileName) => {
        let exportData = [];
        for (let i = 0; i < apiData?.length; i++) {
            exportData?.push({
                userName: apiData[i]?.user?.name,
                userId: apiData[i]?.user?.id,
                userProfileImage: apiData[i]?.user?.profile_image,
                userHandicapIndex: apiData[i]?.user?.hc_index,
                userRoundCount: apiData[i]?.round_count,
                userScore: apiData[i]?.score,
            })
        }
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const handleCarouselSelect = (selectedIndex, e) => {
        // Assuming you have an array of carousel data
        const selectedData = carouselData[selectedIndex];
        handleCarouselClick(selectedData);
    };

    function handleCarouselClick(data) {
        setStandingsData(data?.leaderboard);
        let userdata = data?.leaderboard;
        let obj = {};
        for (let i = 0; i < userdata?.length; i++) {
            if (userdata[i]?.user?.id === Number(localStorage?.getItem('userId'))) {
                obj.score = userdata[i]?.score;
                obj.ranking = i + 1;
                obj.rounds = userdata[i]?.round_count;
                break;
            }
        }
        obj['prize'] = data?.tier?.prize
        setUserData(obj);
    }


    return (
        <><Navbar />
            {
                loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" />
                    :
                    <div className="standings-wrapper">
                        {getwidth >= mobileBreakpoint && <img src={svg?.UserDashboardDft} className="default--bg" alt="img NP" />}

                        {/* <div className="body-wrapper"> */}
                        <div className="tier-car-wrapper">
                            <div className="tier-carousel">
                                <Carousel onSelect={handleCarouselSelect} controls={true} interval={null}>
                                    {carouselData?.map((slide, index) => (
                                        <Carousel.Item key={index} className="tier-carousel-item">
                                            <h3 className="tier-carousel-title">{slide.tier?.name}</h3>
                                            <p className="tier-carousel-month">{convertDateToMonth(slide?.tier?.season?.start_date
                                            )}- {convertDateToMonth(slide?.tier?.season?.end_date)}</p>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </div>
                        </div>

                        <div className="rounds-history-wrapper d-flex justify-content-between">
                            {/* <div className="sec-wrapper"> */}
                            <div className="round-score-wrapper">
                                <div className="round-details d-flex">
                                    <div className="score-data d-flex flex-column align-items-center">
                                        <div className="score-val">{userData?.rounds !== 0 ? Number(userData?.score)?.toFixed(1) : 'N/A'}</div>
                                        <div className="score-txt">Score</div>
                                    </div>

                                    <div className="score-data d-flex flex-column align-items-center">
                                        <div className="score-val">{userData?.ranking}<span className="score-ordinal">{formatNumberAsOrdinal(userData?.ranking)}</span></div>
                                        <div className="score-txt">Ranking</div>
                                    </div>

                                    <div className="score-data d-flex flex-column align-items-center">
                                        <div className="score-val">{userData?.rounds}/6</div>
                                        <div className="score-txt">Rounds</div>
                                    </div>

                                    <div className="score-data d-flex flex-column align-items-center">
                                        <div className="score-val">${formatPrize(Math.floor(Number(userData?.prize)))}
                                        </div>
                                        <div className="score-txt">Prize value</div>
                                    </div>
                                </div>
                            </div>
                            <div className="actions-details-wrapper">
                                <div className="actions-details d-flex flex-column">
                                    {getwidth >= mobileBreakpoint && <div className="actions-txt">Actions</div>}
                                    <div className="actions-btns d-flex justify-content-between">
                                        <Button className="btn-1" onClick={(e) => exportToCSV(standingsData, 'Standings')}><img src={svg?.DownloadArrow} className="download-icon" alt="img NP" />Download</Button>
                                        <Button className="btn-2" onClick={() => navigate('/standings/history')}>Standings History</Button>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>

                        <div className="standings-body">

                            <div className="standings-data">
                                <div className="standings-data-header">
                                    <div className="leaderboard-txt">Leaderboard</div>
                                    <div className="rounds-score-txt">
                                        <div className="leaderboard-rounds">Rounds</div>
                                        <div className="leaderboard-rounds">Score</div>
                                    </div>
                                </div>

                                <div className="standings-data-body mt-4">
                                    {
                                        standingsData?.map((data, index) => (
                                            <div className={data?.user?.id === Number(localStorage?.getItem('userId')) ? "activeUser body-data" : "body-data"}
                                                onClick={() => window.location.href = `/otheruserprofile?user_id=${data?.user?.id}`}>
                                                <div className="body-data1">
                                                    <img src={handleRankImg(index + 1)} className="rank-img" alt="rank img NP" />
                                                    <div className="body-user-wrapper">
                                                        <div className="user-img-div">
                                                            <img src={data?.user?.profile_image || svg?.DefUser} className="user-img" alt="img NP" />
                                                        </div>
                                                        <div className="user-details">
                                                            <div className="u-name">{data?.user?.name}</div>
                                                            <div className="handicap-index">Handicap Index®: {data?.user?.hc_index}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="body-data2">
                                                    <div className="round-val">{data?.round_count}/6</div>
                                                    <div className="score-val" style={{ color: data?.score < 0 ? '#88BD36' : '#242725' }}>{data?.round_count === 0 ? `N/A` : data?.score}</div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>

            }
        </>
    )
}

export default Standings;
