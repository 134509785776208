import React from "react";
import { useLocation } from 'react-router-dom';
import { createSeason } from '../../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import svg from '../../../assets/images/svg/index';
import './styles.scss';

const CreateSeason = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let data = location?.state?.leagueData;

    const schema = Yup.object().shape({
        name: Yup.string().required('Season name is required'),
        startDate: Yup.string().required('Season start date is required'),
        endDate: Yup.string().required('Season end date is required'),
        credits: Yup.string().required('Season credit is required'),
    });
    return (
        <div className="create-season-wrapper adminpadding">
            <div className="season-back-arrow-cont">
                <img src={svg?.SeasonBack} alt="back arrow NP" className="back-arrow-img cursor-pointer" onClick={() => navigate(-1)} />
                <div className="create-season-heading">
                    Create Season: {data?.name}
                </div>
            </div>
            <div className="create-season-body mt-4">
                <Formik
                    validationSchema={schema}
                    initialValues={{ name: "", credits: '', description: '', startDate: '', endDate: '' }}
                    onSubmit={async (values) => {
                        let body = {
                            "seasons": [{
                                name: values?.name,
                                description: values?.description?.replace(/\n/g, " ") || '',
                                price: values?.credits,
                                start_date: values?.startDate,
                                end_date: values?.endDate,
                            }]
                        }
                        try {
                            let resp = await createSeason(body, data?.id);
                            if (resp) {
                                toast.success('Season created successfully');
                                navigate('/admin/dashboard/create-league/create-season/create-tiers', {
                                    state: { leagueData: data, seasonData: resp }
                                })
                            }
                        }
                        catch (error) {
                            toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
                        }
                    }}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        isValid,
                        dirty,
                        /* and other goodies */
                    }) => (
                        <form
                            className="create-league-form w-75"
                            onSubmit={handleSubmit}
                            noValidate>
                            <div className="Auth-form-content w-100">
                                <Form.Group controlId="name">
                                    <label htmlFor="name" className="greyLabel">
                                        Season Name
                                    </label>
                                    <div
                                        className={`form-floating mt-2 mb-3 ${touched.name && errors.name
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            // placeholder="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-100"
                                            isInvalid={touched.name && errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>

                                <Form.Group controlId="description">
                                    <label htmlFor="description" className="greyLabel">
                                        Description
                                    </label>
                                    <div
                                        className={`form-floating mt-2 mb-3 ${touched.description && errors.description
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            // placeholder="name"
                                            value={values.description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-100"
                                            isInvalid={touched.description && errors.description}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.description}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>

                                <div className="season-dates-wrapper d-flex justify-content-center align-items-center w-100">
                                    <Form.Group controlId="startDate" className="w-50">
                                        <label htmlFor="startDate" className="greyLabel">
                                            Season start
                                        </label>
                                        <div
                                            className={`form-floating mt-2 mb-3 ${touched.startDate && errors.startDate
                                                ? 'has-validation-error'
                                                : ''
                                                }`}>
                                            <Form.Control
                                                type="date"
                                                name="startDate"
                                                min={new Date()?.toISOString()?.split('T')[0]}
                                                // placeholder="name"
                                                value={values.startDate}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="w-100"
                                                isInvalid={touched.startDate && errors.startDate}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.startDate}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="endDate" className="w-50">
                                        <label htmlFor="endDate" className="greyLabel">
                                            Season end
                                        </label>
                                        <div
                                            className={`form-floating mt-2 mb-3 ${touched.endDate && errors.endDate
                                                ? 'has-validation-error'
                                                : ''
                                                }`}>
                                            <Form.Control
                                                type="date"
                                                name="endDate"
                                                // placeholder="name"
                                                min={values?.startDate || new Date()?.toISOString()?.split('T')[0]}
                                                value={values.endDate}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="w-100"
                                                isInvalid={touched.endDate && errors.endDate}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.endDate}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                </div>

                                <div className="season-dates-wrapper d-flex justify-content-center align-items-center w-100">
                                    <Form.Group controlId="credits" className="w-50">
                                        <label htmlFor="credits" className="greyLabel">
                                            Required credits
                                        </label>
                                        <div
                                            className={`form-floating mt-2 mb-3 ${touched.credits && errors.credits
                                                ? 'has-validation-error'
                                                : ''
                                                }`}>
                                            <Form.Control
                                                type="number"
                                                min="0"
                                                name="credits"
                                                // placeholder="name"
                                                value={values.credits}
                                                onChange={handleChange}
                                                onInput={(e) => {
                                                    e.target.value = Math.abs(e.target.value);
                                                    handleChange(e); // Trigger the handleChange function to update the form state.
                                                  }}
                                                onBlur={handleBlur}
                                                className="w-100"
                                                isInvalid={touched.credits && errors.credits}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.credits}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                    <div className="dummy-div w-50"></div>
                                </div>

                                <div className="d-grid gap-2 mt-3">
                                    <button
                                        type="submit"
                                        // onClick={() => (genderVal?.length === 0) ?
                                        //     setGenderError(true)
                                        //     :
                                        //     setGenderError(false)
                                        // }
                                        className="btn btn-primary create-league-btn">
                                        Create Season
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default CreateSeason;