import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { deleteAccount } from '../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import ScheduleRound from './ScheduleRound';
import PreviousRound from './PreviousRound';
import './styles.scss';

const GameScheduling = (props) => {
    const [showLinx, setLinx] = useState(props?.showlinx);
    const [getPreviousRound, setPreviousRound] = useState(false);
    const [showscheduleRound, setShowscheduleRound] = useState(props?.getshowscheduleRound || false);

    const handleClose = () => {
        props.handleLinxPopup();
        setShowscheduleRound(false);
    }

    useEffect(() => {
        setLinx(props.showlinx);
    }, [props.showlinx]);

    const scheduleRound = async () => {
        setShowscheduleRound(true);
        setLinx(false);
    }

    const handleLogPrevious = () => {
        setShowscheduleRound(false);
        setLinx(false);
        setPreviousRound(true);
    }

    const handleClosePreviousRound = () => {
        setShowscheduleRound(false);
        setLinx(false);
        setPreviousRound(false);        
        props.handleLinxPopup();
    }

    const handleCloseScheduleRound = () => {
        setShowscheduleRound(false);
        setPreviousRound(false);
        props.handleLinxPopup();
    }

    return (
        <>
            {
                (showLinx === true && showscheduleRound === false) &&
                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    className='schedule-game-wrapper'
                    show={props.show}
                    onHide={handleClose}
                    centered>
                    <Modal.Header>
                        <Modal.Title className="modalTitle">Hit the Linx</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modalBody">
                        <div className="del-sub">
                            Register a round you have already booked and invite your friends to it! Alternatively, input your scores from a round you recently completed to track your scores and course history.
                        </div>
                        <div className='del-buttons-wrapper w-100'>
                            <Button
                                onClick={() => scheduleRound()}
                                className="delBtn w-100">
                                Schedule a Round
                            </Button>
                            <Button className="cancelBtn w-100" onClick={() => handleLogPrevious()}>
                                Log previous Round
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            }
            {
                showscheduleRound === true &&
                <ScheduleRound show={ScheduleRound} handleCloseScheduleRound={handleCloseScheduleRound} userProfilePic={props?.userProfilePic} />
            }
            {
                getPreviousRound === true &&
                <PreviousRound show={getPreviousRound} handleClosePreviousRound={handleClosePreviousRound} userProfilePic={props?.userProfilePic} />
            }
        </>
    );
};

export default GameScheduling;
