import React, { useState } from "react";
import Logo from '../../../assets/images/svg/Logo.svg';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Row, Col, Image } from 'react-bootstrap';
import { LoginSocialGoogle } from "reactjs-social-login"
import { GoogleLoginButton } from "react-social-login-buttons"
import AppleLogin from "react-apple-login"
import FacebookLogin from "react-facebook-login"
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import svg from '../../../assets/images/svg/index'
import { forgotPass } from '../../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

const ForgotPassword = () => {
    const navigate = useNavigate();

    const schema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required')
    });

    return (
        <div className="forgot-pass-wrapper d-flex justify-content-center">
            <div className="user-signin-form-wrapper w-100 mt-4">
                <img src={Logo} className="app-logo" alt="app logo NP" />
                <Formik
                    validationSchema={schema}
                    initialValues={{ email: '' }}
                    onSubmit={async (values) => {
                        let body = {
                            "email": values.email
                        }
                        try {
                            let resp = await forgotPass(body);
                            if (resp?.message) {
                                toast.success(resp?.message);
                                setTimeout(() => {
                                    navigate('/login')
                                }, 2000);
                            }
                        }
                        catch (error) {
                            toast.error(error?.response?.data?.message || 'An error occurred while processing your request.');
                        }

                    }}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                        /* and other goodies */
                    }) => (
                        <form
                            className="login-form"
                            onSubmit={handleSubmit}
                            noValidate>
                            <div className="forgot-pass-content w-100">
                                <div className="signin_heading">Forgot Password?</div>
                                <div className="verify-email-txt">
                                    Please enter your email address and we will send you a link to reset your password.
                                </div>
                                <Form.Group controlId="email">
                                    <div
                                        className={`form-floating mb-3 ${touched.email && errors.email
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-100"
                                            isInvalid={touched.email && errors.email}
                                        />
                                        {!values.email && (
                                            <label htmlFor="email" className="greyLabel">
                                                Email
                                            </label>
                                        )}
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>

                                <button
                                    type="submit"
                                    className="btn btn-primary signin_btn"
                                    style={{ width: '100%' }}>
                                    Reset Password
                                </button>

                                <div className="cancel-txt cursor-pointer" onClick={() => {
                                    navigate(-1);
                                }}>Cancel</div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default ForgotPassword;