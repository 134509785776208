import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import { createTiers } from '../../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { editTier } from '../../../api/request';
import { useNavigate } from 'react-router-dom';
import svg from '../../../assets/images/svg/index';
import './styles.scss';

const EditTier = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let data = location?.state?.tierData;
    let leagueData = location?.state?.leagueData;

    return (
        <div className="create-tiers-wrapper adminpadding">
            <div className="season-back-arrow-cont">
                <img src={svg?.SeasonBack} alt="back arrow NP" className="back-arrow-img cursor-pointer" onClick={() => navigate(-1)} />
                <div className="create-season-heading">
                    Edit Tier: {data?.name}
                </div>
            </div>
            <div className="create-season-body mt-4">
                <Formik
                    // validationSchema={schema}
                    initialValues={{
                        name: data?.name || "",
                        description: data?.description || "",
                        startDate: data?.handicap_from || 0,
                        endDate: data?.handicap_to || 0,
                        noOfSlots: data?.max_slots || 0,
                        prizeVal: data?.prize || "",
                        prizeDesc: data?.prize_description || ""
                    }}
                    onSubmit={async (values) => {
                        const body = {
                            "name": values?.name,
                            "description": values?.description,
                            "handicap_from": Number(values?.startDate),
                            "handicap_to": Number(values?.endDate),
                            "max_slots": Number(values?.noOfSlots),
                            "prize": String(values?.prizeVal),
                            "prize_description": values?.prizeDesc
                        }
                        try {
                            let resp = await editTier(body, data?.id);
                            if (resp) {
                                toast.success('Tier updated successfully');
                                navigate(-1)
                            }
                        }
                        catch (error) {
                            toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
                        }
                    }}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        isValid,
                        dirty,
                        /* and other goodies */
                    }) => (
                        <form
                            className="create-league-form w-75"
                            onSubmit={handleSubmit}
                            noValidate>
                            <div className={`Auth-form-content w-100`}>
                                <Form.Group controlId='name'>
                                    <label htmlFor='name' className="greyLabel">
                                        Tier Name
                                    </label>
                                    <div
                                        className={`form-floating mt-2 mb-3 ${touched?.name && errors?.name
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            type="text"
                                            name='name'
                                            value={values?.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-100"
                                            isInvalid={touched?.name && errors?.name}
                                        />
                                        {(values?.name === '' || values?.name === undefined) && (
                                            <Form.Control.Feedback type="invalid" className="d-block">
                                                Tier name is required
                                            </Form.Control.Feedback>
                                        )}
                                    </div>
                                </Form.Group>

                                <Form.Group controlId='description'>
                                    <label htmlFor='description' className="greyLabel">
                                        Description
                                    </label>
                                    <div
                                        className={`form-floating mt-2 mb-3 ${touched?.description && errors?.description
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            as="textarea"
                                            name='description'
                                            // placeholder="name"
                                            value={values?.description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-100"
                                            isInvalid={touched?.description && errors?.description}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.description}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>

                                <div className="season-dates-wrapper d-flex justify-content-center align-items-center w-100">

                                    <>{leagueData?.type !== 2 &&
                                        <div className="handicap-range w-50">
                                            <label htmlFor='startDate' className="greyLabel">
                                                Handicap range
                                            </label>
                                            <div className="handicap-range-field d-flex" style={{ gap: '10px' }}>
                                                <Form.Group controlId='startDate' className="w-50">
                                                    <div
                                                        className={`form-floating mt-2 mb-3 ${touched?.startDate && errors?.startDate
                                                            ? 'has-validation-error'
                                                            : ''
                                                            }`}>
                                                        <label style={{ display: values?.startDate !== '' && values?.startDate >= 0 ? 'none' : 'block' }} className="floating-handicap-label">
                                                            From:
                                                        </label>

                                                        <Form.Control
                                                            type="number"
                                                            onFocus={() => {
                                                                if (!values?.startDate) {
                                                                    setFieldValue('startDate', '');
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                e.target.value = Math.abs(e.target.value);
                                                                handleChange(e); // Trigger the handleChange function to update the form state.
                                                            }}
                                                            name='startDate'
                                                            value={values?.startDate}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="w-100"
                                                            isInvalid={touched?.startDate && errors?.startDate}
                                                        />
                                                        {((values?.startDate === '' || values?.startDate === undefined) && data?.type === 1) && (
                                                            <Form.Control.Feedback type="invalid" className="d-block">
                                                                Handicap From is required
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </div>
                                                </Form.Group>
                                                <Form.Group controlId='endDate' className="w-50">
                                                    <div
                                                        className={`form-floating mt-2 mb-3 ${touched?.endDate && errors?.endDate
                                                            ? 'has-validation-error'
                                                            : ''
                                                            }`}>
                                                        <label style={{ display: values?.endDate !== '' && values?.endDate >= 0 ? 'none' : 'block' }} className="floating-handicap-label">
                                                            To:
                                                        </label>

                                                        <Form.Control
                                                            type="number"
                                                            onFocus={() => {
                                                                if (!values?.endDate) {
                                                                    setFieldValue('endDate', '');
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                e.target.value = Math.abs(e.target.value);
                                                                handleChange(e); // Trigger the handleChange function to update the form state.
                                                            }}
                                                            name='endDate'
                                                            value={values?.endDate}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="w-100"
                                                            isInvalid={touched?.endDate && errors?.endDate}
                                                        />
                                                        {((values?.endDate === '' || values?.endDate === undefined) && data?.type === 1) && (
                                                            <Form.Control.Feedback type="invalid" className="d-block">
                                                                Handicap To is required
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    }
                                    </>

                                    <Form.Group controlId='noOfSlots' className={leagueData?.type === 2 ? 'w-100' : "w-50"}>
                                        <label htmlFor='noOfSlots' className="greyLabel">
                                            Number of slots
                                        </label>
                                        <div
                                            className={`form-floating mt-2 mb-3 ${touched?.noOfSlots && errors?.noOfSlots
                                                ? 'has-validation-error'
                                                : ''
                                                }`}>
                                            <Form.Control
                                                type="number"
                                                name='noOfSlots'
                                                // placeholder="name"
                                                value={values?.noOfSlots}
                                                onInput={(e) => {
                                                    e.target.value = Math.abs(e.target.value);
                                                    handleChange(e); // Trigger the handleChange function to update the form state.
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="w-100"
                                                isInvalid={touched?.noOfSlots && errors?.noOfSlots}
                                            />
                                            {(values?.noOfSlots === '' || values?.noOfSlots === undefined) && (
                                                <Form.Control.Feedback type="invalid" className="d-block">
                                                    Number of slots are required
                                                </Form.Control.Feedback>
                                            )}
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.noOfSlots}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                </div>

                                <Form.Group controlId='prizeVal'>
                                    <label htmlFor='prizeVal' className="greyLabel">
                                        Prize value
                                    </label>
                                    <div
                                        className={`form-floating mt-2 mb-3 ${touched?.prizeVal && errors?.prizeVal
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            type="number"
                                            name='prizeVal'
                                            // placeholder="name"
                                            value={values?.prizeVal}
                                            onInput={(e) => {
                                                e.target.value = Math.abs(e.target.value);
                                                handleChange(e); // Trigger the handleChange function to update the form state.
                                            }}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-100"
                                            isInvalid={touched?.prizeVal && errors?.prizeVal}
                                        />
                                        {(values?.prizeVal === '' || values?.prizeVal === undefined) && (
                                            <Form.Control.Feedback type="invalid" className="d-block">
                                                Prize value is required
                                            </Form.Control.Feedback>
                                        )}
                                    </div>
                                </Form.Group>

                                <Form.Group controlId='prizeDesc'>
                                    <label htmlFor='prizeDesc' className="greyLabel">
                                        Prize description
                                    </label>
                                    <div
                                        className={`form-floating mt-2 mb-3 ${touched?.prizeDesc && errors?.prizeDesc
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            type="text"
                                            name='prizeDesc'
                                            // placeholder="name"
                                            value={values?.prizeDesc}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-100"
                                            isInvalid={touched?.prizeDesc && errors?.prizeDesc}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.prizeDesc}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="d-grid gap-2 mt-4">
                                <button
                                    type="submit"
                                    className="btn btn-primary create-league-btn">
                                    Update Tier
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div >
        </div >
    )
}

export default EditTier;