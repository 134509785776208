import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';
import Select from 'react-select';
import svg from '../../../assets/images/svg/index';
import * as FileSaver from 'file-saver';
import { fetchLeagueRanking, fetchSelLeagueSeason, fetchSelSeasonTiers } from '../../../api/request';
import * as XLSX from 'xlsx';
import MoveToLeaguesPopup from './MoveToLeaguesPopup.jsx';
import Loader from '../../../CustomComponents/Loader';
import {
    useTable,
    usePagination,
    useSortBy,
    useRowSelect,
    useGlobalFilter,
} from 'react-table';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LeagueRanking = (props) => {
    const navigate = useNavigate();
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [getNextPage, setNextPage] = useState(false);
    const [getPrevPage, setPrevPage] = useState(false);
    const [loading, setLoading] = useState(true); // Add loading state
    const [noFound, setNoFound] = useState(false);
    const [products, setProducts] = useState([]);
    const [getData, setData] = useState({});
    const productsRef = useRef(products);
    const [userId, setUserId] = useState(null);
    const [selectAllChecked, setSelectAllChecked] = useState(true);
    const [seasonOptions, setseasonOptions] = useState([])
    const [tierVal, settierVal] = useState('');
    const [tierOptions, settierOptions] = useState([])
    const [seasonVal, setseasonVal] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [rowCheckboxStates, setRowCheckboxStates] = useState({});
    const [showMovePopup, setMovePopup] = useState(false);

    const handlePrevPage = () => {
        if (getData?.next !== null) {
            setNextPage(false);
        }
        if (getData?.previous !== null) {
            const urlParams = new URLSearchParams(new URL(getData?.previous).search);
            const pageValue = urlParams.get("page");
            if (pageValue === null) {
                setCurrentPageIndex(1);
                fetchUsers('1');
            }
            else {
                setCurrentPageIndex(currentPageIndex - 1);
                fetchUsers(pageValue);
            }
        }
        else {
            setPrevPage(true);
        }
    }

    const handleNextPage = () => {
        if (getData?.previous !== null) {
            setPrevPage(false);
        }
        if (getData?.next !== null) {
            const urlParams = new URLSearchParams(new URL(getData?.next).search);
            const pageValue = urlParams.get("page");
            setCurrentPageIndex(currentPageIndex + 1);
            fetchUsers(pageValue);
        }
        else {
            setNextPage(true);
        }
    };

    const handleSeasonDropdown = async (event) => {
        setseasonVal(event);
        settierVal({});
        try {
            let seasonResp = await fetchSelSeasonTiers(event?.value);
            let seasonTemp = seasonResp;
            let seasonArr = [];
            for (let i = 0; i < seasonTemp?.length; i++) {
                seasonArr?.push({
                    label: seasonTemp[i]?.name,
                    value: seasonTemp[i]?.id
                })
            }
            settierOptions(seasonArr);
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occurred while fetching tiers');
        }
        fetchFilteredData(event?.value, tierVal?.value);
    }

    const handleTierDropdown = (event) => {
        settierVal(event);
        fetchFilteredData(seasonVal?.value, event?.value);
    }

    const handleMovePopupClose = () => {
        setMovePopup(false);
        // setSelectAllChecked(false);
    }

    const handleMovePopupSuccessClose = () => {
        setMovePopup(false);
        setSelectedRows([]);
        setRowCheckboxStates({});
        // setseasonVal({});
        // settierVal({});
        // setCurrentPageIndex(1);
        // window?.location?.href();
    }

    useEffect(() => {
        // fetchLeagueData();
        fetchAllSeasons();
    }, []);

    const fetchAllSeasons = async () => {
        try {
            let seasonResp = await fetchSelLeagueSeason({ id: props?.leagueId });
            let seasonTemp = seasonResp;
            let seasonArr = [];
            for (let i = 0; i < seasonTemp?.length; i++) {
                seasonArr?.push({
                    label: seasonTemp[i]?.name,
                    value: seasonTemp[i]?.id
                })
            }
            setseasonOptions(seasonArr);
            setseasonVal({
                label: seasonResp[0]?.name,
                value: seasonResp[0]?.id
            })

            if (seasonResp?.length > 0) {
                try {
                    let tierResp = await fetchSelSeasonTiers(seasonResp[0]?.id);
                    let seasonTemp = tierResp;
                    let tierArr = [];
                    for (let i = 0; i < seasonTemp?.length; i++) {
                        tierArr?.push({
                            label: seasonTemp[i]?.name,
                            value: seasonTemp[i]?.id
                        })
                    }
                    settierOptions(tierArr);
                    settierVal({
                        label: tierResp[0]?.name,
                        value: tierResp[0]?.id
                    })

                    fetchLeagueData(currentPageIndex, seasonResp[0]?.id, tierResp[0]?.id);
                }
                catch (err) {
                    toast.error(err?.response?.data?.message || 'An error occurred while fetching tiers');
                }
            }
            else {
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occurred while fetching seasons');
        }
    }

    const fetchLeagueData = async (pageValue, season, tier) => {
        setLoading(true);
        try {
            let resp = await fetchLeagueRanking(pageValue || currentPageIndex, season, tier);
            if (resp) {
                setProducts(resp?.results);
                setData(resp);
                if (resp?.next !== null) {
                    setNextPage(false);
                }
                setTotalCount(resp?.count);
                setLoading(false);
            }
        }
        catch (err) {
            setLoading(false);
            if (err?.response?.data?.detail === "Invalid token.") {
                navigate('/admin/login');
            }
        }
    }

    const fetchUsers = async (pageValue) => {
        setLoading(true);
        try {
            let resp = await fetchLeagueRanking(pageValue || currentPageIndex, seasonVal?.value, tierVal?.value);
            if (resp) {
                setProducts(resp?.results);
                setData(resp);
                if (resp?.next !== null) {
                    setNextPage(false);
                }
                setTotalCount(resp?.count);
                setLoading(false);
            }
        }
        catch (err) {
            setLoading(false);
            if (err?.response?.data?.detail === "Invalid token.") {
                navigate('/admin/login');
            }
        }
    }

    const fetchFilteredData = async (value, handicap) => {
        let resp = await fetchLeagueRanking('n/a', value, handicap);
        if (resp) {
            setProducts(resp?.results);
            setTotalCount(resp?.count);
            setCurrentPageIndex(1)
        }
    }

    useEffect(() => {
        // Update the ref value whenever the products state changes
        productsRef.current = products;
    }, [products]);

    const data = useMemo(() => products, [products]);

    const handleSelectAllRows = (event) => {

        const isChecked = event?.target?.checked;
        const updatedRowCheckboxStates = productsRef.current.reduce(
            (checkboxStates, row) => {
                checkboxStates[row.user_id] = isChecked;
                return checkboxStates;
            },
            {}
        );

        setSelectedRows(isChecked ? productsRef.current : []);
        setRowCheckboxStates(updatedRowCheckboxStates);
        setSelectAllChecked(isChecked)
    };

    const handleSelectRow = (event, row) => {
        const isChecked = event?.target?.checked;
        setRowCheckboxStates((prevCheckboxStates) => ({
            ...prevCheckboxStates,
            [row?.original?.user_id]: isChecked,
        }));
        if (isChecked) {
            setSelectedRows((prevSelected) => [...prevSelected, row.original]);;
        } else {
            setSelectedRows((prevSelected) =>
                prevSelected.filter((selectedRow) => selectedRow.user_id !== row.original.user_id)
            );
        }
    };

    useEffect(() => {
    }, [selectedRows]);

    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    useEffect(() => {
        // Reset selectedRows when data changes
        setSelectedRows([]);
    }, [data]);

    const columns = useMemo(
        () => [
            {
                Header: (
                    <>
                        <input
                            type="checkbox"
                            onChange={(event) => {
                                handleSelectAllRows(event);
                            }}
                            className="custom-checkbox"
                        />
                        <span className='header-txt'>Ranking</span>
                    </>
                ),
                Cell: ({ row }) => (
                    <div className='uname-cell cursor-pointer'
                    >
                        <input
                            type="checkbox"
                            checked={rowCheckboxStates[row.original.user_id] || false}
                            onChange={(event) => {
                                handleSelectRow(event, row);
                            }
                            }
                            className="custom-checkbox"
                        />
                        <span className='header-cell' onClick={() => {
                            setUserId(row?.original?.user_id);
                        }
                        }>{row?.original?.user_id}</span>
                    </div>
                ),
                accessor: 'ranking',
                sticky: 'left',
                disableSortBy: selectAllChecked,
            },
            {
                Header: 'User Name',
                accessor: 'user_name',
                sticky: 'left',
                disableSortBy: false,
            },
            {
                Header: 'Handicap',
                accessor: 'handicap',
                sticky: 'left',
                disableSortBy: false,
            },
            {
                Header: 'Tier',
                accessor: 'tier_name',
                sticky: 'left',
                disableSortBy: false,
            },
            {
                Header: 'Attest%',
                accessor: 'attest_rate',
                sticky: 'left',
                disableSortBy: false,
            },
            {
                Header: 'Rounds Played',
                accessor: 'round_played',
                sticky: 'left',
                disableSortBy: false,
            },
            {
                Header: 'Linx Score',
                accessor: 'linx_score',
                sticky: 'left',
                disableSortBy: false,
            },
            {
                Header: 'Referral Tracker',
                accessor: 'referred_users',
                sticky: 'left',
                disableSortBy: false,
            },
        ],
        [rowCheckboxStates, selectAllChecked],
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        pageOptions,
        state,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
    );

    const pageCount = Math.ceil(totalCount / 10);

    return (
        <>
            {
                loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" />
                    :
                    <div className="league-ranking-wrapper">
                        <div className="admin-users-body">
                            <div
                                style={{
                                    padding: '0px 30px',
                                    backgroundColor: '##F3F4F6',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: '20px',
                                    borderColor: '#1334B9',
                                }}>
                            </div>
                            <>
                                <div className='app-users-filters-wrapper d-flex justify-content-between'>
                                    <div className='app-users-filters d-flex'>
                                        <div className="admin-users-heading">
                                            League Ranking
                                        </div>
                                        <div className='app-users-dropdown d-flex w-50'>
                                            <Select
                                                options={seasonOptions}
                                                value={seasonVal}
                                                placeholder={
                                                    <div className="select-placeholder-text">Season</div>
                                                }
                                                onChange={handleSeasonDropdown}
                                                className="w-50 gender-dropdown"
                                            />

                                            <Select
                                                options={tierOptions}
                                                value={tierVal}
                                                placeholder={
                                                    <div className="select-placeholder-text">Tier</div>
                                                }
                                                onChange={handleTierDropdown}
                                                className="w-50 gender-dropdown"
                                            />
                                        </div>
                                    </div>
                                    <>{selectedRows?.length > 0 &&
                                        <div className='admin-header-btns'>
                                            <Button className='move-btn cursor-pointer' onClick={() => setMovePopup(true)}>Move to</Button>
                                            <Button className='move-btn cursor-pointer' onClick={(e) => exportToCSV(selectedRows, 'Users Listing')}>Download as .csv</Button>
                                        </div>
                                    }</>
                                </div>
                                <div className="table-container mt-4">
                                    <table {...getTableProps()} className="table-cls w-100">
                                        <thead className='app-users-table-head'>
                                            {headerGroups?.map((headerGroup) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers?.map((column) => (
                                                        <th
                                                            {...column.getHeaderProps(
                                                                column.getSortByToggleProps(),
                                                            )}>
                                                            {column.render('Header')}
                                                            <span>
                                                                &nbsp;
                                                                {column.disableSortBy === false ? (
                                                                    <Image src={svg?.Selector} className='cursor-pointer'></Image>
                                                                ) : null}
                                                            </span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {page?.map((row) => {
                                                prepareRow(row);
                                                return (
                                                    <tr
                                                        {...row?.getRowProps()}
                                                        className='table-body-row'
                                                    >

                                                        {row?.cells?.map((cell, cellIndex) => {
                                                            return (
                                                                <td {...cell?.getCellProps()}>
                                                                    {cell.render('Cell')}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="paginationBox d-flex justify-content-center mt-4">
                                    <div className='pagination-body d-flex justify-content-center align-items-center'>
                                        <img src={svg.Previcon}
                                            onClick={() => {
                                                if (getData?.previous !== null) handlePrevPage()
                                            }}
                                            disabled={getData?.previous === null}
                                            className={getData?.previous !== null ? 'previcon' : 'disabled'} alt="prev-icon-NP" />
                                        {Array.from({ length: pageCount }, (_, index) => (
                                            <button
                                                key={index}
                                                onClick={() => {
                                                    setCurrentPageIndex(index + 1)
                                                    fetchUsers(index + 1)
                                                }}
                                                className={`paginationButton ${currentPageIndex === index + 1 ? 'activeButton' : ''
                                                    }`}>
                                                {index + 1}
                                            </button>
                                        ))}
                                        <img src={svg.Nexticon} alt="prev-icon-NP"
                                            onClick={() => {
                                                if (getData?.next !== null) handleNextPage()
                                            }}
                                            disabled={getData?.next === null}
                                            className={getData?.next !== null ? 'previcon' : 'disabled'} />
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>

            }
            {
                showMovePopup === true &&
                <MoveToLeaguesPopup show={showMovePopup} handleMovePopupClose={handleMovePopupClose} handleMovePopupSuccessClose={handleMovePopupSuccessClose} userData={selectedRows} />
            }
        </>
    )
}

export default LeagueRanking;