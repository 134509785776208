import React, { useState, useEffect } from "react";
import Navbar from "../../CustomComponents/Navbar";
import { Popover, PopoverBody } from 'react-bootstrap';
import { fetchOtherUserProfile } from '../../api/request';
import { convertDateToMonth } from '../../utils/ConvertDateToMonth.js';
import { formatNumberAsOrdinal } from '../../utils/formatNumberAsOrdinal.js';
import ReportUser from '../ReportUser';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from '../../CustomComponents/Loader';
import svg from '../../assets/images/svg/index';
import { useNavigate, useLocation } from "react-router-dom";
import { useWindowSize } from "../../utils/useWindowSize.js";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

let mobileBreakpoint = 821;
const OtherUserProfile = () => {
    const navigate = useNavigate();
    const [height, width] = useWindowSize()
    const location = useLocation();
    const queryParams = new URLSearchParams(location?.search);
    const [getwidth, setWidth] = useState(0)
    const [userId, setUserId] = useState(queryParams?.get("user_id")); //will change when standing feature integrated
    const [getCoverImage, setCoverImage] = useState('');
    const [showPopover, setShowPopover] = useState(false);
    const [showReportPopup, setReportPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [getMyProfile, setMyProfile] = useState({});
    const [getMyleagues, setMyLeagues] = useState([]);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    useEffect(() => {
        fetchOtherUserData();
    }, []);

    const fetchOtherUserData = async () => {
        setLoading(true);
        try {
            let resp = await fetchOtherUserProfile(userId);
            setLoading(false);
            setMyProfile(resp?.profile);
            setMyLeagues(resp?.my_leagues);
            setCoverImage(resp?.my_leagues[0]?.league?.cover_image);
        }
        catch (error) {
            toast.error(error?.response?.data?.message || 'An error occurred while processing your request.');
            setLoading(false);
        }
    }

    const handleLeagueCoverPhoto = (data) => {
        setCoverImage(data?.league?.cover_image);
    }

    const togglePopover = () => {
        setShowPopover(!showPopover);
    }

    const handleReportUser = () => {
        setReportPopup(true);
        setShowPopover(false);
    }

    const handleCloseReportPopup = () => {
        setReportPopup(false);
        setShowPopover(false);
    }

    return (
        <>
            <Navbar />
            {
                loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" />
                    :
                    <div className="other-user-profile">
                        <div className={getCoverImage ? "league-img" : "def-league-img"}>
                            <img src={svg.BackArrow} className="backarrow cursor-pointer" alt="back NP" onClick={() => navigate(-1)} />
                            <img src={getCoverImage || svg?.UserDashboardDefault} className="def-img w-100 h-100" alt="def img NP" />
                        </div>

                        <div className="dashboard-body-wrapper">
                            <div className="other-user-info d-flex align-items-center">
                                <div className="other-user-img">
                                    <img src={getMyProfile?.profile_image || svg?.DefaultUser} className="u-img w-100 h-100" alt="u-img NP" />
                                </div>
                                <div className="other-user-data d-flex flex-column">
                                    <div className="u-name">{getMyProfile?.user?.first_name} {getMyProfile?.user?.last_name}</div>
                                    <div className="user-other-info d-flex align-items-center">
                                        <div className="u-handicap">Handicap Index®: {getMyProfile?.profile?.hc_index}</div>
                                        <div className="u-handicap">Attest rate: {getMyProfile?.profile?.attest_rate}%</div>
                                    </div>
                                </div>
                                <div className="three-dots">
                                    <img src={getwidth < mobileBreakpoint ? svg?.VerticalDotsMob : svg?.VerticalDots} className="vertical-dots cursor-pointer" alt="info NP" onClick={() => togglePopover()} />
                                    <>{showPopover === true &&
                                        <Popover
                                            placement="bottom"
                                            isOpen={showPopover}
                                            target="mypopover"
                                        >
                                            <PopoverBody onClick={() => handleReportUser()} className="cursor-pointer">
                                                <img src={svg?.WarningImg} className="report-user-img" alt="report user Np" />

                                                <span className="report-user-txt">Report User</span>
                                            </PopoverBody>
                                        </Popover>
                                    }</>
                                </div>
                            </div>
                            <div className="other-user-my-leagues">
                                <div className="my-leagues-heading">My Leagues</div>
                                <div className="other-user-leagues-listing d-flex flex-column mt-3">
                                    {
                                        getMyleagues?.map((data, index) => (
                                            <div className="league-card d-flex justify-content-between" key={index} onClick={() => handleLeagueCoverPhoto(data)}>
                                                <div className="league-info d-flex flex-column">
                                                    <div className={getwidth < mobileBreakpoint ? "league-name trimTxt1line" : "league-name"}>{data?.league?.name} {data?.season?.name}</div>
                                                    <div className="league-duration">{convertDateToMonth(data?.season?.start_date)} - {convertDateToMonth(data?.season?.end_date)}</div>
                                                </div>

                                                <div className="league-score-ranking d-flex">
                                                    <div className="league-score-info d-flex flex-column">
                                                        <div className="score-val">
                                                            {data?.score || '8.9'}
                                                        </div>
                                                        <div className="score-txt">Score</div>
                                                    </div>
                                                    <div className="white-line"></div>
                                                    <div className="league-score-info d-flex flex-column">
                                                        <div className="score-val">{data?.ranking || 53}
                                                            <span className="format-score-val">{formatNumberAsOrdinal(56)}</span>
                                                        </div>
                                                        <div className="score-txt">Ranking</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
            }
            {
                showReportPopup === true &&
                <ReportUser show={showReportPopup} handleCloseReportPopup={handleCloseReportPopup} userId={userId} />
            }
        </>
    )
}

export default OtherUserProfile;