import React, { useState, useEffect } from 'react';
import { termsConditions } from '../../api/request';
import Loader from '../../CustomComponents/Loader';
import {formatDate} from '../../utils/formatDate';
import './styles.scss';

const TermsConditions = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            let resp = await termsConditions();
            if (resp?.results) {
                setData(resp?.results[0])
            }
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
        }
    }

    return (
        <>{loading === true ?
            <Loader loadingMsg="Data is Loading... Please Wait" />
            :
            <div className='terms-conditions-wrapper'>
                <div className='policy-body-wrapper'>
                    <div className='policy-heading mt-3'>Terms & Conditions</div>
                    <div className='policy-date-time mt-4'>Last Updated: {formatDate(data?.updated_at)}</div>
                    <div className='policy-body mt-3'>{data?.body}</div>
                </div>
            </div>
        }</>
    )
}

export default TermsConditions;