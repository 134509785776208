import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { deleteAccount } from '../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import './confirmscore.scss';

const ConfirmScore = (props) => {
    const navigate = useNavigate();

    const handleClose = () => {
        props.handleScorePopupClose();
    }

    const handleFinishRound = async () => {
        props?.handleConfirmFinishRound()
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='confirm-score-wrapper'
            show={props.show}
            onHide={handleClose}
            centered>
            <Modal.Header>
                <Modal.Title className="modalTitle">Confirm Strokes</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <div className="del-sub">
                    You haven’t attested all players. Go back to
                    attest other players or complete round.
                </div>
                <div className='del-buttons-wrapper w-100'>
                    <Button
                        onClick={handleClose}
                        className="delBtn w-100">
                        Go Back
                    </Button>
                    <Button className="cancelBtn w-100" onClick={() => handleFinishRound()}>
                        Complete Round
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ConfirmScore;
