import React, { useState, useEffect } from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { changePassword } from '../../api/request';
import './styles.scss';
import ResetError from "../UserAuth/ResetPassword/ResetError.jsx";
import {
    faEye,
    faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useWindowSize } from "../../utils/useWindowSize.js";

let mobileBreakpoint = 821;
const PasswordStrengthBar = ({ password }) => {
    // Calculate password strength here and return the appropriate JSX
    // You can define your own logic for determining the password strength
    const strength = calculatePasswordStrength(password);

    return (
        <>{strength.pasStrngth !== '0' &&
            <div className="progress-wrapper">
                <span className="progress-txt">{strength.passTxt}</span>
                <ProgressBar now={strength.pasStrngth} />
            </div>
        }</>
    );
};

const calculatePasswordStrength = (password) => {
    let obj = {
        passTxt: '',
        pasStrngth: ''
    }
    if (password.length === 0) {
        obj['passTxt'] = '';
        obj['pasStrngth'] = '0';
        return obj;
    }
    else if (password.length < 4) {
        obj['passTxt'] = 'Weak';
        obj['pasStrngth'] = '25';
        return obj;
    }
    else if (password.length < 8) {
        obj['passTxt'] = 'Medium';
        obj['pasStrngth'] = '75';
        return obj;
    } else if (password.length >= 8) {
        obj['passTxt'] = 'Strong';
        obj['pasStrngth'] = '100';
        return obj;
    }
};

const Changepassword = () => {
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNew1Password, setShowNew1Password] = useState(false);
    const [showNew2Password, setShowNew2Password] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width])

    const schema = Yup.object().shape({
        passwordOld: Yup.string().required('Password is required').matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
            "Password must contain at least 8 characters, one capital letter, one number, and one special character"
        ),
        password1: Yup.string().required('Password is required').matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
            "Password must contain at least 8 characters, one capital letter, one number, and one special character"
        ),
        password2: Yup.string().required('Password is required').matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
            "Password must contain at least 8 characters, one capital letter, one number, and one special character"
        ),
    });
    return (
        <div className="change-password-settings-wrapper">

            <>{getwidth >= mobileBreakpoint &&
                <div className="password-heading">
                    Change password
                </div>
            }</>
            <div className={getwidth >= mobileBreakpoint ? "password-body mt-4" : "password-body"}>
                <Formik
                    validationSchema={schema}
                    initialValues={{ passwordOld: '', password1: '', password2: '' }}
                    onSubmit={async (values, { resetForm }) => {
                        let body = {
                            "current_password": values.passwordOld,
                            "password1": values.password1,
                            "password2": values.password2
                        }
                        if (values.password1 !== values.password2) {
                            setError(true);
                        }
                        else {
                            try {
                                setError(false)
                                let resp = await changePassword(body);
                                if (resp) {
                                    toast.success(resp?.message || 'Password changed successfully');
                                    resetForm()
                                    setShowNew1Password(false)
                                    setShowNew2Password(false);
                                    setShowCurrentPassword(false);
                                }
                            }
                            catch (error) {
                                toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
                            }
                        }
                    }}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                        /* and other goodies */
                    }) => (
                        <form
                            className="login-form"
                            onSubmit={handleSubmit}
                            noValidate>
                            <div className="Auth-form-content w-100">

                                <Form.Group controlId="password">

                                    <label className="change-pass-label settings-label">Current Password</label>
                                    <div
                                        className={getwidth>=mobileBreakpoint?`form-floating mb-3 w-75 pass-div ${touched.passwordOld && errors.passwordOld
                                            ? 'has-validation-error'
                                            : ''
                                            }` : `form-floating mb-3 w-100 pass-div ${touched.passwordOld && errors.passwordOld
                                                ? 'has-validation-error'
                                                : ''
                                                }`}>
                                        <Form.Control
                                            className={getwidth >= mobileBreakpoint ? "w-100 mt-2 p-2" : "w-100 mt-2 p-2"}
                                            type={showCurrentPassword ? 'text' : 'password'}
                                            name="passwordOld"
                                            placeholder="New Password"
                                            value={values.passwordOld}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.passwordOld && errors.passwordOld}
                                        />
                                        {/* {touched.passwordOld && errors.passwordOld ? null : ( */}
                                            <div className="input-group-append eyeicon-img">
                                                <FontAwesomeIcon
                                                    icon={showCurrentPassword ? faEye : faEyeSlash}
                                                    style={{ color: '#2563EB' }}
                                                    onClick={() => {
                                                        setShowCurrentPassword(!showCurrentPassword)
                                                    }}
                                                />
                                            </div>
                                        {/* )} */}
                                        <Form.Control.Feedback type="invalid" className={getwidth >= mobileBreakpoint ? "w-75" : "w-100"}>
                                            {errors.passwordOld}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>

                                <Form.Group controlId="password">
                                    <div className={getwidth >= mobileBreakpoint ? "label-wrapper" : "label-wrapper w-100"}>
                                        <label className="change-pass-label settings-label">New Password</label>
                                        <PasswordStrengthBar password={values.password1} className={getwidth >= mobileBreakpoint ? "w-50" : "w-100"} />
                                    </div>
                                    <div
                                        className={getwidth>=mobileBreakpoint? `form-floating w-75 mb-3 pass-div ${touched.password1 && errors.password1
                                            ? 'has-validation-error'
                                            : ''
                                            }` : `form-floating w-100 mb-3 pass-div ${touched.password1 && errors.password1
                                                ? 'has-validation-error'
                                                : ''
                                                }`}>
                                        <Form.Control
                                            className={getwidth >= mobileBreakpoint ? "w-100 mt-2 p-2" : "w-100 mt-2 p-2"}
                                            type={showNew1Password ? 'text' : 'password'}
                                            name="password1"
                                            placeholder="New Password"
                                            value={values.password1}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.password1 && errors.password1}
                                        />
                                        {/* {touched.password1 && errors.password1 ? null : ( */}
                                            <div className="input-group-append eyeicon-img">
                                                <FontAwesomeIcon
                                                    icon={showNew1Password ? faEye : faEyeSlash}
                                                    style={{ color: '#2563EB' }}
                                                    onClick={() => {
                                                        setShowNew1Password(!showNew1Password)
                                                    }}
                                                />
                                            </div>
                                        {/* )} */}
                                        <Form.Control.Feedback type="invalid" className={getwidth >= mobileBreakpoint ? "w-75" : "w-100"}>
                                            {errors.password1}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>

                                <Form.Group controlId="password">
                                    <label className="change-pass-label settings-label">Confirm New Password</label>
                                    <div
                                        className={getwidth>=mobileBreakpoint ?`form-floating w-75 mb-3 pass-div ${touched.password2 && errors.password2
                                            ? 'has-validation-error'
                                            : ''
                                            }` : `form-floating w-100 mb-3 pass-div ${touched.password2 && errors.password2
                                                ? 'has-validation-error'
                                                : ''
                                                }`}>
                                        <Form.Control
                                            className={getwidth >= mobileBreakpoint ? "w-100 mt-2 p-2" : "w-100 mt-2 p-2"}
                                            type={showNew2Password ? 'text' : 'password'}
                                            name="password2"
                                            placeholder="Confirm New Password"
                                            value={values.password2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.password2 && errors.password2}
                                        />
                                        {/* {touched.password2 && errors.password2 ? null : */}
                                            <div className="input-group-append eyeicon-img">
                                                <FontAwesomeIcon
                                                    icon={showNew2Password ? faEye : faEyeSlash}
                                                    style={{ color: '#2563EB' }}
                                                    onClick={() => {
                                                        setShowNew2Password(!showNew2Password)
                                                    }}
                                                />
                                            </div>
                                        {/* } */}
                                        <Form.Control.Feedback type="invalid" className={getwidth >= mobileBreakpoint ? "w-75" : "w-100"}>
                                            {errors.password2}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                                <>
                                    {error === true && (
                                        <ResetError errormsg="New passwords not matching !!" className={getwidth >= mobileBreakpoint ? "w-75" : "w-100"} />
                                    )}
                                </>
                                <div className="d-grid gap-2 mt-3">
                                    <button
                                        type="submit"
                                        className={getwidth < mobileBreakpoint ? "w-100 btn btn-primary reset-btn" : "btn btn-primary reset-btn"}
                                    >
                                        Reset Password
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default Changepassword;