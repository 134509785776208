import React, { useState, useEffect } from 'react';
import { termsConditions } from '../../api/request';
import Loader from '../../CustomComponents/Loader';
import { formatDate } from '../../utils/formatDate';
import { useWindowSize } from "../../utils/useWindowSize.js";
import './styles.scss';

let mobileBreakpoint = 821;
const TermsConditions = () => {
    const [data, setData] = useState([]);
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    const fetchData = async () => {
        setLoading(true);
        try {
            let resp = await termsConditions();
            if (resp?.results) {
                setData(resp?.results[0])
            }
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
        }
    }

    return (
        <>{
            loading === true ?
                <Loader loadingMsg="Data is Loading... Please Wait" />
                :
                <div className='terms-conditions-settings-wrapper'>
                    <div className='policy-body-wrapper'>
                        {getwidth >= mobileBreakpoint && <div className='policy-heading'>Terms & Conditions</div>}
                        <div className={getwidth >= mobileBreakpoint ? 'policy-date-time mt-4' : 'policy-date-time'}>Last Updated: {formatDate(data?.updated_at)}</div>
                        <div className='policy-body mt-3'>
                            {data?.body}
                        </div>
                    </div>
                </div>

        }</>
    )
}

export default TermsConditions;