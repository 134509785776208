import React, { useState } from "react";
import Logo from '../../../assets/images/svg/Logo.svg';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Row, Col, Image } from 'react-bootstrap';
import SocialLogin from '../Signin/SocialLogin';
import {
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { signup } from '../../../api/request';
import svg from '../../../assets/images/svg/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

const Signup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [stateVal, setStateVal] = useState('');
  const [callbackUrl, setCallbackUrl] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const queryParams = new URLSearchParams(location?.search);
  let referCode = queryParams.get("refer_code");

  const schema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required').matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
      "Password must contain at least 8 characters, one capital letter, one number, and one special character"),
    rememberme: Yup.boolean()
      .required('Required to accept the Conditions and Policies')
      .oneOf([true], 'You must accept the Conditions and Policies')
    // rememberme: Yup.boolean()
    //   .required('Required to accept the Conditions and Policies')
  });

  return (
    <div className="user-signin-wrapper d-flex justify-content-center">
      <div className="user-signin-form-wrapper w-100">
        <img src={Logo} className="app-logo" alt="app logo NP" />
        <Formik
          validationSchema={schema}
          initialValues={{ email: '', password: '', rememberme: false }}
          onSubmit={async (values) => {
            let body = {
              "email": values.email,
              "password": values.password
            }
            try {
              let resp = await signup(body, referCode);
              if (resp) {
                localStorage?.setItem('userEmail', values?.email);
                navigate('/verify-email');
              }
            }
            catch (error) {
              toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
            }

          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
            /* and other goodies */
          }) => (
            <form
              className="login-form"
              onSubmit={handleSubmit}
              noValidate>
              <div className="Auth-form-content w-100">
                <div className="signin_heading">Sign Up</div>
                <Form.Group controlId="email">
                  <div
                    className={`form-floating  mb-3 ${touched.email && errors.email
                      ? 'has-validation-error'
                      : ''
                      }`}>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="w-100"
                      isInvalid={touched.email && errors.email}
                    />
                    {!values.email && (
                      <label htmlFor="email" className="greyLabel">
                        Email
                      </label>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group controlId="password">
                  <div
                    className={`form-floating mb-3 ${touched.password && errors.password
                      ? 'has-validation-error'
                      : ''
                      }`}>
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.password && errors.password}
                    />
                    {/* {touched.password && errors.password ? null : ( */}
                      <div className="input-group-append eyeicon-img">
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          style={{ color: '#2563EB' }}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </div>
                    {/* )} */}
                    {!values.password && (
                      <label htmlFor="email" className="greyLabel">
                        Password
                      </label>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>

                <Row>
                  <Col xs={12} md={12} className="rememberme_checkbox">
                    <div className="form-check">
                      <label className="form-check-label remember_me">
                        I have read the{" "}
                        <span className="terms_span cursor-pointer" onClick={() => navigate('/terms-conditions')}>Terms and Conditions</span>{" "}
                        and <span className="terms_span cursor-pointer" onClick={() => navigate('/privacy-policy')}>Privacy Policy</span>.
                      </label>
                      <input
                        className="form-check-input chkBox  cursor-pointer"
                        type="checkbox"
                        checked={values?.rememberme}
                        name={"rememberme"}
                        // onChange={handleChange}
                        // onChange={(e) => {
                        //   handleChange(e);
                        //   // Explicitly set the value to false when the checkbox is not checked
                        //   if (!e.target.checked) {
                        //     setFieldValue('rememberme', false);
                        //   }
                        // }}
                        onChange={(e) => {
                          // Convert the value to a boolean explicitly
                          const checked = e.target.checked;
                          handleChange(e);
                          setFieldValue('rememberme', checked);
                        }}
                        onBlur={handleBlur}
                      />
                      {/* Move the Form.Control.Feedback inside the form-check div */}
                      {touched.rememberme && errors.rememberme && (
                        <Form.Control.Feedback type="" className="text-danger invalid-terms-feedback">
                          {errors.rememberme}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                </Row>

                <div className="d-grid gap-2 mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary signin_btn"
                    style={{ width: '100%' }}>
                    Sign Up
                  </button>
                </div>

                <div className="login-footer d-flex align-items-center mt-4">
                  <div className="loginLine"></div>
                  <span className="logintxt">Or</span>
                  <div className="loginLine"></div>
                </div>

                {/* <div className="social-logins-wrapper">
                  <div className="signin_fb_login w-75 mb-3 rounded-pill">
                    <FacebookLogin
                      // appId="653445549918273" //APP ID
                      appId="579990700942399"
                      fields="name,email,picture"
                      textButton="Log In with Facebook"
                      render={(renderProps) => (
                        <button onClick={renderProps.onClick}>
                          <img
                            src={svg.FacebookLogo}
                            alt="fb np"
                          />
                        </button>
                      )}
                      // icon={
                      //   <img
                      //     src={svg.FacebookLogo}
                      //     className="facebook_login"
                      //     alt="fbicon np"
                      //   />
                      // }
                      callback={responseFacebook}
                    />
                  </div>
                  <div className="signin_google_login w-75 mb-3 rounded-pill">
                    <LoginSocialGoogle
                      client_id={
                        "993169876801-92aink9vca20s5ver7bj9uapbmlkgia1.apps.googleusercontent.com"
                      }
                      scope="openid profile email"
                      discoveryDocs="claims_supported"
                      onResolve={responseGoogle}
                      onReject={err => {
                        console.log(err)
                      }}
                    >
                      <GoogleLoginButton className="google_social_login" />
                    </LoginSocialGoogle>
                  </div>
                  <AppleLogin
                    clientId="com.spotHunter.web"
                    state={stateVal}
                    redirectURI={callbackUrl}
                    // redirectURI="https://parkauthority-37803.botics.co/"
                    // redirectURI="https://parkauthority-37803.botics.co/accounts/apple/login/callback/"
                    callback={handleApplesign}
                    responseMode="form_post"
                    responseType="code id_token"
                    scope="email name"
                  />
                </div> */}

                <div className="social-logins-wrapper d-flex align-items-center justify-content-center mt-4">
                  <SocialLogin />
                </div>
                <div className="no-account-txt mt-4 cursor-pointer" onClick={() => navigate('/login')}>
                  Already have an account? Sign In Here!
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default Signup;