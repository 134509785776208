export function formatTime(inputTime) {
const timeParts = inputTime.split(":");
let hours = parseInt(timeParts[0]);
const minutes = timeParts[1];
let ampm = "AM";

if (hours >= 12) {
  ampm = "PM";
  if (hours > 12) {
    hours -= 12;
  }
}

const formattedTime = `${hours}:${minutes} ${ampm}`;
return formattedTime;
}