import React, { useState } from "react";
import Logo from '../../../assets/images/svg/Logo.svg';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import svg from '../../../assets/images/svg/index'
import { resetPass } from '../../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import ResetError from "./ResetError";
import {
    faEye,
    faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ResetPassword = () => {
    const [getResetPass, setresetPass] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const keyValue = queryParams.get("key");
    const emailValue = decodeURIComponent(queryParams.get("email").replace(' ', '+'));

    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [showNew1Password, setShowNew1Password] = useState(false);
    const [showNew2Password, setShowNew2Password] = useState(false);

    const schema = Yup.object().shape({
        password1: Yup.string().required('Password is required').matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
            "Password must contain at least 8 characters, one capital letter, one number, and one special character"),
        password2: Yup.string().required('Password is required').matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
            "Password must contain at least 8 characters, one capital letter, one number, and one special character"),
    });

    return (
        <div className="reset-pass-wrapper d-flex justify-content-center">
            <div className="user-signin-form-wrapper w-100">
                <img src={Logo} className="app-logo" alt="app logo NP" />
                <Formik
                    validationSchema={schema}
                    initialValues={{ password1: '', password2: '' }}
                    onSubmit={async (values) => {
                        let body = {
                            "email": emailValue,
                            "key": keyValue,
                            "password1": values?.password1,
                            "password2": values.password2
                        }
                        if (values.password1 !== values.password2) {
                            setError(true);
                            return;
                        }
                        else {
                            try {
                                let resp = await resetPass(body);
                                if (resp?.message) {
                                    toast.success(resp?.message);
                                    setShowNew1Password(false)
                                    setShowNew2Password(false);
                                    setresetPass(true);
                                    navigate('/login')
                                }
                            }
                            catch (error) {
                                toast.error(error?.response?.data?.message || 'An error occurred while processing your request.');
                                setresetPass(false);
                            }
                        }
                    }}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                        /* and other goodies */
                    }) => (
                        <form
                            className="login-form"
                            onSubmit={handleSubmit}
                            noValidate>
                            <div className="Auth-form-content w-100">
                                <div className="signin_heading">Reset Password</div>
                                <Form.Group controlId="password">
                                    <div
                                        className={`form-floating mb-3 pass-div ${touched.password1 && errors.password1
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            type={showNew1Password ? 'text' : 'password'}
                                            name="password1"
                                            placeholder="New Password"
                                            value={values.password1}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.password1 && errors.password1}
                                        />
                                        {!values.password1 && (
                                            <label htmlFor="email" className="greyLabel">
                                                New Password
                                            </label>
                                        )}
                                        {/* {touched.password1 && errors.password1 ? null : ( */}
                                            <div className="input-group-append eyeicon-img">
                                                <FontAwesomeIcon
                                                    icon={showNew1Password ? faEye : faEyeSlash}
                                                    style={{ color: '#2563EB' }}
                                                    onClick={() => {
                                                        setShowNew1Password(!showNew1Password)
                                                    }}
                                                />
                                            </div>
                                        {/* )} */}
                                        <Form.Control.Feedback type="invalid">
                                            {errors.password1}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>

                                <Form.Group controlId="password">
                                    <div
                                        className={`form-floating mb-3 pass-div ${touched.password2 && errors.password2
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            type={showNew2Password ? 'text' : 'password'}
                                            name="password2"
                                            placeholder="Confirm New Password"
                                            value={values.password2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.password2 && errors.password2}
                                        />
                                        {!values.password2 && (
                                            <label htmlFor="email" className="greyLabel">
                                                Confirm New Password
                                            </label>
                                        )}
                                        {/* {touched.password2 && errors.password2 ? null : */}
                                            <div className="input-group-append eyeicon-img">
                                                <FontAwesomeIcon
                                                    icon={showNew2Password ? faEye : faEyeSlash}
                                                    style={{ color: '#2563EB' }}
                                                    onClick={() => {
                                                        setShowNew2Password(!showNew2Password)
                                                    }}
                                                />
                                            </div>
                                        {/* } */}
                                        <Form.Control.Feedback type="invalid">
                                            {errors.password2}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                                <>
                                    {error === true && (
                                        <ResetError errormsg="Passwords not matching !!" />
                                    )}
                                </>
                                {getResetPass !== true &&
                                    <div className="d-grid gap-2 mt-3">
                                        <button
                                            type="submit"
                                            className="btn btn-primary signin_btn"
                                            style={{ width: '100%' }}>
                                            Reset Password
                                        </button>
                                        <button
                                            onClick={() => navigate('/login')}
                                            className="btn btn-primary cancel_btn"
                                            style={{ width: '100%' }}>
                                            Cancel
                                        </button>
                                    </div>
                                }
                                <>{
                                    getResetPass === true &&
                                    <div className="d-grid gap-2 mt-3">
                                        <button
                                            type="submit"
                                            className="btn btn-primary signin_btn"
                                            style={{ width: '100%' }}
                                            onClick={() => navigate('/login')}>
                                            Back to Login
                                        </button>
                                    </div>
                                }</>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default ResetPassword;