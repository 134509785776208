import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';
import Select from 'react-select';
import { getLeagues, fetchFilteredLeaguesData } from '../../../api/request';
import svg from '../../../assets/images/svg/index';
import Loader from '../../../CustomComponents/Loader';
import {
    useTable,
    usePagination,
    useSortBy,
    useRowSelect,
    useGlobalFilter,
} from 'react-table';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';

const AdminLeaguesList = () => {
    const navigate = useNavigate();
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [getNextPage, setNextPage] = useState(false);
    const [getPrevPage, setPrevPage] = useState(false);
    const [getData, setData] = useState({});
    const [loading, setLoading] = useState(true); // Add loading state
    const [noFound, setNoFound] = useState(false);
    const [products, setProducts] = useState([]);
    const [search, setSearch] = useState('');
    const [genderOptions, setgenderOptions] = useState([{
        label: 'Male',
        value: '1'
    },
    {
        label: 'Female',
        value: '2'
    },
    {
        label: 'Both',
        value: '3'
    },
    {
        label: 'All',
        value: '4'
    }])
    const [statusVal, setStatusVal] = useState('');
    const [statusOptions, setStatusOptions] = useState([{
        label: 'Planned',
        value: 1,
    },
    {
        label: 'Active',
        value: 2
    },
    {
        label: 'Completed',
        value: 3
    },
    {
        label: 'All',
        value: 4
    }]);
    const [genderVal, setgenderVal] = useState('');

    const fetchFilteredData = async (gender, status, search) => {
        let resp = await fetchFilteredLeaguesData(gender, status, search);
        if (resp) {
            setProducts(resp?.results);
            setData(resp);
            if (resp?.next !== null) {
                setNextPage(false);
            }
            setTotalCount(resp?.count);
            setLoading(false);
        }
    }

    const handlePrevPage = () => {
        if (getData?.next !== null) {
            setNextPage(false);
        }
        if (getData?.previous !== null) {
            const urlParams = new URLSearchParams(new URL(getData?.previous).search);
            const pageValue = urlParams.get("page");
            if (pageValue === null) {
                setCurrentPageIndex(1);
                fetchData('1', genderVal?.value, statusVal?.value);
            }
            else {
                setCurrentPageIndex(currentPageIndex - 1);
                fetchData(pageValue, genderVal?.value, statusVal?.value);
            }
        }
        else {
            setPrevPage(true);
        }
    }

    const handleNextPage = () => {
        if (getData?.previous !== null) {
            setPrevPage(false);
        }
        if (getData?.next !== null) {
            const urlParams = new URLSearchParams(new URL(getData?.next).search);
            const pageValue = urlParams.get("page");
            setCurrentPageIndex(currentPageIndex + 1);
            fetchData(pageValue, genderVal?.value, statusVal?.value, search);
        }
        else {
            setNextPage(true);
        }
    };

    const handleGenderDropdown = (event) => {
        setCurrentPageIndex(1);
        setgenderVal(event);
        fetchFilteredData(event?.value, statusVal?.value, search);
    }
    const handleStatusDropdown = (event) => {
        setCurrentPageIndex(1);
        setStatusVal(event);
        fetchFilteredData(genderVal?.value, event?.value, search);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async (pageValue) => {
        setLoading(true);
        try {
            let resp = await getLeagues(pageValue || currentPageIndex, genderVal?.value, statusVal?.value, search);
            if (resp) {
                setProducts(resp?.results);
                setData(resp);
                if (resp?.next !== null) {
                    setNextPage(false);
                }
                setTotalCount(resp?.count);
                setLoading(false);
            }
        }
        catch (err) {
            setLoading(false);
            if (err?.response?.data?.detail === "Invalid token.") {
                navigate('/admin/login');
            }
        }
    }
    const data = useMemo(() => products, [products]);

    const handleGenderDisplay = (genderData) => {
        if (genderData === 1) {
            return 'Male'
        }
        if (genderData === 2) {
            return 'Female'
        }
        if (genderData === 3) {
            return 'Both'
        }
    }
    const handleTypeDisplay = (data) => {
        if (data === 1) {
            return 'Competitive'
        }
        else if (data === 2) {
            return 'Recreational'
        }
    }
    const handleStatusDisplay = (data) => {
        if (data === 1) {
            return 'Planned'
        }
        if (data === 2) {
            return 'Active'
        }
        if (data === 3) {
            return 'Completed'
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: 'League Name',
                accessor: 'name',
                sticky: 'left',
                disableSortBy: false,
                Cell: ({ row }) => <span className='cursor-pointer' onClick={() => navigate('/admin/dashboard/specific-league', {
                    state: {
                        league: row?.original
                    }
                })}>{row?.original?.name}</span>,
            },
            {
                Header: 'Gender',
                accessor: 'gender',
                sticky: 'left',
                disableSortBy: false,
                Cell: ({ row }) => <span>{handleGenderDisplay(row?.original?.gender)}</span>,
            },
            {
                Header: 'Status',
                accessor: 'status',
                sticky: 'left',
                disableSortBy: false,
                Cell: ({ row }) => <span>{handleStatusDisplay(row?.original?.status)}</span>,
            },
            {
                Header: 'Revenue',
                accessor: 'revenue',
                sticky: 'left',
                disableSortBy: false,
                Cell: ({ row }) => <span>${(row?.original?.revenue)}</span>,
            }, {
                Header: 'Type',
                accessor: 'type',
                sticky: 'left',
                disableSortBy: false,
                Cell: ({ row }) => <span>{handleTypeDisplay(row?.original?.type)}</span>,
            }
        ],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
    );

    const pageCount = Math.ceil(totalCount / 10);

    return (
        <>{loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" /> :
            <div className="admin-users-wrapper adminpadding">
                <div className="admin-users-heading">
                    Leagues
                </div>

                <div className="admin-users-body">
                    <div
                        style={{
                            padding: '0px 30px',
                            backgroundColor: '##F3F4F6',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: '20px',
                            borderColor: '#1334B9',
                        }}>
                    </div>
                    <>
                        <div className='leagues-headers'>
                            <div className='app-users-filters d-flex'>
                                <div className="search-bar-leagues w-50">
                                    <input
                                        type="text"
                                        value={search}
                                        className="SearchLabel searchUsers w-100"
                                        onChange={(e) => {
                                            setSearch(e?.target?.value);
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                setSearch(e?.target?.value);
                                                fetchFilteredData(genderVal?.value, statusVal?.value, e?.target?.value);  
                                            }
                                        }}
                                        placeholder="Search"
                                        style={{ '::placeholder': { color: '#4A514C99' } }}
                                    />

                                    <span className="search-icon" onClick={() => {
                                        fetchFilteredData(genderVal?.value, statusVal?.value, search);
                                    }}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.75293 12.8767C8.13916 12.8767 9.41748 12.4285 10.4634 11.6814L14.3979 15.616C14.5806 15.7986 14.8213 15.8899 15.0786 15.8899C15.6182 15.8899 16 15.4749 16 14.9436C16 14.6946 15.917 14.4539 15.7344 14.2795L11.8247 10.3616C12.6465 9.28247 13.1362 7.94604 13.1362 6.49341C13.1362 2.98218 10.2642 0.110107 6.75293 0.110107C3.25 0.110107 0.369629 2.97388 0.369629 6.49341C0.369629 10.0046 3.2417 12.8767 6.75293 12.8767ZM6.75293 11.4988C4.01367 11.4988 1.74756 9.23267 1.74756 6.49341C1.74756 3.75415 4.01367 1.48804 6.75293 1.48804C9.49219 1.48804 11.7583 3.75415 11.7583 6.49341C11.7583 9.23267 9.49219 11.4988 6.75293 11.4988Z" fill="#88BD36" />
                                        </svg>

                                    </span>
                                </div>
                                <div className='app-users-dropdown d-flex'>
                                    <Select
                                        options={genderOptions}
                                        value={genderVal}
                                        placeholder={
                                            <div className="select-placeholder-text">Gender</div>
                                        }
                                        onChange={handleGenderDropdown}
                                        className="w-100 gender-dropdown"
                                    />

                                    <Select
                                        options={statusOptions}
                                        value={statusVal}
                                        placeholder={
                                            <div className="select-placeholder-text">Status</div>
                                        }
                                        onChange={handleStatusDropdown}
                                        className="w-100 gender-dropdown"
                                    />
                                </div>
                            </div>

                            <div className='add-league width15'>
                                <Button className='add-league-btn w-100' onClick={() => navigate('/admin/dashboard/create-league')}>Add New League</Button>
                            </div>
                        </div>
                        <div className="table-container mt-4">
                            <table {...getTableProps()} className="table-cls w-100">
                                <thead className='app-users-table-head'>
                                    {headerGroups?.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers?.map((column) => (
                                                <th
                                                    {...column.getHeaderProps(
                                                        column.getSortByToggleProps(),
                                                    )}>
                                                    {column.render('Header')}
                                                    <span>
                                                        &nbsp;
                                                        {column.disableSortBy === false ? (
                                                            <Image src={svg?.Selector} className='cursor-pointer'></Image>
                                                        ) : null}
                                                    </span>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page?.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr
                                                {...row?.getRowProps()}
                                                className='table-body-row'
                                            >

                                                {row?.cells?.map((cell, cellIndex) => {
                                                    return (
                                                        <td {...cell?.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="paginationBox d-flex justify-content-center mt-4">
                            <div className='pagination-body d-flex justify-content-center align-items-center'>
                                <img src={svg.Previcon}
                                    onClick={() => {
                                        if (getData?.previous !== null) handlePrevPage()
                                    }}
                                    disabled={getData?.previous === null}
                                    className={getData?.previous !== null ? 'previcon' : 'disabled'} alt="prev-icon-NP" />
                                {Array.from({ length: pageCount }, (_, index) => (
                                    <button
                                        key={index}
                                        onClick={() => {
                                            setCurrentPageIndex(index + 1)
                                            fetchData(index + 1, genderVal?.value, statusVal?.value)
                                        }}
                                        className={`paginationButton ${currentPageIndex === index + 1 ? 'activeButton' : ''
                                            }`}>
                                        {index + 1}
                                    </button>
                                ))}
                                <img src={svg.Nexticon} alt="prev-icon-NP"
                                    onClick={() => {
                                        if (getData?.next !== null) handleNextPage()
                                    }}
                                    disabled={getData?.next === null}
                                    className={getData?.next !== null ? 'previcon' : 'disabled'} />
                            </div>
                        </div>
                    </>
                </div>
            </div>}</>
    )
}

export default AdminLeaguesList;