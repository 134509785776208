import React, { useState, useEffect } from 'react';
import { Button, Modal, Dropdown, InputGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { updateRound, deletePlayer } from '../../api/request';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from "../../utils/useWindowSize.js";
import { ghinLogin } from '../../api/ghinApi/ghinLogin.js';
import { searchGolfCourses } from '../../api/ghinApi/searchGolfCourses.js';
import 'react-toastify/dist/ReactToastify.css';
import svg from '../../assets/images/svg/index';
import Invitefriends from '../GameScheduling/Invitefriends';
import './editround.scss';
import BottomTooltip from '../UserOnboarding/BottomTooltip';
import * as Yup from 'yup';

let mobileBreakpoint = 821;
const EditRound = (props) => {
    const validationSchema = Yup.object().shape({
        getDate: Yup.string().required('Date is required'),
        getTime: Yup.string().required('Time is required'),
        holeNumVal: Yup.object().shape({
            label: Yup.string().required('Starting from Holes are required'),
            value: Yup.number().required('Starting from Holes are required'),
        }),
        roundTypeVal: Yup.object().shape({
            label: Yup.string().required('Round type is required'),
            value: Yup.string().required('Round type is required'),
        }),
    });
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)
    const [formErrors, setFormErrors] = useState({});
    const [getSelectedGolfcourse, setSelectedGolfcourse] = useState(props?.roundData?.golf_course
    );
    const [stateOptions, setStateOptions] = useState([
        { "label": "Alabama", "value": "US-AL" },
        { "label": "Alaska", "value": "US-AK" },
        { "label": "Arizona", "value": "US-AZ" },
        { "label": "Arkansas", "value": "US-AR" },
        { "label": "California", "value": "US-CA" },
        { "label": "Colorado", "value": "US-CO" },
        { "label": "Connecticut", "value": "US-CT" },
        { "label": "Delaware", "value": "US-DE" },
        { "label": "Florida", "value": "US-FL" },
        { "label": "Georgia", "value": "US-GA" },
        { "label": "Hawaii", "value": "US-HI" },
        { "label": "Idaho", "value": "US-ID" },
        { "label": "Illinois", "value": "US-IL" },
        { "label": "Indiana", "value": "US-IN" },
        { "label": "Iowa", "value": "US-IA" },
        { "label": "Kansas", "value": "US-KS" },
        { "label": "Kentucky", "value": "US-KY" },
        { "label": "Louisiana", "value": "US-LA" },
        { "label": "Maine", "value": "US-ME" },
        { "label": "Maryland", "value": "US-MD" },
        { "label": "Massachusetts", "value": "US-MA" },
        { "label": "Michigan", "value": "US-MI" },
        { "label": "Minnesota", "value": "US-MN" },
        { "label": "Mississippi", "value": "US-MS" },
        { "label": "Missouri", "value": "US-MO" },
        { "label": "Montana", "value": "US-MT" },
        { "label": "Nebraska", "value": "US-NE" },
        { "label": "Nevada", "value": "US-NV" },
        { "label": "New Hampshire", "value": "US-NH" },
        { "label": "New Jersey", "value": "US-NJ" },
        { "label": "New Mexico", "value": "US-NM" },
        { "label": "New York", "value": "US-NY" },
        { "label": "North Carolina", "value": "US-NC" },
        { "label": "North Dakota", "value": "US-ND" },
        { "label": "Ohio", "value": "US-OH" },
        { "label": "Oklahoma", "value": "US-OK" },
        { "label": "Oregon", "value": "US-OR" },
        { "label": "Pennsylvania", "value": "US-PA" },
        { "label": "Rhode Island", "value": "US-RI" },
        { "label": "South Carolina", "value": "US-SC" },
        { "label": "South Dakota", "value": "US-SD" },
        { "label": "Tennessee", "value": "US-TN" },
        { "label": "Texas", "value": "US-TX" },
        { "label": "Utah", "value": "US-UT" },
        { "label": "Vermont", "value": "US-VT" },
        { "label": "Virginia", "value": "US-VA" },
        { "label": "Washington", "value": "US-WA" },
        { "label": "West Virginia", "value": "US-WV" },
        { "label": "Wisconsin", "value": "US-WI" },
        { "label": "Wyoming", "value": "US-WY" }
    ]
    );
    const [stateVal, setStateVal] = useState(stateOptions[0]);
    const [getstate, setState] = useState('');
    const [selectedOption, setSelectedOption] = useState(props?.roundData?.golf_course?.name);
    const [playingUsers, setPlayingUsers] = useState([{
        id: props?.roundData?.players[0]?.user_id || null,
        name: props?.roundData?.players[0]?.name || null,
        profile_pic: props?.roundData?.players[0]?.profile_image || null,
        is_organizer: props?.roundData?.players[0]?.is_organizer || null,
        is_tee_off: props?.roundData?.players[0]?.is_tee_off,
    }, {
        id: props?.roundData?.players[1]?.user_id || null,
        name: props?.roundData?.players[1]?.name || null,
        profile_pic: props?.roundData?.players[1]?.profile_image || null,
        is_organizer: props?.roundData?.players[1]?.is_organizer || null,
        is_tee_off: props?.roundData?.players[1]?.is_tee_off,
    }, {
        id: props?.roundData?.players[2]?.user_id || null,
        name: props?.roundData?.players[2]?.name || null,
        profile_pic: props?.roundData?.players[2]?.profile_image || null,
        is_organizer: props?.roundData?.players[2]?.is_organizer || null,
        is_tee_off: props?.roundData?.players[2]?.is_tee_off,
    }, {
        id: props?.roundData?.players[3]?.user_id || null,
        name: props?.roundData?.players[3]?.name || null,
        profile_pic: props?.roundData?.players[3]?.profile_image || null,
        is_organizer: props?.roundData?.players[3]?.is_organizer || null,
        is_tee_off: props?.roundData?.players[0]?.is_tee_off,
    }]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [getTime, setTime] = useState(props?.roundData?.round_time);
    const [getDate, setDate] = useState(props?.roundData?.round_date);
    const [showInviteFriends, setShowInviteFriends] = useState(false);
    const [holesOptions, setholesOptions] = useState([{
        label: '9 holes',
        value: 9
    },
    {
        label: '18 holes',
        value: 18
    }]);
    const [holeVal, setHoleVal] = useState({
        label: `${props?.roundData?.holes} holes`,
        value: props?.roundData?.holes
    });
    const [getToken, setToken] = useState('');
    const [showPopover, setShowPopover] = useState(false);
    const [holesNumberOptions, setholesNumberOptions] = useState([{
        label: 'Hole 1',
        value: 1
    },
    {
        label: 'Hole 2',
        value: 2
    },
    {
        label: 'Hole 3',
        value: 3
    },
    {
        label: 'Hole 4',
        value: 4
    },
    {
        label: 'Hole 5',
        value: 5
    },
    {
        label: 'Hole 6',
        value: 6
    },
    {
        label: 'Hole 7',
        value: 7
    },

    {
        label: 'Hole 8',
        value: 8
    },
    {
        label: 'Hole 9',
        value: 9
    }]);
    const [initialholesNumberOptions, setinitialholesNumberOptions] = useState([{
        label: 'Hole 1',
        value: 1
    },
    {
        label: 'Hole 2',
        value: 2
    },
    {
        label: 'Hole 3',
        value: 3
    },
    {
        label: 'Hole 4',
        value: 4
    },
    {
        label: 'Hole 5',
        value: 5
    },
    {
        label: 'Hole 6',
        value: 6
    },
    {
        label: 'Hole 7',
        value: 7
    },

    {
        label: 'Hole 8',
        value: 8
    },
    {
        label: 'Hole 9',
        value: 9
    }, {
        label: 'Hole 10',
        value: 10
    },
    {
        label: 'Hole 11',
        value: 11
    },
    {
        label: 'Hole 12',
        value: 12
    },
    {
        label: 'Hole 13',
        value: 13
    },
    {
        label: 'Hole 14',
        value: 14
    },
    {
        label: 'Hole 15',
        value: 15
    },
    {
        label: 'Hole 16',
        value: 16
    },

    {
        label: 'Hole 17',
        value: 17
    },
    {
        label: 'Hole 18',
        value: 18
    }]);
    const [holeNumVal, setHoleNumVal] = useState({
        label: `Hole ${props?.roundData?.start_hole_number}`,
        value: props?.roundData?.start_hole_number,
    });

    const [roundTypeOptions, setroundTypeOptions] = useState([{
        label: 'Front 9',
        value: 'F9'
    },
    {
        label: 'Back 9',
        value: 'B9'
    }]);
    const [roundTypeVal, setroundTypeVal] = useState({
        label: '',
        value: '',
    });
    let timeDisabled = false
    const timeDisabledResp = playingUsers?.filter((data) => (data?.is_tee_off === true && data?.is_organizer !== true));
    if (timeDisabledResp?.length > 0)
        timeDisabled = true;
    else
        timeDisabled = false;

    let dateDisabled = false
    const dateDisabledResp = playingUsers?.filter((data) => (data?.is_tee_off === true && data?.is_organizer !== true));
    if (dateDisabledResp?.length > 0)
        dateDisabled = true;
    else
        dateDisabled = false;

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    useEffect(() => {
        for (let i = 0; i < holesNumberOptions?.length; i++) {
            if (props?.roundData?.start_hole_number === holesNumberOptions[i]?.value) {
                setHoleNumVal({
                    label: holesNumberOptions[i]?.label,
                    value: holesNumberOptions[i]?.value
                })
            }
        }

        for (let i = 0; i < roundTypeOptions?.length; i++) {
            if (props?.roundData?.round_type === roundTypeOptions[i]?.value) {
                setroundTypeVal({
                    label: roundTypeOptions[i]?.label,
                    value: roundTypeOptions[i]?.value
                })
            }
        }

        for (let i = 0; i < stateOptions?.length; i++) {
            if (props?.roundData?.golf_course?.state_code === stateOptions[i]?.value) {
                setState(stateOptions[i]?.label);
                setStateVal({
                    label: stateOptions[i]?.label,
                    value: stateOptions[i]?.value
                })
            }
        }

    }, []);

    useEffect(() => {
        handleGhinLogin();
        // setholesNumberOptions(initialholesNumberOptions?.slice(0, initialholesNumberOptions?.length / 2))
    }, []);

    const handleHolesNumberDropdpwn = (event) => {
        setHoleNumVal(event);
        // Update formErrors for selectedOption
        const selectedOptionError = validateHolesNumberOption(event?.value);
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            'holeNumVal.label': selectedOptionError,
        }));
    }
    const handleStateDropdpwn = (event) => {
        setStateVal(event);
        setSelectedOption('');
    }
    const handleSelectOption = (option) => {
        setSelectedOption(option?.FullName);
        setSelectedGolfcourse(option);
        setIsDropdownOpen(false);
    };

    const handleRoundTypeDropdpwn = (event) => {
        setroundTypeVal(event);
        setHoleNumVal({
            label: '',
            value: ''
        })
        // Update formErrors for selectedOption
        const selectedOptionError = validateRoundTypeOption(event?.value);
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            'roundTypeVal.label': selectedOptionError,
        }));
        if (event?.value === 'F9') {
            setholesNumberOptions(initialholesNumberOptions?.slice(0, initialholesNumberOptions?.length / 2))
        }
        else {
            setholesNumberOptions(initialholesNumberOptions?.slice(initialholesNumberOptions?.length / 2, initialholesNumberOptions?.length))
        }
    }

    const validateRoundTypeOption = (value) => {
        if (!value) {
            return 'Round type is required';
        }
        return ''; // No error
    };
    const handleHolesDropdpwn = (event) => {
        setHoleVal(event);
        // Update formErrors for selectedOption
        const selectedOptionError = validateHolesOption(event?.value);
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            'holeVal.label': selectedOptionError,
        }));
    }

    const validateHolesOption = (value) => {
        if (!value) {
            return 'Hole numers are required';
        }
        return ''; // No error
    };
    const validateHolesNumberOption = (value) => {
        if (!value) {
            return 'Starting from Hole numbers are required';
        }
        return ''; // No error
    };
    const handleGhinLogin = async () => {
        let resp = await ghinLogin();
        setToken(resp?.token);
    }
    const handleInputChange = async (e) => {
        const inputValue = e.target.value.toLowerCase();
        setSelectedOption(inputValue);
        // setIsPlayerDropdownOpen(false);
        try {
            const filtered = await searchGolfCourses(getToken, inputValue, stateVal?.value);
            setFilteredOptions(filtered);
            setIsDropdownOpen(inputValue.length > 0);

            // Update formErrors for selectedOption
            const selectedOptionError = validateSelectedOption(inputValue);
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                selectedOption: selectedOptionError,
            }));
        }
        catch (error) {
            console.error('Error while searching golf courses:', error);
        }
    };

    const validateSelectedOption = (value) => {
        if (!value) {
            return 'Golf course is required';
        }
        return ''; // No error
    };
    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Popover Title</Popover.Title>
            <Popover.Content>
                This is the content of the popover.
            </Popover.Content>
        </Popover>
    );

    const handleUserImage = (data) => {
        if (data?.profile_pic === null && data?.id === null) {
            return svg.DefUser
        }
        if (data?.id !== null && data?.profile_pic !== null) {
            return data?.profile_pic
        }
        if (data?.id !== null && data?.profile_pic === null) {
            return svg.DefUser
        }
    }

    const handleDeletePlayer = async (data) => {
        const body = {
            id: props?.roundData?.id,
            user_id: data?.id
        }
        try {
            let resp = await deletePlayer(body);
            let temparr = [...playingUsers];
            let finalArr = temparr?.filter((option) => option?.id !== data?.id);
            for (let i = 0; i < temparr?.length; i++) {
                if (temparr[i]?.id === data?.id) {
                    finalArr?.push({
                        id: null,
                        name: null,
                        profile_pic: null,
                        is_tee_off: null,
                        is_organizer: null
                    })
                }
            }
            setPlayingUsers(finalArr);
            if (resp) {
                toast.success(resp?.message || 'Player deleted successfully');
                // navigate('/home');
            }
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'Error in deleting player');
        }
    }

    const handleTimeChange = (e) => {
        setTime(e?.target?.value);
        const dateError = validateTime(e?.target?.value);
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            getTime: dateError,
        }));
    }

    const validateTime = (value) => {
        if (!value) {
            return 'Time is required';
        }
        return ''; // No error
    };

    const validateDate = (value) => {
        if (!value) {
            return 'Date is required';
        }
        return ''; // No error
    };

    const handleDateChange = (e) => {
        setDate(e?.target?.value);
        const dateError = validateDate(e?.target?.value);
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            getDate: dateError,
        }));
    }

    const handleClose = () => {
        props.closeEditRound();
    }

    const handleInviteFriendsClose = () => {
        setShowInviteFriends(false);
    }

    const handleInviteFriends = () => {
        setShowInviteFriends(true);
    }

    const handlescheduleRound = async () => {
        try {
            await validationSchema.validate(
                {
                    selectedOption,
                    getDate,
                    getTime,
                    holeVal,
                    playingUsers,
                    holeNumVal,
                    roundTypeVal,
                },
                { abortEarly: false }
            );
            let arr = [];
            for (let i = 0; i < playingUsers?.length; i++) {
                if (playingUsers[i]?.id !== null) {
                    arr?.push(playingUsers[i]?.id);
                }
            }
            const body = {
                golf_course: {
                    "name": getSelectedGolfcourse?.name || getSelectedGolfcourse?.FullName,
                    "facility": getSelectedGolfcourse?.facility || getSelectedGolfcourse?.FacilityID,
                    "usga_id": getSelectedGolfcourse?.usga_id || getSelectedGolfcourse?.CourseID,
                    "address_line1": getSelectedGolfcourse?.address_line1 || getSelectedGolfcourse?.Address1,
                    "address_line2": getSelectedGolfcourse?.address_line2 || getSelectedGolfcourse?.Address2,
                    "longitude": Number(getSelectedGolfcourse?.longitude) || getSelectedGolfcourse?.GeoLocationLongitude,
                    "latitude": Number(getSelectedGolfcourse?.latitude) || getSelectedGolfcourse?.GeoLocationLatitude,
                    "city": getSelectedGolfcourse?.city || getSelectedGolfcourse?.City,
                    "state_code": getSelectedGolfcourse?.state_code || getSelectedGolfcourse?.State,
                    "country": getSelectedGolfcourse?.country || getSelectedGolfcourse?.Country,
                    "phone": getSelectedGolfcourse?.phone || getSelectedGolfcourse?.Telephone,
                    "active": getSelectedGolfcourse?.active || getSelectedGolfcourse?.CourseStatus,
                    "course_name": getSelectedGolfcourse?.course_name || getSelectedGolfcourse?.CourseName,
                },
                round_date: getDate,
                round_time: getTime,
                // players: arr,
                round_type: roundTypeVal?.value,
                start_hole_number: holeNumVal?.value
            }
            try {
                let resp = await updateRound(body, props?.roundData?.id);
                if (resp) {
                    toast.success('Round updated successfully');
                    window?.location?.reload();
                }
            }
            catch (err) {
                toast.error(err?.response?.data?.message || 'Error in updating a round');
            }
        }
        catch (error) {
            const errors = {};
            // if (error.path === 'holeVal.label' || error.path === 'holeVal.value') {
            //     errors.holeVal = error.message;
            // } else {
            //     // Handle validation errors for other fields
            //     error.inner.forEach((e) => {
            //         errors[e.path] = e.message;
            //     });
            // }
            setFormErrors(errors);
        }
    }

    return (
        <>
            {showInviteFriends === false &&
                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    className='edit-round-wrapper'
                    show={props.show}
                    onHide={handleClose}
                    dialogClassName={getwidth < mobileBreakpoint && "modal-from-bottom"} // Apply the custom CSS class
                    animation={false} // Disable built-in animations
                    centered={getwidth < mobileBreakpoint ? false : true}
                >
                    <Modal.Header closeButton closeButtonClassName="custom-close-icon">
                        <Modal.Title className="modalTitle">Edit Round</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modalBody">
                        <div className='schedule-round-body w-100'>
                            <div className='holes-dropdown w-100 marginTop15'>
                                <div className='search-golf-course-label'>State</div>
                                <div className='state-dropdown-body mt-2'>
                                    <Select
                                        options={stateOptions}
                                        value={stateVal}
                                        placeholder={
                                            <div className="select-placeholder-text">Select</div>
                                        }
                                        onChange={handleStateDropdpwn}
                                        className="w-100 holes-select-dropdown"
                                        components={{
                                            Option: (props) => (
                                                <div
                                                    {...props.innerProps}
                                                    className={'holes-select-item'}
                                                >
                                                    {props.children}
                                                </div>
                                            ),
                                        }}
                                    />
                                    {formErrors['holeVal.label'] && <div className="error text-danger-txt">{formErrors['holeVal.label']}</div>}
                                </div>
                            </div>

                            <div className='search-golf-course-label mt-4'>Where are you playing?</div>
                            <Dropdown className='mt-2'>
                                <InputGroup>
                                    <>{selectedOption === '' &&
                                        <span className="search-icon">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.0625 15.625C12.6869 15.625 15.625 12.6869 15.625 9.0625C15.625 5.43813 12.6869 2.5 9.0625 2.5C5.43813 2.5 2.5 5.43813 2.5 9.0625C2.5 12.6869 5.43813 15.625 9.0625 15.625Z" stroke="#4A514C" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.7026 13.7031L17.4996 17.5" stroke="#4A514C" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </span>
                                    }</>
                                    <input
                                        type="text"
                                        value={selectedOption}
                                        className="SearchLabel searchUsers w-100"
                                        style={{
                                            padding: selectedOption === '' ? '10px 10px 10px 40px' : '10px 10px 10px 10px', '::placeholder': { color: '#565957', fontSize: '16px', fontWeight: '400' },
                                        }}
                                        onChange={handleInputChange}
                                        placeholder="Search for a golf course...."
                                    />
                                </InputGroup>
                                {formErrors?.selectedOption && <div className="error text-danger-txt">{formErrors?.selectedOption}</div>}
                                <Dropdown.Menu show={isDropdownOpen} className='users-dropdown' style={{ display: isDropdownOpen ? 'block' : 'none' }}>
                                    {filteredOptions?.map((option, index) => (
                                        <Dropdown.Item
                                            key={index}
                                            onClick={() => handleSelectOption(option)}
                                        >
                                            {/* {option} */}
                                            <div className="user-name">{option.FullName}</div>
                                            <div className="user-address">{option.City}, {option?.Country}</div>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>

                            <div className='round-date-time w-100 marginTop15'>
                                <div className='search-golf-course-label'>When is your tee time?</div>
                                <div className='round-date-time-body d-flex mt-2'>
                                    <input className="form-control startDate" type="date" min={new Date()?.toISOString()?.split('T')[0]} onChange={handleDateChange} value={getDate} pattern="\d{1,2}\/\d{1,2}\/\d{2,4}" disabled={dateDisabled} />
                                    <input className="form-control startDate" type="time" onChange={
                                        handleTimeChange} value={getTime} step="60" disabled={timeDisabled} />
                                </div>

                                <div className='round-date-time-body d-flex'>
                                    {formErrors?.getDate && <div className="error text-danger-txt w-50">{formErrors?.getDate}</div>}
                                    {formErrors?.getTime && <div className="error text-danger-txt w-50 errorMsg">{formErrors?.getTime}</div>}
                                </div>
                            </div>

                            <div className='holes-dropdown w-100 marginTop15'>
                                <div className='search-golf-course-label'>How many holes did you schedule?</div>

                                <div className='holes-dropdown-body mt-2'>
                                    <Select
                                        isDisabled={true}
                                        options={holesOptions}
                                        value={holeVal}
                                        placeholder={
                                            <div className="select-placeholder-text">Select</div>
                                        }
                                        onChange={handleHolesDropdpwn}
                                        className="w-100 holes-select-dropdown"
                                        components={{
                                            Option: (props) => (
                                                <div
                                                    {...props.innerProps}
                                                    className={'holes-select-item'}
                                                >
                                                    {props.children}
                                                </div>
                                            ),
                                        }}
                                    />
                                    {formErrors['holeVal.label'] && <div className="error text-danger-txt">{formErrors['holeVal.label']}</div>}
                                </div>
                            </div>

                            <div className='round-date-time-body d-flex marginTop15'>
                                <div className='search-golf-course-label w-50'>Round Type</div>
                                <div className='search-golf-course-label w-50'>Starting from</div>
                            </div>

                            <div className='holes-dropdown w-100 mt-1'>
                                <div className='round-date-time-body d-flex'>
                                    <Select
                                        options={roundTypeOptions}
                                        value={roundTypeVal}
                                        placeholder={
                                            <div className="select-placeholder-text">Select</div>
                                        }
                                        onChange={handleRoundTypeDropdpwn}
                                        className="w-100 holes-select-dropdown"
                                        components={{
                                            Option: (props) => (
                                                <div
                                                    {...props.innerProps}
                                                    className={'holes-select-item'}
                                                >
                                                    {props.children}
                                                </div>
                                            ),
                                        }}
                                    />

                                    <Select
                                        options={holesNumberOptions}
                                        value={holeNumVal}
                                        placeholder={
                                            <div className="select-placeholder-text">Select</div>
                                        }
                                        onChange={handleHolesNumberDropdpwn}
                                        className="w-100 holes-select-dropdown"
                                        components={{
                                            Option: (props) => (
                                                <div
                                                    {...props.innerProps}
                                                    className={'holes-select-item'}
                                                >
                                                    {props.children}
                                                </div>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='round-date-time-body d-flex justify-between'>
                                {formErrors['holeNumVal.label'] && <div className="error text-danger-txt w-50">{formErrors['holeNumVal.label']}</div>}
                                {formErrors['roundTypeVal.label'] && <div className="error text-danger-txt w-50 errorMsg">{formErrors['roundTypeVal.label']}</div>}
                            </div>


                            <div className='playing-users w-100 marginTop15'>
                                <div className='search-golf-course-label'>Who are you playing with?</div>
                                <div>
                                    <BottomTooltip tooltipText="Your friends can join you even if they compete in a different tier! Hit the Linx™ together, just like you always have!" />
                                </div>
                            </div>

                            <div className='playing-users-listing w-100 marginTop15'>
                                {[...Array(4)].map((_, index) => {
                                    const playerData = playingUsers[index];
                                    return (
                                        <div className='playing-user-info d-flex flex-column align-item-center' key={index}>
                                            <div className='playing-user-pic'>
                                                {playerData?.id !== null ? (
                                                    <>
                                                        <img src={handleUserImage(playerData)} className={playerData?.is_organizer === true ? 'player-img activeUser' : 'player-img'} alt='Player Imdage' />
                                                        {
                                                            (index !== 0 && playerData?.is_tee_off === false) &&
                                                            <img src={svg?.DeletePlayer} className='del-player-img' alt="del-player-np" onClick={() => handleDeletePlayer(playerData)} />
                                                        }
                                                    </>
                                                ) : (
                                                    <img src={svg?.AddPlayer} className='add-player' alt='Add Player' />
                                                )}
                                            </div>
                                            <div className='player-name trimTxt1line'>{playerData?.name || `Player ${index + 1}`}</div>
                                        </div>
                                    );
                                })}
                            </div>

                        </div>
                        <div className='del-buttons-wrapper w-100'>
                            {/* <div className='or-txt'>Or</div> */}
                            <Button className="cancelBtn w-50" onClick={() => { handleInviteFriends() }
                            }>
                                Invite Friends
                            </Button>
                            <Button
                                onClick={() => handlescheduleRound()}
                                className="delBtn w-50">
                                Confirm
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal >
            }
            {
                showInviteFriends === true &&
                <Invitefriends show={showInviteFriends} handleClose={handleInviteFriendsClose} />
            }
        </>
    );
};

export default EditRound;
