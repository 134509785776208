import axios from "axios"
import { APP_JSON_HEADER, BASE_URL, successStatusCode } from "./config"

import EndPoints from "./endPoints"

const GET = "GET"
const POST = "POST"
const PUT = "PUT"
const DELETE = "DELETE"
const PATCH = "PATCH"

export async function request(
  endPoint,
  method,
  body,
  passToken = true,
  formDataParam = "false"
) {
  let APIURL = BASE_URL + endPoint
  const header = await APP_JSON_HEADER(passToken, formDataParam)

  return await axios({
    method: method,
    url: APIURL,
    data: body,
    headers: header
  })
    .then(function (response) {
      if (successStatusCode.includes(response?.status)) return response?.data
    })
    .catch(error => {
      console.log(error);
      throw error;
    })
}

export async function login(params) {
  return request(EndPoints.login, POST, params, false);
}

export async function signup(params, referCode) {
  if (referCode) {
    return request(`${EndPoints.signUp}?refer_code=${referCode}`, POST, params, false)
  }
  return request(EndPoints.signUp, POST, params)
}

export async function forgotPass(params) {
  return request(EndPoints.forgotPassword, POST, params, false)
}

export async function resetPass(params) {
  return request(EndPoints.resetPass, POST, params, false)
}

export async function logout(params) {
  return request(EndPoints.logout, POST, params)
}

export async function changePassword(params) {
  return request(EndPoints.changePassword, POST, params)
}

export async function deleteAccount(params) {
  return request(`${EndPoints.deleteAccount}${params.user_id}/`, DELETE, params)
}

export async function privacyPolicy(params) {
  return request(EndPoints.privacyPolicy, GET, params, false);
}

export async function termsConditions(params) {
  return request(EndPoints.termsConditions, GET, params, false);
}

export async function sendFeedback(params) {
  return request(EndPoints.sendFeedback, POST, params);
}

export async function manageNotifications(params, userId) {
  return request(`${EndPoints.manageNotifications}${userId}/`, PATCH, params);
}

export async function getUserProfile(params) {
  return request(EndPoints.getUserProfile, GET, params);
}

export async function profileCreation(params, userId) {
  return request(`${EndPoints.profile}${userId}/`, PATCH, params);
}

export async function profileCreationUpload(params, userId) {
  return request(`${EndPoints.profile}${userId}/`, PATCH, params, true, "true");
}

export async function inviteFriends(params) {
  return request(`${EndPoints.inviteFriends}`, POST, params);
}

export async function fetchAdminGhin(params) {
  return request(`${EndPoints.adminAppUsersGhin}`, GET, params);
}

export async function fetchAdminUsers(currentPageIndex = 0, gender = '', handicap = '', search = '') {
  return request(`${EndPoints.adminAppusers}?page=${currentPageIndex}&gender=${gender}&ghin_score=${handicap}&search=${search}`, GET);
}

export async function fetchLeagueRanking(currentPageIndex = 0, season = '', tier = '') {
  if(currentPageIndex==='n/a'){
    return request(`${EndPoints.adminLeagueRanking}?season=${season}&tier=${tier}`, GET);
  }
  return request(`${EndPoints.adminLeagueRanking}?page=${currentPageIndex}&season=${season}&tier=${tier}`, GET);
}

export async function moveUsers(users) {
  return request(EndPoints?.moveUsers, POST, users);
}

export async function fetchGenderAdminUsers(gender = '', handicap = '', search = '') {
  if (gender === 'ALL') {
    return request(`${EndPoints.adminAppusers}?ghin_score=${handicap}&search=${search}`, GET);
  }
  return request(`${EndPoints.adminAppusers}?gender=${gender}&ghin_score=${handicap}&search=${search}`, GET);
}

export async function fetchSpecificuser(params) {
  return request(`${EndPoints.adminAppusers}${params}/`, GET, params);
}

export async function deleteSpecificUser(params) {
  return request(`${EndPoints.adminAppusers}${params}/`, DELETE, params);
}

export async function getadminFeedback(params) {
  return request(`${EndPoints.adminFeedback}`, GET, params);
}

export async function sendadminFeedback(params, body) {
  return request(`${EndPoints.adminFeedback}${params}/`, PATCH, body);
}

export async function fetchBasicUserInfo(params) {
  return request(`${EndPoints.getUserProfile}`, GET, params);
}

export async function updateUserProfile(params, id) {
  return request(`${EndPoints?.updateUserProfile}${id}/`, PATCH, params);
}

export async function changePhoto(params, id) {
  return request(`${EndPoints?.updateUserProfile}${id}/`, PUT, params, true, "true");
}

export async function fbSocialLogin(params) {
  return request(EndPoints?.fbsociallogin, POST, params, false)
}

export async function getContentList(params) {
  return request(EndPoints?.getAdContent, GET, params)
}

export async function createContent(params) {
  return request(EndPoints?.getAdContent, POST, params, true, "true")
}

export async function removeAd(params) {
  return request(`${EndPoints?.getAdContent}${params?.id}/`, DELETE, params);
}

export async function editAd(params, id) {
  return request(`${EndPoints?.getAdContent}${id}/`, PATCH, params, true, "true");
}

export async function manageSubscriptions(params) {
  return request(EndPoints?.manageSubscriptions, GET, params);
}

export async function updateSubscriptions(params) {
  return request(EndPoints?.updateManageSubscriptions, PATCH, params);
}

export async function createLeague(params) {
  return request(EndPoints?.leagues, POST, params, true, "true");
}

export async function editLeague(params, id) {
  return request(`${EndPoints?.leagues}${id}/`, PATCH, params, true, "true");
}

export async function getLeagues(currentPageIndex = 0, gender = '', status = '', search = '') {
  if (status !== 4) {
    return request(`${EndPoints?.leagues}?page=${currentPageIndex}&gender=${gender}&status=${status}&search=${search}`, GET);
  }
  return request(`${EndPoints?.leagues}?page=${currentPageIndex}&gender=${gender}&search=${search}`, GET);
}

export async function deleteLeague(params, id) {
  return request(`${EndPoints?.leagues}${id}/`, DELETE, params);
}

export async function deleteTier(params, id) {
  return request(`${EndPoints?.deleteTier}${id}/`, DELETE, params);
}

export async function editTier(params, id) {
  return request(`${EndPoints?.deleteTier}${id}/`, PATCH, params);
}

export async function deleteSeason(params, id) {
  return request(`${EndPoints?.adminSeason}${id}/`, DELETE, params);
}

export async function createSeason(params, id) {
  return request(`${EndPoints?.leagues}${id}/create-seasons/`, POST, params);
}

export async function editSeason(params, id) {
  return request(`${EndPoints?.adminSeason}${id}/`, PATCH, params);
}

export async function createTiers(params, id) {
  return request(`${EndPoints?.admintiers}${id}/create-tiers/`, POST, params);
}

export async function fetchFilteredLeaguesData(gender = '', status = '', search = '') {
  if (status !== 4 && gender !== '4') {
    return request(`${EndPoints.leagues}?gender=${gender}&status=${status}&search=${search}`, GET);
  }
  if (status === 4 && gender === '4') {
    return request(`${EndPoints.leagues}&search=${search}`, GET);
  }
  if (status === 4) {
    return request(`${EndPoints.leagues}?gender=${gender}&search=${search}`, GET);
  }
  if (gender === '4') {
    return request(`${EndPoints.leagues}?status=${status}&search=${search}`, GET);
  }
  // return request(`${EndPoints.leagues}?gender=${gender}&page=${currentPageIndex}&search=${search}`, GET);
}

export async function fetchAllSeasons(params) {
  return request(`${EndPoints?.leagues}${params?.id}/all-seasons/`, GET, params);
}

export async function fetchFilteredSeasonData(params) {
  return request(`${EndPoints?.adminSeason}${params}/`, GET, params);
}

export async function fetchAllTiers(params) {
  return request(`${EndPoints?.adminSeason}${params}/all-tiers/`, GET, params);
}

export async function fetchSpecificLeague(params) {
  return request(`${EndPoints?.leagues}${params}/`, GET, params);
}

export async function fetchRecLeaguesBanner(params) {
  return request(`${EndPoints?.getUserAdContent}?page=2`, GET, params);
}

export function fetchRecommendedLeagues(params) {
  return request(`${EndPoints?.recommendedLeagues}`, GET, params);
}

export function fetchSubscriptionData(params) {
  return request(`${EndPoints?.subscription}`, GET, params);
}

export function fetchExploreLeagues(leaguetype = '', search = '') {
  if (leaguetype === '3') {
    return request(`${EndPoints.exploreLeagues}?search=${search}`, GET);
  }
  return request(`${EndPoints.exploreLeagues}?league__type=${leaguetype}&search=${search}`, GET);
}

export function confirmPayment(params) {
  return request(`${EndPoints?.confirmPayment}`, POST, params);
}

export function fetchCardDetails(params) {
  return request(EndPoints?.savedCardDetails, GET, params);
}

export function fetchGetPaymentMethods(params) {
  return request(EndPoints?.getPaymentMethods, GET, params);
}

export function joinLeague(params) {
  return request(EndPoints?.joinleague, POST, params);
}

export function fetchAllLeagues(params) {
  return request(EndPoints?.leagues, GET, params);
}

export function fetchSelLeagueSeason(params) {
  return request(`${EndPoints?.leagues}${params?.id}/all-seasons/`, GET, params);
}

export async function fetchSelSeasonTiers(params) {
  return request(`${EndPoints?.adminSeason}${params}/all-tiers/`, GET, params);
}

export function fetchSavedCardDetails(params) {
  return request(EndPoints?.savedCard, GET, params);
}

export function getMyLeagues(params) {
  return request(EndPoints?.myLeagues, GET, params);
}

export function fetchPastRounds(params) {
  return request(EndPoints?.pastRound, GET, params);
}

export function fetchSinglePastRound(params) {
  return request(`${EndPoints?.pastRound}${params?.id}/`, GET, params);
}

export function fetchUpcomingRounds(params) {
  return request(EndPoints?.upComingRound, GET, params);
}

export function fetchSingleUpcomingRound(params) {
  return request(`${EndPoints?.upComingRound}${params?.id}/`, GET, params);
}

export function fetchRoundUsers(params) {
  return request(`${EndPoints?.searchRoundUser}?search=${params}`, GET, params);
}

export function scheduleRounds(params) {
  return request(EndPoints?.scheduleRounds, POST, params);
}

export function updateRound(params, roundId) {
  return request(`${EndPoints.scheduleRounds}${roundId}/`, PATCH, params);
}

export function deleteRound(params) {
  return request(`${EndPoints.scheduleRounds}${params}/`, DELETE, params);
}

export function deletePlayer(params) {
  return request(`${EndPoints.scheduleRounds}${params?.id}/remove-user/${params?.user_id}/`, PATCH, params);
}

export function fetchUserNotifications(params) {
  return request(EndPoints?.userNotifications, GET, params);
}

export function readNotifications(params) {
  return request(EndPoints?.readNotifications, GET, params);
}

export function acceptRejectNotification(params) {
  return request(`${EndPoints?.acceptRejectNotification}${params?.id}/invitation/${params?.action}/`, PATCH, params);
}

export function reportUser(params) {
  return request(EndPoints?.reportUser, POST, params);
}

export function fetchOtherUserProfile(params) {
  return request(`${EndPoints?.otherUserProfile}${params}/`, GET, params);
}

export function managePaymentHistory(params) {
  return request(EndPoints?.paymentHistory, GET, params);
}

export function googleSocialLogin(params) {
  return request(EndPoints?.googleLogin, POST, params);
}

export function facebookSocialLogin(params) {
  return request(EndPoints?.facebookLogin, POST, params);
}

export function appleSocialLogin(params) {
  return request(EndPoints?.appleLogin, POST, params);
}

export function removeUserFlag(params) {
  return request(`${EndPoints?.removeFlag}${params}/remove-flag/`, PATCH, params);
}

export function addGhin(params) {
  return request(`${EndPoints?.ghin}`, POST, params);
}

export function roundTeeOff(params, roundId) {
  return request(`${EndPoints?.scheduleRounds}${roundId}/tee-off/`, POST, params);
}

export function showScoreData(params) {
  return request(`${EndPoints?.scheduleRounds}${params}/scorecard/`, GET, params);
}

export function addScore(params, roundId) {
  return request(`${EndPoints?.scheduleRounds}${roundId}/add-score/`, POST, params);
}

export function resendLink(params) {
  return request(`${EndPoints?.resendLink}`, POST, params, false);
}

export function userStandings(params) {
  return request(EndPoints?.standings, GET, params);
}

export function finishRound(params) {
  return request(`${EndPoints?.scheduleRounds}${params}/finish/`, POST, params);
}

export function fetchStandingsLeagues(params) {
  return request(EndPoints?.standingLeagues, GET, params);
}


export function fetchStandingsSeasons(leagueId) {
  return request(`${EndPoints?.standingLeagues}${leagueId}/seasons/`, GET);
}

export function fetchStandingsHistory(leagueId = '', seasonId = '', search = '') {
  return request(`${EndPoints?.standingHistory}?search=${search}&league=${leagueId}&season=${seasonId}`)
}

export function addPaymentMethod(params) {
  return request(EndPoints?.paymentMethod, POST, params);
}

export function deletePaymentMethod(params) {
  return request(EndPoints?.paymentMethod, DELETE, params);
}

export function fetchRecLeaguesPagination(page) {
  return request(`${EndPoints?.recommendedLeaguesWithPagination}?page=${page}`, GET);
}