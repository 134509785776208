import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import svg from '../../../assets/images/svg/index';
import Select from 'react-select';
import { Button, Col, Row } from 'react-bootstrap';
import { formatDate } from '../../../utils/formatDate.js';
import { fetchAllSeasons, fetchFilteredSeasonData, fetchSelSeasonTiers, fetchSpecificLeague } from '../../../api/request';
import DeleteLeague from "../DeleteLeague";
import ErrorMsg from '../../../CustomComponents/ErrorMsg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../CustomComponents/Loader';
import './styles.scss';
import LeagueRanking from "../LeagueRanking";

const SpecificLeague = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let leagueDataTemp = location?.state?.league;
    const [leagueData, setleagueData] = useState({});
    const [leagueType, setLeagueType] = useState(1);
    const [getAllSeasons, setAllSeasons] = useState([]);
    const [allTiers, setAllTiers] = useState([]);
    const [showNoTiers, setNoTiers] = useState(false);
    const [showNoSeason, setNoSeason] = useState(false);
    const [filteredSeason, setFilteredSeason] = useState({});
    const [seasonOptions, setseasonOptions] = useState([]);
    const [seasonVal, setseasonVal] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAllTiers, setShowAllTiers] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [showId, setId] = useState(null);
    const [deleteName, setdeleteName] = useState('');

    const handleDeleteLeague = (data, name) => {
        setShowDeletePopup(true);
        setId(data)
        setdeleteName(name);
    }

    const handleDeletePopup = () => {
        setShowDeletePopup(false);
    }

    const toggleViewAll = () => {
        setShowAllTiers(!showAllTiers);
    };

    useEffect(() => {
        fetchAllSeasonsdata();
        fetchLeagueData();
    }, []);

    const fetchLeagueData = async () => {
        try {
            let resp = await fetchSpecificLeague(leagueDataTemp?.id);
            if (resp) {
                setleagueData(resp);
                setLeagueType(resp?.type);
            }
        }
        catch (err) {
            if (err?.response?.data?.detail === "Invalid token.") {
                navigate('/admin/login');
            }
        }
    }
    const fetchAllSeasonsdata = async () => {
        try {
            let resp = await fetchAllSeasons(leagueDataTemp);
            let tempdata = resp;
            let seasonOption = [];
            for (let i = 0; i < resp?.length; i++) {
                seasonOption?.push({
                    label: `${resp[i]?.name}- ${formatDate(resp[i]?.start_date)}`,
                    value: resp[i]?.id
                })
            }

            if (resp?.length > 0) {
                fetchAllTiersData(resp[0]?.id);
            }
            if (tempdata?.length > 0) {
                setNoSeason(false);
            }
            else {
                setNoSeason(true);
            }
            setseasonOptions(seasonOption);
            setAllSeasons(resp);
            setFilteredSeason(resp[0]);
            setseasonVal({
                label: `${resp[0]?.name}- ${formatDate(resp[0]?.end_date)}`,
                value: resp[0]?.id
            })
        }
        catch (err) {
            // toast.error(err?.response?.data?.message || 'Error in processing your request');
            if (err?.response?.data?.detail === "Invalid token.") {
                navigate('/admin/login');
            }
        }
    }

    const fetchAllTiersData = async (id) => {
        try {
            let resp = await fetchSelSeasonTiers(id);
            setAllTiers(resp);
            if (resp?.length === 0) {
                setNoTiers(true);
            }
            else {
                setNoTiers(false);
            }
        }
        catch (err) {
            // toast.error(err?.response?.data?.message || 'Error in fetching tiers');
        }
    }

    const handleSeasonDropdown = (event) => {
        setseasonVal(event);
        fetchFilteredData(event?.value);
    }

    const fetchFilteredData = async (season) => {
        try {
            // setLoading(true);
            let resp = await fetchFilteredSeasonData(season);
            if (resp) {
                setAllSeasons(resp);
                setFilteredSeason(resp);
                fetchAllTiersData(resp?.id);
                // setLoading(false);
            }
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'Error in fetching season');
            // setLoading(false);
        }
    }

    const displayGender = (genderData) => {
        if (genderData === 1) {
            return 'Male'
        }
        if (genderData === 2) {
            return 'Female'
        }
        if (genderData === 3) {
            return 'Both'
        }
    }

    const displayStatus = (data) => {
        if (data === 1) {
            return 'Planned'
        }
        if (data === 2) {
            return 'Active'
        }
        if (data === 3) {
            return 'Completed'
        }
    }

    const displayType = (data) => {
        if (data === 1) {
            return 'Competitive'
        }
        else if (data === 2) {
            return 'Recreational'
        }
    }

    return (
        <>
            {loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" /> :
                <div className="specific-league-wrapper adminpadding">
                    <div className="specific-league-heading-wrapper d-flex align-items-center justify-content-between">
                        <div className="specific-league-heading d-flex justify-content-center align-items-center">
                            <img src={svg?.SeasonBack} className="back-arrow cursor-pointer" alt="back NP" onClick={() => navigate(-1)} />
                            <div className="heading-txt">{leagueData?.name}</div>
                        </div>

                        <div className="specific-league-revenue">
                            <div className="revenue-txt">Revenue: ${leagueData?.revenue}</div>
                        </div>
                    </div>

                    <div className="specific-league-body d-flex justify-content-between align-items-center">
                        <div className="league-body-wrapper">
                            <div className="league-body-header d-flex justify-content-between">
                                <div className="league-txt">General</div>

                                <div className="tier-edit-del d-flex justify-content-center" style={{ gap: '10px' }}>
                                    <img src={svg?.PlusIcon} className="league-edit cursor-pointer" onClick={() => navigate('/admin/dashboard/create-league')} alt="" />
                                    <img src={svg?.Pencil} className="edit-tier cursor-pointer" alt="edit NP" onClick={() => navigate('/admin/dashboard/edit-league', {
                                        state: {
                                            leaguedata: leagueData
                                        }
                                    })} />
                                    <img src={svg?.Trash} onClick={() => handleDeleteLeague(leagueData?.id, 'League')} className="edit-tier cursor-pointer" alt="edit NP" />
                                </div>
                            </div>
                            <div className="league-body">
                                <div className="league-name">{leagueData?.name}</div>
                                <div className="league-desc trimTxt2lines">{leagueData?.description}</div>
                                <div className="league-data">
                                    <div className="league-data-txt">
                                        <div className="gender">Gender</div>
                                        <div className="gender-txt">{displayGender(leagueData?.gender)}</div>
                                    </div>
                                    <div className="league-data-txt">
                                        <div className="gender">Status</div>
                                        <div className="gender-txt">{displayStatus(leagueData?.status)}</div>
                                    </div>
                                    <div className="league-data-txt">
                                        <div className="gender">Type</div>
                                        <div className="gender-txt">{displayType(leagueData?.type)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="league-body-wrapper p-4">
                            <div className="league-body-header d-flex justify-content-between">
                                <div className="league-txt">Season</div>
                                <div className="edit-add-season d-flex justify-content-between align-items-center">
                                    <img src={svg?.PlusIcon} className="league-edit cursor-pointer" onClick={() => navigate('/admin/dashboard/create-league/create-season', {
                                        state: { leagueData: leagueData }
                                    })} alt="" />

                                    <>{!showNoSeason &&
                                        <div className="tier-edit-del d-flex justify-content-center" style={{ gap: '10px' }}>
                                            <img src={svg?.Pencil} className="edit-tier cursor-pointer" alt="edit NP" onClick={() => navigate('/admin/dashboard/create-league/edit-season', {
                                                state: {
                                                    seasonData: filteredSeason,
                                                    leagueData: leagueData
                                                }
                                            })} />
                                            <img src={svg?.Trash} onClick={() => handleDeleteLeague(filteredSeason?.id, 'Season')} className="edit-tier cursor-pointer" alt="edit NP" />
                                        </div>
                                    }</>
                                </div>
                            </div>

                            <div className="league-body season-body">
                                {showNoSeason ?
                                    <ErrorMsg errormsg={'No Records Exist'} />
                                    :
                                    <>
                                        <Select
                                            options={seasonOptions}
                                            value={seasonVal}
                                            placeholder={
                                                <div className="select-placeholder-text">Season</div>
                                            }
                                            onChange={handleSeasonDropdown}
                                            className="w-100 gender-dropdown"
                                        />
                                        <div className="league-desc trimTxt2lines">{filteredSeason?.description}</div>
                                        <div className="league-data">
                                            <div className="league-data-txt">
                                                <div className="gender">Start</div>
                                                <div className="gender-txt">{formatDate(filteredSeason?.start_date)}</div>
                                            </div>
                                            <div className="league-data-txt">
                                                <div className="gender">End</div>
                                                <div className="gender-txt">{formatDate(filteredSeason?.end_date)}</div>
                                            </div>
                                            <div className="league-data-txt">
                                                <div className="gender">Credits</div>
                                                <div className="gender-txt">{filteredSeason?.price}</div>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                    </div>

                    <>{filteredSeason &&
                        <div className="tier-body-wrapper specific-league-body d-flex justify-content-between align-items-center">
                            <div className="tier-body p-4">
                                <div className="tier-body-header d-flex justify-content-between">
                                    <div className="league-txt">Tiers</div>

                                    <img src={svg?.PlusIcon} className="league-edit" onClick={() => {
                                        let seasonDataArr = [];
                                        const newSeasonDataArr = seasonDataArr.concat(filteredSeason); // Create a copy of the array with the new element added
                                        navigate('/admin/dashboard/create-league/create-season/create-tiers', {
                                            state: { seasonData: newSeasonDataArr, leagueData: leagueData }
                                        });
                                    }} alt="" />

                                </div>

                                <div className={showAllTiers === true && allTiers?.length > 0 ? "all-tiers-wrapper mt-4 all-tiers-wrapper-show" : "all-tiers-wrapper mt-4"}>
                                    {showNoTiers === false ?
                                        <>
                                            {allTiers?.map((data, index) => {
                                                if (!showAllTiers && index > 2) {
                                                    return null; // Skip rendering when index is 2
                                                }

                                                return (
                                                    <div className={showAllTiers === true ? "tier-body-cont w-100" : "tier-body-cont"} key={index} xs={4} md={4}>
                                                        <div className="tier-name-cont d-flex justify-content-between align-items-center">
                                                            <div className="tier-name">
                                                                {data?.name}
                                                            </div>
                                                            <div className="tier-edit-del d-flex justify-content-center" style={{ gap: '10px' }}>
                                                                <img src={svg?.Pencil} className="edit-tier cursor-pointer" alt="edit NP" onClick={() => {
                                                                    navigate('/admin/dashboard/edit-tier', {
                                                                        state: {
                                                                            tierData: data,
                                                                            leagueData: leagueDataTemp
                                                                        }
                                                                    })
                                                                }} />
                                                                <img src={svg?.Trash} onClick={() => handleDeleteLeague(data?.id, 'Tier')} className="edit-tier cursor-pointer" alt="edit NP" />
                                                            </div>
                                                        </div>

                                                        <div className="tier-body-data d-flex flex-column mt-2">
                                                            <div className="tier-des trimTxt2lines">{data?.description}</div>

                                                            <div className="tier-body-row1 d-flex">
                                                                <div className="tier-filled-slots">Filled slots: 45</div>
                                                                <div className="tier-filled-slots">Max slots: {data?.max_slots}</div>
                                                            </div>

                                                            <div className="tier-body-row1 d-flex">
                                                                <div className="tier-filled-slots">Handicap range: {leagueType === 2 ? 'NA' : <>{data?.handicap_from}-{data?.handicap_to}</>
                                                                }
                                                                </div>
                                                                <div className="tier-filled-slots">Prize value: ${data?.prize}</div>
                                                            </div>

                                                            <div className="tier-des trimTxt2lines">{data?.prize_description}</div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                        : <ErrorMsg errormsg={'No Records Exist'} />
                                    }

                                </div>

                                <>{allTiers?.length > 3 &&
                                    <div className="view-all-tiers mt-3" onClick={toggleViewAll}>
                                        {
                                            showAllTiers === true ? 'Hide' : 'View All'
                                        }
                                    </div>
                                }</>
                            </div>
                        </div>
                    }</>

                    <LeagueRanking leagueId={leagueDataTemp?.id} />
                </div>
            }
            {
                showDeletePopup === true && <DeleteLeague name={deleteName} id={showId} show={showDeletePopup} handleDelClose={handleDeletePopup} />
            }
        </>
    )
}

export default SpecificLeague;