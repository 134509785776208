import React, { useEffect, useState } from "react";
import svg from '../../assets/images/svg/index';
import Navbar from "../../CustomComponents/Navbar";
import { Button, Carousel } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';
import { convertTimeToHoursMinutes } from '../../utils/convertTimeToHoursMinutes.js';
import { showScoreData, addScore, finishRound } from '../../api/request';
import Loader from '../../CustomComponents/Loader';
import ConfirmScore from './ConfirmScore.jsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

const Scorecard = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const roundIdFromLocation = location?.state?.roundId;
    const [maxTeeAccuracy, setMaxTeeAccuracy] = useState('');
    const [carouselData, setCarouselData] = useState([]);
    const [areaClickedFlag, setareaClickedFlag] = useState(true);
    const [scoreData, setScoreData] = useState([]);
    const [newScoreData, setNewScoreData] = useState([]);
    const [holeStats, setHoleStats] = useState({});
    const [showAddScorePlayersData, setAddScorePlayersData] = useState([]);
    const [playerScores, setPlayerScores] = useState([]);
    const [playerPutts, setPlayerPutts] = useState([]);
    const [selectedAreas, setSelectedAreas] = useState('');
    const [roundId, setRoundId] = useState(roundIdFromLocation || '');
    const [Loading, setLoading] = useState(false);
    const [selectedCarouselIndex, setselectedCarouselIndex] = useState(0);
    const [showConfirmScorePopup, setshowConfirmScorePopup] = useState(false);

    useEffect(() => {
        fetchScoreData();
    }, []);

    useEffect(() => {
        if (roundId) {
            localStorage.setItem('roundId', roundId);
        }
    }, [roundId]);

    useEffect(() => {
        if (selectedCarouselIndex > 0) {
            localStorage.setItem('holeIndex', selectedCarouselIndex);
        }
    }, [selectedCarouselIndex])

    // Function to handle page reloads by checking localStorage
    const handlePageReload = () => {
        const storedRoundId = localStorage.getItem('roundId');
        const storedHoleIndex = localStorage.getItem('holeIndex');
        if (storedRoundId) {
            setRoundId(storedRoundId);
        }
        if (storedHoleIndex) {
            setselectedCarouselIndex(parseInt(storedHoleIndex)); // Parse and set the holeIndex
        }
    };

    const scoreHandling = (data) => {

        if (data?.is_submitted === false) {
            return <div className="hole-val">-</div>
        }
        if (data?.score === data?.par - 1) {
            return <div className="hole-val" style={{
                border: '0.6px solid #0F100F',
                borderRadius: '50%',
            }}>{data?.score}</div>
        }
        if (data?.score === data?.par) {
            return <div className="hole-val">{data?.score}</div>
        }
        if (data?.score === data?.par + 1) {
            return <div className="hole-val" style={{
                border: '0.6px solid #0F100F'
            }}>{data?.score}</div>
        }
        if (data?.score === data?.par + 2) {
            return <div className="hole-val" style={{
                border: '3px double #0F100F'
            }}>{data?.score}</div>
        }
        if (data?.score <= data?.par - 2) {
            return <div className="hole-val" style={{
                border: '3px double #0F100F',
                borderRadius: '50%',
            }}>{data?.score}</div>
        }
        else {
            return <div className="hole-val">{data?.score}</div>
        }
    }

    // Call handlePageReload when the component mounts to restore roundId on page reload
    useEffect(() => {
        handlePageReload();
    }, []);


    useEffect(() => {
        if (showAddScorePlayersData.length > 0 && areaClickedFlag === true) {
            // if (playerScores[0] === showAddScorePlayersData[0]?.score && playerPutts[0] === showAddScorePlayersData[0]?.putts) {
            // Check if there are existing values for score and putts
            const initialScores = showAddScorePlayersData.map((data) => data.score || 0);
            const initialPutts = showAddScorePlayersData.map((data) => data.putts || 0);

            setPlayerScores(initialScores);
            setPlayerPutts(initialPutts);
            // }
        }
    }, [showAddScorePlayersData]);

    const fetchScoreData = async () => {
        let carouselArr = [];
        setLoading(true)
        try {
            let resp = await showScoreData(roundId);
            setScoreData(resp);
            const initialScores = resp.map(data => data.holes[0].score); // Assuming the initial scores are in the first hole data
            const initialPutts = resp.map(data => data.holes[0].putts); // Assuming the initial putts are in the first hole data

            setPlayerScores(initialScores);
            setPlayerPutts(initialPutts);
            setNewScoreData(resp?.slice(1, resp?.length));
            for (let i = 0; i < resp[0]?.holes?.length; i++) {
                carouselArr?.push({ caption: `Hole ${resp[0]?.holes[i]?.number}`, par: `Par ${resp[0]?.holes[i]?.par}`, yards: resp[0]?.holes[i]?.length, thirdParam: resp[0]?.holes[i]?.number, is_submitted: resp[0]?.holes[i]?.is_submitted });
                if ((resp[0]?.holes[i]?.is_submitted === true && resp[0]?.holes[i]?.number !== 9)) {
                    setselectedCarouselIndex(resp[0]?.holes[i]?.number)
                }
            }
            if (resp[0]?.holes[0]?.tee_accuracy) {
                setSelectedAreas(resp[0]?.holes[0]?.tee_accuracy);
            }
            else {
                setSelectedAreas('');
            }
            setCarouselData(carouselArr);
            let tempArr = resp[0]?.holes;
            let tempHoleIndex = parseInt(localStorage?.getItem('holeIndex'));
            if (tempHoleIndex > 0) {
                for (let i = 0; i < tempArr?.length; i++) {
                    if (tempArr[i]?.number === tempHoleIndex + 1) {
                        setLoading(false)
                        handleFirstCarouselClick({
                            caption: `${tempArr[i]?.number}`, par: `${tempArr[i]?.par}`, yards: tempArr[i]?.length, thirdParam: tempArr[i]?.number, is_submitted: resp[i]?.is_submitted
                        }, resp);
                        return;
                    }
                }
            }
            else {
                setLoading(false)
                handleFirstCarouselClick({
                    caption: `${resp[0]?.holes[0]?.number}`, par: `${resp[0]?.holes[0]?.par}`, yards: resp[0]?.holes[0]?.length, thirdParam: resp[0]?.holes[0]?.number, is_submitted: resp[0]?.holes[0]?.is_submitted
                }, resp);
            }


        }
        catch (err) {
            setLoading(false)
            toast.error(err?.response?.data?.message || 'Error in fetching score data');
        }
    }

    const handleIncrement = (index) => {
        const updatedScores = [...playerScores];
        updatedScores[index]++;
        setPlayerScores(updatedScores);
    };

    const handleDecrement = (index) => {
        const updatedScores = [...playerScores];
        updatedScores[index]--;
        setPlayerScores(updatedScores);
    };

    const handlePuttsIncrement = (index) => {
        const updatedPutts = [...playerPutts];
        updatedPutts[index]++;
        setPlayerPutts(updatedPutts);
    };

    const handlePuttsDecrement = (index) => {
        const updatedPutts = [...playerPutts];
        if (updatedPutts[index] > 0) {
            updatedPutts[index]--;
            setPlayerPutts(updatedPutts);
        }
    };

    const handleAddScore = async () => {
        // Gather the updated scores from the playerScores array
        const updatedPlayerData = showAddScorePlayersData.map((data, index) => ({
            ...data,
            score: playerScores[index],
            putts: playerPutts[index],
        }));

        let arr = [];
        for (let i = 0; i < updatedPlayerData?.length; i++) {
            if (updatedPlayerData[i]?.isOwner === true) {
                if ((selectedAreas)?.toLowerCase() === '' || selectedAreas === null) {
                    toast.error('Tee shot accuracy is mandatory!!');
                    return;
                }
                else {
                    arr?.push(
                        {
                            "id": updatedPlayerData[i]?.id,
                            "tee_accuracy": (selectedAreas)?.toLowerCase() || '',
                            "score": updatedPlayerData[i]?.score,
                            "putts": updatedPlayerData[i]?.putts,
                            "number": updatedPlayerData[i]?.hole_number
                        }
                    )
                }
            }
            else {
                arr?.push(
                    {
                        "id": updatedPlayerData[i]?.id,
                        "score": updatedPlayerData[i]?.score,
                        "number": updatedPlayerData[i]?.hole_number,
                        tee_accuracy: "",
                        putts: 0
                    }
                )
            }
        }
        let currentHoledata = carouselData?.filter((item) => item?.thirdParam === arr[0]?.number);
        try {
            let resp = await addScore(arr, roundId);
            if (resp[0]?.status === true) {
                toast.success(resp[0]?.message || 'Score added successfully');
                let nextHoleIndex = currentHoledata[0]?.thirdParam !== 9 ? currentHoledata[0]?.thirdParam + 1 : currentHoledata[0]?.thirdParam;
                if (nextHoleIndex <= carouselData.length) {
                    // Navigate to the next hole
                    handleCarouselSelect(nextHoleIndex - 1, 'false');
                    setselectedCarouselIndex(nextHoleIndex - 1);
                    window.location.reload();
                } else {
                    // Handle case when a<ll holes are completed
                    setselectedCarouselIndex(0);
                    window.location.reload();
                }
            }
        }
        catch (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    const handleFinishRound = async () => {
        let playerFinishStatus;
        let playerScoreData = scoreData?.slice(1, scoreData?.length);
        const userFinishStatus = scoreData[0]?.holes?.filter((item) => item?.is_submitted
            === false);
        if (userFinishStatus?.length > 0) {
            toast.error('Add scores for all the holes!!')
        }
        else {
            for (let i = 0; i < playerScoreData?.length; i++) {
                playerFinishStatus = playerScoreData[i]?.holes.filter((item) => item?.is_submitted === false);
                break;
            }

            if (playerFinishStatus?.length > 0) {
                setshowConfirmScorePopup(true);
            }
            else {
                //call finish round API
                handleConfirmFinishRound()
            }
        }
    }

    const handleConfirmFinishRound = async () => {
        try {
            let resp = await finishRound(roundId);
            setshowConfirmScorePopup(false);
            navigate('/home');
            localStorage?.removeItem('holeIndex')
            localStorage?.removeItem('roundId')
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occured while finishing round');
        }
    }

    const handleScorePopupClose = () => {
        setshowConfirmScorePopup(false);
    }

    const handleAreaClick = (area, falg) => {
        if (falg !== false)
            setareaClickedFlag(false);
        const updatedData = showAddScorePlayersData.map((item, index) => {
            if (index === 0) {
                return { ...item, tee_accuracy: area };
            }
            return item;
        });

        setAddScorePlayersData(updatedData)
        setSelectedAreas(area)
        setPlayerPutts(playerPutts)
        setPlayerScores(playerScores)
    };

    // Function to apply CSS styles based on the selected area
    const getAreaStyles = (area, isSelected) => {
        const defaultStyles = {
            backgroundColor: "white",
            color: "black",
        };

        if (isSelected) {
            // Apply styles for the selected area
            return {
                backgroundColor: "#88BD36",
                color: "white",
            };
        }

        return defaultStyles;
    };

    // Function to apply CSS styles based on the selected area
    const getHoleStatsAreaStyles = (data, area) => {
        const defaultStyles = {
            backgroundColor: "#F1F2F2", // Default background color
            color: "black", // Default text color
        };
        if (data === area) {
            return {
                backgroundColor: "#88BD36",
                color: "white",
            };
        }

        return defaultStyles;
    };

    const handleFirstCarouselClick = (data, scoreResp) => {
        // setareaClickedFlag(false);
        handleAreaClick(selectedAreas, false);
        let holeFlag = {};
        let addScorePlayersData = [];
        holeFlag = scoreResp[0]?.holes_stats?.find((item) => Number(item?.hole_number) === Number(data?.thirdParam));
        setHoleStats(holeFlag);
        handleMaxHoleStats(holeFlag);
        let selHoleNumber = data?.thirdParam;
        for (let i = 0; i < scoreResp?.length; i++) {
            for (let j = 0; j < scoreResp[i]?.holes?.length; j++) {
                if (Number(scoreResp[i]?.holes[j]?.number) === Number(selHoleNumber)) {
                    addScorePlayersData?.push({
                        id: scoreResp[i]?.holes[j]?.id,
                        hole_number: scoreResp[i]?.holes[j]?.number,
                        score: scoreResp[i]?.holes[j]?.score,
                        putts: scoreResp[i]?.holes[j]?.putts,
                        tee_accuracy: scoreResp[i]?.holes[j]?.tee_accuracy,
                        name: `${scoreResp[i]?.player?.first_name} ${scoreResp[i]?.player?.last_name}`,
                        hc_index: scoreResp[i]?.player?.hc_index,
                        profile_image: scoreResp[i]?.player?.profile_image,
                        isOwner: scoreResp[i]?.for_me,
                        is_tee_off: scoreResp[i]?.is_tee_off
                    })
                }
            }
            setAddScorePlayersData(addScorePlayersData);
        }
    }

    const handleMaxHoleStats = (holeStatsInput) => {
        const teeAccuracy = holeStatsInput?.tee_accuracy;

        // Convert the values to numbers with a fallback value of 0 for empty strings
        const teeAccuracyOver = parseFloat(teeAccuracy?.over) || 0;
        const teeAccuracyLeft = parseFloat(teeAccuracy?.left) || 0;
        const teeAccuracyHit = parseFloat(teeAccuracy?.hit) || 0;
        const teeAccuracyRight = parseFloat(teeAccuracy?.right) || 0;
        const teeAccuracyShort = parseFloat(teeAccuracy?.short) || 0;

        if (
            teeAccuracyOver === 0 &&
            teeAccuracyLeft === 0 &&
            teeAccuracyHit === 0 &&
            teeAccuracyRight === 0 &&
            teeAccuracyShort === 0
        ) {
            setMaxTeeAccuracy('');
            return null; // Or return some meaningful value or handle this case as needed
        }


        // Create an object to associate values with keys
        const teeAccuracyValues = {
            over: teeAccuracyOver,
            left: teeAccuracyLeft,
            hit: teeAccuracyHit,
            right: teeAccuracyRight,
            short: teeAccuracyShort,
        };

        // Find the maximum value among the percentages
        const maxPercentage = Math.max(
            teeAccuracyOver,
            teeAccuracyLeft,
            teeAccuracyHit,
            teeAccuracyRight,
            teeAccuracyShort
        );

        // Find all keys associated with the maximum value
        const maxKeys = Object.keys(teeAccuracyValues).find(
            (key) => teeAccuracyValues[key] === maxPercentage
        );

        console.log('maxPercentage', maxPercentage);
        console.log('maxKeys', maxKeys);

        setMaxTeeAccuracy(maxKeys);
        return maxKeys;
    };

    const handleCarouselSelect = (selectedIndex, flag) => {
        const prevIndex = selectedCarouselIndex;
        if (selectedIndex > prevIndex && flag !== 'false') {
            if (carouselData[prevIndex]?.is_submitted !== true) {
                toast.error('You are navigating to a new hole without submitting a score for the current hole');
            }
        }
        else if (selectedIndex < prevIndex) {
        }
        setselectedCarouselIndex(selectedIndex);
        // Assuming you have an array of carousel data
        const selectedData = carouselData[selectedIndex];
        localStorage.setItem('holeIndex', selectedIndex);
        handleCarouselClick(selectedData);
    };

    const handleCarouselClick = (data) => {
        // handleAreaClick(selectedAreas);
        let holeFlag = {};
        let addScorePlayersData = [];
        holeFlag = scoreData[0]?.holes_stats?.find((item) => Number(item?.hole_number) === Number(data?.thirdParam));
        setHoleStats(holeFlag);
        console.log('holeFlag inside handlecarouselclick', holeFlag);
        handleMaxHoleStats(holeFlag);
        let selHoleNumber = data?.thirdParam;
        for (let i = 0; i < scoreData?.length; i++) {
            for (let j = 0; j < scoreData[i]?.holes?.length; j++) {
                if (Number(scoreData[i]?.holes[j]?.number) === Number(selHoleNumber)) {
                    addScorePlayersData?.push({
                        id: scoreData[i]?.holes[j]?.id,
                        hole_number: scoreData[i]?.holes[j]?.number,
                        score: scoreData[i]?.holes[j]?.score,
                        putts: scoreData[i]?.holes[j]?.putts,
                        tee_accuracy: scoreData[i]?.holes[j]?.tee_accuracy,
                        name: `${scoreData[i]?.player?.first_name} ${scoreData[i]?.player?.last_name}`,
                        hc_index: scoreData[i]?.player?.hc_index,
                        profile_image: scoreData[i]?.player?.profile_image,
                        isOwner: scoreData[i]?.for_me,
                        is_tee_off: scoreData[i]?.is_tee_off
                    })
                }
            }
            setSelectedAreas(addScorePlayersData[0]?.tee_accuracy)
            setAddScorePlayersData(addScorePlayersData);
        }
        const initialScores = showAddScorePlayersData.map((data) => data.score || 0);
        const initialPutts = showAddScorePlayersData.map((data) => data.putts || 0);

        setPlayerScores(initialScores);
        setPlayerPutts(initialPutts);
    }
    return (
        <>
            <Navbar />
            {
                Loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" />
                    :
                    <div className="scorecard-wrapper">
                        <div className={"def-league-img"}>
                            <img src={svg?.UserDashboardDefault} className="def-img w-100 h-100" alt="def img NP" />
                        </div>

                        <div className="dashboard-body-wrapper">
                            <div className="scores-hole-carousel">
                                <Carousel activeIndex={selectedCarouselIndex} onSelect={handleCarouselSelect} controls={true} interval={null}>
                                    {carouselData?.map((slide, index) => (
                                        <Carousel.Item key={index} className="my-leagues-carousel-item">
                                            <h3 className="my-leagues-carousel-title">{slide.caption}</h3>
                                            <div className="score-car-data">{slide?.par} | {slide?.yards}yards | {slide?.thirdParam} </div>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </div>

                            <div className="scorecard-addscore d-flex">
                                <div className="scorecard">
                                    <div className="score-heading">Scorecard</div>
                                    <div className="score-card-listing mt-2">
                                        <div className="score-card">
                                            <div className="user-info d-flex justify-between align-items-center greyBg">
                                                <div className="user-pic-info">
                                                    <div className="user-profile">
                                                        <img src={scoreData[0]?.player?.profile_image || svg?.DefUser} className="user-img" alt="user-img-np" />
                                                    </div>
                                                    <div className="user-info-wrapper d-flex flex-column">
                                                        <div className="user-name">{scoreData[0]?.player?.first_name} {scoreData[0]?.player?.last_name} (you)</div>
                                                        <div className="user-handicap">Handicap: {scoreData[0]?.player?.hc_index}</div>
                                                    </div>
                                                </div>
                                                <div className="user-score">
                                                    {scoreData[0]?.total_score}
                                                </div>
                                            </div>

                                            <div className="user-holes whiteBg">
                                                <div className="hole-txt">Hole</div>
                                                {
                                                    scoreData[0]?.holes?.map((item, indexHole) => (
                                                        <div className="hole-val">{item?.number}</div>
                                                    ))
                                                }
                                            </div>

                                            <div className="user-par greyBg">
                                                <div className="par-txt">Par</div>
                                                {
                                                    scoreData[0]?.holes?.map((item, indexHole) => (
                                                        <div className="par-val">{item?.par}</div>
                                                    ))
                                                }
                                            </div>

                                            <div className="user-scores whiteBg">
                                                <div className="hole-txt">Score</div>
                                                {
                                                    scoreData[0]?.holes?.map((item, indexHole) => (
                                                        scoreHandling(item)
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <>{
                                            newScoreData?.map((data, index) => (
                                                <div className="score-card">
                                                    <div className="user-info d-flex justify-between align-items-center greyBg">
                                                        <div className="user-pic-info">
                                                            <div className="user-profile">
                                                                <img src={data?.player?.profile_image || svg?.DefUser} className="user-img" alt="user-img-np" />
                                                            </div>
                                                            <div className="user-info-wrapper d-flex flex-column">
                                                                <div className="user-name">{data?.player?.first_name} {data?.player?.last_name}</div>
                                                                <div className="user-handicap">Handicap: {data?.player?.hc_index}</div>
                                                            </div>
                                                        </div>
                                                        {/* {data?.is_tee_off === true && */}
                                                        <div className="user-score">
                                                            {data?.total_score}
                                                        </div>
                                                        {/* } */}
                                                    </div>

                                                    <div className="user-holes whiteBg">
                                                        <div className="hole-txt">Hole</div>
                                                        {
                                                            data?.holes?.map((item, indexHole) => (
                                                                <div className="hole-val">{item?.number}</div>
                                                            ))
                                                        }
                                                    </div>

                                                    <div className="user-scores greyBg">
                                                        <div className="hole-txt">Score</div>
                                                        {
                                                            // data?.is_tee_off === true ?
                                                            <>
                                                                {
                                                                    data?.holes?.map((item, indexHole) => (
                                                                        <div className="hole-val">{scoreHandling(item)}</div>
                                                                    ))
                                                                }
                                                            </>
                                                            // :
                                                            // <>
                                                            //     <span className="tee_off_error">Tee Off Pending</span>
                                                            // </>
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }</>

                                    </div>
                                </div>

                                <div className="add-score-wrapper">
                                    <div className="add-score-div">
                                        <div className="add-score-heading-wrapper d-flex align-items-center">
                                            <div className="add-score-heading">Add Score</div>
                                            <div className="add-score-round-time">Round Time: {convertTimeToHoursMinutes(scoreData[0]?.start_date)}</div>
                                            <Button className="add-score-btn" onClick={() => handleAddScore()}>Submit Score</Button>
                                        </div>
                                        <div className="add-score-user-info mt-3">

                                        </div>

                                        <div className="add-score-user-listing mt-2">
                                            {
                                                showAddScorePlayersData?.map((data, index) => (
                                                    index === 0 ?
                                                        <div className="add-score-player-card height190">
                                                            <div className="player-card-first-div">
                                                                <div className="square-div-wrapper">
                                                                    <div className="row">
                                                                        <div className="first-row-first-col width70"></div>
                                                                        <div className="first-row-sec-col  whiteBox" style={getAreaStyles('over', selectedAreas === 'over' ? selectedAreas : data?.tee_accuracy === 'over')}
                                                                            onClick={() => handleAreaClick("over")}>Over</div>
                                                                        <div className="first-row-third-col width70"></div>
                                                                    </div>

                                                                    <div className="row" style={{ gap: '5px' }}>
                                                                        <div className="sec-row-first-col whiteBox" style={getAreaStyles('left', selectedAreas === 'left' ? selectedAreas : data?.tee_accuracy === 'left')}
                                                                            onClick={() => handleAreaClick("left")}>Left</div>
                                                                        <div className="sec-row-sec-col whiteBox" style={getAreaStyles('hit', selectedAreas === 'hit' ? selectedAreas : data?.tee_accuracy === 'hit')}
                                                                            onClick={() => handleAreaClick("hit")}>Hit</div>
                                                                        <div className="sec-row-third-col whiteBox" style={getAreaStyles('right', selectedAreas === 'right' ? selectedAreas : data?.tee_accuracy === 'right')}
                                                                            onClick={() => handleAreaClick("right")}>Right</div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="third-row-first-col width70"></div>
                                                                        <div className="third-row-sec-col whiteBox" style={getAreaStyles('short', selectedAreas === 'short' ? selectedAreas : data?.tee_accuracy === 'short')}
                                                                            onClick={() => handleAreaClick("short")}>Short</div>
                                                                        <div className="third-row-third-col width70"></div>
                                                                    </div>

                                                                </div>
                                                                <div className="score-putts-heading d-flex flex-column">
                                                                    <div className="user-score">Strokes</div>
                                                                    <div className="user-score">Putts</div>
                                                                </div>
                                                            </div>

                                                            <div class="score-puts-wrapper d-flex flex-column align-items-end">
                                                                <div className="user-score-div d-flex">
                                                                    {/* <div className="user-score">Score</div> */}
                                                                    <div className="user-inc-dec">
                                                                        <div className="user-decreeement" onClick={() => handleDecrement(index)}><span className="inc-txt"></span></div>
                                                                        <div className="user-inc-dec-val org-score">
                                                                            {/* {playerScores[index] || data?.score} */}
                                                                            {playerScores[index] !== undefined ? playerScores[index] : data?.score}
                                                                        </div>
                                                                        <div className="user-increement" onClick={() => handleIncrement(index)}><span className="inc-txt"></span></div>
                                                                    </div>
                                                                </div>

                                                                <div className="user-score-div d-flex">
                                                                    {/* <div className="user-score">Putts</div> */}
                                                                    <div className="user-inc-dec">
                                                                        <div className="user-decreeement" onClick={() => handlePuttsDecrement(index)}><span className="inc-txt"></span></div>
                                                                        <div className="user-inc-dec-val  org-score">{playerPutts[index] || data?.putts}</div>
                                                                        <div className="user-increement" onClick={() => handlePuttsIncrement(index)}><span className="inc-txt"></span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="add-score-player-card">
                                                            <div className="player-card-first-div">
                                                                <div className="user-profile">
                                                                    <img src={data?.profile_image || svg?.DefUser} className="user-img" alt="user-img" />
                                                                </div>
                                                                <div className="user-info d-flex flex-column">
                                                                    <div className="user-name">{data?.name}</div>
                                                                    <div className="user-handicap">Handicap: {data?.hc_index}</div>
                                                                </div>

                                                                <div className="user-score">Strokes</div>
                                                            </div>
                                                            <div className="user-inc-dec d-flex">
                                                                <div className="user-decreeement" onClick={() => handleDecrement(index)}><span className="inc-txt"></span></div>
                                                                <div className="user-inc-dec-val">{playerScores[index] || data?.score}</div>
                                                                <div className="user-increement" onClick={() => handleIncrement(index)}><span className="inc-txt"></span></div>
                                                            </div>

                                                        </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <Button className="finish-round-btn w-100" onClick={() => handleFinishRound()}>Finish Round</Button>

                                    <div className="add-score-holes-stats">
                                        <div className="stats-heading">
                                            Hole Stats History
                                        </div>
                                        <div className="hole-stats-listing mt-2">
                                            <div className="recorded-plays d-flex flex-column align-items-center">
                                                <div className="rec-plays-value">
                                                    {holeStats?.recorded_plays || '0'}
                                                </div>
                                                <div className="rec-plays-txt trimTxt1line">
                                                    Recorded Plays
                                                </div>
                                            </div>

                                            <div className="recorded-plays d-flex flex-column align-items-center">
                                                <div className="rec-plays-value">
                                                    {holeStats?.avg_score || '0'}
                                                </div>
                                                <div className="rec-plays-txt trimTxt1line">
                                                    Average Score
                                                </div>
                                            </div>

                                            <div className="recorded-plays d-flex flex-column align-items-center">
                                                <div className="rec-plays-value">
                                                    {holeStats?.avg_putts || '0'}
                                                </div>
                                                <div className="rec-plays-txt trimTxt1line">
                                                    Average Putts
                                                </div>
                                            </div>

                                            <div className="stats-pie">
                                                <div className="square-div-wrapper">
                                                    <div className="row">
                                                        <div className="first-row-first-col width60 noCursor"></div>
                                                        <div className="first-row-sec-col greyBox noCursor" style={getHoleStatsAreaStyles(maxTeeAccuracy?.toLocaleLowerCase(), 'over')}>Over <div>{(holeStats?.tee_accuracy?.over !== '' && holeStats?.tee_accuracy?.over !== undefined) ? `${holeStats?.tee_accuracy?.over}%` : '-'}</div></div>
                                                        <div className="first-row-third-col width60 noCursor"></div>
                                                    </div>

                                                    <div className="row" style={{ gap: '5px' }}>
                                                        <div className="sec-row-first-col greyBox noCursor" style={getHoleStatsAreaStyles(maxTeeAccuracy?.toLocaleLowerCase(), 'left')}>Left<div>{(holeStats?.tee_accuracy?.left !== '' && holeStats?.tee_accuracy?.left !== undefined) ? `${holeStats?.tee_accuracy?.left}%` : '-'}</div></div>
                                                        <div className="sec-row-sec-col greyBox noCursor" style={getHoleStatsAreaStyles(maxTeeAccuracy?.toLocaleLowerCase(), 'hit')}>Hit<div>{(holeStats?.tee_accuracy?.hit !== '' && holeStats?.tee_accuracy?.hit !== undefined) ? `${holeStats?.tee_accuracy?.hit}%` : '-'}</div></div>
                                                        <div className="sec-row-third-col greyBox noCursor" style={getHoleStatsAreaStyles(maxTeeAccuracy?.toLocaleLowerCase(), 'right')}>Right<div>{(holeStats?.tee_accuracy?.right !== '' && holeStats?.tee_accuracy?.right !== undefined) ? `${holeStats?.tee_accuracy?.right}%` : '-'}</div></div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="third-row-first-col width60 noCursor"></div>
                                                        <div className="third-row-sec-col greyBox noCursor" style={getHoleStatsAreaStyles(maxTeeAccuracy?.toLocaleLowerCase(), 'short')}>Short<div>{(holeStats?.tee_accuracy?.short !== '' && holeStats?.tee_accuracy?.short !== undefined) ? `${holeStats?.tee_accuracy?.short}%` : '-'}</div></div>
                                                        <div className="third-row-third-col width60 noCursor"></div>
                                                    </div>

                                                </div>
                                                <div className="chart-heading">Tee shot accuracy </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            {
                showConfirmScorePopup === true &&
                <ConfirmScore show={showConfirmScorePopup} handleScorePopupClose={handleScorePopupClose} handleConfirmFinishRound={handleConfirmFinishRound} />
            }
        </>
    )
}

export default Scorecard;