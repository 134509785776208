import React, { useState, useEffect } from "react";
import { Formik } from 'formik';
import { Form, Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from "../../utils/useWindowSize.js";
import { inviteFriends } from '../../api/request.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './invitefriends.scss';

let mobileBreakpoint = 821;
const Invitefriends = (props) => {
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    const initialVal = { email: '', shareLink: localStorage?.getItem('refer_url') };
    const copyLink = (event) => {
        if (event.target.classList.contains("copyBtn")) {
            // Prevent the default action (copying to clipboard) for the "Copy" button
            event.preventDefault();
            const tempInput = document.createElement("input")
            tempInput.value = initialVal.shareLink
            document.body.appendChild(tempInput)
            tempInput.select()
            document.execCommand("copy")
            document.body.removeChild(tempInput)
            toast.success('Link copied successfully');
        }
    }

    const handleClose = () => {
        props?.handleClose()
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='invite-friends-popup-wrapper'
            show={props.show}
            onHide={handleClose}
            dialogClassName={getwidth < mobileBreakpoint && "modal-from-bottom"} // Apply the custom CSS class
            animation={false} // Disable built-in animations
            centered={getwidth < mobileBreakpoint ? false : true}
        >
            <Modal.Header closeButton>
                <Modal.Title className="modalTitle password-heading">Invite Friends</Modal.Title>
            </Modal.Header>

            <Modal.Body className="modalBody">
                <div className="change-password-settings-wrapper">
                    <div className="password-body mt-4">

                        <div className="sub-heading">Golf is better with friends! Earn a <b>free season</b> when you invite 3 friends who join Linx League!</div>
                        <Formik
                            initialValues={initialVal}
                            onSubmit={async (values, { resetForm }) => {
                                const emailsArray = values?.email.split(',').map(email => email.trim()); const body = {
                                    "emails": emailsArray
                                }
                                try {
                                    let resp = await inviteFriends(body);
                                    if (resp === '') {
                                        toast.success('Invitation sent successfully');
                                        props.handleClose();
                                        resetForm()
                                    }
                                }
                                catch (err) {
                                    toast.error(err?.response?.data?.message || 'An error occurred while processing your request.');
                                }

                            }}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                isValid,
                                dirty,
                                /* and other goodies */
                            }) => (
                                <form
                                    className="login-form mt-4"
                                    onSubmit={handleSubmit}
                                    noValidate>
                                    <div className="Auth-form-content w-100">
                                        <Form.Group controlId="email">

                                            <label className="change-pass-label settings-label">Email address (comma separated)</label>
                                            <div
                                                className={`form-floating mb-3 ${touched.email && errors.email
                                                    ? 'has-validation-error'
                                                    : ''
                                                    }`}>
                                                <div className="form-input-wrapper w-100">
                                                    <Form.Control
                                                        className="w-100 mt-2 p-2"
                                                        type='text'
                                                        name="email"
                                                        style={{ borderRadius: '8px' }}
                                                        placeholder="Email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.email && errors.email}
                                                    />
                                                </div>
                                                <Form.Control.Feedback type="invalid" className="w-50">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </div>

                                        </Form.Group>
                                        <div className="or-txt">Or</div>
                                        <Form.Group controlId="shareLink">
                                            <label className="change-pass-label settings-label">Share your link</label>
                                            <div
                                                className={`form-floating mb-3 ${touched.shareLink && errors.shareLink
                                                    ? 'has-validation-error'
                                                    : ''
                                                    }`}>
                                                <div className="input-group w-100">
                                                    <Form.Control
                                                        className="w-100 mt-2 p-2"
                                                        type='text'
                                                        name="shareLink"
                                                        placeholder="https://referrallinkjacobdulanlinx"
                                                        value={values.shareLink?.slice(0, 35)}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        style={{ borderRadius: '8px' }}
                                                        isInvalid={touched.shareLink && errors.shareLink}
                                                    />
                                                    <button variant="primary" className="copyBtn" onClick={(event) => copyLink(event)}>Copy</button>
                                                </div>
                                                <Form.Control.Feedback type="invalid" className="w-50">
                                                    {errors.shareLink}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Form.Group>

                                        <Button className="send-btn w-100 mt-2" type="submit">Send</Button>
                                    </div>
                                </form>
                            )
                            }
                        </Formik >
                    </div >
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default Invitefriends;