import React, { useEffect, useState } from "react";
import { Button, Carousel } from 'react-bootstrap'
import Navbar from "../../CustomComponents/Navbar";
import { getMyLeagues, fetchSinglePastRound, fetchSingleUpcomingRound, userStandings } from '../../api/request';
import svg from '../../assets/images/svg/index';
import { useNavigate, useLocation } from 'react-router-dom';
import { formatRoundsDate } from '../../utils/formatRoundsDate.js';
import { formatTime } from '../../utils/formatTime.js';
import './styles.scss';
import Loader from '../../CustomComponents/Loader';
import EditRound from "./EditRound";
import DeleteRound from "./DeleteRound";
import { ghinLogin } from '../../api/ghinApi/ghinLogin.js';
import { teeSetListing } from '../../api/ghinApi/teeSetListing.js';
import TeeOff from "./TeeOff";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RoundDetails = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const roundData = location?.state?.roundData;
    const userData = location?.state?.userData;
    const roundType = location?.state?.round;
    const [loading, setLoading] = useState(true);
    const [roundDetails, setRoundDetails] = useState({});
    const [showTeeOff, setShowTeeOff] = useState(false);
    const [showEditRound, setEditRound] = useState(false);
    const [showDeleteRound, setDeleteRound] = useState(false);
    const [getCoverImage, setCoverImage] = useState('');
    const [selLeagueId, setSelLeagueId] = useState(0);
    const [myLeaguesData, setMyLeagues] = useState([]);
    const [carouselData, setCarouselData] = useState([]);
    const [roundsItems, setRoundsItems] = useState([{}, {}, {}, {}]);
    const [getToken, setToken] = useState('');
    const [getTeeSetListing, setTeeSetListing] = useState([]);
    const cardsLength = carouselData?.length;

    useEffect(() => {
        fetchMyLeagues();
        fetchRoundDetails();
        handleGhinLogin();
        fetchStandingsData();
    }, []);

    const handleGhinLogin = async () => {
        let gender = userData?.gender === 'FEMALE' ? 'F' : 'M';
        let resp = await ghinLogin();
        setToken(resp?.token);
        try {
            let teesetResp = await teeSetListing(resp?.token, roundData?.golf_course?.usga_id, gender);
            setTeeSetListing(teesetResp);
        }
        catch (err) {
            console.log('Error in fetching tee_set listing', err);
        }
    }

    const fetchMyLeagues = async () => {
        try {
            let resp = await getMyLeagues();
            setMyLeagues(resp);
            setSelLeagueId(resp[0]?.id)
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occurred while fetching my leagues.')
        }
    }

    const fetchStandingsData = async () => {
        try {
            let resp = await userStandings();
            setCarouselData(resp);
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occured while fetching standings');
        }
    }

    const fetchRoundDetails = async () => {
        try {
            setLoading(true);
            if (roundType === 'Upcoming Round') {
                let resp = await fetchSingleUpcomingRound({ id: roundData?.id });
                setRoundDetails(resp);
                setLoading(false)
            }
            else {
                let resp = await fetchSinglePastRound({ id: roundData?.id });
                setRoundDetails(resp);
                setLoading(false)
            }
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occured while fetching round details');
            setLoading(false)
        }
    }

    const closeEditRound = () => {
        setEditRound(false);
        setDeleteRound(false);
        setShowTeeOff(false);
    }
    const handleCarouselSelect = (selectedIndex, e) => {
        // Assuming you have an array of carousel data
        const selectedData = carouselData[selectedIndex];
        handleCarouselClick(selectedData);
    };

    function handleCarouselClick(data) {
    }

    const handleCardNumClass = (incomingNum) => {
        switch (incomingNum) {
            case 1: return 'numberOne';
            case 2: return 'numberTwo';
            case 3: return 'numberThree';
            case 4: return 'numberFour';
            default: return 'numberFour'
        }
    }

    const handleMyLeague = (data) => {
        setCoverImage(data?.league?.cover_image);
        setSelLeagueId(data?.id);
    }

    return (
        <>
            <Navbar />
            {loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" /> :
                <div className="round-details-wrapper">
                    <div className={getCoverImage ? "league-img" : "def-league-img"}>
                        <img src={getCoverImage || svg?.UserDashboardDefault} className="def-img w-100 h-100" alt="def img NP" />
                    </div>

                    <div className="round-details-body-wrapper">
                        <div className="round-details">
                            <div className="round-name trimTxt2lines">
                                {roundDetails?.golf_course?.course_name}
                            </div>
                            <div className="round-type mt-2">
                                {roundType}
                            </div>
                        </div>

                        <div className="my-leagues-rounds-info">
                            <div className="my-leagues-rounds-players">
                                <div className="my-league-round-details">
                                    <div className="detail-heading">Details</div>
                                    <div className="details-info d-flex">
                                        <div className="date-info-wraper d-flex">
                                            <img src={svg?.GreenCalendar} className="green-cal" alt="imgg NP" />
                                            <div className="date-info">{formatRoundsDate(roundDetails?.round_date)}</div>
                                        </div>
                                        <div className="date-info-wraper d-flex">
                                            <img src={svg?.GreenClock} className="green-cal" alt="imgg NP" />
                                            <div className="date-info">{formatTime(roundDetails?.round_time)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-league-players-details">
                                    <div className="player-heading">Players</div>
                                    <div className="players-listing d-flex mt-3">
                                        {
                                            roundDetails?.players?.map((data, index) => (
                                                <div className={data?.user_id === Number(localStorage?.getItem('userId')) ? "player-card activeUser" : "player-card"}>
                                                    <img src={data?.profile_image || svg?.BasicUserDefault} className={data?.user_id === Number(localStorage?.getItem('userId')) ? "player-img activeUserBorder" : "player-img"} alt="player imgNP" />
                                                    <div className="player-name trimTxt1line">{data?.user_id === Number(localStorage?.getItem('userId')) ? 'You' : data?.name}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className="round-actions">
                                    <div className="actions-heading">Actions</div>
                                    <div className="actions-btns-wrapper d-flex mt-3">
                                        {roundDetails?.is_tee_off === false ?
                                            <Button className="tee-off-btn" onClick={() => {
                                                setShowTeeOff(true)
                                            }}>Tee Off</Button>
                                            :
                                            <Button className="tee-off-btn" onClick={() => {
                                                navigate('/scorecard', {
                                                    state: {
                                                        roundId: roundData?.id
                                                    }
                                                })
                                            }}>Score Card</Button>
                                        }
                                        {
                                            (roundDetails?.is_owner === true && roundDetails?.status === 'schedule') &&
                                            <Button className="edit-round-btn" onClick={() => {
                                                setEditRound(true)
                                            }}>Edit Round</Button>
                                        }
                                        <>{(roundDetails?.is_owner === true && roundDetails?.status === 'schedule') &&
                                            <img src={svg?.DeleteAction} className="delete-action cursor-pointer" alt="delete NP" onClick={() => {
                                                setDeleteRound(true)
                                            }
                                            } />
                                        }</>
                                    </div>
                                </div>
                            </div>
                            <div className="my-leagues-rounds">
                                <Carousel onSelect={handleCarouselSelect} controls={true} interval={null}>
                                    {carouselData?.map((slide, index) => (
                                        <Carousel.Item key={index} className="my-leagues-carousel-item">
                                            <h3 className="my-leagues-carousel-title trimTxt1line">{slide?.tier?.name}</h3>
                                            <div className="round-leaderboard-body-wrapper mt-4">
                                                <div className="round-leaderboard-heading">
                                                    <div className="leaderboard-txt">Leaderboard</div>
                                                    <div className={cardsLength >= 4 ? "round-score-wrapper padding30" : "round-score-wrapper padding15"}>
                                                        <div className="round-txt">Rounds</div>
                                                        <div className="score-txt">Score</div>
                                                    </div>
                                                </div>

                                                <div className="rounds-leaderboard-listing d-flex flex-column mt-3">
                                                    {
                                                        slide?.leaderboard?.map((item, index) => (

                                                            <div className={item?.user?.id === Number(localStorage?.getItem('userId')) ? 'rounds-card d-flex align-items-center justify-content-between activeUser' : 'rounds-card d-flex align-items-center justify-content-between'}>
                                                                <div className="card-first-half">
                                                                    <div className={handleCardNumClass(index + 1)}>{index + 1}</div>
                                                                    <div className="card-user-info d-flex align-items-center justify-content-center">
                                                                        <div className="user-image">
                                                                            <img src={item?.user?.profile_image || svg?.DefUser} className="user-img" alt="user img NP" />
                                                                        </div>
                                                                        <div className="user-info d-flex flex-column">
                                                                            <div className="card-user-name">
                                                                                {item?.user?.name}
                                                                            </div>
                                                                            <div className="card-user-handicap">
                                                                                Handicap: {item?.user?.hc_index}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-sec-half">
                                                                    <div className="card-rounds">{item?.round_count}/6</div>
                                                                    <div className="card-score" style={{ color: item?.score < 0 ? '#88BD36' : '#242725' }}>{item?.round_count !== 0 ? item?.score : 'N/A'}</div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                showEditRound === true &&
                <EditRound closeEditRound={closeEditRound} show={showEditRound} roundData={roundDetails} />
            }
            {
                showDeleteRound === true &&
                <DeleteRound closeDeleteRound={closeEditRound} show={showDeleteRound} roundData={roundDetails} />
            }
            {
                showTeeOff === true &&
                <TeeOff userData={userData} roundData={roundData} teeSetListing={getTeeSetListing} show={showTeeOff} handleTeeOffPopupClose={closeEditRound} />
            }
        </>
    )
}

export default RoundDetails;