import React, { useEffect, useState } from 'react'
import Navbar from '../../CustomComponents/Navbar';
import { privacyPolicy } from '../../api/request';
import Loader from '../../CustomComponents/Loader';
import {formatDate} from '../../utils/formatDate';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

const PrivacyPolicy = (props) => {
    const navigate= useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            let resp = await privacyPolicy();
            if (resp?.results) {
                setData(resp?.results[0])
            }
            
            setLoading(false);
        }
        catch (err) {
            console.log('Error in fetching privacy policy', err);
            // if (err?.response?.data?.detail === "Invalid token.") {
            //     toast.error(err?.response?.data?.detail);
            //     navigate('/login');
            // }
            setLoading(false);
        }
    }

    return (
        <>
            {
                props.shownav === "true" && <Navbar />}

            <>{loading === true ?
                <Loader loadingMsg="Data is Loading... Please Wait" />
                :
                <div className='privacy-policy-wrapper'>
                    <div className='policy-body-wrapper'>
                        <div className='policy-heading mt-3'>Privacy Policy</div>
                        <div className='policy-date-time mt-4'>Last Updated: {formatDate(data?.updated_at)}</div>
                        <div className='policy-body mt-3'>{data?.body}</div>
                    </div>
                </div>
            }</>
        </>
    )
}

export default PrivacyPolicy;