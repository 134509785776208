import AuthBackground from './AuthBackground.svg';
import Logo from './Logo.svg';
import FacebookLogo from './FacebookLogo.svg';
import GoogleLogo from './GoogleLogo.svg';
import AppleLogo from './AppleLogo.svg';
import OnboardingBg from './OnboardingBg.svg';
import DefaultUser from './DefaultUser.svg';
import UsgaLogo from './UsgaLogo.svg';
import BackArrow from './BackArrow.svg';
import Banner from './Banner.svg';
import Bellicon from './Bellicon.svg';
import Listicon from './Listicon.svg';
import Usericon from './Usericon.svg';
import Navlogo from './Navlogo.svg';
import MobileNavLogo from './MobileNavLogo.svg';
import AdminBg from './AdminBg.svg';
import AdminLogo from './AdminLogo.svg';
import league from './leagues.svg';
import Subscriptions from './Subscriptions.svg';
import Users from './Users.svg';
import AdContent from './AdContent.svg';
import Courses from './Courses.svg';
import Notifications from './Notifications.svg';
import Feedback from './Feedback.svg';
import Selector from './Selector.svg';
import Previcon from './Previcon.svg';
import Nexticon from './Nexticon.svg';
import Logout from './Logout.svg';
import UserBack from './UserBack.svg';
import BasicUserDefault from './BasicUserDefault.svg';
import Star from './Star.svg';
import EditPen from './EditPen.svg';
import DefUser from './DefUser.svg';
import UploadFile from './UploadFile.svg';
import SeasonBack from './SeasonBack.svg';
import Pencil from './Pencil.svg';
import Trash from './Trash.svg';
import OnbaordingBg from './OnbaordingBg.svg';
import RightArrow from './RightArrow.svg';
import PlusIcon from './PlusIcon.svg';
import LeagueDef from './LeagueDef.svg';
import Cup from './Cup.svg';
import Info from './Info.svg';
import SubStar from './SubStar.svg';
import Mastercard from './Mastercard.svg';
import UserDashboardDft from './UserDashboardDft.svg';
import OneNoStanding from './OneNoStanding.svg';
import TwoNoStanding from './TwoNoStanding.svg';
import ThreeNoStanding from './ThreeNoStanding.svg';
import FourNoStanding from './FourNoStanding.svg';
import DownloadArrow from './DownloadArrow.svg';
import UserDashboardDefault from './UserDashboardDefault.svg';
import RecPrizes from './RecPrizes.svg';
import RoundsDef2 from './RoundsDef2.svg';
import RoundsDef3 from './RoundsDef3.svg';
import AddPlayer from './AddPlayer.svg';
import GreenClock from './GreenClock.svg';
import GreenCalendar from './GreenCalendar.svg';
import DeleteAction from './DeleteAction.svg';
import DeletePlayer from './DeletePlayer.svg';
import InfoIcon from './InfoIcon.svg';
import VerticalDots from './VerticalDots.svg';
import VerticalDotsMob from './VerticalDotsMob.svg';
import WarningImg from './WarningImg.svg';
import Flag from './Flag.svg';
import RoundsDef1 from './RoundsDef1.svg';
import RoundsDef4 from './RoundsDef4.svg';

export default {
    AuthBackground: AuthBackground,
    Logo: Logo,
    FacebookLogo: FacebookLogo,
    GoogleLogo: GoogleLogo,
    AppleLogo: AppleLogo,
    OnboardingBg: OnboardingBg,
    DefaultUser: DefaultUser,
    UsgaLogo: UsgaLogo,
    BackArrow: BackArrow,
    Banner: Banner,
    Bellicon: Bellicon,
    Listicon: Listicon,
    Usericon: Usericon,
    Navlogo: Navlogo,
    MobileNavLogo: MobileNavLogo,
    AdminBg: AdminBg,
    AdminLogo: AdminLogo,
    league: league,
    Subscriptions: Subscriptions,
    Users: Users,
    AdContent: AdContent,
    Courses: Courses,
    Notifications: Notifications,
    Feedback: Feedback,
    Selector: Selector,
    Nexticon: Nexticon,
    Previcon: Previcon,
    Logout: Logout,
    UserBack: UserBack,
    BasicUserDefault: BasicUserDefault,
    Star: Star,
    EditPen: EditPen,
    DefUser: DefUser,
    UploadFile: UploadFile,
    SeasonBack: SeasonBack,
    Pencil: Pencil,
    Trash: Trash,
    OnbaordingBg: OnbaordingBg,
    RightArrow: RightArrow,
    PlusIcon: PlusIcon,
    LeagueDef: LeagueDef,
    Cup: Cup,
    Info: Info,
    SubStar: SubStar,
    Mastercard: Mastercard,
    UserDashboardDft: UserDashboardDft,
    OneNoStanding: OneNoStanding,
    TwoNoStanding: TwoNoStanding,
    ThreeNoStanding: ThreeNoStanding,
    FourNoStanding: FourNoStanding,
    DownloadArrow: DownloadArrow,
    UserDashboardDefault: UserDashboardDefault,
    RecPrizes: RecPrizes,
    AddPlayer:AddPlayer,
    GreenClock:GreenClock,
    GreenCalendar: GreenCalendar,
    DeleteAction:DeleteAction,
    DeletePlayer: DeletePlayer,
    InfoIcon:InfoIcon,
    VerticalDots: VerticalDots,
    VerticalDotsMob: VerticalDotsMob,
    WarningImg: WarningImg,
    Flag: Flag,
    RoundsDef1: RoundsDef1,
    RoundsDef2: RoundsDef2,
    RoundsDef3: RoundsDef3,
    RoundsDef4: RoundsDef4
}