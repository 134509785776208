import React, { useEffect, useState } from "react";
import { fetchSubscriptionData, fetchRecLeaguesBanner } from '../../api/request';
import { useWindowSize } from "../../utils/useWindowSize.js";
import { toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import svg from '../../assets/images/svg/index.js';

let mobileBreakpoint = 821;
const SubscriptionForm = (props) => {
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0);
    const [getBanner, setBanner] = useState('');
    const [subscriptionData, setSubscriptionData] = useState([]);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    useEffect(() => {
        fetchSubData();
        fetchBanner();
    }, []);

    const fetchBanner = async () => {
        try {
            let resp = await fetchRecLeaguesBanner();
            setBanner(resp[0]?.image || '');
        }
        catch (err) {
            console.log('Error in fetching banner', err);
        }
    }

    const fetchSubData = async () => {
        try {
            let resp = await fetchSubscriptionData();
            setSubscriptionData(resp);
        }
        catch (err) {
            console.log('Error in fetchSubscriptionData', err);
        }
    }

    const handleTriangleTxt = (dataIndex) => {
        if (dataIndex === 0) {
            return '';
        }
        if (dataIndex === 1) {
            return 'Most Popular!';
        }
        if (dataIndex === 2) {
            return 'Best Value!'
        }
    }

    const handleSubConfirm = (incomingData) => {
        let obj = {
            recommendedLeaguesData: props?.leagueData,
            subscriptionData: incomingData
        }
        props?.handleNext(obj, 'sub');
    }

    const handleClose = () => {
        props?.closePopup('sub');
    }

    const handleCardDisable = (data) => {
        let diff = null;
        if (Number(props?.userCreditBal) > Number(props?.leagueData?.season?.price)) {
            diff = Number(props?.userCreditBal) - Number(props?.leagueData?.season?.price)
        }
        else if (Number(props?.userCreditBal) <= Number(props?.leagueData?.season?.price)) {
            diff = Number(props?.leagueData?.season?.price) - Number(props?.userCreditBal)
        }
        return diff;
    }

    return (
        <>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                className='sub-content-wrapper schedule-round-wrapper'
                show={props.show}
                onHide={handleClose}
                dialogClassName={getwidth < mobileBreakpoint && "modal-from-bottom"} // Apply the custom CSS class
                animation={false} // Disable built-in animations
                centered={getwidth < mobileBreakpoint ? false : true}>
                <Modal.Header closeButton>
                    <Modal.Title className="modalTitle">
                        <div className="subscription-info d-flex justify-content-center align-items-center mt-2">
                            <div className="subscription-txt">{props?.leagueData?.name}</div>
                            <div className="subscription-prize">{`Up to $${parseInt(props?.leagueData?.prize)} prizes each season`}</div>
                        </div></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modalBody">

                    <div className="banner-info">
                        <>{getBanner === '' && <div className="add-banner-txt">Ad Banner</div>}</>
                        <img src={getBanner !== '' ? getBanner : svg?.Banner} className="banner-img w-100" alt="banenr NP" />
                    </div>

                    <div className="subscription-form-wrapper">
                        {
                            subscriptionData?.map((data, index) => (

                                <div className="subs-card d-flex flex-column" key={index}>
                                    <>{index !== 0 &&
                                        <>
                                            <div className="triangle-topleft"></div>
                                            <>{index === 1 &&
                                                <img src={svg?.SubStar} className="subs-star" alt="Star NP" />
                                            }</>
                                        </>
                                    }</>
                                    {
                                        Number(data?.credits) < handleCardDisable(data) && (
                                            <div className="disabled-overlay"></div>
                                        )}
                                    <div className={index === 2 ? 'triangle-txt right5px' : 'triangle-txt'}>{handleTriangleTxt(index)}</div>
                                    <div className="sub-heading trimTxt1line">{data?.name}</div>
                                    <div className="sub-green-line"></div>
                                    <div className="sub-description trimTxt1line">{data?.description}</div>
                                    <div className="sub-price trimTxt1line">${parseInt(data?.price)}</div>
                                    <div className="sub-credits-wrapper d-flex flex-column align-items-center">
                                        <div className="credits-head">
                                            Credits
                                        </div>
                                        <div className="credit-score-wrapper d-flex align-items-center">
                                            <img src={svg?.Star} className="star-img" alt="star IMg NP" />
                                            <div className="credit-score">{data?.credits}</div>
                                        </div>
                                    </div>
                                    <button className="sub-select" onClick={() => handleSubConfirm(data)}>Select</button>
                                </div>
                            ))
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SubscriptionForm;