import React, { useEffect, useState } from "react";
import { getadminFeedback, sendadminFeedback } from '../../../api/request';
import { Button } from 'react-bootstrap';
import svg from '../../../assets/images/svg/index';
import Loader from '../../../CustomComponents/Loader';
import ErrorMsg from '../../../CustomComponents/ErrorMsg';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

const AdminFeedback = () => {
    const navigate = useNavigate();
    const [feedback, setFeedback] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedFeedbackIndex, setSelectedFeedbackIndex] = useState(-1);
    const [replyTxt, setreplyTxt] = useState('');
    const [noFound, setNoFound] = useState(false);

    useEffect(() => {
        fetchFeedback();
    }, []);

    const fetchFeedback = async () => {
        setLoading(true);
        try {
            let resp = await getadminFeedback();
            if (resp) {
                setFeedback(resp);
                setLoading(false);
                if (resp?.length === 0) {
                    setNoFound(true);
                }
            }
        }
        catch (err) {
            setLoading(false);
            if (err?.response?.data?.detail === "Invalid token.") {
                navigate('/admin/login');
            }
        }
    }

    const handleReplyButtonClick = (index) => {
        setSelectedFeedbackIndex(index);
    }

    const sendFeedback = async (id) => {
        try {
            const body = {
                reply: replyTxt
            }
            let resp = await sendadminFeedback(id, body);
            if (resp?.message === 'success') {
                toast.success('Feedback reply sent successfully');
                window?.location?.reload();
            }
        }
        catch (err) {
            toast.error('Error in sending feedback reply');
        }
    }

    return (
        <>
            {
                loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" />
                    :
                    <>{
                        noFound === true ?
                            <ErrorMsg errormsg={'No Records Exist'} />
                            :
                            <div className="AdminFeedback-Wrapper adminpadding">
                                <div className="feedback-heading">Feedback</div>
                                <div className="admin-feedback-body">
                                    {
                                        feedback?.map((data, index) => (
                                            <div className="feedback-item">
                                                <div className="feedback-item-body d-flex justify-content-between">
                                                    <div className="user-data d-flex align-items-center">
                                                        <div className="user-img">
                                                            <img src={data?.profile_image || svg?.DefaultUser} className="user-profile" alt="imageNP" />
                                                        </div>
                                                        <div className="user-details d-flex flex-column">
                                                            <div className="u-name">{data?.full_name}</div>
                                                            <div className="u-email">{data?.email}</div>
                                                        </div>
                                                    </div>
                                                    {/* <Button className={data?.reply !== null ? 'replied-btn' : 'reply-btn'}
                                                        onClick={() => (data?.reply === null) ?
                                                            handleReplyButtonClick(index)
                                                            : null
                                                        }
                                                    >{data?.reply !== null ? 'Replied' : 'Reply'}</Button> */}
                                                </div>

                                                <div className="message-body mt-3">
                                                    <div className="subject">{data?.subject}</div>
                                                    <div className="msg-body mt-2 trimTxt1line">{data?.message}</div>
                                                </div>

                                                <>{selectedFeedbackIndex === index &&
                                                    <div className="reply-feedback-body mt-3">
                                                        <label className="msg-label">Message</label>
                                                        <textarea className="msg-text-area w-100" row={5} placeholder="Write a message..." onChange={(e) => {
                                                            setreplyTxt(e?.target?.value)
                                                        }} />

                                                        <div className="reply-btns-body">
                                                            <Button className="cancel-btn">Cancel</Button>
                                                            <Button className="reply-btn" onClick={() => sendFeedback(data?.id)}>Send Reply</Button>
                                                        </div>
                                                    </div>
                                                }</>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                    }</>

            }
        </>
    )
}

export default AdminFeedback;