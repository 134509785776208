import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { editAd } from '../../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import svg from '../../../assets/images/svg/index';
import './editcontent.scss';

const EditContent = (props) => {
    const [selFile, setSelFile] = useState('');
    const [incomingImg, setIncomingimg] = useState(props?.subscriptionImage || props?.recommendedImage);
    const handleClose = () => {
        props.handleDelClose();
    }

    const handleFileInputClick = async (e, page) => {
        const file = e?.target?.files[0];

        if (file && file.type.startsWith('image/')) {
            setSelFile(file);
            setIncomingimg('');
        } else {
            // Handle the case where the selected file is not an image
            toast.error('Please select a valid image file.');
        }
    }


    const handleEditAccount = async () => {
        const formData = new FormData();
        if (selFile) {
            formData.append('image', selFile);
        }
        try {
            let resp = await editAd(formData, props?.contentId);
            if (resp) {
                toast.success('Ad updated successfully');
                window.location.reload();
            }
        }
        catch (err) {
            toast.error('Error in updated Ad');
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='edit-content-wrapper'
            show={props.show}
            onHide={handleClose}
            centered>
            <Modal.Header>
                <Modal.Title className="modalTitle">Edit {props?.contentScreen} Content</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <>{incomingImg !== '' &&
                    <img src={incomingImg} className='incomingImg h-100 w-100' alt="incomingImg NP" />
                }</>

                <div className='upload-content mt-2'>
                    <div className='upload-content-cont mt-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className={selFile ? "upload-body content-list-image p-0 d-flex flex-column justify-content-center align-items-center" : "upload-body content-list-image d-flex flex-column justify-content-center align-items-center"}>
                            <img src={selFile ? URL.createObjectURL(selFile) : svg?.UploadFile} className={selFile ? 'upload-img h-100 w-100' : "def-photo h-100 w-100"} alt="def NP" />
                            <div className="upload-bodycont">
                                <input type="file" id="file-input" name="file-input"
                                    accept="image/*"
                                    onChange={(e) => handleFileInputClick(e, props?.page)} />

                                <>{!selFile &&
                                    <label id="file-input-label-edit" for="file-input"
                                    >Edit File</label>
                                }</>
                                {/* <span className="sel-file">{selFile?.name}</span> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='del-buttons-wrapper w-100'>
                    <Button className="cancelBtn w-50" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleEditAccount()}
                        className="delBtn w-50">
                        Save
                    </Button>
                </div>
            </Modal.Body>
        </Modal >
    );
};

export default EditContent;
