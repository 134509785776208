import React, { useState, useEffect } from 'react';
import { getContentList } from '../../../api/request';
import Loader from '../../../CustomComponents/Loader';
import svg from '../../../assets/images/svg/index';
import { Button, Form } from 'react-bootstrap';
import DeleteContent from './DeleteContent.jsx';
import { createContent } from '../../../api/request';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import EditContent from './EditContent';

const AdContent = () => {
    const navigate= useNavigate();
    const [contentData, setContentData] = useState([]);
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [leaguesData, setLeaguesData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selFile, setSelFile] = useState('');
    const [pageNum, setPage] = useState(null);
    const [recommendedImage, setRecommendedImage] = useState('');
    const [subscriptionImage, setSubscriptionImage] = useState('');
    const [showDelAcc, setshowDelAcc] = useState(false);
    const [showEditAcc, setshowEditAcc] = useState(false);
    const [recUploadTxt, setrecUploadTxt] = useState(true);
    const [subUploadTxt, setsubUploadTxt] = useState(true);
    const [contentScreen, setcontentScreen] = useState('');
    const [pageId, setPageId] = useState(null);
    const [contentId, setContentId] = useState(null);
    const [editContentHandlingInput, setEditContentHandlingInput] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            let resp = await getContentList();
            if (resp) {
                setContentData(resp);
                const obj1 = resp?.filter((data) => data.page === 7);
                setSubscriptionData(obj1);

                if (obj1?.page === 7) {
                    setsubUploadTxt(false)
                }

                const obj2 = resp?.filter((data) => data.page === 2);
                setLeaguesData(obj2);
                if (obj2?.page === 2) {
                    setrecUploadTxt(false);
                }

                setLoading(false);
            }
        }
        catch (err) {
            setLoading(false);
            if (err?.response?.data?.detail === "Invalid token.") {
                navigate('/admin/login');
            }
        }
    }

    const handleDelClose = () => {
        setshowDelAcc(false);
        setshowEditAcc(false);
    }

    const handleFileInputClick = async (e, page) => {
        setSelFile(e?.target?.files[0]);
        if (page === 2) {
            setrecUploadTxt(false);
        }
        if (page === 7) {
            setsubUploadTxt(false);
        }
        setPageId(page);
        const formData = new FormData();
        if (e?.target?.files[0]) {
            formData.append('image', e?.target?.files[0]);
        }
        formData.append('page', page);
        try {
            let resp = await createContent(formData);
            if (resp) {
                toast.success('Ad created successfully');
                window.location.reload();
            }
        }
        catch (err) {
            toast.error('Error in creating Ad');
        }
    }

    return (
        <>
            <div className='ad-content-wrapper adminpadding'>
                <div className='content-header'>
                    Ad Content
                </div>
                <div className='ad-content-body mt-4'>
                    <>{
                        leaguesData?.length > 0 ?
                            <div className='content-list-wrapper'>
                                <div className='content-list'>
                                    <div className='content-heading-wrapper d-flex justify-between'>
                                        <div className='content-list-heading'>Recommended Leagues Screen
                                        </div>
                                        <div className='content-btns'>
                                            <Button className="remove-ad-btn" onClick={() => {
                                                setshowDelAcc(true)
                                                setContentId(leaguesData[0]?.id)
                                            }}>Remove Ad</Button>
                                            <Button className="edit-ad-btn" onClick={() => {
                                                setshowEditAcc(true)
                                                setcontentScreen('Recommended Leagues');
                                                setRecommendedImage(leaguesData[0]?.image);
                                                setContentId(leaguesData[0]?.id);
                                                setPage(leaguesData[0]?.page);
                                            }}>Edit Ad</Button>
                                        </div>
                                    </div>

                                    <div className='content-list-image mt-3'>
                                        <img src={leaguesData[0]?.image} className='list-img w-100' alt="list img NP" />
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='upload-content mt-2'>
                                <div className='upload-content-name'>Recommended Leagues Screen</div>
                                <div className='upload-content-cont mt-4'>
                                    <div className={(selFile && pageId === 2) ? "upload-body content-list-image p-0" : "upload-body content-list-image"}>
                                        <img src={(selFile && pageId === 2) ? URL.createObjectURL(selFile) : svg?.UploadFile} className={(selFile && pageId === 2) ? 'upload-img' : "def-photo"} alt="def NP" />
                                        <div className="upload-bodycont">
                                            <input type="file" id="file-input-rec"
                                                className="file-input-rec" name="file-input"
                                                accept="image/*"
                                                onChange={(e) => handleFileInputClick(e, 2)} />

                                            <>{
                                                recUploadTxt === true &&
                                                <label id="file-input-label-rec" for="file-input-rec"
                                                >Upload File</label>

                                            }
                                            </>
                                            {/* <span className="sel-file">{selFile?.name}</span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }</>


                    <>{
                        subscriptionData?.length > 0 ?
                            <div className='content-list-wrapper'>
                                <div className='content-list'>
                                    <div className='content-heading-wrapper d-flex justify-between'>
                                        <div className='content-list-heading'>Subscription Screen
                                        </div>
                                        <div className='content-btns'>
                                            <Button className="remove-ad-btn" onClick={() => {
                                                setshowDelAcc(true)
                                                setContentId(subscriptionData[0]?.id)
                                            }}>Remove Ad</Button>
                                            <Button className="edit-ad-btn" onClick={() => {
                                                setshowEditAcc(true)
                                                setcontentScreen('Subscription')
                                                setSubscriptionImage(subscriptionData[0]?.image);
                                                setContentId(subscriptionData[0]?.id);
                                                setPage(subscriptionData[0]?.page);
                                            }}>Edit Ad</Button>
                                        </div>
                                    </div>

                                    <div className='content-list-image mt-3'>
                                        <img src={subscriptionData[0]?.image} className='list-img w-100' alt="list img NP" />
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='upload-content mt-2'>
                                <div className='upload-content-name'>Subscription Screen</div>
                                <div className='upload-content-cont mt-4'>
                                    <div className={(selFile && pageId === 7) ? "upload-body content-list-image p-0" : "upload-body content-list-image"}>
                                        <img src={(selFile && pageId === 7) ? URL.createObjectURL(selFile) : svg?.UploadFile} className={(selFile && pageId === 7) ? 'upload-img' : "def-photo"} alt="def NP" />
                                        <div className="upload-bodycont">
                                            <input type="file" className="file-input-sub" id="file-input-sub" name="file-input"
                                                accept="image/*"
                                                onChange={(e) => handleFileInputClick(e, 7)} />

                                            <>{subUploadTxt === true &&
                                                <label id="file-input-label-sub" for="file-input-sub"
                                                >Upload File</label>
                                            }</>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }</>
                </div>
            </div>
            {
                showDelAcc === true &&
                <DeleteContent handleDelClose={handleDelClose} show={showDelAcc} contentId={contentId} />
            }

            {
                showEditAcc === true &&
                <EditContent handleDelClose={handleDelClose} show={showEditAcc} contentId={contentId} contentScreen={contentScreen} recommendedImage={recommendedImage} subscriptionImage={subscriptionImage} pageNum={pageNum} page={contentScreen === 'Recommended Leagues' ? 2 : 7} />
            }
        </>
    )
}

export default AdContent;