import React, { useState, useEffect } from 'react';
import usStates from 'us-state-codes';
import { Button, Modal, Dropdown, FormControl, InputGroup } from 'react-bootstrap';
import { fetchRoundUsers, scheduleRounds } from '../../api/request';
import { ghinLogin } from '../../api/ghinApi/ghinLogin.js';
import { searchGolfCourses } from '../../api/ghinApi/searchGolfCourses.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import svg from '../../assets/images/svg/index';
import Select from 'react-select';
import { useWindowSize } from "../../utils/useWindowSize.js";
import Invitefriends from './Invitefriends.jsx';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import './scheduleround.scss';

let mobileBreakpoint = 821;
const ScheduleRound = (props) => {
    const validationSchema = Yup.object().shape({
        selectedOption: Yup.string().required('Golf course is required'),
        getDate: Yup.string().required('Date is required'),
        getTime: Yup.string().required('Time is required'),
        holeVal: Yup.object().shape({
            label: Yup.string().required('Holes are required'),
            value: Yup.number().required('Holes are required'),
        }),
        holeNumVal: Yup.object().shape({
            label: Yup.string().required('Starting from Holes are required'),
            value: Yup.number().required('Starting from Holes are required'),
        }),
        roundTypeVal: Yup.object().shape({
            label: Yup.string().required('Round type is required'),
            value: Yup.string().required('Round type is required'),
        }),
    });
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    useEffect(() => {
        handleGhinLogin();
        setholesNumberOptions(initialholesNumberOptions?.slice(0, initialholesNumberOptions?.length / 2))
    }, []);

    const handleGhinLogin = async () => {
        let resp = await ghinLogin();
        setToken(resp?.token);
    }

    const fetchPlayerOptions = async (searchTerm) => {
        try {
            let resp = await fetchRoundUsers(searchTerm);
            return resp;
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'Error in fetching players')
        }
    }

    const handleUserImage = (data) => {
        if (data?.profile_image === null && data?.id === null) {
            return svg.DefUser
        }
        if (data?.id !== null && data?.profile_image !== null) {
            return data?.profile_image
        }
        if (data?.id !== null && data?.profile_image === null) {
            return svg.DefUser
        }
    }

    const handleAddPlayer = (data) => {
        const arr = [...playingUsers];

        let userProfile = handleUserImage(data);
        // Find the first empty slot
        const emptySlotIndex = arr.findIndex((player) => !player.name && player.profile_pic === null);

        if (emptySlotIndex !== -1) {
            const fullName = data?.first_name + " " + data?.last_name;
            arr[emptySlotIndex] = {
                id: data?.id,
                name: fullName,
                profile_pic: userProfile
            };
            setPlayingUsers(arr);
        } else {
            // Display a message or handle the case when there are no empty slots
            toast.error('No empty slots available for players');
        }
    }

    const [golfCourseOptions, setGolfCourseOptions] = useState([]);
    const [playingUsers, setPlayingUsers] = useState([{
        id: Number(localStorage?.getItem('userId')),
        name: 'You',
        profile_pic: props?.userProfilePic
    }, {
        id: null,
        name: '',
        profile_pic: null
    }, {
        id: null,
        name: '',
        profile_pic: null
    }, {
        id: null,
        name: '',
        profile_pic: null
    }]);
    const [getTime, setTime] = useState('');
    const [getDate, setDate] = useState('');
    const [showInviteFriends, setShowInviteFriends] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [getSelectedGolfcourse, setSelectedGolfcourse] = useState({});
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [playerselectedOption, setPlayerSelectedOption] = useState('');
    const [playerfilteredOptions, setPlayerFilteredOptions] = useState([]);
    const [isPlayerDropdownOpen, setIsPlayerDropdownOpen] = useState(false);
    const [holesOptions, setholesOptions] = useState([{
        label: '9 holes',
        value: 9
    },
    {
        label: '18 holes',
        value: 18
    }]);
    const [holeVal, setHoleVal] = useState({
        label: '9 holes',
        value: 9
    });

    const [stateOptions, setStateOptions] = useState([
        { "label": "Alabama", "value": "US-AL" },
        { "label": "Alaska", "value": "US-AK" },
        { "label": "Arizona", "value": "US-AZ" },
        { "label": "Arkansas", "value": "US-AR" },
        { "label": "California", "value": "US-CA" },
        { "label": "Colorado", "value": "US-CO" },
        { "label": "Connecticut", "value": "US-CT" },
        { "label": "Delaware", "value": "US-DE" },
        { "label": "Florida", "value": "US-FL" },
        { "label": "Georgia", "value": "US-GA" },
        { "label": "Hawaii", "value": "US-HI" },
        { "label": "Idaho", "value": "US-ID" },
        { "label": "Illinois", "value": "US-IL" },
        { "label": "Indiana", "value": "US-IN" },
        { "label": "Iowa", "value": "US-IA" },
        { "label": "Kansas", "value": "US-KS" },
        { "label": "Kentucky", "value": "US-KY" },
        { "label": "Louisiana", "value": "US-LA" },
        { "label": "Maine", "value": "US-ME" },
        { "label": "Maryland", "value": "US-MD" },
        { "label": "Massachusetts", "value": "US-MA" },
        { "label": "Michigan", "value": "US-MI" },
        { "label": "Minnesota", "value": "US-MN" },
        { "label": "Mississippi", "value": "US-MS" },
        { "label": "Missouri", "value": "US-MO" },
        { "label": "Montana", "value": "US-MT" },
        { "label": "Nebraska", "value": "US-NE" },
        { "label": "Nevada", "value": "US-NV" },
        { "label": "New Hampshire", "value": "US-NH" },
        { "label": "New Jersey", "value": "US-NJ" },
        { "label": "New Mexico", "value": "US-NM" },
        { "label": "New York", "value": "US-NY" },
        { "label": "North Carolina", "value": "US-NC" },
        { "label": "North Dakota", "value": "US-ND" },
        { "label": "Ohio", "value": "US-OH" },
        { "label": "Oklahoma", "value": "US-OK" },
        { "label": "Oregon", "value": "US-OR" },
        { "label": "Pennsylvania", "value": "US-PA" },
        { "label": "Rhode Island", "value": "US-RI" },
        { "label": "South Carolina", "value": "US-SC" },
        { "label": "South Dakota", "value": "US-SD" },
        { "label": "Tennessee", "value": "US-TN" },
        { "label": "Texas", "value": "US-TX" },
        { "label": "Utah", "value": "US-UT" },
        { "label": "Vermont", "value": "US-VT" },
        { "label": "Virginia", "value": "US-VA" },
        { "label": "Washington", "value": "US-WA" },
        { "label": "West Virginia", "value": "US-WV" },
        { "label": "Wisconsin", "value": "US-WI" },
        { "label": "Wyoming", "value": "US-WY" }
    ]
    );
    const [stateVal, setStateVal] = useState(stateOptions[0]);
    const [initialholesNumberOptions, setinitialholesNumberOptions] = useState([{
        label: 'Hole 1',
        value: 1
    },
    {
        label: 'Hole 2',
        value: 2
    },
    {
        label: 'Hole 3',
        value: 3
    },
    {
        label: 'Hole 4',
        value: 4
    },
    {
        label: 'Hole 5',
        value: 5
    },
    {
        label: 'Hole 6',
        value: 6
    },
    {
        label: 'Hole 7',
        value: 7
    },

    {
        label: 'Hole 8',
        value: 8
    },
    {
        label: 'Hole 9',
        value: 9
    }, {
        label: 'Hole 10',
        value: 10
    },
    {
        label: 'Hole 11',
        value: 11
    },
    {
        label: 'Hole 12',
        value: 12
    },
    {
        label: 'Hole 13',
        value: 13
    },
    {
        label: 'Hole 14',
        value: 14
    },
    {
        label: 'Hole 15',
        value: 15
    },
    {
        label: 'Hole 16',
        value: 16
    },

    {
        label: 'Hole 17',
        value: 17
    },
    {
        label: 'Hole 18',
        value: 18
    }]);
    const [holesNumberOptions, setholesNumberOptions] = useState([{
        label: 'Hole 1',
        value: 1
    },
    {
        label: 'Hole 2',
        value: 2
    },
    {
        label: 'Hole 3',
        value: 3
    },
    {
        label: 'Hole 4',
        value: 4
    },
    {
        label: 'Hole 5',
        value: 5
    },
    {
        label: 'Hole 6',
        value: 6
    },
    {
        label: 'Hole 7',
        value: 7
    },

    {
        label: 'Hole 8',
        value: 8
    },
    {
        label: 'Hole 9',
        value: 9
    }, {
        label: 'Hole 10',
        value: 10
    },
    {
        label: 'Hole 11',
        value: 11
    },
    {
        label: 'Hole 12',
        value: 12
    },
    {
        label: 'Hole 13',
        value: 13
    },
    {
        label: 'Hole 14',
        value: 14
    },
    {
        label: 'Hole 15',
        value: 15
    },
    {
        label: 'Hole 16',
        value: 16
    },

    {
        label: 'Hole 17',
        value: 17
    },
    {
        label: 'Hole 18',
        value: 18
    }]);
    const [holeNumVal, setHoleNumVal] = useState({
        label: '',
        value: 0,
    });

    const [roundTypeOptions, setroundTypeOptions] = useState([{
        label: 'Front 9',
        value: 'F9'
    },
    {
        label: 'Back 9',
        value: 'B9'
    }]);
    const [roundTypeVal, setroundTypeVal] = useState({
        label: 'Front 9',
        value: 'F9'
    });
    const [getToken, setToken] = useState('');

    const handleHolesDropdpwn = (event) => {
        setHoleVal(event);
        // Update formErrors for selectedOption
        const selectedOptionError = validateHolesOption(event?.value);
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            'holeVal.label': selectedOptionError,
        }));
    }

    const handleStateDropdpwn = (event) => {
        setStateVal(event);
    }

    const handleHolesNumberDropdpwn = (event) => {
        setHoleNumVal(event);
        // Update formErrors for selectedOption
        const selectedOptionError = validateHolesNumberOption(event?.value);
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            'holeNumVal.label': selectedOptionError,
        }));
    }

    const handleRoundTypeDropdpwn = (event) => {
        setroundTypeVal(event);
        // Update formErrors for selectedOption
        const selectedOptionError = validateRoundTypeOption(event?.value);
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            'roundTypeVal.label': selectedOptionError,
        }));
        let holeNumValue = [];
        if (event?.value === 'F9') {
            holeNumValue = initialholesNumberOptions?.slice(0, initialholesNumberOptions?.length / 2);
            setholesNumberOptions(initialholesNumberOptions?.slice(0, initialholesNumberOptions?.length / 2));
        }
        else {
            holeNumValue = initialholesNumberOptions?.slice(initialholesNumberOptions?.length / 2, initialholesNumberOptions?.length);
            setholesNumberOptions(initialholesNumberOptions?.slice(initialholesNumberOptions?.length / 2, initialholesNumberOptions?.length))
        }
        setHoleNumVal(holeNumValue[0]);
    }

    const validateRoundTypeOption = (value) => {
        if (!value) {
            return 'Round type is required';
        }
        return ''; // No error
    };

    const validateHolesNumberOption = (value) => {
        if (!value) {
            return 'Starting from Hole numbers are required';
        }
        return ''; // No error
    };

    const handleTimeChange = (e) => {
        setTime(e?.target?.value);
        const dateError = validateTime(e?.target?.value);
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            getTime: dateError,
        }));
    }

    const validateTime = (value) => {
        if (!value) {
            return 'Time is required';
        }
        return ''; // No error
    };

    const validateDate = (value) => {
        if (!value) {
            return 'Date is required';
        }
        return ''; // No error
    };

    const handleDateChange = (e) => {
        setDate(e?.target?.value);
        const dateError = validateDate(e?.target?.value);
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            getDate: dateError,
        }));
    }

    const validateSelectedOption = (value) => {
        if (!value) {
            return 'Golf course is required';
        }
        return ''; // No error
    };

    const validateHolesOption = (value) => {
        if (!value) {
            return 'Hole numers are required';
        }
        return ''; // No error
    };

    const handleInputChange = async (e) => {
        const inputValue = e.target.value.toLowerCase();
        setSelectedOption(inputValue);
        setIsPlayerDropdownOpen(false);
        try {
            const filtered = await searchGolfCourses(getToken, inputValue, stateVal?.value);
            setFilteredOptions(filtered);
            setIsDropdownOpen(inputValue.length > 0);

            // Update formErrors for selectedOption
            const selectedOptionError = validateSelectedOption(inputValue);
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                selectedOption: selectedOptionError,
            }));
        }
        catch (error) {
            console.error('Error while searching golf courses:', error);
        }
    };

    const handlePlayerInputChange = async (e) => {
        setIsDropdownOpen(false);
        const inputValue = e.target.value.toLowerCase();
        setPlayerSelectedOption(inputValue);
        let returnedVal = await fetchPlayerOptions(inputValue);
        let matchedItems = [];

        for (const returnedItem of returnedVal) {
            let isMatch = false;
            for (const playingUser of playingUsers) {
                if (returnedItem.id === playingUser.id) {
                    isMatch = true;
                    break; // No need to continue checking once a match is found
                }
            }
            if (!isMatch) {
                matchedItems.push(returnedItem);
            }
        }
        setPlayerFilteredOptions(matchedItems);
        setIsPlayerDropdownOpen(inputValue.length > 0);
    };


    const handleSelectOption = (option) => {
        setSelectedOption(option?.FullName);
        setSelectedGolfcourse(option);
        setIsDropdownOpen(false);
    };
    const handlePlayerSelectOption = (option) => {
        setPlayerSelectedOption(option?.name);
        setIsPlayerDropdownOpen(false);
    };

    const handleClose = () => {
        props.handleCloseScheduleRound();
    }

    const handleInviteFriendsClose = () => {
        setShowInviteFriends(false);
    }

    const handleInviteFriends = () => {
        setShowInviteFriends(true);
    }

    const handlescheduleRound = async () => {
        try {
            await validationSchema.validate(
                {
                    selectedOption,
                    getDate,
                    getTime,
                    holeVal,
                    playingUsers,
                    holeNumVal,
                    roundTypeVal,
                },
                { abortEarly: false }
            );
            let arr = [];
            for (let i = 0; i < playingUsers?.length; i++) {
                if (playingUsers[i]?.id !== null) {
                    arr?.push(playingUsers[i]?.id);
                }
            }
            const body = {
                golf_course: {
                    "name": getSelectedGolfcourse?.FullName,
                    "facility": getSelectedGolfcourse?.FacilityID,
                    "usga_id": getSelectedGolfcourse?.CourseID,
                    "address_line1": getSelectedGolfcourse?.Address1,
                    "address_line2": getSelectedGolfcourse?.Address2,
                    "longitude": getSelectedGolfcourse?.GeoLocationLongitude,
                    "latitude": getSelectedGolfcourse?.GeoLocationLatitude,
                    "city": getSelectedGolfcourse?.City,
                    "state_code": getSelectedGolfcourse?.State,
                    "country": getSelectedGolfcourse?.Country,
                    "phone": getSelectedGolfcourse?.Telephone,
                    "active": getSelectedGolfcourse?.CourseStatus,
                    "course_name": getSelectedGolfcourse?.CourseName,
                },
                round_date: getDate,
                round_time: getTime,
                holes: holeVal?.value,
                players: arr,
                round_type: roundTypeVal?.value,
                start_hole_number: holeNumVal?.value
            }
            try {
                let resp = await scheduleRounds(body);
                if (resp) {
                    toast.success('Round created successfully');
                    window?.location?.reload();
                }
            }
            catch (err) {
                toast.error(err?.response?.data?.message || 'Error in scheduling a round');
            }
        }
        catch (error) {
            const errors = {};
            if (error.path === 'holeVal.label' || error.path === 'holeVal.value') {
                errors.holeVal = error.message;
            } else {
                // Handle validation errors for other fields
                error.inner.forEach((e) => {
                    errors[e.path] = e.message;
                });
            }
            setFormErrors(errors);
        }
    }

    return (
        <>
            {showInviteFriends === false &&
                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    className='schedule-round-wrapper'
                    show={props.show}
                    onHide={handleClose}
                    dialogClassName={getwidth < mobileBreakpoint && "modal-from-bottom"} // Apply the custom CSS class
                    animation={false} // Disable built-in animations
                    centered={getwidth < mobileBreakpoint ? false : true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="modalTitle">Schedule a Round</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modalBody">
                        <div className="del-sub">
                            Begin a round now or register an upcoming round you have booked and invite your friends to join you!
                        </div>

                        <div className='holes-dropdown w-100 marginTop15'>
                            <div className='search-golf-course-label'>State</div>
                            <div className='state-dropdown-body mt-2'>
                                <Select
                                    options={stateOptions}
                                    value={stateVal}
                                    placeholder={
                                        <div className="select-placeholder-text">Select</div>
                                    }
                                    onChange={handleStateDropdpwn}
                                    className="w-100 holes-select-dropdown"
                                    components={{
                                        Option: (props) => (
                                            <div
                                                {...props.innerProps}
                                                className={'holes-select-item'}
                                            >
                                                {props.children}
                                            </div>
                                        ),
                                    }}
                                />
                                {formErrors['holeVal.label'] && <div className="error text-danger-txt">{formErrors['holeVal.label']}</div>}
                            </div>
                        </div>

                        <div className='schedule-round-body w-100'>
                            <div className='search-golf-course-label'>Where are you playing?</div>
                            <Dropdown className='mt-2'>
                                <InputGroup>
                                    <>{selectedOption === '' &&
                                        <span className="search-icon">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.0625 15.625C12.6869 15.625 15.625 12.6869 15.625 9.0625C15.625 5.43813 12.6869 2.5 9.0625 2.5C5.43813 2.5 2.5 5.43813 2.5 9.0625C2.5 12.6869 5.43813 15.625 9.0625 15.625Z" stroke="#4A514C" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.7026 13.7031L17.4996 17.5" stroke="#4A514C" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </span>
                                    }</>
                                    <input
                                        type="text"
                                        value={selectedOption}
                                        className="SearchLabel searchUsers w-100"
                                        style={{
                                            padding: selectedOption === '' ? '10px 10px 10px 40px' : '10px 10px 10px 10px', '::placeholder': { color: '#565957', fontSize: '16px', fontWeight: '400' },
                                        }}
                                        onChange={handleInputChange}
                                        placeholder="Search for a golf course...."
                                    />
                                </InputGroup>
                                {formErrors?.selectedOption && <div className="error text-danger-txt">{formErrors?.selectedOption}</div>}
                                <Dropdown.Menu show={isDropdownOpen} className='users-dropdown' style={{ display: isDropdownOpen ? 'block' : 'none' }}>
                                    {filteredOptions?.map((option, index) => (
                                        <Dropdown.Item
                                            key={index}
                                            onClick={() => handleSelectOption(option)}
                                        >
                                            {/* {option} */}
                                            <div className="user-name">{option.FullName}</div>
                                            <div className="user-address">{option.City}, {option?.Country}</div>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>

                            <div className='round-date-time w-100 marginTop15'>
                                <div className='search-golf-course-label'>When is your tee time?</div>
                                <div className='round-date-time-body d-flex mt-2'>
                                    <input className="form-control startDate" type="date" min={new Date()?.toISOString()?.split('T')[0]} onChange={handleDateChange} value={getDate} pattern="\d{1,2}\/\d{1,2}\/\d{2,4}" />
                                    <input className="form-control startDate" type="time" onChange={
                                        handleTimeChange} value={getTime} step="60" />
                                </div>

                                <div className='round-date-time-body d-flex'>
                                    {formErrors?.getDate && <div className="error text-danger-txt w-50">{formErrors?.getDate}</div>}
                                    {formErrors?.getTime && <div className="error text-danger-txt w-50 errorMsg">{formErrors?.getTime}</div>}
                                </div>
                            </div>

                            <div className='holes-dropdown w-100 marginTop15'>
                                <div className='search-golf-course-label'>How many holes did you schedule?</div>
                                <div className='holes-dropdown-body mt-2'>
                                    <Select
                                        options={holesOptions}
                                        value={holeVal}
                                        placeholder={
                                            <div className="select-placeholder-text">Select</div>
                                        }
                                        onChange={handleHolesDropdpwn}
                                        className="w-100 holes-select-dropdown"
                                        components={{
                                            Option: (props) => (
                                                <div
                                                    {...props.innerProps}
                                                    className={'holes-select-item'}
                                                >
                                                    {props.children}
                                                </div>
                                            ),
                                        }}
                                    />
                                    {formErrors['holeVal.label'] && <div className="error text-danger-txt">{formErrors['holeVal.label']}</div>}
                                </div>
                            </div>

                            <div className='round-date-time-body d-flex marginTop15'>
                                <div className='search-golf-course-label w-50'>Round Type</div>
                                <div className='search-golf-course-label w-50'>Starting from</div>
                            </div>

                            <div className='holes-dropdown w-100 mt-1'>
                                <div className='round-date-time-body d-flex'>
                                    <Select
                                        options={roundTypeOptions}
                                        value={roundTypeVal}
                                        placeholder={
                                            <div className="select-placeholder-text">Select</div>
                                        }
                                        onChange={handleRoundTypeDropdpwn}
                                        className="w-100 holes-select-dropdown"
                                        components={{
                                            Option: (props) => (
                                                <div
                                                    {...props.innerProps}
                                                    className={'holes-select-item'}
                                                >
                                                    {props.children}
                                                </div>
                                            ),
                                        }}
                                    />

                                    <Select
                                        options={holesNumberOptions}
                                        value={holeNumVal}
                                        placeholder={
                                            <div className="select-placeholder-text">Select</div>
                                        }
                                        onChange={handleHolesNumberDropdpwn}
                                        className="w-100 holes-select-dropdown"
                                        components={{
                                            Option: (props) => (
                                                <div
                                                    {...props.innerProps}
                                                    className={'holes-select-item'}
                                                >
                                                    {props.children}
                                                </div>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='round-date-time-body d-flex justify-between'>
                                {formErrors['holeNumVal.label'] && <div className="error text-danger-txt w-50 floatRIght">{formErrors['holeNumVal.label']}</div>}
                                {formErrors['roundTypeVal.label'] && <div className="error text-danger-txt w-50 errorMsg">{formErrors['roundTypeVal.label']}</div>}
                            </div>

                            <div className='playing-users w-100 marginTop15'>
                                <div className='search-golf-course-label'>Who are you playing with?</div>
                                <div className='playing-users-body mt-2'>
                                    <Dropdown className='mt-2'>
                                        <InputGroup>
                                            <>{playerselectedOption === '' &&
                                                <span className="search-icon">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.0625 15.625C12.6869 15.625 15.625 12.6869 15.625 9.0625C15.625 5.43813 12.6869 2.5 9.0625 2.5C5.43813 2.5 2.5 5.43813 2.5 9.0625C2.5 12.6869 5.43813 15.625 9.0625 15.625Z" stroke="#4A514C" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M13.7026 13.7031L17.4996 17.5" stroke="#4A514C" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                            }</>
                                            <input
                                                type="text"
                                                value={playerselectedOption}
                                                className="SearchLabel searchUsers w-100"
                                                style={{
                                                    padding: playerselectedOption === '' ? '10px 10px 10px 40px' : '10px 10px 10px 10px', '::placeholder': { color: '#565957', fontSize: '16px', fontWeight: '400' },
                                                }}
                                                onChange={handlePlayerInputChange}
                                                placeholder="Search for Linx members...."
                                            />
                                        </InputGroup>
                                        <Dropdown.Menu show={isPlayerDropdownOpen} className='players-dropdown' style={{ display: isPlayerDropdownOpen ? 'block' : 'none' }}>
                                            {playerfilteredOptions?.map((option, index) => (
                                                <Dropdown.Item
                                                    key={index}
                                                    onClick={() => handlePlayerSelectOption(option)}
                                                    className='player-dropdown-item d-flex justify-content-between'
                                                >
                                                    <div className='player-detail d-flex'>
                                                        <img src={handleUserImage(option)} className="user-profile" alt="imasge NP" />
                                                        <div className="user-name">{option.first_name} {option.last_name}</div>
                                                    </div>
                                                    <Button className='add-player-btn' onClick={() => handleAddPlayer(option)} > Add</Button>
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>

                            <div className='playing-users-listing w-100 marginTop15'>
                                {[...Array(4)].map((_, index) => {
                                    const playerData = playingUsers[index];
                                    return (
                                        <div className='playing-user-info d-flex flex-column align-item-center' key={index}>
                                            <div className='playing-user-pic'>
                                                {
                                                    playerData?.profile_pic !== null && index !== 0 && (
                                                        <img src={playerData?.profile_pic} className={index === 0 ? 'player-img activeUser' : 'player-img'} alt='Player Imdage' />
                                                    )
                                                }
                                                {
                                                    (playerData?.profile_pic !== null && index === 0) &&
                                                    <img src={playerData?.profile_pic} className={index === 0 ? 'player-img activeUser' : 'player-img'} alt='Player Imdage' />
                                                }
                                                {
                                                    (playerData?.profile_pic === null && index !== 0) &&
                                                    (
                                                        <img src={svg?.AddPlayer} className='add-player' alt='Add Player' />
                                                    )
                                                }
                                                {
                                                    (playerData?.profile_pic === null && index === 0) &&
                                                    (
                                                        <img src={svg?.DefUser} className='add-player' alt='Add Player' />
                                                    )
                                                }
                                            </div>
                                            <div className='player-name trimTxt1line'>{playerData?.name || 'Add Player'}</div>
                                        </div>
                                    );
                                })}
                            </div>

                        </div>
                        <div className='del-buttons-wrapper w-100'>
                            {/* <div className='or-txt'>Or</div> */}
                            <Button className="cancelBtn w-50" onClick={() => { handleInviteFriends() }
                            }>
                                Invite Friends
                            </Button>
                            <Button
                                onClick={() => handlescheduleRound()}
                                className="delBtn w-50">
                                Confirm
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal >
            }
            {
                showInviteFriends === true &&
                <Invitefriends show={showInviteFriends} handleClose={handleInviteFriendsClose} />
            }
        </>
    );
};

export default ScheduleRound;
