import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { logout } from '../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import './delete.scss';

const Logout = (props) => {
    const navigate = useNavigate();
    const handleClose = () => {
        props.handleDelClose();
    }

    const handleLogout = async () => {
        try {
            let resp = await logout();
            if (resp?.message) {
                toast.success(resp?.message);
                localStorage.removeItem('email')
                localStorage.removeItem('token')
                localStorage.removeItem('userId');
                localStorage.removeItem('on_boarding_complete');
                localStorage.removeItem('refer_url');
                localStorage.removeItem('userProfile');
                localStorage.removeItem('userEmail');
                localStorage.removeItem('isAdmin');
                localStorage.removeItem('holeIndex')
                localStorage.removeItem('roundId')
                if (props?.adminLogout === 'true') {
                    navigate('/admin/login');
                }
                else {
                    navigate('/login');
                }
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='delete-acc-wrapper'
            show={props.show}
            onHide={handleClose}
            centered>
            <Modal.Header>
                <Modal.Title className="modalTitle">Log Out</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <div className="del-sub">
                    Are you sure you want to Log Out?
                </div>
                <div className='del-buttons-wrapper w-100'>
                    <Button
                        className="delBtn w-100" onClick={() => handleLogout()}>
                        Log Out
                    </Button>
                    <Button className="cancelBtn w-100" onClick={handleClose}>
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default Logout;
