import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { deleteAccount } from '../../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
// import './delete.scss';

const DeleteNotifications = (props) => {
    console.log('selected row data in DeleteNotifications', props?.data);
    const navigate = useNavigate();

    const handleClose = () => {
        props.handleDelClose();
    }

    const handleDelAccount = async () => {
        let body = { user_id: Number(localStorage.getItem('userId')) };
        try {
            let resp = await deleteAccount(body);
            console.log('repsonse from deleteAccount', resp);
        }
        catch (error) {
            console.log('Error in deleteAccount', error);
            toast.error(error?.message || 'An error occurred while processing your request.')
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='delete-acc-wrapper'
            show={props.show}
            onHide={handleClose}
            centered>
            <Modal.Header>
                <Modal.Title className="modalTitle">Delete {props?.data?.length > 1 ? 'Notifications' : 'Notification'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <div className="del-sub">
                    Are you sure you want to Delete {props?.data?.length} {props?.data?.length > 1 ? 'Notifications' : 'Notification'} ? This cannot be undone.
                </div>
                <div className='del-buttons-wrapper w-100'>
                    <Button
                        onClick={() => handleDelAccount()}
                        className="delBtn w-100">
                        Delete {props?.data?.length > 1 ? 'Notifications' : 'Notification'}
                    </Button>
                    <Button className="cancelBtn w-100" onClick={handleClose}>
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DeleteNotifications;
