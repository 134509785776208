import React, { useState, useEffect } from "react";
import svg from '../../assets/images/svg/index';
import { Menu, MenuItem } from '@mui/material';
import { useWindowSize } from "../../utils/useWindowSize.js";
import { formatTime } from '../../utils/formatTime.js';
import { getUserProfile, fetchUserNotifications, readNotifications, acceptRejectNotification } from '../../api/request';
import { slide as MobMenu } from 'react-burger-menu';
import { hoursAgo } from '../../utils/formatHoursAgo.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Logout from '../../components/Settings/Logout';
import NotiTeeOff from './NotiTeeOff.jsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

let mobileBreakpoint = 821;
const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [noFound, setNoFound] = useState(false);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const [anchorE2, setAnchorE2] = useState(null);
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0);
    const [userProfile, setUserProfile] = useState('');
    const [showLogout, setShowLogout] = useState(false);
    const [activeNavItem, setActiveNavItem] = useState('');
    const [notificationsData, setNotificationsData] = useState([]);
    const [showTeeOff, setShowTeeOff] = useState(false);
    const [isNotiRead, setisNotiRead] = useState(true);
    const [roundData, setRoundData] = useState({})
    const [userData, setUserData] = useState({})

    const handleTeeOff = (data) => {
        setAnchorE2(null);
        setNotificationsOpen(false);
        setAnchorEl(null);
        setOpen(false);
        setRoundData(data?.roundData);
        setUserData(data?.userData)
        setShowTeeOff(true)
    }

    const closeEditRound = () => {
        setShowTeeOff(false);
    }

    const displayNotiTxt = (data) => {
        if (data?.invitation_type === 'round_invitation') {
            return (
                <div className="noti-txt trimTxt2lines"><span className="u-name">{data?.user_name?.length > 11 ? data?.user_name?.slice(0, 11) : data?.user_name}</span> invited you to an upcoming round at <span className="u-name">{data?.round_name?.length > 24 ? data?.round_name?.slice(0, 23) : data?.round_name}</span> <div className="noti-date">{data?.start_date}</div>
                    <div className="noti-date">{formatTime(data?.start_time)}</div></div>
            )
        }
        if (data?.invitation_type === 'round_accepted') {
            return (
                <div className="noti-txt trimTxt2lines"><span className="u-name">{data?.user_name?.length > 11 ? data?.user_name?.slice(0, 11) : data?.user_name}</span> accepted your invite for an upcoming round at <span className="u-name">{data?.round_name?.length > 24 ? data?.round_name?.slice(0, 23) : data?.round_name}</span><div className="noti-date">{data?.start_date}</div>
                    <div className="noti-date">{formatTime(data?.start_time)}</div></div>
            )
        }
        if (data?.invitation_type === 'round_rejected') {
            return (
                <div className="noti-txt trimTxt2lines"><span className="u-name">{data?.user_name?.length > 11 ? data?.user_name?.slice(0, 11) : data?.user_name}</span> rejected your invite for an upcoming round at <span className="u-name">{data?.round_name?.length > 24 ? data?.round_name?.slice(0, 23) : data?.round_name}</span><div className="noti-date">{data?.start_date}</div>
                    <div className="noti-date">{formatTime(data?.start_time)}</div></div>
            )
        }

        if (data?.invitation_type === 'removed_from_round') {
            return (
                <div className="noti-txt trimTxt2lines">You have been removed from <span className="u-name">{data?.round_name?.length > 24 ? data?.round_name?.slice(0, 23) : data?.round_name}</span><div className="noti-date">{data?.start_date}</div>
                    <div className="noti-date">{formatTime(data?.start_time)}</div></div>
            )
        }
        if (data?.invitation_type === 'round_tee_off') {
            return (
                <div className="noti-txt trimTxt2lines">You have an upcoming round today at <span className="u-name">{data?.round_name?.length > 24 ? data?.round_name?.slice(0, 23) : data?.round_name}</span><div className="noti-date">{data?.start_date}</div>
                    <div className="noti-date">{formatTime(data?.start_time)}</div></div>
            )
        }
        if (data?.invitation_type === 'round_updated') {
            return (
                <div className="noti-txt trimTxt2lines">Your round <span className="u-name">{data?.round_name?.length > 24 ? data?.round_name?.slice(0, 23) : data?.round_name}</span> has been updated<div className="noti-date">{data?.start_date}</div>
                    <div className="noti-date">{formatTime(data?.start_time)}</div></div>
            )
        }
    }

    const handleAcceptNotifications = (data) => {
        callAcceptRejectNotifications(data?.round_id, 'accept');
    }

    const handleRejectNotifications = (data) => {
        callAcceptRejectNotifications(data?.round_id, 'reject');
    }

    const callAcceptRejectNotifications = async (id, action) => {
        const body = {
            id: id,
            'action': action
        }
        try {
            let resp = await acceptRejectNotification(body);
            toast.success(resp?.message || `Notification invitation ${body.action}ed`);
            getUserNotifications()
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occured while accepting/rejecting notifications');
        }
    }
    const handleLogout = () => {
        setShowLogout(true);
        setOpen(false);
    }
    const handleDelClose = () => {
        setShowLogout(false);
    }

    useEffect(() => {
        // Determine the active navigation item based on the current location
        if (location.pathname === '/home') {
            setActiveNavItem('Home');
        } else if (location.pathname === '/standings') {
            setActiveNavItem('Standings');
        }
        else if (location.pathname === '/standings/history') {
            setActiveNavItem('Standings');
        }
        else if (location.pathname === '/exploreLeagues') {
            setActiveNavItem('Explore');
        }
    }, [location]);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width])

    useEffect(() => {
        fetchUserData();
        getUserNotifications();
    }, []);


    const fetchUserData = async () => {
        try {
            let resp = await getUserProfile();
            setUserProfile(resp?.profile_image);
        }
        catch (err) {
            console.log('err in user details', err);
        }
    }

    const resetNotificationReadStatus = async () => {
        try {
            let resp = await readNotifications();
            toast.success('Notification read successfully');
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occurred while reading notifications')
        }
    }

    const getUserNotifications = async () => {
        try {
            let resp = await fetchUserNotifications();
            let arr = [];
            for (let i = 0; i < resp?.length; i++) {
                arr?.push({
                    id: resp[i]?.id,
                    user_name: `${resp[i]?.sender?.first_name} ${resp[i]?.sender?.last_name}`,
                    user_profile: resp[i]?.sender?.profile_image || svg?.DefaultUser,
                    round_name: resp[i]?.notification?.round?.golf_course,
                    invitation_type: resp[i]?.notification?.type,
                    start_date: resp[i]?.notification?.round?.round_date,
                    start_time: resp[i]?.notification?.round?.round_time,
                    created_time: resp[i]?.notification?.created_date?.split(' ')[1],
                    round_id: resp[i]?.notification?.round?.id,
                    userData: resp[i]?.receiver,
                    roundData: resp[i]?.notification?.round
                })
            }
            const readFlag = resp?.filter((data) => data?.is_read === false);
            if (readFlag?.length === 0) {
                setisNotiRead(true);
            }
            else {
                setisNotiRead(false);
            }
            setNotificationsData(arr);
            if (resp?.length === 0) {
                setNoFound(true);
            }
            else {
                setNoFound(false);
            }
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occurred while processing your request.')
        }
    }

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };
    const handleMenuClose = () => {
        setAnchorE2(null);
        setNotificationsOpen(false);
        setAnchorEl(null);
        setOpen(false);
    };

    const handleNotificationsMenuOpen = (event) => {
        setAnchorEl(null);
        setOpen(false);
        setAnchorE2(event?.currentTarget);
        getUserNotifications();
        if (isNotiRead === false) {
            resetNotificationReadStatus();
        }
        getUserNotifications();
        setNotificationsOpen(true);
    }

    const handleNotificationsMenuClose = () => {
        setAnchorE2(null);
        setNotificationsOpen(false);
    };

    return (
        <>
            <div className="navbar-wrapper">
                <div className="navbar-logo">
                    <img src={width >= mobileBreakpoint ? svg?.Navlogo : svg?.MobileNavLogo} className="navbar-logo-img cursor-pointer" alt="logo Np" 
                    onClick={() => navigate('/home')} 
                     />
                </div>

                <div className="nav-items">
                    {/* <div className={`nav-item ${activeNavItem === 'Home' ? 'active' : ''}`}
                        onClick={() => navigate('/home')}>Home</div> */}

                    <div className={`nav-item ${(activeNavItem === 'Standings') ? 'active' : ''}`}
                        onClick={() => navigate('/standings')}>Standings</div>
                    <div
                        className={`nav-item ${activeNavItem === 'Explore' ? 'active' : ''}`}
                        onClick={() => navigate('/exploreLeagues')}>Explore</div>
                </div>

                <div className="user-nav-item">
                    <div className="noti-icon-wrapper">
                        <img src={svg.Bellicon} className="bell-icon" alt="bell icon NP" onClick={handleNotificationsMenuOpen} />
                        <>{isNotiRead === false && <div className="green-dot"></div>}</>
                    </div>
                    <div className="settings-nav">
                        <img src={svg.Listicon} className="list-icon" alt="list icon NP" onClick={handleMenuOpen} />
                        <img src={(userProfile === '' || userProfile === null) ? svg.DefUser : userProfile} className="user-icon" alt="user icon NP" />
                    </div>
                </div>
            </div>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                className="notifications-wrapper">
                <MenuItem
                    key={0}
                    className="menu-item-noti"
                    onClick={() => navigate('/userprofile')}
                // onClick={() => handleNotifications(item.id, item.is_read)}
                >
                    Account
                </MenuItem>

                <MenuItem
                    key={0}
                    className="menu-item-noti"
                    onClick={() => navigate('/managepayments')}
                // onClick={() => handleNotifications(item.id, item.is_read)}
                >
                    Payments
                </MenuItem>

                <MenuItem
                    key={0}
                    className="menu-item-noti"
                    onClick={() => navigate('/settings')}
                >
                    Settings
                </MenuItem>

                <MenuItem
                    key={0}
                    className="menu-item-noti"
                    onClick={() => handleLogout()}
                >
                    Log out
                </MenuItem>
            </Menu>
            <Menu
                anchorEl={anchorE2}
                open={notificationsOpen}
                onClose={handleNotificationsMenuClose}
                className="notifications-wrapper-1">
                <MenuItem className="noti-heading">Notifications</MenuItem>
                <>{
                    noFound === true ? <div class="alert alert-danger error-msg text-center no-res-found" role="alert">No Records Exist</div>
                        :
                        <>
                            {notificationsData?.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    className="menu-item-noti">
                                    <div className="notification-items-wrapper d-flex flex-column">
                                        <div className="menu-item-div1">
                                            {isNotiRead === false && <div className="green-dot"></div>
                                            }
                                            <div className="noti-user-profile"><img src={item?.user_profile} className={isNotiRead === false ? "user-icon-green user-profile w-100 h-100" : "user-profile w-100 h-100"} alt="use profile Np" /></div>
                                            <div className="noti-txt-info">{displayNotiTxt(item)}</div>
                                        </div>
                                        <div className="noti-created-time">{hoursAgo(item?.created_time) > 1 ? `${hoursAgo(item?.created_time)} hours ago` : `${hoursAgo(item?.created_time)} hour ago`} </div>
                                        {
                                            item?.invitation_type === 'round_tee_off' &&
                                            <div className="accept-reject-btns d-flex align-items-center">
                                                <Button className='tee-off-btn' onClick={() => handleTeeOff(item)}>Tee Off</Button>
                                            </div>
                                        }

                                        {
                                            item?.invitation_type === 'round_invitation' &&
                                            <div className="accept-reject-btns d-flex align-items-center">
                                                <Button className='tee-off-btn' onClick={() => {
                                                    handleAcceptNotifications(item)
                                                }}>Accept</Button>
                                                <Button className='reject-btn' onClick={() => {
                                                    handleRejectNotifications(item)
                                                }}>Reject</Button>
                                            </div>
                                        }
                                    </div>
                                </MenuItem>
                            ))}</>
                }</>

            </Menu>
            {
                showLogout === true &&
                <Logout handleDelClose={handleDelClose} show={showLogout} />
            }
            {
                showTeeOff === true &&
                <NotiTeeOff show={showTeeOff} handleTeeOffPopupClose={closeEditRound} roundData={roundData} userData={userData} />
            }
        </>
    )
}

export default Navbar