import React, { useState } from "react";
import Logo from '../../../assets/images/svg/Logo.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { resendLink } from '../../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

const VerifyEmail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userEmail = localStorage.getItem('userEmail');

    const handleSubmit = async (event) => {
        event?.preventDefault()
        try {
            let resp = await resendLink({ email: userEmail });
            toast.success(resp?.message || 'Verification link sent successfully');
        }
        catch (err) {
            toast.error(err?.resp?.data?.message || 'An error occured while sending verification link');
        }
    }

    return (
        <div className="verify-email-wrapper d-flex justify-content-center">
            <div className="user-signin-form-wrapper w-100">
                <img src={Logo} className="app-logo" alt="app logo NP" />
                <form
                    className="login-form width335"
                    onSubmit={handleSubmit}
                    noValidate>
                    <div className="verify-content w-100">
                        <div className="signin_heading">Verify your email</div>

                        <div className="verify-email-txt">
                            In order to start using your Linx League account you need to verify your email address.
                        </div>
                        <div className="d-grid">
                            <button
                                type="submit"
                                className="btn btn-primary signin_btn"
                                style={{ width: '100%' }}>
                                Resend Link
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default VerifyEmail;