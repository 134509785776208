import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';
import Select from 'react-select';
import svg from '../../../assets/images/svg/index';
import * as FileSaver from 'file-saver';
import { fetchAdminUsers, fetchGenderAdminUsers, fetchAdminGhin } from '../../../api/request';
import * as XLSX from 'xlsx';
import MoveToPopup from './MoveToPopup.jsx';
import Loader from '../../../CustomComponents/Loader';
import SpecifcUsers from './SpecifcUsers.jsx';
import { handleFetchLocation } from '../../../utils/fetchLocation.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    useTable,
    usePagination,
    useSortBy,
    useRowSelect,
    useGlobalFilter,
} from 'react-table';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';

const AdminAppusers = () => {
    const navigate = useNavigate();
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [getNextPage, setNextPage] = useState(false);
    const [getPrevPage, setPrevPage] = useState(false);
    const [getData, setData] = useState({});
    const [loading, setLoading] = useState(true); // Add loading state
    const [noFound, setNoFound] = useState(false);
    const [products, setProducts] = useState([]);
    const productsRef = useRef(products);
    const [search, setSearch] = useState('');
    const [locations, setLocations] = useState({});
    const [userId, setUserId] = useState(null);
    const [showSingleUser, setshowSingleUser] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(true);
    const [genderOptions, setgenderOptions] = useState([{
        label: 'MALE',
        value: 'MALE'
    },
    {
        label: 'FEMALE',
        value: 'FEMALE'
    },
    {
        label: 'ALL',
        value: 'ALL'
    }])
    const [handicapVal, setHandicapVal] = useState('');
    const [handicapOptions, setHandicapOptions] = useState([])
    const [genderVal, setgenderVal] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [rowCheckboxStates, setRowCheckboxStates] = useState({});
    const [showMovePopup, setMovePopup] = useState(false);

    const closeshowSingleUser = () => {
        setshowSingleUser(false);
        fetchUsers();
    }

    const handlePrevPage = () => {
        if (getData?.next !== null) {
            setNextPage(false);
        }
        if (getData?.previous !== null) {
            const urlParams = new URLSearchParams(new URL(getData?.previous).search);
            const pageValue = urlParams.get("page");
            if (pageValue === null) {
                setCurrentPageIndex(1);
                fetchUsers('1', genderVal?.value, handicapVal?.value);
            }
            else {
                setCurrentPageIndex(currentPageIndex - 1);
                fetchUsers(pageValue, genderVal?.value, handicapVal?.value);
            }
        }
        else {
            setPrevPage(true);
        }
    }

    const handleNextPage = () => {
        if (getData?.previous !== null) {
            setPrevPage(false);
        }
        if (getData?.next !== null) {
            const urlParams = new URLSearchParams(new URL(getData?.next).search);
            const pageValue = urlParams.get("page");
            setCurrentPageIndex(currentPageIndex + 1);
            fetchUsers(pageValue, genderVal?.value, handicapVal?.value);
        }
        else {
            setNextPage(true);
        }
    };

    const handleGenderDropdown = (event) => {
        setCurrentPageIndex(1);
        setgenderVal(event);
        fetchFilteredData(event?.value, handicapVal?.value, search);
    }
    const handleHandicapDropdown = (event) => {
        setCurrentPageIndex(1);
        setHandicapVal(event);
        fetchFilteredData(genderVal?.value, event?.value,  search);
    }
    const handleMovePopupClose = () => {
        setMovePopup(false);
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    // useEffect(() => {
    //     const fetchLocationData = async () => {
    //         const updatedLocations = {};
    //         for (const row of products) {
    //             const location = await handleFetchLocation(row.zip_code);
    //             updatedLocations[row.zip_code] = location;
    //         }
    //         setLocations(updatedLocations);
    //     };

    //     fetchLocationData();
    // }, [products]);


    // const fetchGhin = async () => {
    //     setLoading(true);
    //     try {
    //         let resp = await fetchAdminGhin();
    //         let arr = [];
    //         if (resp) {
    //             setLoading(false);
    //             for (let i = 0; i < resp?.length; i++) {
    //                 arr?.push({
    //                     label: resp[i]?.ghin_score,
    //                     value: resp[i]?.ghin_score,
    //                 })
    //             }
    //             setHandicapOptions(arr);
    //         }
    //     }
    //     catch (err) {
    //         setLoading(false);
    //         if (err?.response?.data?.detail === "Invalid token.") {
    //             navigate('/admin/login');
    //         }
    //     }
    // }

    const fetchUsers = async (pageValue) => {
        setLoading(true);
        try {
            let resp = await fetchAdminUsers(pageValue || currentPageIndex, genderVal?.value, handicapVal?.value, search);
            if (resp) {
                setProducts(resp?.results);
                setData(resp);
                if (resp?.next !== null) {
                    setNextPage(false);
                }
                setTotalCount(resp?.count);
                setLoading(false);
            }
        }
        catch (err) {
            setLoading(false);
            if (err?.response?.data?.detail === "Invalid token.") {
                navigate('/admin/login');
            }
        }
    }

    const fetchFilteredData = async (value, handicap, search) => {
        let resp = await fetchGenderAdminUsers(value, handicap, search);
        if (resp) {
            setProducts(resp?.results);
            setData(resp);
            if (resp?.next !== null) {
                setNextPage(false);
            }
            setTotalCount(resp?.count);
            setLoading(false);
        }
    }

    useEffect(() => {
        // Update the ref value whenever the products state changes
        productsRef.current = products;
    }, [products]);

    const data = useMemo(() => products, [products]);

    const handleSelectAllRows = (event) => {

        const isChecked = event?.target?.checked;
        const updatedRowCheckboxStates = productsRef.current.reduce(
            (checkboxStates, row) => {
                checkboxStates[row.id] = isChecked;
                return checkboxStates;
            },
            {}
        );

        setSelectedRows(isChecked ? productsRef.current : []);
        setRowCheckboxStates(updatedRowCheckboxStates);
        setSelectAllChecked(isChecked)
    };

    const handleSelectRow = (event, row) => {
        const isChecked = event?.target?.checked;
        setRowCheckboxStates((prevCheckboxStates) => ({
            ...prevCheckboxStates,
            [row?.original?.id]: isChecked,
        }));
        if (isChecked) {
            setSelectedRows((prevSelected) => [...prevSelected, row.original]);;
        } else {
            setSelectedRows((prevSelected) =>
                prevSelected.filter((selectedRow) => selectedRow.id !== row.original.id)
            );
        }
    };

    useEffect(() => {
    }, [selectedRows]);

    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    useEffect(() => {
        // Reset selectedRows when data changes
        setSelectedRows([]);
    }, [data]);

    const columns = useMemo(
        () => [
            {
                Header: (
                    <>
                        <input
                            type="checkbox"
                            onChange={(event) => {
                                handleSelectAllRows(event);
                            }}
                            className="custom-checkbox"
                        />
                        <span className='header-txt'>User Name</span>
                    </>
                ),
                Cell: ({ row }) => (
                    <div className='uname-cell cursor-pointer'
                    >
                        <input
                            type="checkbox"
                            checked={rowCheckboxStates[row.original.id] || false}
                            onChange={(event) => {
                                handleSelectRow(event, row);
                            }
                            }
                            className="custom-checkbox"
                        />
                        <span className='header-cell' onClick={() => {
                            setUserId(row?.original?.id);
                            setshowSingleUser(true);
                        }
                        }>{row?.original?.full_name} {row?.original?.is_reported === true && <img src={svg?.Flag} className='user-flag-img' alt="user flag" />}</span>
                    </div>
                ),
                accessor: 'full_name',
                sticky: 'left',
                disableSortBy: selectAllChecked,
            },
            {
                Header: 'Email',
                accessor: 'email',
                sticky: 'left',
                disableSortBy: false,
            },
            // {
            //     Header: 'Location',
            //     // Cell: ({ row }) => <div>{handleFetchLocation(row?.original?.zip_code)}</div>,
            //     Cell: ({ row }) => <span>{locations[row?.original?.zip_code]}</span>,
            //     sticky: 'left',
            //     disableSortBy: false,
            // },
            {
                Header: 'Handicap',
                accessor: 'ghin_score',
                sticky: 'left',
                disableSortBy: false,
            },
            {
                Header: 'Gender',
                accessor: 'gender',
                sticky: 'left',
                disableSortBy: false,
            },
            {
                Header: 'Referrals',
                accessor: 'referrals',
                sticky: 'left',
                disableSortBy: false,
            },
        ],
        [rowCheckboxStates, selectAllChecked],
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
    );

    const pageCount = Math.ceil(totalCount / 10);

    return (
        <>
            {
                loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" />
                    :
                    <div className="admin-users-wrapper adminpadding">
                        <div className='admin-users-head-wrapper d-flex align-items-center' style={{ gap: '10px' }}>
                            <>{showSingleUser === true && <img src={svg?.UserBack} className='back-arrow cursor-pointer' alt="backNP" onClick={() => setshowSingleUser(false)} />}</>
                            <div className="admin-users-heading">
                                Users
                            </div>
                        </div>

                        <>{showSingleUser === true ? <SpecifcUsers userId={userId} closeshowSingleUser={closeshowSingleUser} />
                            :
                            <div className="admin-users-body">
                                <div
                                    style={{
                                        padding: '0px 30px',
                                        backgroundColor: '##F3F4F6',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: '20px',
                                        borderColor: '#1334B9',
                                    }}>
                                </div>
                                <>
                                    <div className='app-users-filters-wrapper d-flex justify-content-between'>
                                        <div className='app-users-filters d-flex'>
                                            <div className="search-bar w-75">
                                                <input
                                                    type="text"
                                                    value={search}
                                                    className="SearchLabel searchUsers w-100"
                                                    onChange={(e) => {
                                                        setSearch(e?.target?.value);
                                                    }}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            setSearch(e?.target?.value);
                                                            fetchFilteredData(genderVal?.value, handicapVal?.value, e?.target?.value);
                                                        }
                                                    }}
                                                    placeholder="Search"
                                                    style={{ '::placeholder': { color: '#4A514C99' } }}
                                                />

                                                <span className="search-icon" onClick={() => {
                                                    fetchFilteredData(genderVal?.value, handicapVal?.value, search);
                                                }}>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.75293 12.8767C8.13916 12.8767 9.41748 12.4285 10.4634 11.6814L14.3979 15.616C14.5806 15.7986 14.8213 15.8899 15.0786 15.8899C15.6182 15.8899 16 15.4749 16 14.9436C16 14.6946 15.917 14.4539 15.7344 14.2795L11.8247 10.3616C12.6465 9.28247 13.1362 7.94604 13.1362 6.49341C13.1362 2.98218 10.2642 0.110107 6.75293 0.110107C3.25 0.110107 0.369629 2.97388 0.369629 6.49341C0.369629 10.0046 3.2417 12.8767 6.75293 12.8767ZM6.75293 11.4988C4.01367 11.4988 1.74756 9.23267 1.74756 6.49341C1.74756 3.75415 4.01367 1.48804 6.75293 1.48804C9.49219 1.48804 11.7583 3.75415 11.7583 6.49341C11.7583 9.23267 9.49219 11.4988 6.75293 11.4988Z" fill="#88BD36" />
                                                    </svg>

                                                </span>
                                            </div>
                                            <div className='app-users-dropdown d-flex w-50'>
                                                <Select
                                                    options={genderOptions}
                                                    value={genderVal}
                                                    placeholder={
                                                        <div className="select-placeholder-text">Gender</div>
                                                    }
                                                    onChange={handleGenderDropdown}
                                                    className="w-100 gender-dropdown"
                                                />

                                                {/* <Select
                                                    options={handicapOptions}
                                                    value={handicapVal}
                                                    placeholder={
                                                        <div className="select-placeholder-text">Handicap</div>
                                                    }
                                                    onChange={handleHandicapDropdown}
                                                    className="w-100 gender-dropdown"
                                                /> */}
                                            </div>
                                        </div>
                                        <>{selectedRows?.length > 0 &&
                                            <div className='admin-header-btns'>
                                                {/* <Button className='move-btn cursor-pointer' onClick={() => setMovePopup(true)}>Move to</Button> */}
                                                <Button className='move-btn cursor-pointer' onClick={(e) => exportToCSV(selectedRows, 'Users Listing')}>Download as .csv</Button>
                                            </div>
                                        }</>
                                    </div>
                                    <div className="table-container mt-4">
                                        <table {...getTableProps()} className="table-cls w-100">
                                            <thead className='app-users-table-head'>
                                                {headerGroups?.map((headerGroup) => (
                                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                                        {headerGroup.headers?.map((column) => (
                                                            <th
                                                                {...column.getHeaderProps(
                                                                    column.getSortByToggleProps(),
                                                                )}>
                                                                {column.render('Header')}
                                                                <span>
                                                                    &nbsp;
                                                                    {column.disableSortBy === false ? (
                                                                        <Image src={svg?.Selector} className='cursor-pointer'></Image>
                                                                    ) : null}
                                                                </span>
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            <tbody {...getTableBodyProps()}>
                                                {page?.map((row) => {
                                                    prepareRow(row);
                                                    return (
                                                        <tr
                                                            {...row?.getRowProps()}
                                                            className='table-body-row'
                                                        >

                                                            {row?.cells?.map((cell, cellIndex) => {
                                                                return (
                                                                    <td {...cell?.getCellProps()}>
                                                                        {cell.render('Cell')}
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="paginationBox d-flex justify-content-center mt-4">
                                        <div className='pagination-body d-flex justify-content-center align-items-center'>
                                            <img src={svg.Previcon}
                                                onClick={() => {
                                                    if (getData?.previous !== null) handlePrevPage()
                                                }}
                                                disabled={getData?.previous === null}
                                                className={getData?.previous !== null ? 'previcon' : 'disabled'} alt="prev-icon-NP" />
                                            {Array.from({ length: pageCount }, (_, index) => (
                                                <button
                                                    key={index}
                                                    onClick={() => {
                                                        setCurrentPageIndex(index + 1)
                                                        fetchUsers(index + 1, genderVal?.value, handicapVal?.value)
                                                    }}
                                                    className={`paginationButton ${currentPageIndex === index + 1 ? 'activeButton' : ''
                                                        }`}>
                                                    {index + 1}
                                                </button>
                                            ))}
                                            <img src={svg.Nexticon} alt="prev-icon-NP"
                                                onClick={() => {
                                                    if (getData?.next !== null) handleNextPage()
                                                }}
                                                disabled={getData?.next === null}
                                                className={getData?.next !== null ? 'previcon' : 'disabled'} />
                                        </div>
                                    </div>
                                </>
                            </div>
                        }</>
                    </div>

            }
            {
                showMovePopup === true &&
                <MoveToPopup show={showMovePopup} handleMovePopupClose={handleMovePopupClose} />
            }
        </>
    )
}

export default AdminAppusers;