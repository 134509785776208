import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from "../../utils/useWindowSize.js";
import Select from 'react-select';
import { roundTeeOff } from '../../api/request.js'
import Loader from '../../CustomComponents/Loader';
import TeeOffTooltip from '../../CustomComponents/TeeOffTooltip';
import './teeOff.scss';

let mobileBreakpoint = 821;
const TeeOff = (props) => {
    const navigate = useNavigate();
    const teeSet = props?.teeSetListing;
    const [teeSetOptions, setTeeSetOptions] = useState([]);
    const [teeSetVal, setTeeSetVal] = useState({});
    const [tierError, setTierError] = useState(false);
    const [getwidth, setWidth] = useState(0)
    const [loading, setLoading] = useState(true);
    const [noFound, setNoFound]= useState(false);
    const [height, width] = useWindowSize()


    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    useEffect(() => {
        handleTeeSetDropDown();
    }, [teeSet]);

    const handleTeeSetDropDown = () => {
        setLoading(true);
        const arr = [];
        for (let i = 0; i < teeSet?.length; i++) {
            arr?.push({
                label: `${teeSet[i]?.TeeSetRatingName} ${teeSet[i]?.TotalYardage}y    ${teeSet[i]?.TotalPar}`,
                value: teeSet[i]?.TeeSetRatingId
            })
        }
        setTeeSetOptions(arr);
        setTeeSetVal(arr[0]);
            setLoading(false);
    }

    const handleTierDropdown = (event) => {
        setTeeSetVal(event);
        setTierError(false);
    }

    const handleClose = () => {
        props.handleTeeOffPopupClose();
    }

    const handleTeeConfirm = async () => {
        let filteredTeeSet = [];
        let holesArr = [];
        let tempHolesArr = []
        let roundType = props?.roundData?.round_type;
        filteredTeeSet = teeSet.filter((item) => item?.TeeSetRatingId === teeSetVal?.value);

        if (roundType === 'F9') {
            tempHolesArr = [...filteredTeeSet[0]?.Holes?.slice(0, ((filteredTeeSet[0]?.Holes?.length) / 2))];
        }
        else {
            tempHolesArr = [...filteredTeeSet[0]?.Holes?.slice(9, filteredTeeSet[0]?.Holes?.length)];
        }

        for (let i = 0; i < tempHolesArr?.length; i++) {
            holesArr?.push({
                usga_hole_id: tempHolesArr[i]?.HoleId,
                number: tempHolesArr[i]?.Number,
                par: tempHolesArr[i]?.Par,
                length: tempHolesArr[i]?.Length,
                allocation: tempHolesArr[i]?.Allocation || 0,
            })
        }

        let ratingsArr = filteredTeeSet[0]?.Ratings;
        let frontObj = ratingsArr?.filter((item) => item?.RatingType === 'Front');
        let backObj = ratingsArr?.filter((item) => item?.RatingType === 'Back');
        let frontParCount = 0;
        let backParCount = 0;
        let frontHoles = filteredTeeSet[0]?.Holes;
        for (let i = 0; i < filteredTeeSet[0]?.HolesNumber / 2; i++) {
            frontParCount += frontHoles[i]?.Par
        }

        for (let i = 9; i < filteredTeeSet[0]?.HolesNumber; i++) {
            backParCount += frontHoles[i]?.Par
        }
        const body = {
            tee_set: {
                usga_id: filteredTeeSet[0]?.TeeSetRatingId,
                name: filteredTeeSet[0]?.TeeSetRatingName,
                gender: filteredTeeSet[0]?.Gender,
                total_yards: filteredTeeSet[0]?.TotalYardage,
                total_meters: filteredTeeSet[0]?.TotalMeters,
                total_par: filteredTeeSet[0]?.TotalPar,
                front_par: frontParCount,
                back_par: backParCount,
                back_rating: backObj[0]?.CourseRating,
                front_rating: frontObj[0]?.CourseRating,
                slope: filteredTeeSet[0]?.Ratings[0]?.SlopeRating

            },
            holes: holesArr
        }

        try {
            let resp = await roundTeeOff(body, props?.roundData?.id);
            toast.success(resp?.message || 'Tee off done successfully');
            navigate('/scorecard', {
                state: {
                    roundId: props?.roundData?.id
                }
            });

        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occured in tee_off');
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='tee-off-popup-wrapper'
            show={props.show}
            onHide={handleClose}
            dialogClassName={getwidth < mobileBreakpoint && "modal-from-bottom"}
            animation={false} // Disable built-in animations
            centered={getwidth < mobileBreakpoint ? false : true}
        >
            <Modal.Header closeButton className='move-popup-header d-flex'>
                <Modal.Title className="modalTitle move-to-title">Round  Setup</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <div className="del-sub">
                    {props?.roundData?.golf_course?.name}
                </div>

                <div className='select-wrapper w-100'>
                    <div className="mt-2">
                        <label className="greyLabel move-to-league-label">Select a Tee</label>
                        &nbsp;
                        <TeeOffTooltip />
                        {
                            loading === true ? <Loader loadingMsg="Data is Loading... Please Wait" />
                                :
                                <Select
                                    options={teeSetOptions}
                                    value={teeSetVal}
                                    placeholder={
                                        <div className="select-placeholder-text">Select</div>
                                    }
                                    onChange={handleTierDropdown}
                                    className="w-100 gender-dropdown mt-2"
                                />
                        }
                    </div>
                    <>{tierError === true &&
                        <div className="invalid-feedback displayBlock">
                            Select a Tee is required
                        </div>
                    }</>
                </div>

                <div className='del-buttons-wrapper w-100 mt-2'>
                    <Button
                        onClick={() => handleTeeConfirm()}
                        className="delBtn w-100">
                        Confirm
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default TeeOff;
