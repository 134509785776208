import React, { useState, useEffect } from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { Form, Row, Col, Image } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import svg from '../../../assets/images/svg/index'
import { editLeague } from '../../../api/request';
import { toast } from 'react-toastify';
import {
    Radio
} from "@material-ui/core";
import './styles.scss';

const EditLeague = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const leagueData = location?.state?.leaguedata;

    const handleSelImage = () => {
        if (selImage) {
            if (selImage instanceof File) {
                return URL.createObjectURL(selImage);
            }
            else {
                return selImage
            }
        }
        else {
            return svg?.UploadFile
        }
    }

    const displayGender = (genderData) => {
        if (genderData === 1) {
            return 'Male'
        }
        if (genderData === 2) {
            return 'Female'
        }
        if (genderData === 3) {
            return 'Both'
        }
    }

    const displayStatus = (data) => {
        if (data === 1) {
            return 'Planned'
        }
        if (data === 2) {
            return 'Active'
        }
        if (data === 3) {
            return 'Completed'
        }
    }

    const displayType = (data) => {
        if (data === 1) {
            return 'Competitive'
        }
        else if (data === 2) {
            return 'Recreational'
        }
    }

    const [genderVal, setgenderVal] = useState({
        label: displayGender(leagueData?.gender), value: leagueData?.gender
    });
    const [leagueResp, setLeagueResp] = useState({});
    const [gender, setGender] = useState([{
        label: 'Male',
        value: 1,
    },
    {
        label: 'Female',
        value: 2,
    },
    {
        label: 'Both',
        value: 3,
    }]);

    const [leagueStatus, setleagueStatus] = useState([{
        label: 'Planned',
        value: 1,
    },
    {
        label: 'Active',
        value: 2
    },
    {
        label: 'Completed',
        value: 3
    }]);
    const [leagueStatusVal, setleagueStatusVal] = useState({
        label: displayStatus(leagueData?.status), value: leagueData?.status
    });
    const [genderError, setGenderError] = useState(false);
    const [statusError, setStatusError] = useState(false);
    const [selImage, setSelImage] = useState(leagueData?.cover_image);

    const handleGenderDropdown = (event) => {
        setgenderVal(event);
        setGenderError(false)
    }

    const handleStatusDropdown = (event) => {
        setleagueStatusVal(event);
        setStatusError(false);
    }

    const handleFileInputClick = async (e) => {
        setSelImage(e?.target?.files[0]);
    }
    const schema = Yup.object().shape({
        name: Yup.string().required('League Name is required'),
        leagueType: Yup.string().required('League Type is required'),
    });

    return (
        <div className="create-league-wrapper adminpadding">
            <div className="season-back-arrow-cont d-flex align-items-center" style={{
                gap:
                    '10px'
            }}>
                <img src={svg?.SeasonBack} alt="back arrow NP" className="back-arrow-img cursor-pointer" onClick={() => navigate(-1)} />
                <div className="create-league-heading">
                    Edit League
                </div>
            </div>

            <div className="create-league-body mt-4">
                <Formik
                    validationSchema={schema}
                    initialValues={{ name: leagueData?.name || "", leagueType: String(leagueData?.type) || '', description: leagueData?.description || '' }}
                    onSubmit={async (values) => {
                        let formData = new FormData();
                        formData.append('name', values?.name);
                        formData.append('gender', genderVal?.value);
                        formData.append('status', leagueStatusVal?.value || '');
                        formData.append('description', values?.description || '');
                        formData.append('type', values?.leagueType || '');
                        if (selImage) {
                            if (selImage instanceof File) {
                                formData.append('cover_image', selImage);
                            }
                        }
                        try {
                            let resp = await editLeague(formData, leagueData?.id);
                            if (resp) {
                                toast.success('League updated successfully');
                                setLeagueResp(resp);
                                navigate(-1)
                            }
                        }
                        catch (error) {
                            toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
                        }
                    }}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        isValid,
                        dirty,
                        /* and other goodies */
                    }) => (
                        <form
                            className="create-league-form w-75"
                            onSubmit={handleSubmit}
                            noValidate>
                            <div className="Auth-form-content w-100">
                                <Form.Group controlId="name">
                                    <label htmlFor="name" className="greyLabel">
                                        League Name
                                    </label>
                                    <div
                                        className={`form-floating mt-2 mb-3 ${touched.name && errors.name
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            type="name"
                                            name="name"
                                            // placeholder="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-100"
                                            isInvalid={touched.name && errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>

                                <label className="greyLabel">Gender</label>
                                <Select
                                    options={gender}
                                    value={genderVal}
                                    placeholder={
                                        <div className="select-placeholder-text">Select</div>
                                    }
                                    onChange={handleGenderDropdown}
                                    className="w-100 mt-1"
                                />
                                <>{genderError === true &&
                                    <div className="invalid-feedback displayBlock">
                                        Gender is required
                                    </div>
                                }</>

                                <>
                                    <label className="greyLabel mt-3">Status</label>
                                    <Select
                                        options={leagueStatus}
                                        value={leagueStatusVal}
                                        placeholder={
                                            <div className="select-placeholder-text">Select</div>
                                        }
                                        onChange={handleStatusDropdown}
                                        className="w-100 mt-1"
                                    />
                                    {statusError === true &&
                                        <div className="invalid-feedback displayBlock">
                                            League status is required
                                        </div>
                                    }</>

                                <Form.Group controlId="leagueType" className="w-100 mt-3">
                                    <label htmlFor="leagueType" className="greyLabel account-label">
                                        League type
                                    </label>
                                    <div
                                        className={`form-floating mb-3 ${touched.leagueType && errors.leagueType
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <div className="">
                                            <label>
                                                <Radio value="1" name="leagueType"
                                                    checked={values.leagueType === "1"}
                                                    onChange={() => setFieldValue("leagueType", "1")}
                                                />
                                                Competitive
                                            </label>
                                            <label>
                                                <Radio value="2" name="leagueType" checked={values.leagueType === "2"}
                                                    onChange={() => setFieldValue("leagueType", "2")} />
                                                Recreational
                                            </label>
                                        </div>
                                        <Form.Control.Feedback className="invalid-feedback displayBlock">
                                            {errors.leagueType}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>

                                <Form.Group controlId="description">
                                    <label htmlFor="description" className="greyLabel">
                                        Description
                                    </label>
                                    <div
                                        className={`form-floating mt-2 mb-3 ${touched.description && errors.description
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            type="description"
                                            name="description"
                                            // placeholder="name"
                                            value={values.description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-100"
                                            isInvalid={touched.description && errors.description}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.description}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>


                                <label htmlFor="name" className="greyLabel mt-2">
                                    Cover Image
                                </label>

                                <>{selImage &&
                                    <div className="upload-bodycont mt-2">
                                        <label id="file-input-label-rec" for="file-input-rec"
                                        >Edit File</label>
                                        <input type="file" id="file-input-rec"
                                            className="file-input-rec" name="file-input"
                                            accept="image/*"
                                            onChange={(e) => handleFileInputClick(e)} />
                                    </div>
                                }</>
                                <div className='upload-content-cont mt-2'>
                                    <div className={selImage ? "upload-body content-list-image p-0" : "upload-body content-list-image"}>
                                        <img src={handleSelImage()} className={selImage ? "sel-img" : "def-photo"} alt="def NP" />
                                        <div className="upload-bodycont">
                                            <input type="file" id="file-input-rec"
                                                className="file-input-rec" name="file-input"
                                                accept="image/*"
                                                onChange={(e) => handleFileInputClick(e)} />

                                            <>{
                                                !selImage &&
                                                <label id="file-input-label-rec" for="file-input-rec"
                                                >Upload File</label>

                                            }
                                            </>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-grid gap-2 mt-3">
                                    <button
                                        type="submit"
                                        onClick={() => {
                                            if (genderVal?.length === 0) {
                                                setGenderError(true)
                                            }
                                            if (leagueStatusVal?.length === 0) {
                                                setStatusError(true);
                                            }
                                            else {
                                                setGenderError(false);
                                                setStatusError(false);
                                            }
                                        }
                                        }
                                        className="btn btn-primary create-league-btn">
                                        Update league
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default EditLeague;