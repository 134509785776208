export function convertTimeToHoursMinutes(givenTime, completed_at) {
    const timeDifference = calculateTimeDifference(givenTime, completed_at);
    const newtime = `${timeDifference?.hours}h ${timeDifference?.minutes}min`;
    return newtime;
}

function calculateTimeDifference(givenTime, completed_at) {
    const givenDate = new Date(givenTime);
    let currentDate;
    if (completed_at) {
        currentDate = new Date(completed_at);
    }
    else {
        currentDate = new Date();
    }

    const timeDifference = currentDate - givenDate;
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    return { hours, minutes };
}
