import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useWindowSize } from "../../utils/useWindowSize.js";
import { reportUser } from '../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

let mobileBreakpoint = 821;
const ReportUser = (props) => {
    const [replyTxt, setreplyTxt] = useState('');
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)

    const handleClose = () => {
        props.handleCloseReportPopup();
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    const handleReportPopup = async () => {
        let body = {
            user: props?.userId,
            message: replyTxt?.replace(/\n/g, " ")
        };
        try {
            let resp = await reportUser(body);
            toast.success(resp?.message || 'User reported successfully');
            props.handleCloseReportPopup();
        }
        catch (error) {
            toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='report-user-popup-wrapper'
            show={props.show}
            onHide={handleClose}
            dialogClassName={getwidth < mobileBreakpoint && "modal-from-bottom"} // Apply the custom CSS class
            animation={false} // Disable built-in animations
            centered={getwidth < mobileBreakpoint ? false : true}>
            <Modal.Header closeButton>
                <Modal.Title className="modalTitle">Report User</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <div className="del-sub">
                    Please write your problem
                </div>
                <div className="reply-feedback-body mt-3">
                    <label className="msg-label">Message</label>
                    <textarea className="msg-text-area w-100" row={5} placeholder="Type here..." onChange={(e) => {
                        setreplyTxt(e?.target?.value)
                    }} style={{
                        '::placeholder': {
                            fontFamily: 'Proxima Nova Condensed',
                            fontSize: '16px',
                            color: '#4A514C',
                        },
                    }} />
                </div>
                <div className='del-buttons-wrapper w-100'>
                    <Button
                        onClick={() => handleReportPopup()}
                        className="delBtn w-100">
                        Submit
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ReportUser;
