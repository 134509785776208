import React, { useState } from "react";
import Logo from '../../../assets/images/svg/Logo.svg';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Row, Col, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import svg from '../../../assets/images/svg/index'
import { login } from '../../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye,
    faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import './styles.scss';

const Login = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const schema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required').matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
            "Password must contain at least 8 characters, one capital letter, one number, and one special character")
    });

    return (
        <div className="admin-signin-wrapper d-flex justify-content-center">
            <div className="user-signin-form-wrapper w-50 mt-4">
                <img src={svg?.AdminLogo} className="admin-logo" alt="app logo NP" />
                <Formik
                    validationSchema={schema}
                    initialValues={{ email: localStorage.getItem("email") || "", password: localStorage.getItem("password") || "" }}
                    onSubmit={async (values) => {
                        let body = {
                            "username": values.email,
                            "password": values.password
                        }
                        try {
                            let resp = await login(body);
                            if (resp?.token) {
                                localStorage.setItem('token', resp?.token)
                                localStorage.setItem('userId', resp?.user?.id);
                                localStorage.setItem('userProfile', resp?.user?.profile?.profile_image);
                                localStorage.setItem('on_boarding_complete', resp?.on_boarding_complete);
                                localStorage.setItem('refer_url', resp?.user?.refer_url);
                                localStorage.setItem('isAdmin', resp?.admin);
                                toast.success('Admin logged in successfully');
                                if (resp?.admin === true) {
                                    window.location.href = '/admin/dashboard'
                                } else {
                                    window.location.href = '/onboarding'
                                }
                            }
                        }
                        catch (error) {
                            toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
                        }
                    }}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                        /* and other goodies */
                    }) => (
                        <form
                            className="login-form w-50"
                            onSubmit={handleSubmit}
                            noValidate>
                            <div className="Auth-form-content w-100">
                                <div className="signin_heading">Sign In</div>
                                <Form.Group controlId="email">
                                    <label htmlFor="email" className="greyLabel">
                                        Email
                                    </label>
                                    <div
                                        className={`form-floating mt-2 mb-3 ${touched.email && errors.email
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-100"
                                            isInvalid={touched.email && errors.email}
                                        />
                                        {!values.email && (
                                            <label htmlFor="email" className="greyLabel">
                                                Email
                                            </label>
                                        )}
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="password">
                                    <label htmlFor="password" className="greyLabel">
                                        Password
                                    </label>
                                    <div
                                        className={`form-floating mb-3 mt-2 pass-div ${touched.password && errors.password
                                            ? 'has-validation-error'
                                            : ''
                                            }`}>
                                        <Form.Control
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            placeholder="Password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.password && errors.password}
                                        />
                                        <div className="input-group-append eyeicon-img">
                                            <FontAwesomeIcon
                                                icon={showPassword ? faEye : faEyeSlash}
                                                style={{ color: '#2563EB' }}
                                                onClick={() => setShowPassword(!showPassword)}
                                            />
                                        </div>
                                        {
                                        !values.password && (
                                            <label htmlFor="email" className="greyLabel">
                                                Password
                                            </label>
                                        )}
                                        <Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>

                                <Row>
                                    <Col className="forgot_pass cursor-pointer">
                                        <p className="text-end text-primary remember_me">
                                            <span
                                                onClick={() => navigate('/forgot-pass')}
                                            >
                                                Forgot Password?
                                            </span>
                                        </p>
                                    </Col>
                                </Row>

                                <div className="d-grid gap-2 mt-3">
                                    <button
                                        type="submit"
                                        className="btn btn-primary signin_btn"
                                        style={{ width: '100%' }}>
                                        Sign In
                                    </button>
                                </div>

                                <div className="login-footer d-flex align-items-center">
                                    Administration
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>

            <div className="w-50 admin-bg-wrapper">
                <img src={svg?.AdminBg} alt="AdminBg" className="admin-bg" />
            </div>
        </div >
    )
}

export default Login;