import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { deleteAccount } from '../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import './delete.scss';

const DeleteAccountChildPopup = (props) => {
    const [deleteTxt, setDelTxt]= useState('');
    const navigate = useNavigate();

    const handleClose = () => {
        props?.closeDelChild();
    }

    const handleDelAccount = async () => {
        if(deleteTxt?.toLocaleLowerCase()!=='delete'){
            toast.error('Type Delete before proceeding!!');
            return;
        }
        let body = { user_id: Number(localStorage.getItem('userId')) };
        try {
            let resp = await deleteAccount(body);
            toast.success(resp?.message);
            localStorage.removeItem('email')
            localStorage.removeItem('password')
            localStorage.removeItem('rememberme')
            localStorage.removeItem('token')
            localStorage.removeItem('userId');
            localStorage.removeItem('on_boarding_complete');
            localStorage.removeItem('refer_url');
            localStorage.removeItem('userProfile');
            localStorage.removeItem('userEmail');
            localStorage.removeItem('isAdmin');
            localStorage.removeItem('holeIndex')
            localStorage.removeItem('roundId')
            navigate('/signup');
            // }
        }
        catch (error) {
            toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='delete-acc-wrapper'
            show={props?.show}
            onHide={handleClose}
            centered>
            <Modal.Header>
                <Modal.Title className="modalTitle">Delete Account</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <div className="del-sub">
                There is no way back! Are you sure you want to PERMANENTLY delete this account? You will lose all existing records and any remaining credits. Please confirm below by typing 'Delete'.
                </div>

                <div className='delete-body-wrapper w-100'>
                    <label className='del-label'>Type 'Delete'</label>
                    <input type='text' className='del-txt-box' value={deleteTxt} onChange={(e) => setDelTxt(e?.target?.value)} />
                </div>
                <div className='del-buttons-wrapper w-100'>
                    <Button
                        onClick={() => handleDelAccount()}
                        className="delBtn w-100">
                        Confirm
                    </Button>
                    <Button className="cancelBtn w-100" onClick={handleClose}>
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DeleteAccountChildPopup;
