import React, { useEffect, useState } from "react";
import Navbar from "../../CustomComponents/Navbar";
import ManageNotification from './ManageNotification.jsx';
import PrivacyPolicy from './PrivacyPolicy.jsx';
import TermsConditions from './TermsConditions.jsx';
import Changepassword from './Changepassword.jsx';
import Invitefriends from './Invitefriends.jsx';
import SendFeedback from './SendFeedback.jsx';
import DeleteAccount from './DeleteAccount.jsx';
import svg from '../../assets/images/svg/index';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from "../../utils/useWindowSize.js";
import Logout from './Logout.jsx';
import './styles.scss';

let mobileBreakpoint = 821;
const Settings = () => {
    const navigate= useNavigate();
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)
    const [showIndex, setIndex] = useState(1);
    const [showSettingsBody, setshowSettingsBody] = useState(false);
    const [showSettingsBodyMob, setshowSettingsBodyMob] = useState(false);
    const [showDelAcc, setshowDelAcc] = useState(false);
    const [showLogout, setshowLogout] = useState(false);
    const [settingsHeader, setsettingsHeader] = useState('Settings');


    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
            if (getwidth <= mobileBreakpoint) {
                setshowSettingsBody(false);
            }
        }
    }, [width])

    const handleSettingsclick = (id) => {
        if (getwidth <= mobileBreakpoint) {
            setshowSettingsBodyMob(true);
        }
        switch (id) {
            case 1: {
                setIndex(1);
                // setsettingsHeader('Notifications');
                setsettingsHeader('Settings');
                break;
            }
            case 2: {
                setIndex(2);
                setsettingsHeader('Privacy Policy');
                break;
            }
            case 3: {
                setIndex(3);
                setsettingsHeader('Terms and Conditions');
                break;
            }
            case 4: {
                setIndex(4);
                setsettingsHeader('Change Password');
                break;
            }
            case 5: {
                setIndex(5);
                setsettingsHeader('Invite Friends');
                break;
            }
            case 6: {
                setIndex(6);
                setsettingsHeader('Send Feedback');
                break;
            }
            case 7: {
                // setIndex(7);
                setshowDelAcc(true);
                break;
            }
            case 8: {
                setshowLogout(true);
                break;
            }
            default: console.log('default')
        }
    }

    useEffect(() => {
    }, [showIndex])

    const renderComponent = () => {
        switch (showIndex) {
            case 1: return <ManageNotification />
            case 2: return <PrivacyPolicy />
            case 3: return <TermsConditions />
            case 4: return <Changepassword />
            case 5: return <Invitefriends />
            case 6: return <SendFeedback />
            default: console.log('default')
        }
    }

    const handleDelClose = () => {
        setshowDelAcc(false);
        setshowLogout(false);
    }

    return (
        <>
            <>{width >= mobileBreakpoint && <Navbar />}</>
            <div className="settings-wrapper">
                <>{width <= mobileBreakpoint && <div className="heading-wrapper align-items-center">
                    <div className="back-img cursor-pointer" onClick={() => {
                        if(settingsHeader==='Settings'){
                            navigate('/home');
                        }
                        setshowSettingsBodyMob(false)
                        setsettingsHeader('Settings');
                        setIndex(1);
                    }
                        }>
                        <img src={svg?.BackArrow} className="backarrow" alt="back arrow NP" />
                    </div>
                    <div className="settings-heading-mob">
                        {settingsHeader}
                    </div>
                </div>}</>
                <>{width > mobileBreakpoint &&
                    <div className="heading-wrapper">
                        <div className="settings-1"></div>
                        <div className="settings-heading">
                            {settingsHeader}
                        </div>
                        <div className="settings-2"></div>
                    </div>
                }</>
                <div className="settings-body">

                    <>{getwidth > mobileBreakpoint &&
                        <>{showSettingsBody === false &&
                            <div className="settings-navbar mt-1">
                                <div className={showIndex === 1 ? "higlightNavitem navbar-item" : "navbar-item"} onClick={() => handleSettingsclick(1)}>Notifications</div>
                                <div className={showIndex === 2 ? "higlightNavitem navbar-item" : "navbar-item"} onClick={() => handleSettingsclick(2)}>Privacy Policy</div>
                                <div className={showIndex === 3 ? "higlightNavitem navbar-item" : "navbar-item"}
                                    onClick={() => handleSettingsclick(3)}>Terms and Conditions</div>
                                <div className={showIndex === 4 ? "higlightNavitem navbar-item" : "navbar-item"} onClick={() => handleSettingsclick(4)}>Change Password</div>
                                <div className={showIndex === 5 ? "higlightNavitem navbar-item" : "navbar-item"}
                                    onClick={() => handleSettingsclick(5)}>Invite Friends</div>
                                <div className={showIndex === 6 ? "higlightNavitem navbar-item" : "navbar-item"} onClick={() => handleSettingsclick(6)}>Send Feedback</div>
                                <div className={showDelAcc === true ? "higlightNavitem navbar-item" : "navbar-item"} onClick={() => handleSettingsclick(7)}>Delete Account</div>
                                <div className={showLogout === true ? "higlightNavitem navbar-item" : "navbar-item"} onClick={() => handleSettingsclick(8)}>Log Out</div>
                            </div>
                        }</>
                    }
                    </>

                    <>{getwidth <= mobileBreakpoint &&
                        <>{showSettingsBodyMob === false &&
                            <div className="settings-navbar mt-1">
                                <div className={showIndex === 1 ? "higlightNavitem navbar-item" : "navbar-item"} onClick={() => handleSettingsclick(1)}>Notifications</div>
                                <div className={showIndex === 2 ? "higlightNavitem navbar-item" : "navbar-item"} onClick={() => handleSettingsclick(2)}>Privacy Policy</div>
                                <div className={showIndex === 3 ? "higlightNavitem navbar-item" : "navbar-item"}
                                    onClick={() => handleSettingsclick(3)}>Terms and Conditions</div>
                                <div className={showIndex === 4 ? "higlightNavitem navbar-item" : "navbar-item"} onClick={() => handleSettingsclick(4)}>Change Password</div>
                                <div className={showIndex === 5 ? "higlightNavitem navbar-item" : "navbar-item"}
                                    onClick={() => handleSettingsclick(5)}>Invite Friends</div>
                                <div className={showIndex === 6 ? "higlightNavitem navbar-item" : "navbar-item"} onClick={() => handleSettingsclick(6)}>Send Feedback</div>
                                <div className={showDelAcc === true ? "higlightNavitem navbar-item" : "navbar-item"} onClick={() => handleSettingsclick(7)}>Delete Account</div>
                                <div className={showLogout === true ? "higlightNavitem navbar-item" : "navbar-item"} onClick={() => handleSettingsclick(8)}>Log Out</div>
                            </div>
                        }</>
                    }
                    </>
                    <>
                        {getwidth >= mobileBreakpoint ? (
                            <div className="settings-body-txt">
                                {renderComponent()}
                            </div>
                        ) : (
                            <>
                                {showSettingsBodyMob === true && (
                                    <div className="settings-body-txt w-100">
                                        {renderComponent()}
                                    </div>
                                )}
                            </>
                        )}
                    </>


                </div>
            </div>
            {
                showDelAcc === true &&
                <DeleteAccount handleDelClose={handleDelClose} show={showDelAcc} />
            }
            {
                showLogout === true &&
                <Logout handleDelClose={handleDelClose} show={showLogout} />
            }
        </>
    )
}

export default Settings;