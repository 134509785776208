import React, { useEffect, useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Subscription from './Subscription.jsx';
import PaymentformElements from './PaymentformElements';
import './subs.scss';


const SubscriptionPayment = (props) => {
    const [showSub, setHide] = useState(true);
    const [showPayment, setShowPayment] = useState(false);
    const [subData, setSubData] = useState({});
    
    const closePopup = (type) => {
        setHide(false);
        setShowPayment(false);
        props?.handleCloseSubPayment();
    }

    const handleNext = (data, type) => {
        if (type === 'sub') {
            setShowPayment(true);
            setHide(false);
        }
        setSubData(data);
    }

    return (
        <div className="sub-payment-wrapper">
            {showSub && (
                <Subscription
                    show={props?.showHide}
                    leagueId={props?.leagueId}
                    leagueData={props?.leagueData}
                    userCreditBal={props?.userCreditBal}
                    handleNext={handleNext}
                    closePopup={closePopup}
                />
            )}
            {
                showPayment &&
                <PaymentformElements
                    show={showPayment}
                    subData={subData}
                    leagueId={props?.leagueId}
                    leagueData={props?.leagueData}
                    // handleNext={handleNext}
                    closePopup={closePopup}
                />
            }

        </div>
    )
}

export default SubscriptionPayment;