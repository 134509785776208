import React, { useState } from 'react';
import svg from '../../assets/images/svg/index';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './bottomTooltip.scss';

const BottomTooltip = (props) => {
  const [tooltipText, setTooltipTxt]= useState(props?.tooltipText || "Handicap Tiers are subject to change based on registrations and availability at Linx League's Sole Discretion.");

  return (
    <OverlayTrigger
      key="bottom"
      placement="bottom"
      overlay={<Tooltip id={`tooltip-bottom`}>{tooltipText}</Tooltip>}
    >
      <img src={svg?.Info} className={`tooltip-trigger cursor-pointer ${props?.className}`} alt="info NP" />
    </OverlayTrigger>
  );
};

export default BottomTooltip;
