import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { removeAd } from '../../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import './delete.scss';

const DeleteContent = (props) => {

    const handleClose = () => {
        props.handleDelClose();
    }

    const handleDelAccount = async () => {
        let body = {
            id: props?.contentId
        };
        try {
            let resp = await removeAd(body);
            if(resp===undefined){
                toast.success('Ad removed successfully');
                props.handleDelClose();
                window.location.reload()
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='delete-content-wrapper'
            show={props.show}
            onHide={handleClose}
            centered>
            <Modal.Header>
                <Modal.Title className="modalTitle">Remove Ad</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <div className="del-sub">
                    Are you sure you want to Remove Ad?
                </div>
                <div className='del-buttons-wrapper w-100'>
                    <Button
                        onClick={() => handleDelAccount()}
                        className="delBtn w-100">
                        Remove Ad
                    </Button>
                    <Button className="cancelBtn w-100" onClick={handleClose}>
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DeleteContent;
